import axios from "axios"

export default {
  register(credentials) {
    return axios.post("/auth/registration/", credentials)
  },

  login(credentials) {
    return axios.post("/auth/login/", credentials)
  },

  forgot(data) {
    return axios.post("/auth/forgot/", data)
  },

  reset(data) {
    return axios.post("/auth/reset/", data)
  },

  me() {
    return axios.get("/auth/me/")
  },

  logout() {
    return axios.post("/auth/logout/")
  },

  update(data) {
    return axios.patch("/users/", data)
  },

  get(id) {
    return axios.post("/users/info/", { id: id })
  },

  deleteProfileImg() {
    return axios.delete("/users/profileimg/")
  },

  garminOauthUrl() {
    return axios.get('/garmin/oauth_url/')
  }
}
