<template>
  <div class="windchart mt-5">
    <h1 class="is-size-4" v-if="this.tag === 'T'">Tacking Improvement</h1>
    <h1 class="is-size-4" v-if="this.tag === 'G'">Gybing Improvement</h1>
    <apexchart v-if="improvement" type="line" :options="options" :series="series">
    </apexchart>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import {linearRegression, linearRegressionLine, rSquared} from 'simple-statistics'
export default {
  name: 'TackingImprovement',
  props: ['start', 'end', 'tag', 'boatclass', 'user'],
  components: {
    'apexchart': VueApexCharts,
  },
  data(){
    return {
      improvement: {
      },
    }
  },
  mounted(){
    this.getMetrics()
  },
  methods:{
    getMetrics(){
      const url = `/reports/improvement/?start=${this.start}&end=${this.end}&tag=${this.tag}&class=${this.boatclass}&user=${this.user}`
      axios.get(url).then(response => {
        this.improvement = response.data
      })
    },
    calcRegression(series){
      const coords = series.filter(point => point.y != null).map(point => {
        return [point.x.getTime(), point.y]
      })
      var lr = linearRegression(coords)
      var lrfunc = linearRegressionLine(lr)
      var rLine = []
      for (var d = new Date(this.start); d <= new Date(this.end); d.setDate(d.getDate() + 1)){
        rLine.push({x: new Date(d), y: lrfunc(d.getTime())})
      }
      var rise = rLine[1].y - rLine[0].y
      var run = new Date(rLine[1].x).getTime() / 86400000 - new Date(rLine[0].x).getTime() / 86400000  // 86400000 miliseconds in a day
      return {line: rLine, percentage: ((rise / run) * 100).toFixed(2) }
    }
  },
  computed:{
    options(){
      return {
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          decimalsInFloat: 2,
          title: {
            text: 'Manuever Loss (Meters)'
          }
        },
        stroke: {
          dashArray: [0, 0, 0, 0, 2, 2, 2, 2],
          width: [5, 5, 5, 5, 2, 2, 2, 2]
        },
        markers: {
          size: [5, 5, 5, 5, 0, 0, 0, 0],
          showNullDataPoints: true,
        },
        colors:['#008FFB', '#00E396', '#FEB019', '#FF4560'],
        tooltip: {
          shared: false,
          intersect: true,
        },
      }
    },
    series(){
      let light = []
      let sf  = []
      let df = []
      let windy = []
      Object.entries(this.improvement).forEach(entry => {
        light.push({x: new Date(entry[0]), y: entry[1].Light})
        sf.push({x: new Date(entry[0]), y: entry[1].Sf})
        df.push({x: new Date(entry[0]), y: entry[1].Df})
        windy.push({x: new Date(entry[0]), y: entry[1].Windy})
      })
      const lightRegression = this.calcRegression(light)
      const sfRegression = this.calcRegression(sf)
      const dfRegression = this.calcRegression(df)
      const windyRegression = this.calcRegression(windy)
      return [
        {name: 'Light', data: light, type: 'scatter'},
        {name: 'Sf', data: sf, type: 'scatter'},
        {name: 'Df', data: df, type: 'scatter'},
        {name: 'Windy', data: windy, type: 'scatter'},
        {name: `${lightRegression.percentage}%`, data: lightRegression.line, type: 'line'},
        {name: `${sfRegression.percentage}%`, data: sfRegression.line, type: 'line'},
        {name: `${dfRegression.percentage}%`, data: dfRegression.line, type: 'line'},
        {name: `${windyRegression.percentage}%`, data: windyRegression.line, type: 'line'},
    ]
    }
  },
  watch:{
    start(){
      this.getMetrics()
    },
    end(){
      this.getMetrics()
    },
    boatclass(){
      this.getMetrics()
    },
    user(){
      this.getMetrics()
    }
  }
}
</script>
<style>
  .windchart {
    max-width: 500px;
    text-align: center;
  }
</style>
