<template>
  <layout>
    <div class="container full-height px-4 pb-4">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <!-- Reverted Navigation Tabs -->
          <div class="tabs">
            <ul>
              <router-link tag="li" :to="{name: 'account'}"><a>Profile</a></router-link>
              <li class="is-active"><a>Garmin</a></li>
              <router-link tag="li" :to="{name: 'plan'}"><a>Plan</a></router-link>
              <router-link tag="li" :to="{name: 'beta'}"><a>Beta Features</a></router-link>
            </ul>
          </div>

          <!-- Garmin Connect Section -->
          <div class="box has-background-light padded-container">
            <img src="@/assets/icons/Garmin-Logo.png" alt="Garmin Logo" style="width: 150px; margin-right: 10px;">
            <p class="is-size-5 has-text-weight-semibold">
              Sync your Garmin Tracks with Racing Alpha
            </p>
            <p class="mb-4">
              Login to your Garmin Connect account to automatically sync your Garmin sailing tracks with Racing Alpha.
            </p>

            <!-- Garmin Connect Button with Logo -->
            <div class="has-text-centered">
              <a :href="oauthUrl">
                <b-button :disabled="user.garmin_connected" class="button is-link is-medium">
                  Garmin Connect
                </b-button>
              </a>
            </div>

            <!-- Connection Status -->
            <p v-if="user.garmin_connected" class="mt-4 has-text-success">
              Garmin Connected. To disconnect, please remove Racing Alpha from your account on garmin.com.
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer-links />
  </layout>
</template>

<script>
import layout from "@/views/layouts/Default";
import footerLinks from "@/components/shared/footer-links";
import api from "@/services/api/auth";
import { mapState } from "vuex";

export default {
  name: 'Garmin',
  components: {
    layout,
    footerLinks
  },
  data() {
    return {
      oauthUrl: '',
    }
  },
  created() {
    if (!this.user.garmin_connected) {
      api.garminOauthUrl().then(response => {
        this.oauthUrl = response.data;
      });
    }
  },
  computed: {
    ...mapState({
      user(state) {
        return state.auth.user;
      }
    })
  }
}
</script>

<style scoped>
.padded-container {
  padding: 2rem;
}
.button img {
  vertical-align: middle;
}
.has-text-success{
  color: #005aa8;
}
</style>
