import { render, staticRenderFns } from "./AttachWindModal.vue?vue&type=template&id=5b8b2241&scoped=true&"
import script from "./AttachWindModal.vue?vue&type=script&lang=js&"
export * from "./AttachWindModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8b2241",
  null
  
)

export default component.exports