<template>
  <div class="card card-default">
    <div class="card-header" :class="{ separator: separator }">
      <div class="card-title">{{ title }}</div>
    </div>
    <div class="card-block">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    title: {
      type: String,
      default: "",
    },
    separator: {
      type: Boolean,
      default: false,
    },
    bg: {
      type: String,
      default: "white",
    },
  },
}
</script>

<style lang="sass" scoped>
.card
  display: flex
  flex-direction: column
  -webkit-transition: all 0.2s ease
  transition: all 0.2s ease
  background-color: $white
  flex-basis: 500px
  padding: 20px
  border: 1px solid $border-color
  .card-header
    padding-bottom: 20px
    margin-bottom: 10px
    // &.separator
    //   border-bottom: 1px solid $border-color
    .card-title
      font-size: 20px
</style>
