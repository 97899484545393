<template>
  <layout>
    <div v-if="race">
      <div class="race-map-container" v-if="race.wind_estimates">
        <mapbox
          access-token="pk.eyJ1Ijoib2xla3NhbmRyemlib3JvdiIsImEiOiJjazU1N2RjbTAwYXJ4M25tcTVpOHM4MzRhIn0.5zAQ6BGd72UXtVzs0wurFA"
          :map-options="{
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [
              firstTrack.trackpoints.length > 0
                ? firstTrack.trackpoints[0].lon
                : 0,
              firstTrack.trackpoints.length > 0
                ? firstTrack.trackpoints[0].lat
                : 0,
            ],
            bearing: initialWindDir,
            zoom: 14,
            pitchWithRotate: false,
          }"
          :nav-control="{
            show: true,
            options: {
              showCompass: true,
              showZoom: false,
              visualizePitch: false,
            },
          }"
          :scale-control="{
            show: true,
            position: 'top-right',
          }"
          :fullscreen-control="{
            show: true,
            position: 'top-right',
          }"
          @map-load="load"
          @map-rotate="rotate"
        />
        <div class="race-map-overlay-tag-area">
          <RaceTabs :showgold="raceJournal.length > 0">
            <template v-slot:boatdatalist>
              <BoatDataList
                v-if="indicators"
                :races="raceTracks"
                :indicators="indicators || []"
                :modes="getModes"
                :selectedMode="selectionMode"
                :followedBoatIndex="followedBoatIndex"
                @setFollowedBoatIndex="setFollowedBoatIndex"
              />
            </template>
            <template v-slot:goldstar>
              <GoldStarList :trackJournal="raceJournal" @goldStarClicked="setClickedGoldStar" @goldStarHover="goldStarHover"></GoldStarList>
            </template>
          </RaceTabs>

          <div class="map-overlay-add-boat" style="display: none">
            <div class="add-icon">+</div>
            <h6 class="add-boat-text">add boat</h6>
          </div>

        </div>
        <WindDirection
          v-if="user.show_wind_arrow && windEstimates"
          :currentPoint="currentWindEstimate"
          :mapRotation="mapRotation"
          :initialWindDir="initialWindDir"
          measure="ewd"
        />
        <ColorBox
          v-if="race && dataMode"
          :minMaxValues="getMinMax"
          :selectionMode="selectionMode"
        />
        <div class="map-player-container">
          <div class="start-buttons-container">
            <div class="start-button" @click="onPause">
              <i
                v-if="pause"
                class="fa fa-play"
                aria-hidden="true"
                style="color: white; font-size: 16px"
              >
              </i>
              <i
                v-if="!pause"
                class="fa fa-pause"
                aria-hidden="true"
                style="color: white; font-size: 16px"
              >
              </i>
            </div>
            <div v-if="xSpeed" class="x-speed" @click="changeSpeed">
              {{ xSpeed[currentSpeed] }}x
            </div>
          </div>
          <TrackChart
            :tracks="tracks"
            :currentTime="currentTime"
            :map="map"
            :pause="pause"
            :startTime="startTime"
            :endTime="endTime"
            :selectionMode="selectionMode"
            :lastDataMode="lastDataMode"
            :modes="getModes"
            :colors="colors"
            :units="units"
            :speed="xSpeed[currentSpeed]"
            :hoverTime="hoverTime"
            :indicators="indicators"
            :changeMathType="changeMathType"
            :mathType="mathType"
            :selectedMathType="selectedMathType"
            :getAggregates="getAggregates"
            :highlightedGoldStar="highlightedGoldStar"
            :clickedGoldStar="clickedGoldStar"
            currentBoat="0"
            :race_id="id"
            :trackJournal="raceJournal"
            @setCurrentTime="setCurrentTime"
            @isLoading="isLoading"
            @setOpacityTime="updateHover"
            @mouseLeft="mouseLeft"
            @selectSection="selectSection"
            @setClickedTime="moveToStartpoint"
            @toggleIsShowGoldStar="toggleIsShowGoldStar"
            @goldStarClicked="setClickedGoldStar"
            @goldStarSaved="setClickedGoldStar"
            @onPause="onPause"
          />
          <div class="extra-buttons-container">
            <div class="extra-button">
              <p>Data Mode</p>
              <div class="select uppercase">
                <select v-model="selectionMode">
                  <option
                    class="uppercase"
                    v-for="(mode, index) in getModes"
                    v-bind:value="mode"
                    :key="index"
                  >
                    {{ mode }}
                  </option>
                </select>
              </div>
            </div>
            <div class="extra-button" @click="tailToggle">
              <template v-if="tail">
                <p>tail</p>
                <img src="../../assets/icons/tail.png" width="50" />
              </template>
              <template v-else>
                <p>full tail</p>
                <img src="../../assets/icons/fulltail.png" width="50" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      style="position: absolute; left: calc(50% - 75px); top: calc(50vh - 75px)"
    >
      <img src="../../assets/icons/loading.gif" width="100" height="100" />
    </div>
  </layout>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import Mapbox from "mapbox-gl-vue";
import BoatDataList from "@/views/tracks/boat-data-list.vue";
import WindDirection from "@/views/tracks/trackMap/wind-direction.vue";
import TrackChart from "@/components/track-chart";
import ColorBox from "@/components/color-box";
import RaceTabs from "@/views/race/raceMap/race-tabs.vue"
import GoldStarList from "@/views/tracks/trackMap/gold-star-list.vue"
import { mapActions, mapState, mapGetters } from "vuex";
import {
  nearBy,
} from "@/services/map/animation.js";

import layout from "@/views/layouts/Default.vue";
import { Hooper, Navigation as HooperNavigation, Slide } from "hooper";
import "hooper/dist/hooper.css";
import {
  COLORS,
} from "@/constants";
import { MapMixin } from "@/mixins/MapMixin";

export default {
  name: "RaceMap",
  components: {
    BoatDataList,
    WindDirection,
    Mapbox,
    layout,
    Hooper,
    Slide,
    HooperNavigation,
    TrackChart,
    ColorBox,
    RaceTabs,
    GoldStarList,
  },
  props: ["id"],
  mixins: [MapMixin],
  data() {
    return {
      myUser: null,
      start: [],
      expand: false,
      c_time: 0,
      selectionMode: "sog",
      checkNames: [],
      loading: true,
      colors: COLORS,
      startTime: null,

      ACord: [],
      maxValue: 0,

      currentBoat: 0,
      mapRotation: 0,
    };
  },
  created() {
    this.map = null;
    this.myUser = this.$store.getters["auth/user"];
    const share_id = this.$route.query.share_id;
    this.fetchRace({ id: this.id, share_id: share_id });
    this.$store.dispatch("journal/fetchRaceJournal", {
      race_id: this.id,
      share_id: share_id,
    });
  },

  beforeDestroy() {
    this.clearRace();
  },

  computed: {
    ...mapGetters({
      firstTrack: "races/firstRaceTrack",
      race: "races/getRace",
      raceTracks: "races/getRaceTracks",
      raceJournal: "journal/getRaceJournal",
      user: "auth/user",
    }),

    currentWindEstimate() {
      // get the wind estimate object closest to the current time
      if (!this.windEstimates) {
        return null;
      }
      let time = this.currentTime;
      let currWindEstimate = this.windEstimates[time];
      if (!currWindEstimate) {
        let windEstimateTime = nearBy(Object.keys(this.windEstimates), time);
        currWindEstimate = this.windEstimates[windEstimateTime];
      }
      return currWindEstimate;
    },

    windEstimates() {
      // turn the races list of wind estimates into an object
      // of wind estimates keyed by timestamp
      let windEstimates = null;
      try {
        windEstimates = Object.keys(this.race.wind_estimates);
      } catch (error) {
        return null;
      }
      let flatEstimates = windEstimates.reduce((obj, est_id) => {
        let est = this.race.wind_estimates[est_id];
        const d = new Date(est.time).getTime();
        obj[d] = est;
        return obj;
      }, {});
      return flatEstimates;
    },

    initialWindDir() {
      let race = this.race;
      try {
        let windEstimates = Object.keys(this.race.wind_estimates);
        let firstId = windEstimates[0];
        let wind_dir = this.race.wind_estimates[firstId].ewd;
        return wind_dir;
      } catch (error) {
        return 0;
      }
    },

    getModes() {
      if (this.raceTracks) {
        let modes_set = new Set();
        modes_set.add("none");
        this.raceTracks.forEach((track, i) => {
          let first_trackpoint = track.trackpoints[0];
          let keys = Object.keys(first_trackpoint);
          let modes = keys.filter((key) => first_trackpoint[key] !== null);
          let excluded_modes = new Set(["id", "lat", "lon", "time"]);
          modes = modes.filter((mode) => !excluded_modes.has(mode));
          modes.forEach((mode) => modes_set.add(mode));
        });
        modes_set.add("tags");
        return Array.from(modes_set);
      } else {
        return ["none", "sog", "cog", "tags"];
      }
    },

  },

  methods: {
    rotate(map, e) {
      this.mapRotation = (map.getBearing() - this.initialWindDir) % 360;
      this.mapTrackerWithBoat.setRotate();
    },

    isLoading(loading) {
      this.loading = loading;
    },

    load(map) {
      if (map) {
        this.map = map;
        this.tracks = [...this.raceTracks];
        this.loadMap();
        if (this.linkedJournal) {
          this.setClickedGoldStar(this.linkedJournal);
          return;
        }
        let startTime = new Date(this.tracks[0].trackpoints[0].time).getTime();
        this.setCurrentTime(startTime);
      }
    },

    ...mapActions("races", ["fetchRace", "clearRace"]),
  },
};
</script>

<style lang="sass">
.race-map-container
  display: flex
  flex-direction: column
  height: calc(100vh - 60px)
  position: relative
  overflow-y: hidden

  #map
    height: 100%

  .player-container
    display: flex
    align-items: stretch
    background-color: #dee7fd
    flex-direction: column
    padding-top: 30px
    padding-bottom: 30px

    .play_tail_cont
      display: flex
      justify-content: center

      .button-tail
        background-color: $facebook
        height: 60px
        width: 60px
        border-radius: 50%
        -webkit-transition: all 0.12s ease
        transition: all 0.12s ease
        color: $white

        &:hover
          background-color: $facebook-hover

      .button-pause
        background-color: $facebook
        height: 60px
        width: 60px
        border-radius: 50%
        -webkit-transition: all 0.12s ease
        transition: all 0.12s ease
        color: $white

        &:hover
          background-color: $facebook-hover

      .inputSlider
        width: 50%
        margin: 10px

  .marker
    background-image: url('/mapbox-icon.png')
    background-size: cover
    width: 50px
    height: 50px
    border-radius: 50%
    cursor: pointer

  .boat2
    background-image: url('/boat2.ico')
    background-size: cover
    width: 48px
    height: 48px

  #fit
    display: block
    position: relative
    margin: 0px auto
    width: 50%
    height: 40px
    padding: 10px
    border: none
    border-radius: 3px
    font-size: 12px
    text-align: center
    color: #fff
    background: #ee8a65

  // map overlay
  .map-overlay-selector
    position: absolute
    bottom: 130px
    right: 0px
    padding: 10px
    font-size: 12px

    .map-overlay-inner
      background-color: #fff
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
      border-radius: 3px
      padding: 10px
      margin-bottom: 10px

      display: flex
      flex-direction: column
      align-items: center

      fieldset
        width: 160px
        border: none
        padding: 0

        &:last-child
          margin: 0

      select
        margin-bottom: 10px
        width: 100%

      label
        display: block
        font-weight: bold
        margin: 0 0 5px

  .map-overlay-track-title
    width: 250px
    font-size: 12px
    position: absolute
    top: 10px
    left: 10px
    background-color: #fff
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
    border-radius: 3px
    padding: 10px
    text-align: center

  .race-map-overlay-tag-area
    font-size: 12px
    position: absolute
    top: 10px
    left: 10px
    border-radius: 3px
    display: flex
    flex-direction: column
    align-items: center

    @media (max-width: 767px)
      display: none

    .map-overlay-add-boat
      display: flex
      align-items: center
      padding: 8px 15px
      border-radius: 40px
      background-color: white
      box-shadow: 1px 3px 2px rgba(0,0,0,0.25)
      cursor: pointer
      align-self: flex-start

      .add-icon
        padding: 0px 10px
        background: #176CDF
        border-radius: 50%
        color: white
        font-size: 22px
        margin-right: 10px

      .add-boat-text
        margin: 0
        font-size: 15px
        text-transform: uppercase

    .map-overlay-inner
      display: flex
      flex-direction: column
      align-items: center
      width: 100%

      .hooper
        padding-top: 30px
        padding-bottom: 30px

        &:focus
          outline: none

        .hooper-list
          button
            padding: 0px

            &:focus
              outline: none

          .hooper-slide
            background-color: $bg-color
            margin-bottom: 5px
            border: 1px solid $border-color
            border-radius: 4px
            overflow: hidden
            display: flex

            .slide-flex-container
              display: flex
              width: 100%

              &:hover
                cursor: pointer

              .hooper-slide-image
                width: 110px
                height: 100%
                flex-shrink: 0
                padding: 5px

                img
                  height: 100%
                  width: 100%
                  border-radius: 3px

              .hooper-slide-description
                width: 100%
                padding: 5px
                display: flex
                flex-direction: column

                .segment-name
                  padding-bottom: 5px

      fieldset
        width: 160px
        display: flex
        flex-direction: column
        align-items: center
        border: none
        padding: 0

        &:last-child
          margin: 0

      select
        margin-bottom: 10px
        width: 100%

      label
        display: block
        font-weight: bold
        margin: 0 0 5px

    button
      width: 100%

.map-player-container
  display: flex
  background-color: #dee7fd
  flex-direction: column
  flex-direction: row !important
  align-items: center !important
  position: relative
  z-index: 4

  @media (max-width: 767px)

    flex-direction: column-reverse !important

  > div.start-buttons-container
    padding-left: 20px
    padding-right: 20px

    @media (max-width: 767px)
      position: absolute
      top: -26px
      z-index: 1
      left: calc(50% - 40px)
      display: flex
      align-items: center

    .start-button
      display: flex
      background: #176CDF
      border-radius: 50%
      width: 48px
      height: 48px
      align-items: center
      justify-content: center
      box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.25)
      cursor: pointer

      @media (max-width: 767px)
        margin-right: 10px

    .x-speed
      color: #176CDF
      font-size: 18px
      font-weight: bold
      line-height: 1
      text-transform: none
      text-align: center
      margin-top: 10px
      cursor: pointer

      @media (max-width: 767px)
        margin-top: 25px

  .chart
    background-color: white
    flex: 1
    position: relative
    display: flex

    @media (max-width: 767px)
      flex-direction: column-reverse
      width: 100%

    & > div:first-child
      flex: 1

    & > div.selects
      padding: 10px

      .select-mode
        text-transform: uppercase

        @media (max-width: 767px)
          margin-bottom: 10px

      > div
        @media (max-width: 767px)
          width: 100% !important
          display: flex

  .extra-buttons-container
    display: flex
    text-align: center
    flex-direction: column
    height: 100%

    @media (max-width: 767px)
      flex-direction: row
      width: 100%
      justify-content: space-between

    .extra-button
      flex: 1 1
      padding: 4px 10px
      cursor: pointer

      align-items: center
      justify-content: center
      padding: 10px 10px
      color: #176CDF
      display: flex
      font-size: 12px
      line-height: 1.3
      flex-direction: column
      font-weight: bold
      text-transform: capitalize

      p
        margin-top: 10px
        margin-bottom: 5px
        white-space: nowrap
        @media (max-width: 767px)
          margin: 0

      @media (max-width: 767px)
        width: 100%
        flex: 0
        justify-content: center

      &:hover
        background-color: rgba(47, 128, 237, 0.29)

.track-info-card-boat
  width: 20px
  height: 20px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  margin-right: 5px

  img
    transform: translate(-2px, 0px)
    width: 20px
    height: 20px

.chart-header
  display: flex
  justify-content: space-between
  padding-bottom: 10px
  padding-top: 10px

  .y-axios-value
    text-transform: uppercase
    margin-left: 0px
    color: black
    font-size: 14px
    font-weight: bold
    width: 60px
    margin-left: 10px

  .yot-max-values
    display: flex
    align-items: center
    font-size: 16px
    font-weight: bold
    color: black

    @media (max-width: 767px)
      text-align: center
      justify-content: center
      margin-bottom: 20px

    .math-type
      display: inherit
      margin-left: 20px

      li
        font-size: 14px
        font-weight: 100
        color: #4a4a4a
        cursor: pointer

        &.selected-math-type
          color: black
          font-weight: 500

        &:hover
          text-decoration: underline

      li+li
          border-left: 1px solid #4a4a4a
          margin-left: 5px
          padding-left: 5px

  @media (max-width: 767px)
    flex-direction: column-reverse
</style>

<style lang="sass" scoped>
select,
select option
  text-transform: uppercase

.map-player-container
  .chart
    flex: 1
    position: relative
    display: flex

    & > div:first-child
      flex: 1
</style>
