<template>
  <layout>
    <div class="container is-fluid full-height">
      <!-- Loading spinner until the track is available -->
      <div v-if="!track">
        <div class="spinner-container">
          <p><i class="fa fa-spinner fa-spin"></i></p>
        </div>
      </div>

      <!-- Display track details when available -->
      <div v-else>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="is-size-3">Track Report</h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <h1 class="title is-1">{{ track.id }}</h1>
            </div>
          </div>
        </div>

        <h4 class="is-size-4">{{ track.full_name }}</h4>
        <p>
          {{ track.start_time | moment("M/D/YYYY") }}<br />
          {{ track.location }}
        </p>

        <div class="columns">
          <div class="column">
            <dl v-if="track.metric">
              <dt>DISTANCE</dt>
              <dd>{{ distance }} mi</dd>
              <dt>TWA</dt>
              <dd>{{ track.metric.average_wind_direction }} °</dd>
              <dt>DURATION</dt>
              <dd>{{ duration }}</dd>
              <dt>MAX SPEED</dt>
              <dd>{{ track.metric.max_speed }} kts</dd>
            </dl>
          </div>
          <div class="column">
            <dl>
              <dt>TACKS</dt>
              <dd>{{ track.metric.tacks }}</dd>
              <dt>SETS</dt>
              <dd>{{ track.metric.sets }}</dd>
              <dt>GYBES</dt>
              <dd>{{ track.metric.gybes }}</dd>
              <dt>DOUSES</dt>
              <dd>{{ track.metric.douses }}</dd>
            </dl>
          </div>
        </div>

        <!-- Wind Conditions -->
        <div v-if="track.wind_report && track.wind_report.metrics">
          <h2 class="is-size-4">Wind Conditions</h2>
          <dl>
            <dt>Average TWD</dt>
            <dd>{{ track.wind_report.metrics.avg_twd.toFixed(2) }}°</dd>
            <dt>Average TWS</dt>
            <dd>{{ track.wind_report.metrics.avg_tws.toFixed(2) }} knots</dd>
            <dt>Direction Stability (Std TWD)</dt>
            <dd>{{ track.wind_report.metrics.std_twd.toFixed(2) }}</dd>
            <dt>Pressure Stability (Std TWS)</dt>
            <dd>{{ track.wind_report.metrics.std_tws.toFixed(2) }}</dd>
            <!-- Add more wind metrics as needed -->
          </dl>
        </div>
        <div v-else-if="track.wind_report">
          <p>Wind data is processing...</p>
        </div>
        <div v-else>
          <p>No wind data available.</p>
        </div>

      </div>
    </div>
  </layout>
</template>

<script>
import axios from 'axios';

export default {
  name: "ReportSummary",
  props: ["track"], // Receives track as a prop from the parent component
  data() {
    return {
      windReport: null,
    };
  },
  computed: {
    has_maneuver_angle() {
      return (
        this.track.metrics.length > 0 &&
        this.track.metrics[0].maneuver_angle !== null
      );
    },
    avg_tack_rate() {
      return this.avg_turn_rate("T");
    },
    avg_gybe_rate() {
      return this.avg_turn_rate("G");
    },
    avg_tacking_angle() {
      return this.avg_maneuver_angle("T");
    },
    avg_gybing_angle() {
      return this.avg_maneuver_angle("G");
    },
    distance() {
      return this.track.metric.distance
        ? (this.track.metric.distance * 0.0006213712).toFixed(2)
        : 0;
    },
    duration() {
      if (this.track.metric.duration) {
        let wallTime = new Date(null);
        wallTime.setSeconds(this.track.metric.duration);
        return wallTime.toISOString().substr(11, 8);
      } else {
        return "0:00";
      }
    },
  },
  methods: {
    avg_maneuver_angle(tag) {
      let maneuver_metrics = this.track.metrics.filter(
        (metric) => metric.label === tag
      );
      if (maneuver_metrics.length === 0) return 0;
      let angles = maneuver_metrics.map((metric) => metric.maneuver_angle);
      let avg_angle = angles.reduce((a, b) => a + b, 0) / angles.length;
      return avg_angle.toFixed(2);
    },
    avg_turn_rate(tag) {
      let maneuver_metrics = this.track.metrics.filter(
        (metric) => metric.label === tag
      );
      if (maneuver_metrics.length === 0) return 0;
      let rates = maneuver_metrics.map((metric) => metric.avg_turn_rate);
      let avg_rate = rates.reduce((a, b) => a + b, 0) / rates.length;
      return avg_rate.toFixed(2);
    },
    async fetchWindReport() {
      if (this.track.wind_file) {
        try {
          const windFileId = this.track.wind_file.id;
          // Fetch wind reports associated with this wind file
          const response = await axios.get(
            `${process.env.VUE_APP_API}/windfiles/${windFileId}/reports/`,
            {
              headers: {
                Authorization:
                  "Bearer " + this.$store.getters["auth/user"].access,
              },
            }
          );
          if (response.data && response.data.length > 0) {
            // For simplicity, take the first report
            this.windReport = response.data[0];
          }
        } catch (error) {
          console.error("Error fetching wind report:", error);
        }
      }
    },
  },
  mounted() {
    this.fetchWindReport();
  },
};
</script>

<style scoped>
.spinner-container {
  width: 100%;
  height: 100vh; /* Ensures the container takes full viewport height */
  display: flex; /* Switch to Flexbox */
  justify-content: center; /* Centers horizontally */
}
</style>
