<template>
  <div>
    <div class="container full-height">
      <nav>
        <div class="level mb-5">
          <div class="level-left">
            <div class="level-item">
              <h1 class="is-size-3">Track List</h1>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-field>
                <b-select v-if="user" v-model="selectedmode">
                  <option value="all">Team Tracks</option>
                  <option value="all" v-if="user.role === 1">All Tracks</option>
                  <option value="me">My Tracks</option>
                </b-select>
              </b-field>
            </div>

            <div class="level-item">
              <b-field>
                <b-input
                  v-model="searchText"
                  @change.native="search"
                  type="text"
                  icon="search"
                  :icon-right="searchText !== '' ? 'times' : ''"
                  icon-right-clickable
                  @icon-right-click="clearSearch"
                  ref="searchBar"
                  expanded
                >
                </b-input>
                <p class="control">
                  <b-button v-bind:class="{activeFilter: showFilterDates}" @click="toggleFilter" icon-left="filter" />
                </p>
              </b-field>
            </div>
          </div>
        </div>

        <div class="level mb-5" v-if="showFilterDates">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">
              <b-field>
                <b-datepicker
                  class="custom-date-picker"
                  v-model="filterDates"
                  @input="dateFilter"
                  :range="true"
                  :mobile-native="false"
                  placeholder="Select dates"
                  icon="calendar-alt"
                  :min-date="oldestDate"
                  :max-date="new Date()"
                ></b-datepicker>
                <!-- Work around because @icon-right-click -->
                <!-- doesn't work on b-datepicker -->
                <div v-if="filterDates" class="clear-container" @click="clearDates">
                  <b-icon class="date-clear" icon="times" />
                </div>
              </b-field>
            </div>
          </div>
        </div>
      </nav>
      <Track
        v-for="track in boatTracks"
        :track="track"
        :key="`track-${track.id}`"
        :windfiles="windfiles"
        @show-journal="journal"
        @trackUpdated="reloadTracks"
      />
      <div
        v-if="noResults"
        class="is-flex is-flex-direction-column is-align-items-center"
      >
        <div class="z-stack mb-4">
          <b-icon icon="search" size="is-large" />
          <b-icon icon="times" class="x" />
        </div>
        <h4 class="is-size-4">No Tracks Match Your Search</h4>
        <p>Please try another search.</p>
      </div>
    </div>
    <b-modal v-model="showJournal" id="md-journal">
      <div class="modal-card-body">
        <Journal></Journal>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import RaceForm from "@/views/race/RaceForm.vue";
import Race from "@/views/tracks/Race.vue";
import Track from "@/views/tracks/Track.vue";
import layout from "@/views/layouts/Default.vue";
import Journal from "@/views/journal/Journal.vue";
import Modal from "@/components/ui/Modal.vue";
import moment from "moment";

export default {
  name: "TracksList",
  components: {
    Track,
    layout,
    Journal,
    Race,
    RaceForm,
    Modal,
  },

  data() {
    return {
      selectedmode: "all",
      showJournal: false,
      activities: [
        { id: 1, title: "All activities" },
        { id: 2, title: "My activities" },
      ],
      errors: {
        download: null,
      },
    };
  },
  beforeCreate() {
    this.$store.dispatch("tracks/getTracks", { reset: true });
    this.$store.dispatch("windfiles/getWindfiles")
  },
  created() {
    let oldestDate = this.$store.state.tracks.oldestDate
    if(!oldestDate) {
      this.$store.dispatch("tracks/getOldestDate")
    }
  },
  methods: {
    journal(trackId) {
      this.$store.dispatch("journal/setJournalTrackId", trackId);
      this.showJournal = true;
    },
    search() {
      // unfocus the search bar
      this.$refs.searchBar.$refs.input.blur();
      // get tracks with the search
      this.$store.dispatch("tracks/getTracks", { reset: true });
    },
    clearSearch() {
      this.searchText = ''
      this.search()
    },
    toggleFilter() {
      if (this.showFilterDates) {
        this.filterDates = null;
        this.dateFilter();
      }
      this.showFilterDates = !this.showFilterDates;
    },
    dateFilter() {
      // get tracks with the new date range
      this.$store.dispatch("tracks/getTracks", { reset: true });
    },
    clearDates() {
      console.log("clearDates")
      this.filterDates = null;
      this.dateFilter();
    },
    reloadTracks() {
      this.$store.dispatch("tracks/getTracks", { reset: true });
    },
  },
  destroyed() {
    window.onscroll = () => {};
  },
  mounted() {
    window.onscroll = () => {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        this.$store.dispatch("tracks/getTracks", { reset: false });
      }
    };
  },
  watch: {
    selectedmode() {
      this.$store.commit("tracks/SET_MYTRACKS", this.selectedmode);
      this.$store.dispatch("tracks/getTracks", { reset: true });
    },
    showJournal() {
      // might have fetched tracks when creating regatta report, if didn't finish then reset
      this.$store.dispatch("tracks/setFilterDates", null);
      this.$store.dispatch("tracks/setFilterUsers", null);
      this.$store.dispatch("tracks/getTracks", {reset: true})
    }
  },
  computed: {
    ...mapGetters({
      tracks: "tracks/getAllTracks",
      myTracks: "tracks/myTracks",
      user: "auth/user",
      windfiles: "windfiles/getWindfiles",
    }),

    searchText: {
      get() {
        return this.$store.state.tracks.searchText;
      },
      set(value) {
        this.$store.dispatch("tracks/setSearchText", value);
      },
    },

    showFilterDates: {
      get() {
        return this.$store.state.tracks.showFilterDates
      },
      set(newVal) {
        this.$store.dispatch("tracks/setShowFilterDates", newVal)
      },

    },

    filterDates: {
      get() {
        let date_strings = this.$store.state.tracks.filterDates;
        if (!date_strings) {
          return date_strings
        }
        // transform back into dates
        return date_strings.map(str => new Date(str)) 
      },
      set(newDates) {
        let dates = newDates;
        if (dates) {
          dates = newDates.map((date) => date.toISOString());
        }
        this.$store.dispatch("tracks/setFilterDates", dates);
      },
    },

    oldestDate() {
      let oldestDate = this.$store.state.tracks.oldestDate
      if (!oldestDate) {
        return null
      }
      return moment(oldestDate).toDate() 
    },

    boatTracks() {
      return this.tracks.filter((track) => track.boat_name);
    },
    noResults() {
      let searched = this.searchText !== ''
      let filtered = this.filterDates !== null
      let emptyResults = this.tracks.length === 0
      // if we have searched or filtered and there
      // are no results
      return (searched || filtered) && emptyResults
    },
  },
};
</script>

<style lang="sass">
#md-race-create
  .ch-modal-content
    width: 100%
    max-width: 750px

.activeFilter
  color: $complete-active !important
</style>

<style scoped>
.z-stack {
  position: relative;
  width: 80px;
  height: 50px;
}

.z-stack > * {
  position: absolute;
  top: 0;
  margin: auto;
  left: 50%;
  transform: translate(-50%);
}

.x {
  top: 9px;
  left: 37px;
}

.clear-container {
  position: relative;
}

.date-clear {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  right: 0;
  bottom: 0;
  color:#dbdbdb;
  z-index: 4;
}
.custom-date-picker {
  width: 259px;
}
</style>
