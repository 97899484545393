<template>
  <b-modal
    v-model="show"
    has-modal-card
    trap-focus
    @close="closeModal"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Upload Wind File (NMEA)</p>
        <button
          type="button"
          class="delete"
          @click="closeModal"/>
      </header>
      <section class="modal-card-body">
        <form class="account-form" @submit.prevent="upload">
          <b-field label="Wind File" :message="errors.fileAdded"
                   :type="errors.fileAdded ? 'is-danger' : null "
          >
            <vue-dropzone
              ref="myVueDropzone2"
              id="dropzone2"
              :awss3="awss3"
              :key="componentKey"
              v-on:vdropzone-sending="sendingEvent"
              @vdropzone-file-added="vfileAdded"
              @vdropzone-removed-file="vremoved"
              @vdropzone-s3-upload-error="s3UploadError"
              @vdropzone-s3-upload-success="s3UploadSuccess"
              :options="dropzoneOptions"
            >
            </vue-dropzone>
          </b-field>
          <b-field>
            <b-button
              class="is-primary button button-submit btn-info"
              native-type="submit"
              value="Upload"
            >
              <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
              Upload
            </b-button>
          </b-field>
        </form>
      </section>
    </div>
  </b-modal>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { required } from "vuelidate/lib/validators";
import api from "@/services/api/tracks";
import alertHelper from "@/services/helpers/alertHelper";

export default {
  name: "AddWindModal",
  props: ['show'],
  validations: {
    fileAdded: { required },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data: function() {
    return {
      errors: {},
      fileAdded: null,
      sending: false,
      componentKey: 0,
      isLoading: false,
      s3WindUrl: "",
      params: {},
      filePath: "",
      fileName: ""
    }
  },
  computed: {
    awss3() {
      return {
        signingURL: `${process.env.VUE_APP_API}/windfiles/path/`,
        headers: {
          Authorization: "Bearer " + this.$store.getters["auth/user"].access,
        },
        params : {},
        sendFileToServer : false,  // don't also upload to server -> double check https://github.com/rowanwins/vue-dropzone/pull/294
      }
    },
    dropzoneOptions() {
      return {
        url: process.env.VUE_APP_API + "/windfiles/",
        thumbnailWidth: 150,
        dictDefaultMessage: "Drop or click to select .txt NMEA files.",
        headers: {
          // Authorization: "Bearer " + this.$store.getters["auth/user"].access,
        },
        maxFiles: 1,
        acceptedFiles: ".txt",
        addRemoveLinks: false,
        autoProcessQueue: false,
        timeout: 300000,
        // method: 'PUT'
      }
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    vremoved(file, xhr, error) {
      this.fileAdded = false;
      // window.toastr.warning('', 'Event : vdropzone-removedFile')
    },
    vfileAdded(file) {
      this.fileAdded = true;
      this.fileName = file.name;
    },
    upload: function (event) {
      this.errors = {
        fileAdded: null,
        permissions: null,
      };
      if (!this.$v.$invalid) {
        this.$refs.myVueDropzone2.processQueue();
      } else {
        if (!this.fileAdded) this.errors.fileAdded = "Track File required";
      }
    },
    sendingEvent(file, xhr, formData) {
      this.isLoading = true;
      this.sending = true;
    },
    endUploading() {
      if (this.sending == true) {
        this.$store.dispatch("windfiles/getWindfiles");
        this.$router.push({
          name: "wind",
        });
      } else {
        this.forceRerender();
      }
      this.closeModal();
    },
    s3UploadError(errorMessage) {
      this.isLoading = false;
      this.isSending = false;
      this.$emit('error', errorMessage)
    },
    s3UploadSuccess(s3ObjectLocation) {
      api.windFileUploaded(decodeURIComponent(s3ObjectLocation.split('.com/')[1]), this.fileName).then(() => {
        this.endUploading()
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error)
      });
    },
    closeModal() {
      this.isLoading = false;
      this.isSending = false;
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>