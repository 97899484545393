<template>
  <div class="windchart mt-5">
    <h1 class="is-size-4">Time spent on the water</h1>
    <apexchart type="radialBar" :options="options" :series="series"></apexchart>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
export default {
  name: 'WindCondtitions',
  props: ['start', 'end', 'boatclass', 'user'],
  components: {
    'apexchart': VueApexCharts
  },
  data(){
    return {
      conditions: {
        total_time: 0,
      }
    }
  },
  mounted(){
    this.getMetrics()
  },
  methods:{
    getMetrics(){
      const url = `/reports/windconditions/?start=${this.start}&end=${this.end}&class=${this.boatclass}&user=${this.user}`
      axios.get(url).then(response => {
        this.conditions = response.data
      })
    }
  },
  computed:{
    options(){
      return {
        labels: this.labels,
        plotOptions:{
          radialBar:{
            dataLabels:{
              value:{
                show: true,
                formatter: ((v) => {
                  return `${(v / 100 * this.conditions.total_time / 3600).toFixed(2)} hours`
                })
              },
              total:{
                show: true,
                formatter: ((w) => {
                  return `${(this.conditions.total_time / 3600).toFixed(2)} hours`
                })
              }
            }
          }
        }
      }
    },
    series(){
      if(this.conditions.time_in_conditions){
        return Object.entries(this.conditions.time_in_conditions).map(entry =>{
          if(entry[1] > 0){
            return (entry[1] / this.conditions.total_time * 100).toFixed(2)
          }else{
            return 0
          }
        })
      }else{
        return []
      }
    },
    labels(){
      if(this.conditions.time_in_conditions){
        return Object.entries(this.conditions.time_in_conditions).map(entry =>{
          return entry[0]
        })
      }else{
        return []
      }
    }
  },
  watch:{
    start(){
      this.getMetrics()
    },
    end(){
      this.getMetrics()
    },
    boatclass(){
      this.getMetrics()
    },
    user(){
      this.getMetrics()
    }
  }
}
</script>
<style>
  .windchart {
    max-width: 500px;
    text-align: center;
  }
</style>
