<template>
  <layout>
    <div class="container">
      <!-- Create the Tabs -->
      <b-tabs v-model="activeTab">
        <b-tab-item label="Summary">
          <!-- Summary Tab -->
          <ReportSummary :track="track" />
        </b-tab-item>
        <b-tab-item label="Tacking">
          <!-- Tacking Tab -->
          <Tacking :track="track" />
        </b-tab-item>
        <b-tab-item label="Gybing">
          <!-- Gybing Tab -->
          <Gybing :track="track" />
        </b-tab-item>
        <b-tab-item label="Top Mark">
          <!-- Top Mark Tab -->
          <TopMark :track="track" />
        </b-tab-item>
        <b-tab-item label="Bottom Mark">
          <!-- Bottom Mark Tab -->
          <BottomMark :track="track" />
        </b-tab-item>
        <b-tab-item label="Accelerations">
          <!-- Accelerations Tab -->
          <Accelerations :track="track" />
        </b-tab-item>
      </b-tabs>
    </div>
  </layout>
</template>

<script>
// Importing the components for each tab
import layout from "@/views/layouts/Default.vue";
import ReportSummary from "./reports/ReportSummary.vue";
import Tacking from "./reports/Tacking.vue";
import Gybing from "./reports/Gybing.vue";
import TopMark from "./reports/TopMark.vue";
import BottomMark from "./reports/BottomMark.vue";
import Accelerations from "./reports/Accelerations.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "TrackReport",
  props: ["track_id"],
  components: {
    layout,
    ReportSummary,
    Tacking,
    Gybing,
    TopMark,
    BottomMark,
    Accelerations,
  },
  data() {
    return {
      activeTab: 0, // Control which tab is active, 0 = first tab
    };
  },
  created() {
    this.fetchTrack(this.track_id); // Fetch the track data
    this.user = this.$store.getters["auth/user"];
  },
  beforeDestroy() {
    this.removeTrack(); // Clean up when the component is destroyed
  },
  computed: {
    ...mapState({
      track(state) {
        return state.tracks.track; // Access track data from Vuex store
      },
    }),
  },
  methods: {
    ...mapActions("tracks", ["fetchTrack", "removeTrack"]),
  },
};
</script>

<style scoped>
.spinner-container {
  width: 100vw;
  height: 500px;
  display: grid;
  place-items: center;
}
</style>
