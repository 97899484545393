<template>
  <div class="row">
    <div class="col" v-for="(image, idx) in images" :key="idx">
      <div class="box grid-box">
        <div class="media-img-delete is-rounded" v-if="removeable">
          <a class="has-text-black" @click="deleteMedia(image)"><b-icon icon="times" fixed-width></b-icon></a>
        </div>
        <a :href="image" target="_blank"><img :src="image" /></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImageGrid",
  props: {
    images: {
      type: Array,
      required: true,
    },
    removeable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    deleteMedia(image){
      console.log('in image grid')
      console.log(image)
      this.$emit('delete-media', image)
    }
  }
}
</script>
<style lang="sass">
.grid-box
  padding: 0.5rem !important

.media-img-delete
  background: red
  position: absolute
  z-index: 100
  color: black
  border-radius: 100%

.row
  display: flex
  flex-wrap: wrap
  padding: 0 4px

.col
  flex: 25%
  max-width: 25%
  padding: 0 4px

.col img
  margin-top: 8px
  vertical-align: middle
  width: 100%
  border: 1px solid $dark-grey
</style>
