<template>
  <layout>
    <div class="container">
      <b-tabs v-model="activeTab">
        <b-tab-item label="Tracks">
          <TracksList></TracksList>
        </b-tab-item>
        <b-tab-item label="Races">
          <RaceList></RaceList>
        </b-tab-item>
      </b-tabs>
    </div>
  </layout>
</template>
<script>
import layout from "@/views/layouts/Default.vue"
import TracksList from "@/views/tracks/Tracks"
import RaceList from "@/views/tracks/Races"
export default {
  name: 'Home',
  components: {layout, TracksList, RaceList},
  data(){
    return {
      activeTab: 0
    }
  }
}
</script>
