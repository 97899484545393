const X_Auth_Key = "Authorization"

function axiosMiddleware(Vue, axios, store, router) {
  axios.defaults.baseURL = process.env.VUE_APP_API

  // добавляем токен авторизации
  axios.interceptors.request.use(
    (config) => {
      if (!config.headers.common.hasOwnProperty(X_Auth_Key)) {
        if (store.getters["auth/isAuthenticated"]) {
          const auth_token = store.getters["auth/user"].access

          if (auth_token) {
            config.headers.common[X_Auth_Key] = `Bearer ${auth_token}`
          }
        }
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  axios.interceptors.response.use(
    // обрабатываем результат
    (response) => {
      response.result = response.data

      return Promise.resolve(response)
    },
    // разлогиниваем юзера
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          // 401 Unauthorized
          case 401:
            {
              store.dispatch("auth/logout").then(() => {
                store.dispatch("tracks/clearAll")
                router
                  .push({
                    name: "login",
                  })
                  .catch((error) => {
                    if (error.name != "NavigationDuplicated") {
                      throw error
                    }
                  })
              })

              return Promise.reject(error)
            }
            break

          // 403 Forbidden
          case 403:
            {
              router.push("/")
              return Promise.reject(error)
            }
            break

          default: {
            return Promise.reject(error)
          }
        }
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export default axiosMiddleware
