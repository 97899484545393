import axios from "axios"

export default {
  getRaces() {
    return axios.get("/races/")
  },

  getRace(id, share_id) {
    let url = `/races/${id}/`
    if(share_id != undefined){
      url += `?share_id=${share_id}`
    }
    return axios.get(url)
  },

  getRaceInfo(id) {
    return axios.get(`/races/info/${id}/`)
  },

  getRaceWind(id) {
    return axios.get(`/races/wind/${id}/`)
  },

  createRace(race) {
    return axios.post("/races/", race)
  },

  updateRaceTracks(newRace) {
    return axios.patch(`/races/${newRace.id}/tracks/`, newRace)
  },

  updateRaceName(newRace) {
    return axios.patch(`/races/${newRace.id}/`, newRace)
  },

  deleteRace(id) {
    return axios.delete("/races/" + id + "/")
  },
}
