<template>
  <b-modal :active="show" :on-cancel="close">
    <div v-if="show" class="modal-card-container modal-height">
      <div class="modal-card">
        <div class="modal-card-head">
          <p v-if="race.name" class="modal-card-title is-flex-shrink-1">Manage Tracks for {{race.name}}</p>
          <p v-else class="modal-card-title is-flex-shrink-1">Manage Tracks for Race {{race.id}}</p>
        </div>
        <section class="modal-card-body">
          <h4 class="subtitle is-4">Tracks from {{ raceDayTimestamp | moment("M/D/YYYY") }}</h4>
          <b-checkbox v-for="track in dayTracks" :key="track.id"
            v-model="selectedTracks"
            :native-value="track.id"
          >
            {{ track.id }} - {{ track.full_name }}, {{ track.boat_name }}, 
            {{track.start_time | moment("h:mm a")}} - {{track.end_time | moment("h:mm a")}} 
          </b-checkbox>
        </section>
        <footer class="modal-card-foot">
          <div class="footer-container">
            <b-button type="is-primary" @click="save"> Save </b-button>
            <b-button type="is-danger" @click="close"> Cancel </b-button>
            <p style="color: red;" v-if="showError">{{errors.save}}</p>
          </div>
        </footer>
      </div>
    </div>
  </b-modal>
  
</template>

<script>
import trackApi from "@/services/api/tracks.js"
import moment from "moment"
export default {
  props: {
    race: Object,
    show: Boolean,
  },
  data() {
    return {
      // tracks from the race day
      dayTracks: [],
      selectedTracks: [],
      initialSelectedTracks: [],
      errors: {},
      showError: false,
    }
  },
  created() {
    this.selectedTracks = this.race.track.map(track => track.id)
    this.initialSelectedTracks = [...this.selectedTracks]
  },
  computed: {
    raceDayTimestamp() {
      if(!this.show) {
        return null
      }
      return this.race.track[0].start_time
    },
  },
  methods: {
    setDayTracks() {
      trackApi.getDayTracks('', 'all', this.raceDayTimestamp)
        .then((res) => {
          this.dayTracks = res.data.results
        })
    },
    close() {
      this.$emit('close')
    },
    sameTracks() {
      // See if the same tracks are selected
      let selectedSet = new Set(this.selectedTracks)
      let initialSet = new Set(this.initialSelectedTracks)
      let areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));
      return areSetsEqual(selectedSet, initialSet)
    },
    save() {
      if (this.selectedTracks.length < 2) {
        this.errors.save = "Please select at least 2 tracks."
        this.showError = true
        return
      }
      if (this.sameTracks()) {
        // no changes were made
        this.close()
        return
      }
      let newRace = Object.assign({}, this.race)
      newRace.track = this.selectedTracks
      this.initialSelectedTracks = [...this.selectedTracks]
      this.$store.dispatch("races/updateRaceTracks", newRace)
      this.close()
    },
  },
  watch: {
    show(val) {
      if(val && this.dayTracks.length === 0) {
        // if modal is shown and no tracks yet
        this.setDayTracks()
      }
    },
    selectedTracks(newVal, oldVal) {
      if (newVal.length >= 2) {
        this.errors = {}
        this.showError = false
      }
    }
  }
}
</script>

<style lang="sass">
.modal-height
  max-height: 65vh
  margin-inline: 1rem
</style>