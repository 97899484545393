<template>
  <div class="map-overlay-tag-area">
    <div class="map-overlay-inner">
      <b-tabs expanded v-model="activeTab">
        <b-tab-item value="data" label="Data">
          <div class="tab-scroll-box">
            <slot name="boatdatalist"></slot>
          </div>
        </b-tab-item>
        <b-tab-item value="goldstar" label="Gold Star" v-if="showgold">
          <div class="tab-scroll-box">
            <slot name="goldstar"></slot>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RaceTabs',
  props: ['showgold'],
  data() {
    return {
      activeTab: 'data'
    }
  }
}
</script>
<style>
.tab-scroll-box {
  overflow: auto;
  max-height: 40vh;
}
</style>
