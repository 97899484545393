<template>
  <layout>
    <div class="columns desktop-container">
      <div class="column is-two-fifths">
        <div class="card">
          <div class="card-content">
            <h3 class="is-size-3">Forgot Your Password?</h3>
            <form class="forgot-form" @submit.prevent="send">
              <b-field label="Email" :message="errors.email" :type="errors.email ? 'is-danger' : null ">
                <b-input type="email" v-model="email" class="email" 
                placeholder="Enter your email">
                </b-input>
              </b-field>
              <div class="buttons">
                <b-button
                  :class="{ 'btn-disabled': $v.$invalid }"
                  class="button button-submit btn-info"
                  native-type="submit"
                  value="Send a Reset Link">
                    Send a Reset Link
                </b-button>
                <b-button tag="router-link" class="button button-submit btn-info" to="/login">Back to Login</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import layout from "@/views/layouts/Default.vue"
import { required, email } from "vuelidate/lib/validators"

export default {
  name: "ForgotPassword",
  components: {
    layout,
  },
  validations: {
    email: { required, email },
  },
  data() {
    return {
      email: null,
      errors: {},
    }
  },
  methods: {
    send() {
      this.errors = {}
      if (!this.$v.$invalid) {
        this.$store.dispatch("auth/forgot", {
          url: process.env.VUE_APP_RESET_URL,
          email: this.email,
        })
      } else {
        if (!this.$v.email.required) this.errors.email = "Email required"
        if (!this.$v.email.email) this.errors.email = "Email not valid"
      }
    },
  },
}
</script>

<style scoped>
.email {
  width: 100%;
  border-radius: 4px;
}
</style>
