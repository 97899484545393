<template>
  <div
    class="alert alert-danger"
    :class="{
      'alert-danger': notification.type == 'error',
      'alert-success': notification.type == 'success',
    }"
  >
    <strong v-if="notification.type == 'error'">Warning: </strong>
    <strong v-if="notification.type == 'success'">Success: </strong>
    <strong v-if="notification.type == 'info'">Information: </strong>
    <span>{{ notification.message }}</span>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "alert-element",
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  methods: {
    ...mapActions("notifications", ["remove"]),
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 3000)
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
}
</script>

<style lang="sass">
/* A L E R T S */
.alert
    //position: absolute
    background-image: none
    box-shadow: none
    text-shadow: none
    padding: 9px 19px 9px 15px
    border-radius: 3px
    font-size: 13px
    border-width: 0
    -webkit-transition: all 0.2s linear 0s
    transition: all 0.2s linear 0s
    margin-bottom: 10px
    user-select: none
    width: 510px
    text-align: center

    &.alert-danger
        background-color: #f2dede
        border-color: #ebcccc
        color: #a94442

    &.alert-warning
        background-color: #fcf8e3
        border-color: #faf2cc
        color: #8a6d3b

    &.alert-success
        background-color: #dff0d8
        border-color: #d0e9c6
        color: #3c763d

    &.alert-info
        background-color: #d9edf7
        border-color: #bcdff1
        color: #31708f
</style>
