import store from "@/store/store"

export default {
  check() {
    const userString = localStorage.getItem("user")
    if (userString) {
      const userData = JSON.parse(userString)
      store.dispatch("auth/autoLogin", userData)
    }
  },
}
