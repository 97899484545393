<template>
  <div class="ch-modal" :class="show ? 'show' : 'hide'" :id="id">
    <div class="ch-modal-content">
      <span class="close" @click="$emit('modal-close')">&times;</span>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",
  props: {
    show: {
      type: Boolean,
    },
    id: {
      type: String,
      default: "myModal",
    },
  },
}
</script>
<style lang="sass">
.hide
    display: none

.show
    display: block

.ch-modal
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: rgb(0,0,0)
  background-color: rgba(0,0,0,0.4)


/* Modal Content/Box */
.ch-modal-content
  background-color: $bg-color
  margin: 5% auto
  padding: 20px
  border: 1px solid $dark-grey
  width: 50%


/* The Close Button */
.close
  color: $black
  float: right
  font-size: 28px
  font-weight: bold


.close:hover,
.close:focus
  color: $black
  text-decoration: none
  cursor: pointer
</style>
