<template>
  <div v-if="currentPoint">
    <div v-if="currentPoint[measure]" class="map-overlay-wind-direction">
      <p><b>{{measure.toUpperCase()}}: {{currentPoint[measure]}}°</b></p>
      <span style="display: inline-block;" class="icon is-large">
          <i :style="style" class="fas fa-3x fa-long-arrow-alt-down"></i>
      </span>
    </div>
  </div>
</template>
<script>
import { angle_diff } from "@/utils"

export default {
  props: [
    "currentPoint",
    "mapRotation",
    "initialWindDir",
    "measure",
  ],

  computed: {
    style() {
        return {
            transform: "rotate(" + this.arrowRotation.toString() + "deg)"
        }
    },
    arrowRotation() {
      if (!this.currentPoint[this.measure]) {
        return 0;
      }
      let wind = this.currentPoint[this.measure]
      let rotation = (this.initialWindDir - wind + this.mapRotation) % 360
      rotation = -1 * rotation
      return rotation 
    },

  },
}
</script>
<style scoped>
.map-overlay-wind-direction {
  z-index: 5;
  width: 90px;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 120px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.icon {
    text-align: center;
}
</style>
