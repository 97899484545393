import Vue from "vue"
import Vuex from "vuex"
import * as races from "@/store/modules/races.js"
import * as tracks from "@/store/modules/tracks.js"
import * as auth from "./modules/auth"
import * as journal from "@/store/modules/journal.js"
import * as teams from "@/store/modules/teams.js"
import * as windfiles from "@/store/modules/windfiles.js"
import notifications from "./modules/notifications"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tracks,
    races,
    auth,
    journal,
    notifications,
    teams,
    windfiles,
  },
  namespace: true,
})
