<template>
  <div>
    <div class="container">
      <h1 class="is-size-3">My Teams</h1>
      <b-table :data="teams" class="big-table">
        <!-- Combined Logo and Name Column -->
        <b-table-column field="name" label="Teams" v-slot="props">
          <div class="team-card">
            <router-link
              class="navigation-link"
              :to="`/teams/${props.row.id}`"
            >
              <div class="team-name">{{ props.row.name }}</div>
              <img :src="props.row.logo" class="team-logo" />
            </router-link>
          </div>
        </b-table-column>

        <!-- Coaches Column -->
        <b-table-column field="coaches" label="Coaches" v-slot="props">
          <span
            v-for="(coach, index) in props.row.coaches"
            :key="coach.id"
          >
            {{ coach.first_name }} {{ coach.last_name }}
            <span v-if="index + 1 < props.row.coaches.length">,</span>
          </span>
        </b-table-column>

        <!-- Athletes Column -->
        <b-table-column field="athletes" label="Athletes" :numeric="true" v-slot="props">
          <div class="athletes-count">
              {{ props.row.athletes.length }}
          </div>
        </b-table-column>

        <!-- Empty State Template -->
        <template #empty>
          <div v-if="isLoading" class="has-text-centered">
            <b-loading :is-full-page="false" :can-cancel="false"></b-loading>
          </div>
          <div v-else>
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  You aren't a member of any teams. You can create a new team with
                  the form on the right.
                </p>
              </div>
            </section>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Loading } from "buefy";

export default {
  name: "TeamList",
  components: {
    'b-loading': Loading
  },
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    this.fetchTeams();
  },
  methods: {
    fetchTeams() {
      this.isLoading = true;
      this.$store
        .dispatch("teams/fetchTeams")
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
      this.$store.dispatch("teams/clearTeam");
    },
  },
  computed: {
    ...mapGetters({
      teams: "teams/getTeams",
    }),
  },
};
</script>


<style scoped>
.team-card {
  text-align: left;
}

.team-name {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.team-logo {
  width: 100px;
  object-fit: cover;
  border-radius: 8px; /* Adjust the value for more or less rounding */
}

.athletes-count {
  text-align: right;
}
</style>
