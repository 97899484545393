<template>
  <layout>
    <div class="columns desktop-container">
      <div class="column is-two-fifths">
        <div class="card">
          <div class="card-content">
            <h3 class="is-size-3">Login</h3>
            <form class="login-form" @submit.prevent="login">
              <b-field label="Email" :message="errors.email" :type="errors.email ? 'is-danger' : null ">
                <b-input type="email" v-model="email" >
                </b-input>
              </b-field>
              <b-field label="Password" :message="errors.password" :type="errors.password ? 'is-danger' : null ">
                <b-input v-model="password" type="password" password-reveal></b-input>
              </b-field>
              <div class="buttons">
                <b-button
                  :class="{ 'btn-disabled': $v.$invalid }"
                  class="button btn-default"
                  native-type="submit"
                  value="Log In">
                    Log In
                </b-button>
                <b-button tag="router-link" class="button button-submit btn-info" to="/register">Sign Up</b-button>
              </div>
              <router-link  to="/forgot">Forgot Password?</router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer-links />
  </layout>
</template>

<script>
import card from "@/components/ui/Card"
import layout from "@/views/layouts/Default"
import footerLinks from "@/components/shared/footer-links"
import { required, email, minLength } from "vuelidate/lib/validators"

export default {
  name: "login-form",
  components: {
    card,
    layout,
    footerLinks,
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(3) },
  },
  data() {
    return {
      email: '',
      password: '',
      errors: {},
    }
  },
  methods: {
    login() {
      this.errors = {}
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("auth/login", {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.$router.push({
              name: "home",
            })
          })
      } else {
        if (!this.$v.email.required) this.errors.email = "Email required"
        if (!this.$v.email.email) this.errors.email = "Email not valid"
        if (!this.$v.password.required)
          this.errors.password = "Password required"
        if (!this.$v.password.minLength)
          this.errors.password = "Password must be 6 characters"
      }
    },
  },
}
</script>
