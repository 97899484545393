<template>
  <form class="journal-form" @submit.prevent="submitForm">
    <span class="validation-error-text">{{ errors.nonField }}</span>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Notes">
        <div class="field">
          <b-checkbox :native-value="{value: 'Local Knowledge'}" v-model="form.tags">Local Knowledge</b-checkbox>
        </div>
        <div class="field">
          <b-checkbox :native-value="{value: 'Speed/Tuning'}" v-model="form.tags">Speed/Tuning</b-checkbox>
        </div>
        <div class="field">
          <b-checkbox :native-value="{value: 'Boat Handling'}" v-model="form.tags">Boat Handling</b-checkbox>
        </div>
      </b-tab-item>
      <b-tab-item label="Regatta Report">
        <div class="columns">
          <div class="column">
            <b-field label="Name">
              <b-input v-model="regatta_name"
                       :required="regatta_name"></b-input>
            </b-field>
            <b-field label="Number of Competitors">
              <b-input placeholder="10"
                       v-model="regatta_num_competitors"
                       type="number"
                       :required="regatta_name"
                       min="1">
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Date">
              <b-datepicker
                placeholder="Click to select..."
                v-model="regatta_dates"
                @input="datepickerChanged"
                required
                range>
              </b-datepicker>
            </b-field>
            <b-field label="Finishing Position">
              <b-input placeholder="1 (hopefully!)"
                       v-model="regatta_finish_pos"
                       type="number"
                       min="1">
              </b-input>
            </b-field>
          </div>
        </div>
        <b-field label="Competitors">
          <div style="display: flex; flex-direction: column">
            <div class="card" v-for="(comp, idx) in regatta_competitors">
              <div class="card-content">
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <!--<b-input placeholder="Name"
                               v-model="comp.name"
                               :key="`${idx}-name`"
                               required>
                      </b-input>-->
                      <b-taginput
                        v-model="comp.name"
                        :allow-new="false"
                        :open-on-focus="true"
                        :data="filteredTags"
                        :has-counter="false"
                        field="full_name"
                        @typing="getFilteredTags"
                        @focus="backupIndex = idx"
                        @blur="setBackupName"
                        @add="datepickerChanged"
                        @remove="datepickerChanged"
                        maxtags="1"
                        autocomplete
                        placeholder="Search for User or Type Name"
                        required
                        :key="idx"
                        >
                      </b-taginput>
                    </div>
                    <div class="column">
                      <b-input placeholder="Finishing Position"
                               v-model="comp.finish_position"
                               type="number"
                               :key="`${idx}-pos`"
                               required
                               min="1">
                      </b-input>
                    </div>
                    <div class="column is-1">
                      <b-button icon-right="trash" @click="removeCompetitor(idx)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-button class="card" icon-right="plus" @click="addCompetitor"/>
          </div>
        </b-field>
        <b-field label="Tracks">
          <div v-if="this.showTracks.length > 0 && this.regatta_dates.length === 2" style="display: flex; flex-direction: column">
            <b-checkbox v-for="track in showTracks" v-model="track.selected"
                        native-value="true" :key="track.id"
                        :disabled="track.id === trackId">
              <Track :track="track" :journalView="true">
              </Track>
            </b-checkbox>
          </div>
          <p v-else>
            Please select a date range with tracks to attach any to regatta report.
          </p>
        </b-field>
      </b-tab-item>
      <b-tab-item label="Kite Notes" v-if="!hideKiteForm">
        <!-- Kite options -->
        <div class="columns">
          <div class="column is-2">
            <p class="is-size-4 mt-4">Kite</p>
           </div>
          <div class="column">
            <b-field label="Brand">
              <b-select v-model="form.kite_brand" expanded>
                <option
                  v-for="brand in kiteBrands"
                  v-bind:key="brand"
                  :value="brand"
                >{{ brand }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Model">
              <b-select v-model="form.kite_model" expanded :disabled="!form.kite_brand">
                <option
                  v-for="model in selectedKiteModels"
                  v-bind:key="model"
                  :value="model"
                >{{ model }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Size">
              <b-select v-model="form.kite_size" expanded :disabled="!form.kite_model">
                <option
                  v-for="size in selectedKiteSizes"
                  v-bind:key="size"
                  :value="size"
                >{{ size }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>


        <!-- Foil options -->
        <div class="columns">
          <div class="column is-2">
            <p class="is-size-4 mt-4">Foil</p>
           </div>
          <div class="column">
            <b-field label="Brand">
              <b-select v-model="form.foil_brand" expanded>
                <option
                  v-for="brand in foilBrands"
                  v-bind:key="brand"
                  :value="brand"
                >{{ brand }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Model">
              <b-select v-model="form.foil_model" expanded :disabled="!form.foil_brand">
                <option
                  v-for="model in selectedFoilModels"
                  v-bind:key="model"
                  :value="model"
                >{{ model }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Wing">
              <b-select v-model="form.foil_wing" expanded :disabled="!form.foil_model">
                <option
                  v-for="wing in selectedFoilWings"
                  v-bind:key="wing"
                  :value="wing"
                >{{ wing }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>

        <b-field label="Feel">
            <b-slider v-model="form.feel" :min="1" :max="5" aria-label="Feel" :tooltip="false">
                <b-slider-tick :value="1">Bad</b-slider-tick>
                <b-slider-tick :value="2">2</b-slider-tick>
                <b-slider-tick :value="3">3</b-slider-tick>
                <b-slider-tick :value="4">4</b-slider-tick>
                <b-slider-tick :value="5">Great</b-slider-tick>
            </b-slider>
        </b-field>
      </b-tab-item>
    </b-tabs>
    <b-field>
      <b-input type="textarea"
        v-model="form.content"
        rows="7"
        placeholder="Lessons Learned"
      >
      </b-input>
    </b-field>
    <div>
      <span class="validation-error-text">{{ errors.content }}</span>
    </div>
    <div class="field">
      <vue-dropzone
        ref="journalDropzone"
        id="dropzone"
        @vdropzone-sending="sendingEvent"
        @vdropzone-queue-complete="queueComplete"
        :options="dropzoneOptions"
      >
      </vue-dropzone>
    </div>
    <ImageGrid :images="photoUrls" @delete-media="removeImage" :removeable="true"></ImageGrid>
    <b-button type="is-primary" native-type="submit">Save Journal</b-button>
  </form>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";
import ImageGrid from "@/components/ui/ImageGrid";
import api from "@/services/api/journal";
import trackApi from "@/services/api/tracks";
import axios from "axios";
import moment from "moment";
import Track from "@/views/tracks/Track";

const PHOTO_BASE_URL = process.env.VUE_APP_API + "/journals/"

export default {
  name: "JournalForm",
  components: {
    vueDropzone: vue2Dropzone, ImageGrid, Track
  },
  props: {
    initial: {
      type: Object,
      default(){
        return {
          content: '',
          tags: [],
          track: null,
          race: null,
          photos: [],
          kite_brand: '',
          kite_model: '',
          kite_size: '',
          foil_brand: '',
          foil_model: '',
          foil_wing: '',
          feel: -1,
        }
      }
    }
  },
  data() {
    return {
      form: {...this.initial},
      content: "",
      tags: [],
      dropzoneOptions: {
        url: "changeme",
        thumbnailWidth: 150,
        dictDefaultMessage: "Drag and Drop Images",
        headers: {
          Authorization: "Bearer " + this.$store.getters["auth/user"].access,
        },
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        autoProcessQueue: false,
      },
      errors: {
        content: null,
        nonField: null,
      },
      activeTab: 0,
      hideKiteForm: true,
      boats: [],
      regatta_name: null,
      regatta_dates: [],
      regatta_finish_pos: null,
      regatta_num_competitors: null,
      regatta_competitors: [],
      showTracks: [],
      filteredTags: [],
      backupName: "",
      backupIndex: 0,
      storedTrackSelections: {},
    }
  },
  mounted(){
    trackApi.getTrack(this.trackId, true).then(response => {
      const boat_name = response.data.boat_name.toUpperCase()
      this.hideKiteForm = boat_name.indexOf('KITE') < 0
    })
    axios.get('/boats').then(result => {
      this.boats = result.data
    }).catch(e => {
      console.log(e)
    })
    this.$store.dispatch("auth/me"); // refresh teammates
  },
  methods: {
    submitForm() {
      this.errors = {
        content: null,
        nonField: null,
      }
      this.submitJournal()
        .then((result) => {
          // reset tracks
          this.$store.dispatch("tracks/setFilterDates", null);
          this.$store.dispatch("tracks/setFilterUsers", null);
          this.$store.dispatch("tracks/getTracks", {reset: true})

          if (this.$refs.journalDropzone.getAcceptedFiles().length > 0) {
            this.$refs.journalDropzone.setOption(
              "url",
              PHOTO_BASE_URL + result.data.id + "/photo/"
            )
            this.$refs.journalDropzone.processQueue()
          } else {
            this.queueComplete(result.data)
          }
        })
        .catch((error) => {
          this.errors.nonField = error
        })
    },
    submitJournal() {
      if(this.form.id){
        return api.updateJournal(this.journalPayload, this.journalPayload.id)
      }else{
        return api.createJournal(this.journalPayload)
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("file", file)
    },
    queueComplete(journal) {
      if(this.journalTrackId){
        this.$store.dispatch("journal/fetchTrackJournal")
        this.$store.dispatch("tracks/getTracks", {reset: true})
      }
      this.reset()
      this.$emit("journal-created", journal)
    },
    reset() {
      this.form.content = ""
      this.form.tags = []
      this.regatta_name = null,
      this.regatta_dates = []
      this.regatta_finish_pos = null
      this.regatta_num_competitors = null
      this.regatta_competitors = []
      this.showTracks = []
      this.storedTrackSelections = {}
      this.$refs.journalDropzone.removeAllFiles()
    },
    removeImage(image){
      const photo = this.form.photos.find((p) => p.file === image);
      api.deleteJournalPhoto(this.form.id, photo.id).then(() => {
        this.form.photos.splice(this.form.photos.indexOf(photo), 1);
      })
    },
    addCompetitor() {
      // backup name is so that if a name is added but not made a tag, we can store the text there
      this.regatta_competitors.push({name: null, backup_name: null, finish_position: ""});
    },
    removeCompetitor(idx) {
      this.regatta_competitors.splice(idx, 1);
    },
    datepickerChanged() {
      if (this.regatta_dates.length === 2) {
        this.$store.dispatch("tracks/setFilterDates", this.regatta_dates.map(x => x.toISOString()));
        this.$store.dispatch("tracks/setFilterUsers", [this.user.id, ...this.regatta_competitors
          .flatMap(x => (x.name && x.name.length === 1) ?  x.name[0].id : null).filter(x => (x))
        ]);

        for (const track of this.showTracks) {
          this.storedTrackSelections[track.id] = track.selected
        }
        this.$store.dispatch("tracks/getTracks", { reset: true });
      }
    },
    getFilteredTags(text) {
      this.backupName = text
      this.filteredTags = this.teammatesWithFullName.filter(x => {
        this.$emit('test', [text, x])
        return x.full_name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    setBackupName() {
      this.regatta_competitors[this.backupIndex].backup_name = this.backupName
    },
  },
  computed: {
    ...mapGetters({
      journalTrackId: "journal/getJournalTrackId",
      tracks: "tracks/getAllTracks",
      user: "auth/user",
    }),
    photoUrls() {
      return this.form.photos.map((photo) => photo.file)
    },
    trackId(){
      if(this.form.track){
        return this.form.track.id
      }else{
        return this.journalTrackId
      }
    },
    journalPayload() {
      let isKiteNote = this.activeTab === 2
      let kiteData = {}
      if (isKiteNote) {
        kiteData = {
          kite_brand: this.form.kite_brand,
          kite_model: this.form.kite_model,
          kite_size: this.form.kite_size,
          foil_brand: this.form.foil_brand,
          foil_model: this.form.foil_model,
          foil_wing: this.form.foil_wing,
          feel: this.form.feel,
          jType: 'kite',
        }
      }
      let obj = {
        id: this.form.id,
        track: this.trackId,
        content: this.form.content,
        tags: this.form.tags,
        ...kiteData,
      }
      if (this.regatta_name) {
        let regattaData = {
          name: this.regatta_name,
          start_date: moment(this.regatta_dates[0]).toISOString(),
          end_date: moment(this.regatta_dates[1]).toISOString(),
          number_competitors: this.regatta_num_competitors,
          finish_position: this.regatta_finish_pos,
          tracks: this.showTracks.filter(x => x.selected).map(x => x.id)
        }
        const results = this.regatta_competitors.map((x) => {
          if (!x.name) { // if text was not turned into a tag
            return {name: x.backup_name, finish_position: x.finish_position}
          } else if (typeof x.name[0] === 'string') { // if tag, but not user, not currently using (as allow-new is off)
            return {name: x.name[0], finish_position: x.finish_position}
          } else { // tag and user
            return {user: x.name[0].id, finish_position: x.finish_position}
          }
        })
        regattaData.competitor_results = results
        obj.regatta_report = regattaData
      }
      return obj
    },
    kiteBrands(){
      return new Set(this.boats.filter(boat => {
        return boat.boat_type === 'Kite'
      }).map(boat => {
        return boat.brand
      }))
    },
    foilBrands(){
      return new Set(this.boats.filter(boat => {
        return boat.boat_type === 'Foil'
      }).map(boat => {
        return boat.brand
      }))
    },
    selectedKiteModels(){
      return this.boats.filter(boat => {
        return boat.brand === this.form.kite_brand && boat.boat_type === 'Kite'
      }).map(boat => {
        return boat.name
      })
    },
    selectedFoilModels(){
      return this.boats.filter(boat => {
        return boat.brand === this.form.foil_brand && boat.boat_type === 'Foil'
      }).map(boat => {
        return boat.name
      })
    },
    selectedKiteSizes(){
      const boat = this.boats.find(boat => {
        return boat.brand === this.form.kite_brand && boat.boat_type === 'Kite' && boat.name === this.form.kite_model
      })
      return boat ? boat.sizes_or_wings : []
    },
    selectedFoilWings(){
      const boat = this.boats.find(boat => {
        return boat.brand === this.form.foil_brand && boat.boat_type === 'Foil' && boat.name === this.form.foil_model
      })
      return boat ? boat.sizes_or_wings : []
    },
    teammatesWithFullName() {
      return this.user.teammates.map(x => {
        return {
          ...x,
          full_name: x.first_name + " " + x.last_name
        }
      })
    },
  },
  watch: {
    kite_brand(){
      this.form.kite_model = ''
    },
    kite_model(){
      this.form.kite_size = ''
    },
    foil_brand(){
      this.form.foil_model = ''
    },
    foil_model(){
      this.form.foil_wing = ''
    },
    tracks() {
      this.showTracks = this.tracks.map((x) => {
        if (x.id in this.storedTrackSelections) {
          return {
            ...x,
            selected: this.storedTrackSelections[x.id]
          }
        } else {
          return {
            ...x,
            selected: true
          }
        }
      })
    }
  }
}
</script>
<style lang="sass">
  input[type=checkbox]
    margin-right: 5px
  .card:not(:last-child)
    margin-bottom: 0.75em
  .b-checkbox:not(:last-child)
    margin-bottom: 0.75em
</style>
