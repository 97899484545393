<template>
  <div class="maxwidth">
    <div class="mb-1">
      <div class="level">
        <div class="level-right">
          <h4 class="is-size-3">Journal Entries</h4>
        </div>
        <div class="level-left">
            <b-input v-if="showadd" class="level-item" placeholder="Search" id="search" @input="debounceSearch" v-model="searchInput" type="search"></b-input>
            <b-button v-if="showadd" @click="addNewEntry" class="level-item" icon-right="plus"></b-button>
        </div>
      </div>
      <div v-show="journals.length < 1">
        <p>No journal entries match those search terms.</p>
      </div>
    </div>
    <JournalDetail
      @delete-journal="deleteJournal"
      :journal="journal"
      v-for="journal in journals"
      :key="journal.id"
    >
    </JournalDetail>
    <b-modal v-model="showCreate">
      <div class="modal-card-body">
        <JournalForm @journal-created="journalCreated"></JournalForm>
       </div>
    </b-modal>
  </div>
</template>
<script>
import JournalDetail from "@/views/journal/JournalDetail.vue"
import JournalForm from "@/views/journal/JournalForm.vue"
import debounce from "lodash.debounce"
export default {
  name: "JournalList",
  components: {
    JournalDetail, JournalForm
  },
  props: {
    journals: {
      type: Array,
      required: true,
    },
    showadd: {
      type: Boolean,
      required: false,
      default: false
    }

  },
  data() {
    return {
      search: "",
      searchInput: '',
      showCreate: false
    }
  },
  destroyed() {
    window.onscroll = () => {}
  },
  mounted() {
    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
      if (bottomOfWindow) {
        this.$store.dispatch('journal/fetchJournals', {reset: false, search: this.search})
      }
    }
  },
  methods: {
    deleteJournal(journalId) {
      this.$store.dispatch("journal/deleteJournal", journalId)
    },
    journalCreated(journal){
      this.showCreate = false
      this.$store.dispatch("journal/fetchJournals", {reset: true, search: this.search})
    },
    addNewEntry(){
      this.$store.dispatch("journal/clearJournalTrack")
      this.showCreate = true
    },
    debounceSearch(){
      debounce(() => {
        this.search = this.searchInput
      }, 500)()
    }
  },
  watch:{
    search(){
      this.$store.dispatch('journal/fetchJournals', {reset: true, search: this.search})
    }
  }
}
</script>
<style lang="sass">
.maxwidth
  width: 100%
</style>
