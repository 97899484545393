export function getColorForPercentage(pct) {
  const percentColors = [
    { pct: 0.0, color: { r: 0xa1, g: 0x00, b: 0xc7 } },
    { pct: 0.2, color: { r: 0x32, g: 0x4f, b: 0xd4 } },
    { pct: 0.4, color: { r: 0x48, g: 0xc9, b: 0x1f } },
    { pct: 0.6, color: { r: 0xe0, g: 0xd1, b: 0x00 } },
    { pct: 0.8, color: { r: 0xff, g: 0x17, b: 0x00 } },
    { pct: 1.0, color: { r: 0x79, g: 0x00, b: 0x00 } },
  ]

  let i
  for (i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break
    }
  }
  let lower = percentColors[i - 1]
  let upper = percentColors[i]
  let range = upper.pct - lower.pct
  let rangePct = (pct - lower.pct) / range
  let pctLower = 1 - rangePct
  let pctUpper = rangePct
  let color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  }
  return "rgb(" + [color.r, color.g, color.b].join(",") + ")"
}
export function colorScale(track, mode, min, max) {
  let features = []
  track.forEach((t, i) => {
    if (i < track.length - 1) {
      let value = Math.abs(t[mode])
      let ratio = value / max
      const feature = {
        type: "Feature",
        properties: {
          color: getColorForPercentage(ratio)
        },
        geometry: {
          type: "LineString",
          coordinates: [
            [t.lon, t.lat],
            [track[i + 1].lon, track[i + 1].lat]
          ]
        }
      }
      features.push(feature)
    }
  })
  return features
}