<template>
  <layout>
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-two-fifths">
          <div class="card">
            <div class="card-content">
              <h3 class="is-size-3">Create Account</h3>
              <form class="registration-form" @submit.prevent="register">
                <b-field grouped group-multiline>
                  <b-field label="First Name" :message="errors.first_name" :type="errors.first_name ? 'is-danger' : null ">
                    <b-input type="text" v-model="first_name">
                    </b-input>
                  </b-field>
                  <b-field label="Last Name" :message="errors.last_name" :type="errors.last_name ? 'is-danger' : null ">
                    <b-input type="text" v-model="last_name">
                    </b-input>
                  </b-field>
                </b-field>
                <b-field label="Email" :message="errors.email" :type="errors.email ? 'is-danger' : null ">
                  <b-input type="email" v-model="email">
                  </b-input>
                </b-field>
                <b-field label="Password" :message="errors.password" :type="errors.password ? 'is-danger' : null ">
                  <b-input v-model="password" type="password" password-reveal></b-input>
                </b-field>
                <b-field label="Confirm Password" :message="errors.password_confirmation" :type="errors.password_confirmation ? 'is-danger' : null ">
                  <b-input v-model="password_confirmation" type="password" password-reveal></b-input>
                </b-field>
                <b-field label="Primary Boat Class" :message="errors.primary_boat_class"
                :type="errors.primary_boat_class ? 'is-danger' : null ">
                  <b-select
                    v-model="primary_boat_class"
                  >
                    <option
                      v-for="(option, index) in boatClasses"
                      :value="option"
                      :key="index"
                    >
                      {{ option }}
                    </option>
                  </b-select>
                </b-field>
                <div class="buttons">
                  <b-button
                    :class="{ 'btn-disabled': $v.$invalid }"
                    class="button button-submit btn-info"
                    native-type="submit"
                    value="Sign Up">
                      Sign Up
                  </b-button>
                </div>
                <router-link  to="/login">Already have an acount?</router-link>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer-links />
    </div>
  </layout>
</template>

<script>
import card from "@/components/ui/Card"
import { required, email, minLength, sameAs } from "vuelidate/lib/validators"
import layout from "@/views/layouts/Default"
import footerLinks from "@/components/shared/footer-links"
export default {
  name: "registration",
  components: {
    card,
    layout,
    footerLinks,
  },
  validations: {
    first_name: { required, minLength: minLength(3) },
    last_name: { required, minLength: minLength(3) },
    email: { required, email },
    password: { required, minLength: minLength(3) },
    password_confirmation: { sameAsPassword: sameAs("password") },
    primary_boat_class: {required}
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      password_confirmation: null,
      primary_boat_class: '',
      boatClasses: ["29er", "470", "49er", "49erFX", "C420", "CFJ", "Formula Kite", "i420", "J70", "Laser", "Nacra 17", "Other"],
      errors: {},
    }
  },
  methods: {
    register() {
      this.errors = {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
      }
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("auth/register", {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            primary_boat_class: this.primary_boat_class,
          })
          .then(() => {
            this.$router.push({
              name: "upload",
            })
          })
          .catch((error) => {
            for (var key in error.response.data.errors) {
              if (key == "first_name")
                this.errors.first_name = Object.values(
                  error.response.data.errors.first_name
                ).toString()
              if (key == "last_name")
                this.errors.last_name = Object.values(
                  error.response.data.errors.last_name
                ).toString()
              if (key == "email")
                this.errors.email = Object.values(
                  error.response.data.errors.email
                ).toString()
              if (key == "password") {
                this.errors.password = Object.values(
                  error.response.data.errors.password
                ).toString()
                this.errors.password_confirmation = Object.values(
                  error.response.data.errors.password
                ).toString()
              }
            }
          })
      } else {
        if (!this.$v.first_name.required)
          this.errors.first_name = "First name required"
        if (!this.$v.first_name.minLength)
          this.errors.first_name = "First name must be 3 characters"
        if (!this.$v.last_name.required)
          this.errors.last_name = "Last name required"
        if (!this.$v.last_name.minLength)
          this.errors.last_name = "Last name must be 3 characters"
        if (!this.$v.email.required) this.errors.email = "Email required"
        if (!this.$v.email.email) this.errors.email = "Email not valid"
        if (!this.$v.password.required)
          this.errors.password = "Password required"
        if (!this.$v.password.minLength)
          this.errors.password = "Password must be 3 characters"
        if (!this.$v.password_confirmation.sameAsPassword)
          this.errors.password_confirmation = "Password mismatch"
        if (!this.$v.primary_boat_class.required)
          this.errors.primary_boat_class = "Primary Boat Class required"
      }
    },
  },
}
</script>
