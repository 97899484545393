import axios from 'axios';

export default {
  getPrices() {
    const user = localStorage.getItem('user');
    if (user) {
      const token = JSON.parse(user).access;
      return axios.get('/billing/prices/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      console.error('No user found in localStorage');
    }
  },
  getSubs() {
    const token = JSON.parse(localStorage.getItem('user')).access;
    return axios.get('/billing/subs/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  cancelSub() {
    const token = JSON.parse(localStorage.getItem('user')).access;
    return axios.post('/billing/cancel/', {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  initCheckout(price_id) {
    const token = JSON.parse(localStorage.getItem('user')).access;
    return axios.get(`/billing/init-checkout/?price_id=${price_id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  checkoutResultCheck(sessionId) {
    const token = JSON.parse(localStorage.getItem('user')).access;
    return axios.get(`/billing/checkout-result/?checkout_session_id=${sessionId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};
