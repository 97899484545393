import store from "@/store/store"
import router from "@/router/router"
import api from "@/services/api/races.js"
import alertHelper from "@/services/helpers/alertHelper"
import { getCoordinates } from "@/utils"

export const namespaced = true

export const state = {
  allRaces: [],
  race: null,
}


function collapseTracks(tracks){
  let newTracks = []
  let userIds = []
  tracks.forEach(track => {
    if (!userIds.includes(track.user.id)){
      userIds.push(track.user.id)
      newTracks.push(track)
    } else {
      let trackIndex = userIds.indexOf(track.user.id)
      const numPreviousTrackpoints = newTracks[trackIndex].trackpoints.length;
      newTracks[trackIndex].trackpoints.push(...track.trackpoints)
      // merge racepoints if they exist
      if (track.racepoints) {
        newTracks[trackIndex].racepoints.push(...track.racepoints)
      }
      // Merge segments if they exist.
      if (track.segments)  {
        const modifiedSegments = modifySegmentIndexes(track.segments, numPreviousTrackpoints);
        newTracks[trackIndex].segments.push(...modifiedSegments);
      }
    }
  })
  return newTracks
}

  // go through each segment and modify it's start and end indexes.
/**
 * Go through each segment and modify
 * the start and finish trackpoint indexes
 * to account for the number of previous trackpoints.
 * @param {Segment[]} segments The segments to modify.
 * @param {number} numPreviousTrackpoints The number of trackpoints from
 * previous tracks with the same user.
 * @returns {Segment[]}
 */
function modifySegmentIndexes(segments, numPreviousTrackpoints) {
  const modifiedSegments = segments.map(segment => {
    segment.start += numPreviousTrackpoints;
    segment.finish += numPreviousTrackpoints;
    return segment;
  })
  return modifiedSegments;
}

function addRacePoints(race) {
  // Add ewd, etwa, vmg, lvg
  // to trackpoints
  return race.track.map(track => {
    if (track.racepoints.length === 0) {
      // track has no race points
      return track;
    }
    let newTrackpoints = track.trackpoints.map((trackpoint, i) => {
      let racePoint = track.racepoints[i];
      let windEstimate = race.wind_estimates[racePoint.race_wind_estimate];
      // new trackpoint with added data
      let newTrackpoint = {
        ...trackpoint,
        ewd: windEstimate.ewd,
        etwa: racePoint.etwa,
        vmg: racePoint.vmg,
        lvg: racePoint.lvg,
      };
      return newTrackpoint;
    });
    // return a newTrack with modified trackpoints
    let newTrack = {
      ...track,
      trackpoints: newTrackpoints,
    };
    return newTrack;
  });
}

export const mutations = {
  SET_RACES(state, races) {
    state.allRaces = races
  },
  UPDATE_RACE_TRACKS(state, race) {
    state.allRaces = state.allRaces.map(listedRace => {
      if(listedRace.id === race.id) {
        return race
      } else {
        return listedRace
      }
    })
  },
  SET_RACE(state, race) {
    race.track = collapseTracks(race.track)
    race.track = addRacePoints(race);
    state.race = {
      ...state.race,
      ...race,
    }
  },
  DELETE_RACE(state, race) {
    if (state.race && race.id === state.race.id) {
      state.race = null
    }
    state.allRaces = state.allRaces.filter((t) => t.id !== race.id)
  },
  CLEAR_RACE(state) {
    state.race = null
  },
}

export const actions = {
  createRace({ commit, dispatch }, race) {
    api
      .createRace(race)
      .then((response) => {
        console.log("response of the creation of the item----", response)
        commit("SET_RACE", response);
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem creating race:" + error.response,
        })
      })
  },
  updateRaceTracks({ commit, dispatch }, newRace) {
    api
      .updateRaceTracks(newRace)
      .then((response) => {
        commit("UPDATE_RACE_TRACKS", response.data.race);
        alertHelper.successNotification(dispatch, "Race tracks successfully updated.")
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem updating race tracks:" + error.response,
        })
      })
  },
  updateRaceName({ commit, dispatch}, newRace) {
    api
      .updateRaceName(newRace)
      .then((response) => {
        commit("UPDATE_RACE_TRACKS", response.data.race);
        alertHelper.successNotification(dispatch, response.data.detail)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem updating the race name:" + error.response,
        })
      })
      
  },
  getRaces({ commit, dispatch }) {
    api
      .getRaces()
      .then((response) => {
        commit("SET_RACES", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem fetching races:" + error.response,
        })
      })
  },
  deleteRace({ commit, getters, dispatch }, race) {
    api.deleteRace(race.id).then((response) => {
      if (response.status === 204) {
        commit("DELETE_RACE", race)
        alertHelper.successNotification(dispatch, "Race has been deleted.")
      } else {
        console.log({ type: "error", message: "Could not remove race." })
      }
    })
  },
  async fetchRace({ commit, getters, dispatch }, params) {
    api
      .getRace(params.id, params.share_id)
      .then(async (response) => {
        if (response.data.id) {
          commit("SET_RACE", response.data)
          if (response.data.processing_status === 1) {
            alertHelper.successNotification(
              dispatch,
              "Wind data is processing."
            )
          }
        } else {
          alertHelper.successNotification(
            dispatch,
            response.data.detail
          )
          router.push({
            name: "tracks",
          })
        }
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem fetching Race:" + error.response,
        })
      })
  },
  clearRace({ commit }) {
    commit("CLEAR_RACE")
  },
}

export const getters = {
  getAllRaces(state) {
    return state.allRaces
  },
  firstRaceTrack(state) {
    return state.race ? state.race.track[0] : null
  },
  getRaceTracks() {
    return state.race ? state.race.track : []
  },
  getRace(state) {
    return state.race
  },
}
