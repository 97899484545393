<template>
  <layout>
    <div class="container is-fluid full-height">
      <div v-if="Object.keys(allSelectedSegments).length > 0">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="section-header">
                <h1 v-if="analysisName" class="is-size-3">
                  Report: {{ analysisName }}
                  <b-icon icon="pen"
                          style="font-size: 0.6em; vertical-align: middle !important;"
                          @click.native="showNameModal = true"
                  />
                </h1>
                <h1 v-else class="is-size-3">
                  Straight Line Analysis Report
                  <b-icon icon="pen"
                          style="font-size: 0.6em; vertical-align: middle !important;"
                          @click.native="showNameModal = true"
                  />
                </h1>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <h1 class="title is-1">{{track_id}}</h1>
            </div>
          </div>
        </div>
        <h4 v-if="track.full_name" class="is-size-4">{{ track.full_name }}</h4>
        <p>
        {{ segmentNames.join(', ')}}<br />
          {{ track.start_time | moment("timezone", track.tz, "M/D/YYYY - h:mm a z") }}<br />
          {{track.location}}
        </p>
        <div class="columns" >
          <div class="column">
            <dl v-if="this.track.metric">
              <dt>DURATION</dt>
              <dd>{{ formattedDuration }}</dd>
              <div v-if="avgWindSpeed">
                <dt>AVG WIND SPEED</dt>
                <dd>{{ avgWindSpeed.toFixed(2) }} kts</dd>
              </div>
            </dl>
          </div>
          <div class="column">
            <dl>
              <dt>SELECTED STRAIGHT SEGMENTS</dt>
              <dd>{{ segmentNames.length }}</dd>
              <dt>TRACK STRAIGHT SEGMENTS</dt>
              <dd>{{ track.metric.straights }}</dd>
            </dl>
          </div>
        </div>
        <div class="section-header">
          <h4 class="title is-4">Track Data</h4>
          <b-icon class="is-small"
                  :icon="showTable ? 'eye' : 'eye-slash'"
                  @click.native="showTable = !showTable"
          />
        </div>
        <StraightLineTable v-if="showTable"
                           :selectedSegments="selectedSegments"
                           :userTags="userTags"
                           :filterableTags="availableTagFilters"
                           :corrData="corrData"
                           :track="track"
                           @tagRemoved="tagRemoved"
                           @createAndAddTag="createAndAddTag"
                           @addTagToSegment="addTagToSegment"
                           @tagFilterChanged="tagFilterChanged"
                           @hideTag="hideTag"
                           @calculateCorr="calculateCorr"
        />
        <div class="section-header">
          <h4 class="title is-4">Rhythm Analysis</h4>
          <b-icon class="is-small"
                  :icon="showRhythm ? 'eye' : 'eye-slash'"
                  @click.native="showRhythm = !showRhythm"
          />
        </div>
        <StraightLineRhythm v-if="showRhythm" :lagDataAllSegments="lagDataAllSegments" :selectedSegments="selectedSegments"/>
        <div class="section-header">
          <h4 class="title is-4" style="margin-top: 1em">Track Charts</h4>
          <b-icon class="is-small"
                  :icon="showCharts ? 'eye' : 'eye-slash'"
                  @click.native="showCharts = !showCharts"
          />
        </div>
        <!-- v-show ensures that component is not recreated, saving chart settings -->
        <StraightLineChart
          v-show="showCharts"
          :trackpoints="trackpoints"
          :colors="colors"
          :selectedSegments="selectedSegments"
          :segmentNames="segmentNames"
          :selectionAnalysis="selectionAnalysis"
          :userTags="userTags"
          @dragComplete="dragComplete"
          style="padding-bottom: 5em"
        />
      </div>
      <div class="spinner-container" v-else>
        <p><i class="fa fa-spinner fa-spin"></i></p>
      </div>
    </div>
    <b-modal v-model="showNameModal">
      <header class="modal-card-head">
        <p class="modal-card-title">Change Name</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Straight Line Analysis Name">
          <b-input
            v-model="tempName"
            placeholder="Name for report"
          >
          </b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          label="Cancel"
          @click="showNameModal = false" />
        <b-button
          label="Change Name"
          type="is-primary"
          @click="updateName"
        />
      </footer>
    </b-modal>
  </layout>
</template>

<script>
import layout from "@/views/layouts/Default.vue";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import api from "@/services/api/tracks";
import StraightLineChart from "@/components/straight-line-chart";
import { COLORS } from "@/constants";
import StraightLineTable from "@/views/tracks/StraightLineTable";
import alertHelper from "@/services/helpers/alertHelper";
import StraightLineRhythm from "@/views/tracks/StraightLineRhythm";


export default {
  name: "StraightLineReport",
  props: ["id"],
  components: {
    StraightLineRhythm,
    StraightLineTable,
    layout,
    StraightLineChart
  },
  created() {
    api.getStraightLineAnalysis(this.id).then(response => {
      this.track_id = response.data.track_id
      this.analysisName = response.data.name
      this.tempName = response.data.name
      this.allSelectedSegments = response.data.segments
      this.trackpoints = response.data.all_segments.trackpoints
      this.allSegmentData = response.data.all_segments

      this.fetchTrack(this.track_id)
      this.user = this.$store.getters["auth/user"]
    }).catch((error) => {
      alertHelper.alertNotification(this.$store.dispatch, error)
    });
    api.getUserSegmentTags().then(response => {
      this.userTags = response.data.tags.sort((a, b) => a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'}))
    }).catch((error) => {
      alertHelper.alertNotification(this.$store.dispatch, error)
    });
  },
  data: function () {
    return {
      track_id: 0,
      allSelectedSegments: {},
      allSegmentData: {},
      trackpoints: [],
      colors: COLORS,
      showTable: true,
      showRhythm: true,
      showCharts: true,
      analysisName: null,
      showNameModal: false,
      tempName: null,
      userTags: [],
      tagFilter: null,
      selectionAnalysis: {},
      selectionAnalysisKey: 0,
      corrData: null,
    }
  },
  computed: {
    ...mapState({
      track(state) {
        return state.tracks.track
      },
    }),
    lagDataAllSegments() {
      return this.allSegmentData.sog_lag
    },
    selectedSegments() {
      const allSegments = this.allSelectedSegments
      if (this.tagFilter) {
        const tagFilter = this.tagFilter
        const filtered = Object.keys(allSegments).reduce(function (filtered, key) {
          if (allSegments[key].tags.filter(x => x.id === tagFilter.id).length > 0) {
            filtered[key] = allSegments[key];
          }
          return filtered;
        }, {});
        return filtered
      } else {
        return allSegments;
      }
    },
    availableTagFilters() {
      return Object.values(this.allSelectedSegments)
        .map(x => x.tags)
        .flat()
        .sort((a, b) => a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'}))
        .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i); // remove duplicates
    },
    avgWindSpeed() {
      if (this.selectedSegments.length > 0) {
        if (!('tws' in this.selectedSegments[this.segmentNames[0]].avgs)) {
          return null;
        } else {
          let totalWeights = 0;
          let numerator = 0;
          for (const segment in this.selectedSegments) {
            numerator += this.selectedSegments[segment].avgs.tws * this.selectedSegments[segment].trackpoints.length;
            totalWeights += this.selectedSegments[segment].trackpoints.length;
          }
          return numerator/totalWeights;
        }
      }
    },
    segmentNames() {
      return Object.keys(this.selectedSegments);
    },
    totalDuration() {
      if (this.track) {
        let start = moment.duration('00:00');
        let track = this.track
        return this.segmentNames.reduce(
          function(previousValue, currentValue) {
            let obj = track.metrics.find(x => x.segment_name === currentValue);
            return previousValue.add(moment.duration(obj.duration));
          },
          start
        );
      }
    },
    formattedDuration() {
      if (this.track) {
        return moment.utc(this.totalDuration.as('milliseconds')).format('HH:mm:ss');
      }
    }
  },
  watch: {
    trackpoints(newTrackpoints, oldTrackpoints) {
      if (oldTrackpoints.length === 0 && newTrackpoints.length >= 0)  { // new track
        this.getWholeSelectionAnalysis()
        this.getAnalysisForTags()
      }
    }
  },
  methods: {
    ...mapActions("tracks", ["fetchTrack", "removeTrack"]),
    arrayCompare(array1, array2) { // https://stackoverflow.com/questions/22395357/how-to-compare-two-arrays-are-equal-using-javascript
      return (array1.length === array2.length) && array1.every(function(element, index) {
        return element === array2[index];
      });
    },
    calculateCorr(data) {
      if (data == null) {
        this.corrData = null
      } else {
        api.getCorrs(this.id, data).then((response) => {
          this.corrData = response.data
        }).catch((error) => {
          alertHelper.alertNotification(this.$store.dispatch, error)
        })
      }
    },
    dragComplete(args) {
      if (args.start === undefined || args.end === undefined) {
        this.getWholeSelectionAnalysis()
        return;
      }
      const relativeStart = args.start;
      const relativeEnd = args.end;
      for (const [key, value] of Object.entries(this.selectedSegments)) {
        const formatStr = "YYYY-MM-DDTHH:mm:ss"
        const absoluteStart = moment(value.start_time) + moment.duration(relativeStart);
        let absoluteEnd = moment.utc(moment(value.start_time) + moment.duration(relativeEnd)).format(formatStr) + 'Z';

        if (moment(absoluteStart) >= moment(value.end_time)) { // starts after segment ends, skip
          this.$set(this.selectionAnalysis, key, null)
          return;
        } else if (moment(absoluteEnd) > moment(value.end_time)) { // ends after segment ends, trim
          absoluteEnd = moment(value.end_time)
        }
        this.getSelectionForSegment(moment.utc(absoluteStart).format(formatStr)+'Z', moment.utc(absoluteEnd).format(formatStr)+'Z', key);
      }
    },
    selectionTags() { // get tags that appear on more than one segment
      let count = {}
      let dict = {} // lookup table to save time at end
      for (const tag of this.availableTagFilters) { // initialize to 0, limit to only visible segments on table if filter
        count[tag.id] = 0;
        dict[tag.id] = tag;
      }
      for (const seg of Object.values(this.selectedSegments)) {
        for (const tag of seg.tags) {
          count[tag.id] += 1;
        }
      }
      let tags = []
      for (const [key, value] of Object.entries(count)) {
        if (value > 1) {
          tags.push(dict[key])
        }
      }
      return tags;
    },
    getAnalysisForTags() {
      // remove all tag objects from analysis
      for (const key of Object.keys(this.selectionAnalysis)) {
        if (!isNaN(key)) { // !isNaN will return true if number
          this.$delete(this.selectionAnalysis, key)
        }
      }
      const tags = this.selectionTags()
      const tag_ids = tags.map(x => x.id)
      if (tag_ids.length === 0) {
        return
      }
      api.getTagSelectionAnalysis(this.id, tag_ids).then((response) => {
        for (const [key, value] of Object.entries(response.data)) {
          this.$set(this.selectionAnalysis, key, value)
        }
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error)
      });
    },
    getWholeSelectionAnalysis() {
      if (!('all_segments' in this.selectionAnalysis)) {
        this.getSelectionForSegment(this.allSegmentData.start_time, this.allSegmentData.end_time, 'all_segments');
      }
      const allSegmentsToRemove = Object.keys(this.allSelectedSegments)
      for (const key of Object.keys(this.selectionAnalysis)) {
        if (allSegmentsToRemove.includes(key)) {
          this.$delete(this.selectionAnalysis, key)
        }
      }
      for (const [key, _] of Object.entries(this.selectedSegments)) {
        const start = this.allSelectedSegments[key].start_time;
        const end = this.allSelectedSegments[key].end_time;
        this.getSelectionForSegment(start, end, key);
      }
    },
    getSelectionForSegment(start_time, end_time, segment) {
      api.getStraightSelectionAnalysis(this.id, start_time, end_time).then((response) => {
        this.$set(this.selectionAnalysis, segment, response.data) // use Vue.set to get reactivity on props of obj
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error)
      });
    },
    updateName() {
      this.showNameModal = false;
      api.updateStraightLineAnalysisName(this.id, this.tempName).then(_ => {
        this.analysisName = this.tempName;
      }).catch((error) => {
        this.tempName = this.analysisName;
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
    },
    tagFilterChanged(tag) {
      const oldTags = this.selectionTags().map(x => x.id);
      const oldSegments = Object.keys(this.selectedSegments)
      if (tag) {
        this.tagFilter = tag;
      } else {
        this.tagFilter = null;
      }

      // only get tags/segs again if they are different
      const newTags = this.selectionTags().map(x => x.id)
      const newSegments = Object.keys(this.selectedSegments)
      if (!(this.arrayCompare(oldSegments, newSegments))) {
        this.getWholeSelectionAnalysis()
      }
      if (!(this.arrayCompare(oldTags, newTags))) {
        console.log('different')
        this.getAnalysisForTags()
      }
    },
    tagRemoved(args) {
      api.removeTagFromSegment(args.segment.id, args.tagId, this.track_id).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
      this.selectedSegments[args.segment.name].tags = this.selectedSegments[args.segment.name].tags.filter(x => x.id !== args.tagId);
      this.getAnalysisForTags()
    },
    createAndAddTag(args) {
      // first check if tag already exists for value
      let existingTag = this.userTags.findIndex(x => x.value === args.tagValue);
      if (existingTag !== -1) {
        this.addTagToSegment({...args, tag: this.userTags[existingTag]});
        if (!this.userTags[existingTag].visible) {
          const tagId = this.userTags[existingTag].id
          api.showUserSegmentTag(tagId).then(_ => {
            const tagIndex = this.userTags.findIndex(x => x.id === tagId);
            this.userTags[tagIndex].visible = true;
          }).catch((error) => {
            alertHelper.alertNotification(this.$store.dispatch, error);
          });
        }
        return;
      }

      api.createUserSegmentTags(args.tagValue).then((response) => {
        this.userTags.push(response.data.tag);
        this.userTags.sort((a, b) => a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'}));
        this.addTagToSegment({...args, tag: response.data.tag})
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
    },
    addTagToSegment(args) {
      api.addUserTagToSegment(args.segment.id, args.tag.id, this.track_id).then((_) => {
        this.selectedSegments[args.segment.name].tags.push(args.tag);
        this.getAnalysisForTags();
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
    },
    hideTag(tag) {
      api.hideUserSegmentTag(tag.id).then((_) => {
        const tagIndex = this.userTags.findIndex(x => x.id === tag.id);
        this.userTags[tagIndex].visible = false;
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
    }
  },
}
</script>
<style lang="sass" scoped>
  h4
    display: inline-block !important
  .section-header
    margin-top: 2em
  span
    margin-inline-start: 10px !important
  span:hover
    -webkit-filter: brightness(40%)
    cursor: pointer
</style>

