<template>
  <layout>
    <div class="container" v-if="team">
      <div class="columns">
        <!-- Left Column -->
        <div class="column">
          <h1 class="is-size-3">{{ team.name }}</h1>
          <br>
          <br>
          <h1 class="is-size-5">Coaches</h1>
          <div class="columns is-multiline">
            <div class="column is-one-third" v-for="coach in team.coaches" :key="coach.email">
              <AthleteCard :athlete="coach">
                <template v-slot:footer v-if="isCoach">
                  <footer class="card-footer">
                    <a class="button is-link is-inverted card-footer-item"
                      :disabled="team.coaches.length < 2"
                      @click="checkUpdateRole(coach, 'ATHLETE')">Demote</a>
                    <a class="button is-link is-inverted card-footer-item"
                      :disabled="team.coaches.length < 2"
                      @click="removeAthlete(coach)">Remove</a>
                  </footer>
                </template>
              </AthleteCard>
            </div>
          </div>

          <h1 class="is-size-5">Athletes</h1>
          <button class="button" @click="promptAthlete" v-if="isCoach">
            <i class="fas fa-user-plus"></i> Add
          </button>
          <div class="columns is-multiline">
            <div class="column is-one-third" v-for="athlete in team.athletes" :key="athlete.email">
              <AthleteCard :athlete="athlete">
                <template v-slot:footer v-if="isCoach">
                  <footer class="card-footer">
                    <a class="card-footer-item" @click="updateRole(athlete, 'COACH')">Promote</a>
                    <a class="card-footer-item" @click="removeAthlete(athlete)">Remove</a>
                  </footer>
                </template>
              </AthleteCard>
            </div>
          </div>
        </div>

        <!-- Right Column -->
        <div class="column is-one-quarter">
          <!-- Display the Logo -->
          <div class="logo-container mb-3">
            <img :src="teamLogoUrl" alt="Team Logo" class="team-logo" />
          </div>

          <!-- Permissions Button -->
          <button class="button is-primary is-fullwidth mb-3" v-if="isCoach" @click="openPermissionsDialog">
            Manage Permissions
          </button>

          <!-- Leave Team Button -->
          <button class="button is-danger is-fullwidth mb-3" @click="leaveTeam">
            <i class="fas fa-sign-out-alt"></i> Leave Team
          </button>

          <!-- Update Team Button -->
          <button class="button is-primary is-fullwidth mb-3" v-if="isCreator" @click="toggleUpdateTeam">
            Update Team
          </button>

          <!-- Delete Team Button -->
          <button class="button is-danger is-fullwidth mb-3" v-if="isCreator" @click="deleteTeam">
            <i class="fas fa-trash"></i> Delete Team
          </button>

          <!-- Centered Card for Update Team (if visible) -->
          <div v-if="isUpdateTeamVisible && isCreator" class="modal is-active">
            <div class="modal-background" @click="toggleUpdateTeam"></div>
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">Update Team</p>
                <button class="delete" @click="toggleUpdateTeam"></button>
              </header>
              <section class="modal-card-body">
                <TeamForm :initial="team" @submitted="update" ref="teamform"></TeamForm>
              </section>
            </div>
          </div>

          <!-- Permissions Modal -->
          <Modal
            :isActive="isPermissionsDialogActive"
            title="Team Permissions"
            @close="closePermissionsDialog"
            @save="savePermissions"
          >
            <p>Who can see my tracks on this team?</p>
            <div class="block">
              <label class="radio">
                <input type="radio" v-model="team.membership.permissions" value="ALL" /> All Teammates
              </label>
              <label class="radio">
                <input type="radio" v-model="team.membership.permissions" value="COACH_ONLY" /> Coaches Only
              </label>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import TeamForm from "@/views/teams/TeamForm";
import layout from "@/views/layouts/Default";
import AthleteCard from "@/views/teams/AthleteCard";
import Modal from "@/components/Modal"; // Import the custom Modal component
import { mapGetters } from "vuex";

export default {
  props: ["id"],
  components: { layout, TeamForm, AthleteCard, Modal },
  data() {
    return {
      isPermissionsDialogActive: false, // Control visibility for Permissions modal
      isUpdateTeamVisible: false, // Control visibility for the Update Team centered card
    };
  },
  created() {
    this.$store.dispatch("teams/fetchTeam", { id: this.id });
  },
  methods: {
    // Permissions Modal Logic
    openPermissionsDialog() {
      this.isPermissionsDialogActive = true;
    },
    closePermissionsDialog() {
      this.isPermissionsDialogActive = false;
    },
    savePermissions() {
      this.changePermission();
      this.closePermissionsDialog();
    },
    // Toggle Update Team Card Visibility
    toggleUpdateTeam() {
      this.isUpdateTeamVisible = !this.isUpdateTeamVisible;
    },
    update(formData) {
      this.$store.dispatch("teams/updateTeam", {
        id: this.id,
        formData: formData,
      });
      this.toggleUpdateTeam(); // Hide the card after submitting
    },
    promptAthlete() {
      const email = prompt("Enter athlete's email address");
      if (email) {
        this.addAthlete(email);
      }
    },
    addAthlete(email) {
      this.$store.dispatch("teams/addMember", { id: this.id, email: email });
    },
    removeAthlete(athlete) {
      this.$store.dispatch("teams/removeMember", {
        id: this.id,
        email: athlete.email,
      });
    },
    checkUpdateRole(athlete, role) {
      if (athlete.email === this.user.email) {
        this.confirmDemoteSelf(athlete, role);
      } else {
        this.updateRole(athlete, role);
      }
    },
    confirmDemoteSelf(athlete, role) {
      const confirm = window.confirm("Are you sure you want to demote yourself? You will lose the ability to make changes.");
      if (confirm) {
        this.updateRole(athlete, role);
      }
    },
    updateRole(athlete, role) {
      this.$store.dispatch("teams/updateRole", {
        id: this.id,
        email: athlete.email,
        role: role,
      });
    },
    changePermission() {
      this.$store.dispatch("teams/changePermission", {
        id: this.id,
        permission: this.team.membership.permissions,
      });
    },
    deleteTeam() {
      const confirm = window.confirm("Are you sure you want to delete this team? This action cannot be undone.");
      if (confirm) {
        this.$store.dispatch("teams/deleteTeam", { id: this.id });
        this.$router.replace({ name: "teams" });
      }
    },
    leaveTeam() {
      const confirm = window.confirm("Are you sure you want to leave this team?");
      if (confirm) {
        this.$store.dispatch("teams/leaveTeam", { id: this.id });
        this.$router.replace({ name: "teams" });
      }
    },
  },
  computed: {
    ...mapGetters({
      team: "teams/getTeam",
      user: "auth/user",
    }),
    isCoach() {
      return this.team.coaches.some(coach => coach.email === this.user.email);
    },
    isCreator() {
      return this.team.created_by && this.team.created_by.id === this.user.id;
    },
    teamLogoUrl() {
      if (this.team.logo) {
        return this.team.logo; // Use the logo URL directly
      } else {
        return require('@/assets/logo.png'); // Provide a default logo if needed
      }
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #fafafa;
  padding: 20px;
}

.button {
  margin-bottom: 10px;
}

.logo-container {
  text-align: center;
}

.team-logo {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}
</style>





