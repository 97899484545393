import { render, staticRenderFns } from "./TopMark.vue?vue&type=template&id=34504c55&scoped=true&"
import script from "./TopMark.vue?vue&type=script&lang=js&"
export * from "./TopMark.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34504c55",
  null
  
)

export default component.exports