import Vue from "vue"
import App from "./App.vue"
import router from "./router/router"
import store from "./store/store"
import authToken from "./services/middlewares/authToken"
import commonCSS from "./assets/sass/common.sass"
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/css/fontawesome.css"
import Buefy from "buefy"
import axios from "axios"
import axiosMiddleware from "@/services/middlewares/axios-middleware"
import VueObserveVisibility from "vue-observe-visibility"
import AsyncComputed from "vue-async-computed"
Vue.use(Buefy, {
  defaultIconPack: "fas",
})

Vue.use(AsyncComputed)

const moment = require("moment-timezone")

Vue.use(require("vue-moment"), {
  moment,
})

/**
 * Check token in Storage (after F5)
 */
authToken.check()

Vue.use(axiosMiddleware, axios, store, router)
Vue.use(VueObserveVisibility)

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts)

/* Validation init */
import Vuelidate from "vuelidate"
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  commonCSS,
  render: (h) => h(App),
}).$mount("#app")
