const boaticons = {
  set(
    map,
    StartCoordinateX,
    StartCoordinateY,
    FinishCoordinateX,
    FinishCoordinateY
  ) {
    var boatJson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [StartCoordinateX, StartCoordinateY],
          },
        },
      ],
    }

    var boatJson2 = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [FinishCoordinateX, FinishCoordinateY],
          },
        },
      ],
    }
    map.loadImage("/boat2.ico", function (error, image) {
      if (error) throw error
      map.addImage("boat", image)
      map.addLayer({
        id: "boatId",
        type: "symbol",
        source: {
          type: "geojson",
          data: boatJson,
        },
        layout: {
          "icon-image": "boat",
          "icon-size": 0.5,
        },
      })
      map.addLayer({
        id: "boatId2",
        type: "symbol",
        source: {
          type: "geojson",
          data: boatJson2,
        },
        layout: {
          "icon-image": "boat",
          "icon-size": 0.5,
        },
      })
    })
  },
  // mutations, actions, getters...
}

export default boaticons
