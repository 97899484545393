<template>
  <div v-if="isActive" class="modal is-active">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button class="delete" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <slot></slot> <!-- Content passed into the modal -->
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="saveModal">Save</button>
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal",
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    saveModal() {
      this.$emit('save');
    }
  }
};
</script>

<style scoped>
.modal.is-active {
  display: flex;
}
</style>
