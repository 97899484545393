<template>
  <div>
    <section>
      <b-dropdown
        v-model="hiddenColumns"
        multiple
        aria-role="list"
      >
        <template #trigger>
          <b-button
            type="is-primary"
            icon-right="caret-down"
            style="margin-bottom: 1em"
          >
            Hide Columns ({{ hiddenColumns.length }})
          </b-button>
        </template>
        <b-dropdown-item v-for="column in allColumns" :value="column.field" aria-role="listitem" style="padding-right: 16px">
          <span>{{ column.field }}</span>
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        v-model="tagFilter"
        aria-role="list"
        style="margin-left: 1em"
        @input="tagChanged"
      >
        <template #trigger>
          <b-button
            v-if="tagFilter"
            type="is-primary"
            icon-right="caret-down"
            style="margin-bottom: 1em"
          >
            Filter by Tag ({{ tagFilter.value }})
          </b-button>
          <b-button
            v-else
            type="is-primary"
            icon-right="caret-down"
            style="margin-bottom: 1em"
          >
            Filter by Tag
          </b-button>
        </template>
        <b-dropdown-item v-for="tag in filterableTags" :value="tag" aria-role="listitem" style="padding-right: 16px">
          <span>{{ tag.value }}</span>
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        v-model="corrColumns"
        multiple
        style="margin-left: 1em"
        aria-role="list"
        @change="calculateCorr"
      >
        <template #trigger>
          <b-button
            type="is-primary"
            icon-right="caret-down"
            style="margin-bottom: 1em"
          >
            <div v-if="corrColumns.length > 0">
              Analyze Correlation ({{ corrColumns.length }} columns)
            </div>
            <div v-else>
              Analyze Correlation
            </div>
          </b-button>
        </template>
        <b-dropdown-item v-for="column in allColumns.slice(4, this.allColumns.length)" :value="column.field" aria-role="listitem" style="padding-right: 16px">
          <span>{{ column.field }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </section>
    <b-table :data="tableData" class="big-table" hoverable bordered scrollable
             default-sort="name">
      <b-table-column label="Tags" field="tags" width="200" :td-attrs="attrsForItem">
        <template v-slot="props">
          <b-taglist>
            <div v-for="tag in props.row.tags" class="control">
              <b-tag :type="`is-primary`"
                     aria-close-label="Close tag"
                     closable
                     @close="tagRemoved(props.row, tag.id)"
              >
                {{ tag.value }}
              </b-tag>
            </div>
            <b-dropdown aria-role="list" append-to-body scrollable max-height="200" trap-focus expanded
                        style="width: 1.5em; margin-bottom: 6px">
              <template #trigger>
                <b-icon class="is-small ic-button"
                        icon="plus"
                        role="button"
                />
              </template>
              <b-dropdown-item custom aria-role="listitem">
                <b-input v-model="tagToCreate"
                         placeholder="Create and add tag"
                         @keyup.native.enter="createAndAddTag(props.row)"
                />
              </b-dropdown-item>
              <b-dropdown-item v-for="tag in userTags.filter(x => props.row.tags.filter(y => y.id === x.id).length === 0 && x.visible)"
                               aria-role="listitem"
                               style="text-align: left"
                               @click="addTagToSegment(props.row, tag)">
                {{ tag.value }}
                <b-icon class="is-small ic-button"
                        icon="eye-slash"
                        role="button"
                        style="margin-left: 0.5em"
                        @click.native.stop="hideTag(tag)"
                />
              </b-dropdown-item>
            </b-dropdown>
          </b-taglist>
        </template>
      </b-table-column>
      <template v-for="column2 in selectedColumns">
        <b-table-column sortable :field="column2.field" :width="column2.width" :label="column2.label" :th-attrs="attrsForHeader" :td-attrs="attrsForItem">
          <template v-slot:header="{ column }">
          <span class="is-relative">
            <p>
              {{ column.label }}
            </p>
            <p class="is-size-7" v-if="column2.unit">
              {{ column2.unit }}
            </p>
            <p class="is-size-7" v-else>
              <br>
            </p>
            <b-icon class="is-small sort-icon"
                    icon="arrow-up"
                    :class="{
                          'is-desc': !column.$table.isAsc,
                          'is-invisible': column.$table.currentSortColumn !== column}
                           ">
                </b-icon>
          </span>
          </template>
          <template v-slot="props">
            <p>
              {{ props.row[column2.field] }}
            </p>
          </template>
        </b-table-column>
      </template>
    </b-table>
    <vue-json-to-csv
      :json-data="dataForCsv()"
      @error="val => $emit('err', val)"
    >
      <a>Download CSV</a>
    </vue-json-to-csv>
    <div style="margin-top: 1em; margin-bottom: 1em">
      <h2 class="is-1"><b>Correlation Analysis</b></h2>
      <b-table v-if="corrData" :data="corrData" :columns="corrTableColumns" hoverable sticky-header height="300" style="margin-top: 0.5em">
      </b-table>
      <p v-else>
        Correlation analysis will appear here once three columns and segments are selected
      </p>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "StraightLineTable",
  props: ['selectedSegments', 'userTags', 'filterableTags', 'corrData', 'track'],
  components: {
    VueJsonToCsv,
  },
  data: function () {
    return {
      hiddenColumns: [],
      corrColumns: [],
      tagFilter: null,
      lastSelectedTag: null,
      showAddTagModal: false,
      segmentToAddTag: 0,
      tagToCreate: null,
      corrTableColumns: [
        {
          field: 'col1',
          label: 'Column 1'
        },
        {
          field: 'col2',
          label: 'Column 2'
        },
        {
          field: 'r',
          label: 'r'
        }
      ]
    }
  },
  watch: {
    selectedSegments() { // when segments update, calculate new correlations for them
      this.calculateCorr()
    }
  },
  computed: {
    allColumns() {
      return [...this.reqColumns, ...this.avgColumns, ...this.stdColumns, ...this.travelColumns]
    },
    selectedColumns() {
      return this.allColumns.filter(x => (!this.hiddenColumns.includes(x.field)));
    },
    reqColumns() {
      return [
        {
          field: 'name',
          label: 'Name',
        },
        {
          field: 'start_time',
          label: 'Start Time',
          width: '125'
        },
        {
          field: 'end_time',
          label: 'End Time',
          width: '125'
        },
        {
          field: 'duration',
          label: 'Duration',
        }
      ]
    },
    avgColumns() {
      let columns = [];
      let optionalAvgs = [
        {
          field: 'ewd_avg',
          label: 'EWD',
          unit: '° (avg)'
        },
        {
          field: 'tws_avg',
          label: 'TWS',
          unit: 'kts (avg)'
        },
        {
          field: 'twd_avg',
          label: 'TWD',
          unit: '° (avg)'
        },
        {
          field: 'vmg_avg',
          label: 'VMG',
          unit: 'kts (avg)'
        },
        {
          field: 'vmg_twd_avg',
          label: 'VMG',
          unit: 'kts (avg) via TWD'
        },
        {
          field: 'sog_avg',
          label: 'SOG',
          unit: 'kts (avg)'
      },
        {
          field: 'etwa_avg',
          label: 'ETWA',
          unit: '° (avg)'
        },
        {
          field: 'twa_avg',
          label: 'TWA',
          unit: '° (avg)'
        },
        {
          field: 'heel_avg',
          label: 'Heel',
          unit: '° (avg)'
        },
        {
          field: 'clew_load_avg',
          label: 'Clew Load',
          unit: 'N (avg)'
        },
        {
          field: 'cog_avg',
          label: 'COG',
          unit: '° (avg)'
        }
      ]
      optionalAvgs.forEach((column) => {
        const sumForColumn = this.tableData.reduce((prev, next) => prev + Math.abs(Number(next[column.field])), 0)
        if ((this.tableData[0][column.field]) && (sumForColumn !== 0)) {
          columns.push({
            ...column,
          });
        }
      })
      return columns;
    },
    stdColumns() {
      let columns = [];
      let optionalStds = [
        {
          field: 'heel_std',
          label: 'Heel',
          unit: '° (std)'
        },
        {
          field: 'cog_std',
          label: 'COG',
          unit: '° (std)'
        },
      ]
      optionalStds.forEach((column) => {
        const sumForColumn = this.tableData.reduce((prev, next) => prev + Math.abs(Number(next[column.field])), 0)
        if ((this.tableData[0][column.field]) && (sumForColumn !== 0)) {
          columns.push({
            ...column,
          });
        }
      })
      return columns;
    },
    travelColumns() {
      let columns = [];
      let optionalTravels = [
        {
          field: 'heel_travel',
          label: 'Heel',
          unit: '° (60s avg travel)'
        },
        {
          field: 'pitch_travel',
          label: 'Pitch',
          unit: '° (60s avg travel)'
        },
      ]
      optionalTravels.forEach((column) => {
        const sumForColumn = this.tableData.reduce((prev, next) => prev + Math.abs(Number(next[column.field])), 0)
        if ((this.tableData[0][column.field]) && (sumForColumn !== 0)) {
          columns.push({
            ...column,
          });
        }
      })
      return columns;
    },
    tableData() {
      let data = []
      for (const [key, value] of Object.entries(this.selectedSegments)) {
        let obj = {
          'name': key,
          ...value
        };
        for (const [k, v] of Object.entries(value['avgs'])) {
          obj[`${k}_avg`] = Number(v.toFixed(2));
        }
        for (const [k, v] of Object.entries(value['stds'])) {
          obj[`${k}_std`] = Number(v.toFixed(2));
        }
        for (const [k, v] of Object.entries(value['travels'])) {
          obj[`${k}_travel`] = Number((v/Number(obj['duration'])*60).toFixed(2));
          // 60 second avg projection
        }
        delete obj['avgs'];
        delete obj['stds'];
        delete obj['travels'];
        obj['duration'] = moment.utc(obj['duration']*1000).format('mm:ss');
        obj['start_time'] = moment(obj['start_time']).tz(this.track.tz).format('h:mm:ss A');
        obj['end_time'] = moment(obj['end_time']).tz(this.track.tz).format('h:mm:ss A');
        data.push(obj);
      };
      return data;
    },
  },
  methods: {
    calculateCorr() {
      setTimeout(() => {
        if ((this.corrColumns.length < 3) || (Object.keys(this.selectedSegments).length < 3)) {
          this.$emit('calculateCorr', null);
        } else {
          let obj = {}
          for (const col of this.corrColumns) {
            obj[col] = this.tableData.map(x => x[col])
          }
          this.$emit('calculateCorr', obj)
        }
      }, 5) // have to briefly wait for corr columns to update after change event
    },
    tagRemoved(segment, tagId) {
      this.$emit('tagRemoved', {segment:segment, tagId:tagId});
    },
    tagChanged(tag) {
      // dropdown doesn't have native unselect capability, so this implements it
      if ((this.lastSelectedTag) && (this.lastSelectedTag.id === tag.id)) {
        this.tagFilter = null;
      } else {
        this.lastSelectedTag = tag;
      }
      this.$emit('tagFilterChanged', this.tagFilter)
    },
    createAndAddTag(segmentToAddTo) {
      this.$emit('createAndAddTag', {segment: segmentToAddTo, tagValue: this.tagToCreate});
      this.tagToCreate = null;
    },
    addTagToSegment(segmentToAddTo, tag) {
      this.$emit('addTagToSegment', {segment: segmentToAddTo, tag: tag});
    },
    hideTag(tag) {
      this.$emit('hideTag', tag);
    },
    // http://www.flatuicolorpicker.com/grey-hex-color-model/
    attrsForItem(row, column) {
      if (column.field.includes("avg")) {
        return {
          class: 'centerHeader',
          style: {
            'background-color': '#F5F5F5 !important',
          }
        }
      } else if (column.field.includes("std")) {
        return {
          class: 'centerHeader',
          style: {
            // 'background-color': '#d2d7d3 !important',
          }
        }
      } else if (column.field.includes("travel")) {
        return {
          class: 'centerHeader',
          style: {
            'background-color': '#F5F5F5 !important',
          }
        }
      } else if (column.field.includes("tags")) {
        return {
          style: {
            // 'display': 'block !important',
            'min-width': '15em',
            'max-width': '20em',
          }
        }
      }
    },
    attrsForHeader(column) {
      if (column.field.includes("avg")) {
        return {
          class: 'centerHeader',
          style: {
            'background-color': '#F5F5F5 !important',
          }
        }
      } else if (column.field.includes("std")) {
        return {
          class: 'centerHeader',
          style: {
            // 'background-color': '#d2d7d3 !important',
          }
        }
      } else if (column.field.includes("travel")) {
        return {
          class: 'centerHeader',
          style: {
            'background-color': '#F5F5F5 !important',
          }
        }
      }
    },
    dataForCsv() {
      // https://stackoverflow.com/questions/38750705/filter-object-properties-by-key-in-es6
      const allowedColumns = this.allColumns.map(x => x.field)
      return this.tableData.map((x) => {
        const filtered = Object.keys(x)
          .filter(key => allowedColumns.includes(key))
          .reduce((obj, key) => {
            obj[key] = x[key];
            return obj;
          }, {});
        return filtered;
      })
    },
  },
};
</script>
<style>
  .dropdown {
    width: unset !important;
  }
  .centerHeader {
    text-align: center !important;
  }
  table {
    border: 0px solid transparent !important;
    border-radius: 10px !important;
  }
  div.tags {
    flex-wrap: wrap !important;
    column-gap: 0.2em;
  }
  div.tags:last-child {
    margin-bottom: 0;
  }
  div.tags:not(:last-child) {
    margin-bottom: 0.2rem;
  }
  .ic-button:hover {
    -webkit-filter: brightness(40%);
    cursor: pointer;
  }
</style>
