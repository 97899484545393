<template>
  <div class="dropdown" @mouseover="hover=true" @mouseleave="hover=false">
    <slot name="icon"><i class="fa fa-2x fa-ellipsis-h"></i></slot>
    <div class="dropdown-content dropdown-container" @click="hover = false" v-bind:class="{show: hover}" v-bind:style="{pointerEvents: allowClick}">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Dropdown",
  data() {
    return {
      hover: false,
      allowClick: 'none'
    }
  },
  watch: {
    hover(val) {
      if(!val) {
        // immediately stop clicks on close
        this.allowClick = 'none'
      } else {
        // Allow clicks after a small timeout, to avoid immediately
        // clicking the first item in the menu on mobile
        setTimeout(() => {
          this.allowClick = 'initial'
        }, 50)
      }
    }

  }
}
</script>
<style>
.dropdown {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 50px;
}

.dropdown-container {
  text-align: left;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f9f9f9;
  width: min-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 2;
}

.dropdown-container a {
  display: block;
}

.show {
  display: block;
}
</style>
