import yotSVG_1 from "@/assets/svg/yot-1.svg"
import yotSVG_2 from "@/assets/svg/yot-2.svg"
import yotSVG_3 from "@/assets/svg/yot-3.svg"
import yotSVG_4 from "@/assets/svg/yot-4.svg"
import yotSVG_5 from "@/assets/svg/yot-5.svg"
import yotSVG_6 from "@/assets/svg/yot-6.svg"
import yotSVG_7 from "@/assets/svg/yot-7.svg"
import yotSVG_8 from "@/assets/svg/yot-8.svg"
import yotSVG_9 from "@/assets/svg/yot-9.svg"

import er_49_img from "@/assets/icons/49er.png"
import er_491_img from "@/assets/icons/49er_1.png"
import er_492_img from "@/assets/icons/49er_2.png"
import er_493_img from "@/assets/icons/49er_3.png"
import er_494_img from "@/assets/icons/49er_4.png"
import er_495_img from "@/assets/icons/49er_5.png"
import er_496_img from "@/assets/icons/49er_6.png"
import er_497_img from "@/assets/icons/49er_7.png"
import er_498_img from "@/assets/icons/49er_8.png"
import er_499_img from "@/assets/icons/49er_9.png"
import er_49_opacity_img from "@/assets/icons/49er_opacity.png"
import er_491_opacity_img from "@/assets/icons/49er_1_opacity.png"
import er_492_opacity_img from "@/assets/icons/49er_2_opacity.png"
import er_493_opacity_img from "@/assets/icons/49er_3_opacity.png"
import er_494_opacity_img from "@/assets/icons/49er_4_opacity.png"
import er_495_opacity_img from "@/assets/icons/49er_5_opacity.png"
import er_496_opacity_img from "@/assets/icons/49er_6_opacity.png"
import er_497_opacity_img from "@/assets/icons/49er_7_opacity.png"
import er_498_opacity_img from "@/assets/icons/49er_8_opacity.png"
import er_499_opacity_img from "@/assets/icons/49er_9_opacity.png"

import er_29_img from "@/assets/icons/29er.png"
import er_291_img from "@/assets/icons/29er_1.png"
import er_292_img from "@/assets/icons/29er_2.png"
import er_293_img from "@/assets/icons/29er_3.png"
import er_294_img from "@/assets/icons/29er_4.png"
import er_295_img from "@/assets/icons/29er_5.png"
import er_296_img from "@/assets/icons/29er_6.png"
import er_297_img from "@/assets/icons/29er_7.png"
import er_298_img from "@/assets/icons/29er_8.png"
import er_299_img from "@/assets/icons/29er_9.png"
import er_29_opacity_img from "@/assets/icons/29er_opacity.png"
import er_291_opacity_img from "@/assets/icons/29er_1_opacity.png"
import er_292_opacity_img from "@/assets/icons/29er_2_opacity.png"
import er_293_opacity_img from "@/assets/icons/29er_3_opacity.png"
import er_294_opacity_img from "@/assets/icons/29er_4_opacity.png"
import er_295_opacity_img from "@/assets/icons/29er_5_opacity.png"
import er_296_opacity_img from "@/assets/icons/29er_6_opacity.png"
import er_297_opacity_img from "@/assets/icons/29er_7_opacity.png"
import er_298_opacity_img from "@/assets/icons/29er_8_opacity.png"
import er_299_opacity_img from "@/assets/icons/29er_9_opacity.png"

import yotSVG_1_O from "@/assets/svg/yot-1-opacity.svg"
import yotSVG_2_O from "@/assets/svg/yot-2-opacity.svg"
import yotSVG_3_O from "@/assets/svg/yot-3-opacity.svg"
import yotSVG_4_O from "@/assets/svg/yot-4-opacity.svg"
import yotSVG_5_O from "@/assets/svg/yot-5-opacity.svg"
import yotSVG_6_O from "@/assets/svg/yot-6-opacity.svg"
import yotSVG_7_O from "@/assets/svg/yot-7-opacity.svg"
import yotSVG_8_O from "@/assets/svg/yot-8-opacity.svg"
import yotSVG_9_O from "@/assets/svg/yot-9-opacity.svg"

export const images = {
  yotSVG_1: yotSVG_1,
  yotSVG_2: yotSVG_2,
  yotSVG_3: yotSVG_3,
  yotSVG_4: yotSVG_4,
  yotSVG_5: yotSVG_5,
  yotSVG_6: yotSVG_6,
  yotSVG_7: yotSVG_7,
  yotSVG_8: yotSVG_8,
  yotSVG_9: yotSVG_9,
}

export const imagesO = {
  yotSVG_1_O: yotSVG_1_O,
  yotSVG_2_O: yotSVG_2_O,
  yotSVG_3_O: yotSVG_3_O,
  yotSVG_4_O: yotSVG_4_O,
  yotSVG_5_O: yotSVG_5_O,
  yotSVG_6_O: yotSVG_6_O,
  yotSVG_7_O: yotSVG_7_O,
  yotSVG_8_O: yotSVG_8_O,
  yotSVG_9_O: yotSVG_9_O,
}

export const er_49 = [
  er_491_img,
  er_492_img,
  er_493_img,
  er_494_img,
  er_495_img,
  er_496_img,
  er_497_img,
  er_498_img,
  er_499_img,
]

export const er_29 = [
  er_291_img,
  er_292_img,
  er_293_img,
  er_294_img,
  er_295_img,
  er_296_img,
  er_297_img,
  er_298_img,
  er_299_img,
]

export const er_49_opacity = [
  er_491_opacity_img,
  er_492_opacity_img,
  er_493_opacity_img,
  er_494_opacity_img,
  er_495_opacity_img,
  er_496_opacity_img,
  er_497_opacity_img,
  er_498_opacity_img,
  er_499_opacity_img,
]

export const er_29_opacity = [
  er_291_opacity_img,
  er_292_opacity_img,
  er_293_opacity_img,
  er_294_opacity_img,
  er_295_opacity_img,
  er_296_opacity_img,
  er_297_opacity_img,
  er_298_opacity_img,
  er_299_opacity_img,
]

// source: https://graphicdesign.stackexchange.com/revisions/3815/8
export const COLORS = [
  "#800080",
  "#FF34FF",
  "#0089A3",
  "#0000A6",
  "#FF4A46",
  "#008941",
  // "#FFFFFF",
  "#000000",
  "#A30059",
  // "#FFDBE5",
  "#7A4900",
  "#63FFAC",
  "#B79762",
  "#004D43",
  "#997D87",
  "#5A0007",
  "#809693",
  "#1B4400",
  "#4FC601",
  "#4A3B53",
  "#FF2F80",
  "#61615A",
  "#BA0900",
  "#6B7900",
  "#00C2A0",
  "#FFAA92",
  "#FF90C9",
  "#B903AA",
  "#D16100",
  // "#DDEFFF",
  "#000035",
  "#7B4F4B",
  "#A1C299",
  "#300018",
  "#013349",
  "#00846F",
  "#372101",
  "#FFB500",
  "#C2FFED",
  "#A079BF",
  "#CC0744",
  "#C0B9B2",
  "#C2FF99",
  "#001E09",
  "#00489C",
  "#6F0062",
  "#0CBD66",
  "#EEC3FF",
  "#456D75",
  "#B77B68",
  "#7A87A1",
  "#788D66",
  "#885578",
  "#FAD09F",
  "#FF8A9A",
  "#D157A0",
  "#456648",
  "#886F4C",
  "#34362D",
  "#B4A8BD",
  "#00A6AA",
  "#452C2C",
  "#636375",
  "#A3C8C9",
  "#FF913F",
  "#938A81",
  "#575329",
  "#00FECF",
  "#B05B6F",
  "#3B9700",
  "#04F757",
  "#C8A1A1",
  "#1E6E00",
  "#7900D7",
  "#A77500",
  "#6367A9",
  "#A05837",
  "#6B002C",
  "#772600",
  "#D790FF",
  "#9B9700",
  "#549E79",
  "#201625",
  "#72418F",
  "#BC23FF",
  "#99ADC0",
  "#3A2465",
  "#922329",
  "#5B4534",
  "#FDE8DC",
  "#404E55",
  "#CB7E98",
  "#A4E804",
  "#324E72",
  "#6A3A4C",
  "#83AB58",
  "#001C1E",
  "#D1F7CE",
  "#004B28",
  "#C8D0F6",
  "#A3A489",
  "#806C66",
  "#222800",
  "#BF5650",
  "#E83000",
  "#66796D",
  "#DA007C",
  "#FF1A59",
  "#8ADBB4",
  "#1E0200",
  "#5B4E51",
  "#C895C5",
  "#320033",
  "#FF6832",
  "#CFCDAC",
  "#D0AC94",
  "#7ED379",
  "#012C58",
  "#7A7BFF",
  "#D68E01",
  "#353339",
  "#78AFA1",
  "#FEB2C6",
  "#75797C",
  "#837393",
  "#943A4D",
  "#D2DCD5",
  "#9556BD",
  "#6A714A",
  "#001325",
  "#02525F",
  "#E98176",
  "#DBD5DD",
  "#3D4F44",
  "#7E6405",
  "#02684E",
  "#962B75",
  "#8D8546",
  "#9695C5",
  "#E773CE",
  "#D86A78",
  "#3E89BE",
  "#CA834E",
  "#518A87",
  "#5B113C",
  "#55813B",
  "#E704C4",
  "#00005F",
  "#A97399",
  "#4B8160",
  "#59738A",
  "#FF5DA7",
  "#F7C9BF",
  "#643127",
  "#513A01",
  "#6B94AA",
  "#51A058",
  "#A45B02",
  "#1D1702",
  "#E20027",
  "#E7AB63",
  "#4C6001",
  "#9C6966",
  "#64547B",
  "#97979E",
  "#006A66",
  "#391406",
  "#0045D2",
  "#006C31",
  "#DDB6D0",
  "#7C6571",
  "#9FB2A4",
  "#00D891",
  "#15A08A",
  "#BC65E9",
  "#C6DC99",
  "#203B3C",
  "#671190",
  "#6B3A64",
  "#F5E1FF",
  "#FFA0F2",
  "#CCAA35",
  "#374527",
  "#8BB400",
  "#797868",
  "#C6005A",
  "#3B000A",
  "#C86240",
  "#29607C",
  "#402334",
  "#7D5A44",
  "#B88183",
  "#AA5199",
  "#B5D6C3",
  "#A38469",
  "#9F94F0",
  "#A74571",
  "#B894A6",
  "#71BB8C",
  "#00B433",
  "#789EC9",
  "#6D80BA",
  "#953F00",
  "#5EFF03",
  "#E4FFFC",
  "#1BE177",
  "#BCB1E5",
  "#76912F",
  "#003109",
  "#D20096",
  "#895563",
  "#29201D",
  "#5B3213",
  "#A76F42",
  "#89412E",
  "#1A3A2A",
  "#494B5A",
  "#A88C85",
  "#F4ABAA",
  "#A3F3AB",
  "#00C6C8",
  "#EA8B66",
  "#958A9F",
  "#BDC9D2",
  "#9FA064",
  "#BE4700",
  "#658188",
  "#83A485",
  "#453C23",
  "#47675D",
  "#3A3F00",
  "#061203",
  "#868E7E",
  "#98D058",
  "#6C8F7D",
  "#D7BFC2",
  "#3C3E6E",
  "#D83D66",
  "#6C5E46",
  "#D25B88",
  "#5B656C",
  "#00B57F",
  "#545C46",
  "#866097",
  "#365D25",
  "#252F99",
  "#674E60",
  "#FC009C",
  "#92896B"
]

// 10 seconds
export const TAIL_LENGTH = 30000