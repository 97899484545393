<template>
  <div :class="{ 'golden-section': true, 'is-golden': isGolden }">
    <div
      :style="{
        display: !isGolden ? 'inline-block' : 'flex',
      }"
    >
      <div @click="onStar">
        <b-icon
          icon="star"
          class="star-icon"
          :type="{ 'is-warning': isGolden }"
          />
      </div>
      <b-field>
        <b-input v-show="isGolden" v-model="name" style="margin-left: 20px;" id="gsinput" />
      </b-field>
    </div>
    <div class="tag-buttons" v-if="isGolden">
      <b-button
        @click="tag = 'speed'"
        size="is-small"
        type="is-primary"
        inverted
        :outlined="tag != 'speed'"
        >Speed</b-button
      >
      <b-button
        @click="tag = 'boathandling'"
        size="is-small"
        type="is-primary"
        inverted
        :outlined="tag != 'boathandling'"
        >Boat Handling</b-button
      >
      <b-button
        @click="tag = 'tactics'"
        size="is-small"
        type="is-primary"
        inverted
        :outlined="tag != 'tactics'"
        >Tactics</b-button
      >
      <b-button
        @click="tag = 'localknowledge'"
        size="is-small"
        type="is-primary"
        inverted
        :outlined="tag != 'localknowledge'"
        >Local Knowledge</b-button
      >
      <b-button
        @click="submitJournal"
        size="is-small"
        type="is-success"
      >Save</b-button>
    </div>
  </div>
</template>
<script>
import api from "@/services/api/journal"
export default {
  props: ["track_id", "race_id", "start", "end"],
  data() {
    return {
      isGolden: false,
      name: "",
      tag: "",
    }
  },
  mounted(){
    let stopInput = document.getElementById('gsinput')
    stopInput.addEventListener('keydown', this.stopTheSpace)
  },
  methods: {
    onStar() {
      if (!this.isGolden) {
        this.$emit("toggleGoldStarSection")
        this.isGolden = true
      }else{
        this.$emit('toggleGoldStarSection')
        this.isGolden = false
      }
    },

    stopTheSpace(event){
      // for some reason the vue v-on:keydown is not working, going old school.
      if(event.keyCode === 32){
        event.stopPropagation()
      }
    },

    submitJournal() {
      const journal = this.track_id
        ? {
            jType: "star",
            content: this.name,
            track: this.track_id,
            tags: [{ value: this.tag }],
            s_point: new Date(this.start),
            e_point: new Date(this.end)
          }
        : {
            jType: "star",
            content: this.name,
            race: this.race_id,
            tags: [{ value: this.tag }],
            s_point: new Date(this.start),
            e_point: new Date(this.end)
          }
      if (this.name) {
        api
          .createJournal(journal)
          .then(() => {
            this.name = "";
            this.tag = '';
            if(this.track_id){
              this.$store.dispatch('journal/setJournalTrackId', this.track_id)
            }else{
              this.$store.dispatch('journal/fetchRaceJournal', { race_id: this.race_id, share_id: null })
            }

          })
          .catch((err) => {
            console.log(err)
          })
      }
      this.$emit("toggleGoldStarSection")
      this.isGolden = false
      this.$emit("goldStarSaved", journal)
    },
  },
}
</script>
<style lang="sass">
.golden-section
  position: absolute
  top: 45px
  left: 35px
  width: 85%

  .star-icon
    cursor: pointer

.is-golden
  border: 2px solid #808080
  border-radius: 7px
  padding: 2px 10px
  background: #3c6fbd
  display: flex
  align-items: center
  justify-content: space-between
  z-index: 1

  button
    margin: 0
    margin-right: 0.5em
</style>
