<template>
  <b-navbar type="is-primary" :centered="false">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ name: 'home'}">
        <img src="@/assets/logo.png" alt="home" class="mr-2"> Racing Alpha
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ name: 'home'}">
        Tracks
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/teams'}">
        Teams
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/dashboard'}">
        Dashboard
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/wind'}">
        Wind
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/upload'}">
        Upload
      </b-navbar-item>
      <b-navbar-item class="track-title">
        <div v-if="race">
          <span v-if="race.name">{{ race.name }}</span>
          <span v-else>Race {{ race.id }}</span>
        </div>
        <div  v-if="track">
          <div>Track {{ track.id }} - {{ track | moment("timezone", track.tz, "M/D/YYYY")  }}</div>
          <div></div>
        </div>
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-item tag="router-link" :to="{ path: '/account'}">
        My Account
      </b-navbar-item>
      <b-navbar-item v-if="auth" @click="logout">
        Logout
      </b-navbar-item>
      <b-navbar-item v-else tag="router-link" :to="{ path: '/login'}">
        Login
      </b-navbar-item>
      <b-navbar-item v-if="auth && user" tag="router-link" :to="{ path: '/account'}">
        <img
          class="circle-image"
          v-if="user.profile_img"
          :src="user.profile_img"
        />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  name: "app-header",
  data() {
    return {
      username: "app-header",
      current_time: -7200000,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$store.dispatch("tracks/clearAll")
        this.$router.push({
          name: "login",
        })
      })
    },
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.status,
      user: (state) => (state.auth.user.profile_img ? state.auth.user : null),
      track: (state) => state.tracks.track,
      race: (state) => state.races.race,
    }),
  },
}
</script>

<style>
.track-title {
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}
.circle-image-container {
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
}
.circle-image {
  max-height: none !important;
  width: 50px;
  height: 50px;
  object-fit: cover;
  clip-path: circle();
}

</style>
