import _ from "lodash"

export const maxLargeArray = (values) => {
    // used to find the max of a large array
    // Math.max(...largeArray) will exceed call stack
    let allUndefined = true;
    let max = -Infinity
    for (let i=0; i < values.length; i++){
        let value = values[i]
        if(value) {
            allUndefined = false;
        }
        if (value > max) {
            max = value
        }
    }
    if (allUndefined) {
        return null;
    }
    return max
}

export const minLargeArray = (values) => {
    // used to find the min of a large array
    // Math.min(...largeArray) will exceed call stack
    let allUndefined = true;
    let min = Infinity
    for (let i=0; i < values.length; i++){
        let value = values[i]
        if(value) {
            allUndefined = false;
        }
        if (value < min) {
            min = value
        }
    }
    if (allUndefined) {
        return null;
    }
    return min
}

export const filterObject = (obj, predicate) => {
  const filteredObj = Object.keys(obj)
    .filter( key => predicate(key) )
    .reduce( (res, key) => (res[key] = obj[key], res), {} )
  return filteredObj
}

export const getMidCoordinate = (point1, point2) => {
    let dLon = Math.toRadians(point2.lon - point1.lon)
  //convert to radians
  let lat1 = Math.toRadians(point1.lat)
  let lat2 = Math.toRadians(point2.lat)
  let lon1 = Math.toRadians(point1.lon)

  let Bx = Math.cos(lat2) * Math.cos(dLon)
  let By = Math.cos(lat2) * Math.sin(dLon)
  let lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By))
  let lon3 = lon1 + Math.atan2(By, Math.cos(lat1) + Bx)
  return {
       lat: lat3,
       lon: lon3
  }
}

/**
 * All lat-long specific operation define here
 *
 * http://www.movable-type.co.uk/scripts/latlong.html
 * https://gis.stackexchange.com/questions/157693/getting-all-vertex-lat-long-coordinates-every-1-meter-between-two-known-points
 *
 */

const getPathLength = (lat1, lng1,lat2, lng2) => {
    var lat1rads, lat2rads, deltaLat, lat2rads, deltaLng,
    a, c, dist_metre, R

    // Avoid to return NAN, if finding distance between same lat long.
    if(lat1 == lat2 && lng1 == lng2) {
      return 0
    }

    //Earth Radius (in metre)
    R = 6371000

    lat1rads = degreesToRadians(lat1)
    lat2rads = degreesToRadians(lat2)
    deltaLat = degreesToRadians((lat2-lat1))
    deltaLng = degreesToRadians((lng2-lng1))
    a = Math.sin(deltaLat/2) * Math.sin(deltaLat/2) +
        Math.cos(lat1rads) * Math.cos(lat2rads) * Math.sin(deltaLng/2) * Math.sin(deltaLng/2)
    c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

    dist_metre = R * c

    if(isNaN(dist_metre)) {
        return 0
    }

    return dist_metre
}

const degreesToRadians = (degree) => {
    return  degree * Math.PI/180
}

const radiansToDegrees = (radians) => {
    return radians * 180 / Math.PI
}

const _getDestinationLatLong = (lat, lng, azimuth, distance_metre) => {
    var lat2, lng2, R, brng, d_km, lat1, lng1 

    R = 6378.1 //Radius of the Earth in km

    brng = degreesToRadians(azimuth)
    d_km = distance_metre/1000
    lat1 = degreesToRadians(lat)
    lng1 = degreesToRadians(lng)

    lat2 = Math.asin(Math.sin(lat1) * Math.cos(d_km/R) +
            Math.cos(lat1)* Math.sin(d_km/R)* Math.cos(brng))
    lng2 = lng1 +
            Math.atan2(
                Math.sin(brng) * Math.sin(d_km/R)* Math.cos(lat1),
                Math.cos(d_km/R)- Math.sin(lat1)* Math.sin(lat2))

    //convert back to degrees
    lat2 = radiansToDegrees(lat2)
    lng2 = radiansToDegrees(lng2)

    return [parseFloat(lat2.toFixed(6)), parseFloat(lng2.toFixed(6))]
}

const calculateBearing = (lat1, lng1, lat2, lng2) => {
    let startLat, startLong, endLat, endLong, dLong, dPhi, bearing

    startLat = degreesToRadians(lat1)
    startLong = degreesToRadians(lng1)
    endLat = degreesToRadians(lat2)
    endLong = degreesToRadians(lng2)

    dLong = endLong - startLong
    dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0))

    if (Math.abs(dLong) > Math.PI) {
        if (dLong > 0) {
            dLong = -(2.0 * Math.PI - dLong)
        } else {
            dLong = (2.0 * Math.PI + dLong)
        }
    }

    bearing = (radiansToDegrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0

    return bearing
}

const _buildCoordinates = (interval, azimuth, lat1, lng1, lat2, lng2) => {
    let d, dist, counter, coords, range_list, _coord 

    d = getPathLength(lat1, lng1, lat2, lng2)
    dist = interval
    let inte  = parseFloat(d/interval)

    coords = []
    coords.push([lat1,lng1])
    range_list = _.range(0, dist)

    counter = parseFloat(inte)

    for (var key in range_list) {
        _coord = _getDestinationLatLong(lat1, lng1, azimuth, counter)
        counter = counter + parseFloat(inte)
        coords.push(_coord)
    }

    coords.push([lat2,lng2])

    return coords
}

export const getCoordinates = (lat1, lng1, lat2, lng2, interval_meters) => {
    var azimuth, coords

    azimuth = calculateBearing(lat1, lng1, lat2, lng2)
    coords = _buildCoordinates(interval_meters, azimuth, lat1, lng1, lat2, lng2)

    return coords
}

export const getDistance = (lat1, lng1, lat2, lng2) => {
  const R = 6371000
  const dist = Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng1 - lng2)) * R
  return dist
}

export const angle_subtract = (a1, a2) => {
    let sub = a1 - a2
    if (sub < 0) {
        sub += 360
    }
    return sub
}

export const angle_diff = (a1, a2) => {
    let sub_1 = angle_subtract(a1, a2)
    let sub_2 = angle_subtract(a2, a1)
    return Math.min(sub_1, sub_2)
}