<template>
  <div class="gold-star-list">
    <div class="card" v-for="(journal, idx) in trackJournal" :key="idx" :index="idx">
      <div class="" v-on:click="goldStarClicked(journal)"
        @mouseover="goldStarHovered(journal)"
        @mouseleave="clearGoldStarHover"
      >
        <div class="card-image">
          <img v-if="journal.static_map" :src="journal.static_map" alt="" />
          <img v-else src="/osearch.jpg" alt="" />
        </div>
        <div class="card-content">
          <strong>{{ journal.content }}</strong>
          <div>
            <i class="fa fa-tag"/>
            <span v-for="(tag, idx) in journal.tags" :key="idx + ' ' + journal.id">
              {{ tag.value }}
            </span>
          </div>
          <div>
            <i class="fa fa-clock"/> Start: {{ journal.s_point | localTime }}
              
          </div>
          <div>
            <i class="fa fa-clock"/> End: {{ journal.e_point | localTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
    name: 'GoldStarList',
    props: ['trackJournal'],
    data(){
      return {}
    },
    methods: {
      goldStarClicked(journal){
        this.$emit("goldStarClicked", journal); 
      },
      goldStarHovered(journal) {
        const start = new Date(journal.s_point).getTime();
        const end = new Date(journal.e_point).getTime();
        this.$emit("goldStarHover", {start, end, text: journal.content});
      },
      clearGoldStarHover() {
        this.$emit("goldStarHover", null);
      }
    },
    filters: {
      localTime(isoString) {
        const local = moment.utc(isoString).local().format("HH:mm:ss");
        return local;
      },
    }
}
</script>
<style scoped>
.gold-star-list {
    margin-bottom: 10px;
}
.card {
  margin:  auto;
  width: 200px;
  margin-top:  5px;
  padding:  5px;
}
</style>
