<template>
  <b-modal
    v-model="show"
    has-modal-card
    trap-focus
    @close="$emit('close')"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Attach Wind File to Track {{ trackId }}</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <WindFile v-for="windfile in windfiles" :windfile="windfile" @selected="fileSelected">
        </WindFile>
      </section>
    </div>
    <footer class="modal-card-foot">
    </footer>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import WindFile from "@/views/wind/WindFile";

export default {
  name: "AttachWindModal",
  props: ["show", "trackId", "windfiles"],
  components: {
    WindFile
  },
  methods: {
    fileSelected(id) {
      this.$emit('close')
      this.$emit('selected', id)
    }
  }
};
</script>

<style scoped>

</style>