<!-- src/views/tracks/reports/Tacking.vue -->
<template>
  <div class="tacking-report">
    <!-- Loading spinner until track is available -->
    <div v-if="isLoading" class="spinner-container" aria-busy="true" aria-live="polite">
      <i class="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </div>

    <!-- Track report when track is loaded -->
    <div v-else class="content-container">
      <!-- Track metrics -->
      <div v-if="track.metrics && track.metrics.length > 0" class="columns is-multiline">
        <!-- Adjust chart size -->
        <div class="column is-half-desktop is-full-mobile">
          <!-- Loss chart for tacking -->
          <LossChart class="block" :track="track" tag="T" />
        </div>

        <!-- Summary statistics -->
        <div class="column is-half-desktop is-full-mobile">
          <div class="block">
            <h2 class="title is-4">Summary</h2>
            <dl>
              <dt>Number of Tacks</dt>
              <dd>{{ numberOfTacks }}</dd>

              <dt>Average Loss</dt>
              <dd>{{ averageLoss }} m</dd>

              <dt>Average Maneuver Angle</dt>
              <dd>{{ averageManeuverAngle }}°</dd>

              <!-- New Summary Statistics -->
              <dt>Average Maneuver Duration</dt>
              <dd>{{ averageManeuverDuration }} s</dd>

              <dt>Average Minimum Speed</dt>
              <dd>{{ averageMinSpeed }} m/s</dd>

              <dt>Average Entry Speed</dt>
              <dd>{{ averageEntrySpeed }} m/s</dd>
            </dl>
          </div>
        </div>

        <!-- Tacks Table Section -->
        <div class="column is-full">
          <div class="block">
            <div class="level">
              <div class="level-left">
                <h2 class="title is-4">Tacks</h2>
              </div>
              <div class="level-right">
                <button class="button is-small" @click="toggleTable" aria-label="Toggle Tacks Table">
                  <span v-if="!isTableVisible">Show Table</span>
                  <span v-else>Hide Table</span>
                </button>
              </div>
            </div>

            <transition name="fade">
              <div v-if="isTableVisible">
                <table class="table is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          @change="toggleAllTacks"
                          :checked="allTacksSelected"
                          aria-label="Select All Tacks"
                        />
                      </th>
                      <th>Start Time</th>
                      <th>Loss</th>
                      <th>Entry Speed</th>
                      <th>Exit Speed</th>
                      <th>Min Speed</th>
                      <th>Maneuver Angle</th>
                      <th>Maneuver Duration</th> <!-- New Column -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tack, index) in tacks" :key="index">
                      <td>
                        <input
                          type="checkbox"
                          v-model="tack.selected"
                          @change="updateCalculations"
                          aria-label="Select Tack"
                        />
                      </td>
                      <td>{{ formatTime(tack.start_time) }}</td>
                      <td>{{ tack.loss_og.toFixed(2) }}</td>
                      <td>{{ tack.entry_speed.toFixed(2) }}</td>
                      <td>{{ tack.exit_speed.toFixed(2) }}</td>
                      <td>{{ tack.min_speed.toFixed(2) }}</td>
                      <td>{{ tack.maneuver_angle.toFixed(2) }}</td>
                      <td>{{ tack.maneuver_duration_seconds }} s ({{ tack.maneuver_duration }})</td> <!-- Display both formats -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LossChart from './widgets/LossChart.vue';

export default {
  name: "Tacking",
  props: ["track"],

  data() {
    return {
      tacks: [], // Array of tacks with selected property
      isTableVisible: true, // Control table visibility
      isLoading: true, // Control spinner visibility
    };
  },

  computed: {
    // Number of tacks (selected)
    numberOfTacks() {
      return this.selectedTacks.length;
    },
    // Average loss (selected)
    averageLoss() {
      const totalLoss = this.selectedTacks.reduce((sum, tack) => sum + tack.loss_og, 0);
      return this.numberOfTacks ? (totalLoss / this.numberOfTacks).toFixed(2) : 'N/A';
    },
    // Average maneuver angle (selected)
    averageManeuverAngle() {
      const totalAngle = this.selectedTacks.reduce((sum, tack) => sum + tack.maneuver_angle, 0);
      return this.numberOfTacks ? (totalAngle / this.numberOfTacks).toFixed(2) : 'N/A';
    },
    // Average Maneuver Duration (selected)
    averageManeuverDuration() {
      const totalDuration = this.selectedTacks.reduce((sum, tack) => sum + tack.maneuver_duration_seconds, 0);
      return this.numberOfTacks ? (totalDuration / this.numberOfTacks).toFixed(2) : 'N/A';
    },
    // Average Minimum Speed (selected)
    averageMinSpeed() {
      const totalMinSpeed = this.selectedTacks.reduce((sum, tack) => sum + tack.min_speed, 0);
      return this.numberOfTacks ? (totalMinSpeed / this.numberOfTacks).toFixed(2) : 'N/A';
    },
    // Average Entry Speed (selected)
    averageEntrySpeed() {
      const totalEntrySpeed = this.selectedTacks.reduce((sum, tack) => sum + tack.entry_speed, 0);
      return this.numberOfTacks ? (totalEntrySpeed / this.numberOfTacks).toFixed(2) : 'N/A';
    },
    // Selected tacks
    selectedTacks() {
      return this.tacks.filter(tack => tack.selected);
    },
    // Check if all tacks are selected
    allTacksSelected: {
      get() {
        return this.tacks.length > 0 && this.tacks.every(tack => tack.selected);
      },
      set(value) {
        this.tacks.forEach(tack => {
          tack.selected = value;
        });
      },
    },
  },

  watch: {
    track: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.metrics) {
          this.loadTacks();
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      },
    },
  },

  methods: {
    loadTacks() {
      // Filter tacks from track.metrics
      this.tacks = this.track.metrics
        .filter(metric => metric.label === "T")
        .map(metric => ({
          ...metric,
          selected: true,
          maneuver_duration: metric.duration || "0:00:00", // Ensure duration exists
          maneuver_duration_seconds: this.parseDuration(metric.duration || "0:00:00"), // Convert to seconds
        }));
      // Update calculations
      this.updateCalculations();
    },
    updateCalculations() {
      // Computed properties will automatically update
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString();
    },
    toggleAllTacks() {
      this.allTacksSelected = !this.allTacksSelected;
    },
    toggleTable() {
      this.isTableVisible = !this.isTableVisible;
    },
    /**
     * Parses a duration string formatted as "HH:MM:SS" into total seconds.
     * @param {string} durationStr - Duration string in "HH:MM:SS" format.
     * @returns {number} Total seconds.
     */
    parseDuration(durationStr) {
      const parts = durationStr.split(':').map(part => parseInt(part, 10));
      if (parts.length !== 3 || parts.some(isNaN)) {
        console.warn(`Invalid duration format: "${durationStr}". Expected "HH:MM:SS". Defaulting to 0 seconds.`);
        return 0;
      }
      const [hours, minutes, seconds] = parts;
      return hours * 3600 + minutes * 60 + seconds;
    },
  },

  components: {
    LossChart,
  },
};
</script>


<style scoped>
/* Spinner container - horizontally centered */
.spinner-container {
  width: 100%;
  padding: 2rem 0; /* Adds vertical space without centering */
  display: flex;
  justify-content: center; /* Center horizontally */
}

/* Spinner icon styling */
.spinner-container i {
  font-size: 3rem; /* Adjust size as needed */
  color: #3273dc; /* Optional: change spinner color */
  margin: 0; /* Remove any default margins */
}

/* Content container ensures proper layout when spinner is not visible */
.content-container {
  padding: 1rem; /* Adjust padding as needed */
}

/* Adjust the chart container for responsiveness */
.columns {
  flex-wrap: wrap;
}

/* Transition for table */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Optional: Ensure table columns have consistent width */
.table th, .table td {
  text-align: center;
}

/* Responsive adjustments if needed */
@media (max-width: 768px) {
  .table th, .table td {
    font-size: 0.9rem;
  }
}

/* Screen reader only text */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
