<template>
  <div>
    <layout>
      <div v-if="report">
        <div class="container is-fluid full-height">
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <div class="section-header">
                  <h1 class="is-size-3">
                    Wind Report: "{{ report.name }}"
                  </h1>
                  <h4 class="is-size-5">
                    {{ report.start_time | moment("M/D/YYYY h:mm a")}} — {{ report.end_time | moment("M/D/YYYY h:mm a") }}
                  </h4>
                </div>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <div class="columns">
                  <div class="column">
                    <h1 class="title is-1 is-pulled-right">{{report.wind_file}}</h1>
                    <div class="is-clearfix"></div>
                    <div class="is-pulled-right">
                      <button class="button is-danger" @click="deleteReport">Delete Report</button>
                    </div>
                  </div>
                 </div>
              </div>
            </div>
          </div>
          <div class="section-header">
            <h4 class="title is-4">Wind Speed and Direction</h4>
          </div>
          <div class="chart">
            <apexchart
              :options="chartOptions"
              width="100%"
              height="400"
              :series="chartSeries"
              ref="chart"
            />
          </div>
          <div class="columns" style="margin-top: 0.75em">
            <div class="column">
              <dt>AVG WIND SPEED</dt>
              <dd>{{ metrics.avg_tws.toFixed(2) }} kts</dd>
              <dt>AVG WIND DIRECTION</dt>
              <dd>{{ metrics.avg_twd.toFixed(2) }}°</dd>
              <dt>WIND SPEED STD</dt>
              <dd>{{ metrics.std_tws.toFixed(2) }} kts</dd>
              <dt>WIND DIRECTION STD</dt>
              <dd>{{ metrics.std_twd.toFixed(2) }}°</dd>
            </div>
            <div class="column">
              <dt>SPEED TREND (1s)</dt>
              <dd>{{ metrics.avg_tws_diff_s.toFixed(2) }} kts/s</dd>
              <dt>DIRECTION TREND (1s)</dt>
              <dd>{{ metrics.avg_twd_diff_s.toFixed(2) }}°/s</dd>
              <dt>SPEED TREND (1m)</dt>
              <dd>{{ metrics.avg_tws_diff_min.toFixed(2) }} kts/min</dd>
              <dt>DIRECTION TREND (1m)</dt>
              <dd>{{ metrics.avg_twd_diff_min.toFixed(2) }}°/min</dd>
            </div>
          </div>
          <div>
            <div class="section-header">
              <h4 class="title is-4">Stability</h4>
            </div>
            <div v-if="metrics.stability">
              <dt style="margin-top: 0.75em">Pressure</dt>
              <div class="columns">
                <div class="column">
                  <apexchart
                    width="100%"
                    height="150"
                    :options="boxChartOptions('tws')"
                    :series="boxChartSeries('Pressure', 'tws')"
                  >
                  </apexchart>
                </div>
                <div class="column stability-container">
                  <p class="stability">This report was puffier than {{ (metrics.stability.relative_tws * 100).toFixed(2) }}% of your other reports.</p>
                </div>
              </div>
              <dt>Direction</dt>
              <div class="columns">
                <div class="column">
                  <apexchart
                    width="100%"
                    height="150"
                    :options="boxChartOptions('twd')"
                    :series="boxChartSeries('Direction', 'twd')"
                  >
                  </apexchart>
                </div>
                <div class="column stability-container">
                  <p class="stability">This report was shiftier than {{ (metrics.stability.relative_twd * 100).toFixed(2) }}% of your other reports.</p>
                </div>
              </div>
            </div>
            <p v-else>For relative stability metrics to be calculated, you must have more than one wind report.</p>
          </div>
        </div>
      </div>
      <div class="spinner-container" v-else>
        <p><i class="fa fa-spinner fa-spin"></i></p>
      </div>
    </layout>
  </div>
</template>

<script>
import layout from "@/views/layouts/Default.vue";
import api from "@/services/api/windfiles.js"
import alertHelper from "@/services/helpers/alertHelper";
import moment from "moment";
import { COLORS } from "@/constants";
import VueApexCharts from "vue-apexcharts";
import router from '@/router/router'; // to redirect after deletion

export default {
  name: "WindReport",
  props: ["report_id"],
  components: {
    layout,
    apexchart: VueApexCharts,
  },
  data: function() {
    return {
      report: null,
      metrics: null,
      windpoints: null,
    }
  },
  created() {
    api.getWindReport(this.report_id).then((result) => {
      const data = result.data;
      this.report = data.report;
      this.metrics = data.metrics;
      this.windpoints = data.windpoints;
    }).catch((error) => {
      alertHelper.alertNotification(this.$store.dispatch, error);
    })
  },
  computed: {
    test() {
      if (this.windpoints) {
        return this.duplicates(this.windpoints.map((x) => x['time']))
      }
    },
    chartOptions() {
      const instance = this
      return {
        chart: {
            id: 'wind-speed-dir',
            group: "timeline",
            width: "100%",
            type: "line",
            animations: {
              enabled: false,
            },

          events: {
            zoomed: instance.dragComplete,
          },

          toolbar: {
            show: true,
          },
          parentHeightOffset: 0,
            selection: {
            enabled: true,
              type: "x",
          },
        },
        colors: COLORS,
          states: {
        active: {
          filter: {
            type: "none",
          },
        },
      },
        stroke: {
          show: true,
            curve: "smooth",
            width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        markers: {
          size: 0,
        },
        yaxis: [
          {
            labels: {
              formatter: function(val) {
                return val.toFixed(2);
              },
            },
          },
          {
            opposite: true,
            labels: {
              formatter: function(val) {
                return val.toFixed(2);
              },
            },
          }
        ],
        xaxis: {
        type: "numeric",
          labels: {
          formatter: function(value, opts) {
            const time = moment(instance.windpoints[0].time) + value
            return moment(time).format("M/D HH:mm:ss");
          },
        },
        crosshairs: {
          show: true,
        },
      },
        tooltip: {
          shared: true,
            followCursor: false,
            x: {
            show: false
          },

          y: {
            show: false
          }
        },
      }
    },
    chartSeries() {
      let allSeries = [];
      const startTime = moment(this.windpoints[0].time)
      for (const variable of ["tws_smoothed", "twd_smoothed"]) {
        let seriesData = this.windpoints.map((wp) => {
          return {
            x: moment(wp.time) - startTime, // moment(wp['time']).utc().unix() * 1000,
            y: wp[variable]
          }
        });

        /*let ii = 0;
        while (ii < 0) {
          ii += 1;
          let i = seriesData.length
          while (i--) (i + 1) % 2 === 0 && (seriesData.splice(i, 1));
        }*/

        // dynamically update data on zoom?. re-create series with  only return points that match
        // might be better for performance

        const series = {
          name: variable,
          type: "line",
          data: seriesData,
        };
        allSeries.push(series);
      }
      return allSeries;
    }
  },
  methods: {
    // Method to delete the wind report
    deleteReport() {
      if (confirm('Are you sure you want to delete this wind report?')) {
        api.deleteWindReport(this.report_id)
          .then(() => {
            alert('Wind report deleted successfully.');
            // Redirect to another route (e.g., list of reports) after deletion
            router.push({ name: 'wind' });  // Make sure the 'WindReportsList' route exists
          })
          .catch(error => {
            alertHelper.alertNotification(this.$store.dispatch, error);
          });
      }
    },
    boxChartOptions(variable) {
      const instance = this
      const options = {
        chart: {
          id: variable,
          height: 150,
          type: 'boxPlot',
          toolbar: {
            show: false,
          },
        },
        yaxis: {

        },
        annotations: {
          xaxis: [
            {
              x: instance.metrics[`std_${variable}`],
              borderColor: COLORS[4],
              label: {
                orientation: 'horizontal',
                borderColor: COLORS[4],
                style: {
                  color: '#fff',
                  background: COLORS[4]
                },
                text: `std ${variable}: ${instance.metrics[`std_${variable}`].toFixed(2)}`,
              }
            }
          ]
        },
        plotOptions: {
          bar: {
            horizontal: true
          },
          boxPlot: {
            colors: {
              upper: COLORS[3],
              lower: COLORS[4]
            }
          }
        }
      }

      // if std will be greater/less than axis range, change to fit
      const std = this.metrics[`std_${variable}`]
      if (std < this.metrics.stability[variable][0]) {
        options['yaxis']['min'] = Math.floor(std / 1.1);
      } else if (std > this.metrics.stability[variable][4]) {
        options['yaxis']['max'] = Math.floor(std * 1.1);
      }
      return options;
    },
    boxChartSeries(variable, var_short) {
      const instance = this
      return [{
        type: 'boxPlot',
        data: [
          {
            x: variable,
            y: instance.metrics.stability[var_short].map(x => x.toFixed(2))
          },
        ]
      }];
    },
    dragComplete(even, axes) {
      let start = axes.xaxis.min;
      const end = axes.xaxis.max;
      this.$emit('dragComplete', {start: start, end: end});

      // fetch updated metrics for selection
    },
    duplicates(arr) {
      let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
      // JS by default uses a crappy string compare.
      // (we use slice to clone the array so the
      // original array won't be modified)
      let results = [];
      for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] == sorted_arr[i]) {
          results.push(sorted_arr[i]);
        }
      }
      return results;
    }
  }
}
</script>

<style lang="sass" scoped>
  .stability-container
    align-items: center
    display: flex

  .stability
    display: flex
    align-content: center

</style>