<template>
  <layout>
    <div class="container px-4 pb-4">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <!-- Tabs Navigation -->
          <div class="tabs">
            <ul>
              <router-link tag="li" :to="{name: 'account'}"><a>Profile</a></router-link>
              <router-link tag="li" :to="{name: 'garmin'}"><a>Garmin</a></router-link>
              <li class="is-active"><a>Plan</a></li>
              <router-link tag="li" :to="{name: 'beta'}"><a>Beta Features</a></router-link>
            </ul>
          </div>

          <!-- Current Subscription Status Card -->
          <div class="box has-background-light mb-4">
            <h1 class="title has-text-centered">Current Subscription Status</h1>
            <b-table :data="subs" :columns="tableColumns" v-if="subs.length > 0"></b-table>
            <b-button class="button is-danger is-fullwidth" v-if="subs.length > 0" @click="cancelSub">
              Cancel Subscription
            </b-button>
            <p v-if="subs.length === 0" class="has-text-centered">You are currently on the free Sailor plan.</p>
          </div>

          <!-- Pricing Table Section -->
          <div class="box has-background-light padded-container">
            <h1 class="title has-text-centered">Choose Your Plan</h1>
            <div class="pricing-table">
              <!-- Starter Plan -->
              <div class="pricing-plan" :class="{'is-active': priceIds.length < 1}">
                <div class="plan-header">Sailor</div>
                <div class="plan-price">
                  <span class="plan-price-amount">
                    <span class="plan-price-currency">$</span>0
                  </span>/month
                </div>
                <div class="plan-items">
                  <div class="plan-item">View Tracks</div>
                  <div class="plan-item">-</div>
                  <div class="plan-item">-</div>
                  <div class="plan-item">-</div>
                </div>
                <div class="plan-footer">
                  <button v-if="priceIds.length < 1" class="button is-fullwidth" disabled="disabled">Current plan</button>
                  <button v-else class="button is-fullwidth" @click="cancelSub">Choose</button>
                </div>
              </div>

              <!-- Loop Through Other Prices -->
              <div
                class="pricing-plan"
                :class="{'is-active': priceIds.includes(price.id)}"
                v-for="price in prices"
                :key="price.id"
              >
                <div class="plan-header">{{ price.product.name }}</div>
                <div class="plan-price">
                  <span class="plan-price-amount">
                    <span class="plan-price-currency">$</span>{{ price.amount }}
                  </span>/{{ price.interval }}
                </div>
                <div class="plan-items">
                  <div class="plan-item">View Tracks</div>
                  <div class="plan-item" v-for="feature in price.product.features" :key="feature">
                    {{ feature | transFeature }}
                  </div>
                  <!-- Empty slots if fewer than 3 features -->
                  <div class="plan-item" v-if="price.product.features.length < 2">-</div>
                  <div class="plan-item" v-if="price.product.features.length < 3">-</div>
                </div>
                <div class="plan-footer">
                  <button v-if="!priceIds.includes(price.id)" class="button is-fullwidth" @click="checkout(price.id)">
                    Choose
                  </button>
                  <button v-else class="button is-fullwidth" disabled="disabled">Current plan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-links />
  </layout>
</template>

<script>
import layout from "@/views/layouts/Default";
import footerLinks from "@/components/shared/footer-links";
import billing from "@/services/api/billing";
import { mapGetters } from "vuex";
import alertHelper from "@/services/helpers/alertHelper";

const STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUB_KEY;
let stripe = Stripe(STRIPE_PUB_KEY);

const featureText = {
  athlete: 'Athlete Tools',
  auto_segmentation: 'Auto Segmentation',
  coach_tools: 'Coach Tools'
};

export default {
  name: "Payment",
  components: {
    layout,
    footerLinks
  },
  data() {
    return {
      prices: [],
      subs: [],
      tableColumns: [
        { field: 'price.name', label: 'Plan' },
        { field: 'status', label: 'Status' },
        { field: 'next_billing_date', label: 'Next Bill' }
      ]
    };
  },
  mounted() {
    // Handle checkout result if returning from Stripe
    if (this.$route.query.checkout_session_id) {
      this.checkoutResultCheck(this.$route.query.checkout_session_id);
    }

    // Get prices instead of plans
    billing.getPrices().then((response) => {
      // Convert amount from cents to dollars
      this.prices = response.data.map(price => ({
        ...price,
        amount: (price.amount).toFixed(0),
        interval: price.recurring ? price.recurring.interval : 'one-time'
      }));
    });

    billing.getSubs().then((response) => {
      this.subs = response.data;
    });
  },
  methods: {
    checkout(priceId) {
      billing.initCheckout(priceId).then((response) => {
        const sessionId = response.data.id;
        stripe.redirectToCheckout({
          sessionId: sessionId
        }).then(function (result) {
          if (result.error) {
            console.error(result.error.message);
          }
        });
      }).catch((error) => {
        console.error(error);
      });
    },
    checkoutResultCheck(sessionId) {
      billing.checkoutResultCheck(sessionId).then((response) => {
        if (response.data.status === 'wait') {
          setTimeout(() => {
            return this.checkoutResultCheck(sessionId);
          }, 3000);
        } else if (response.data.status === 'fail') {
          alertHelper.alertNotification(this.$store.dispatch, 'Payment failed. Please try another payment method.');
        } else if (response.data.status === 'success') {
          this.$store.dispatch('auth/me');
          alertHelper.successNotification(this.$store.dispatch, 'Subscription successful, thank you!');
          // Refresh subscriptions
          billing.getSubs().then((response) => {
            this.subs = response.data;
          });
        }
      });
    },
    cancelSub() {
      this.$buefy.dialog.confirm({
        title: 'Cancel subscription?',
        message: 'Are you sure you want to cancel your subscription? You will be returned to the starter plan.',
        cancelText: 'No, return.',
        confirmText: 'Yes, cancel.',
        type: 'is-danger',
        onConfirm: () => {
          billing.cancelSub().then(() => {
            alertHelper.successNotification(this.$store.dispatch, 'Subscription cancelled.');
            this.$store.dispatch('auth/me');
            billing.getSubs().then((response) => {
              this.subs = response.data;
            });
          }).catch((error) => {
            alertHelper.alertNotification(this.$store.dispatch, error);
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    priceIds() {
      return this.subs.filter(sub => !sub.cancel_at_period_end).map(sub => sub.price.id);
    }
  },
  filters: {
    transFeature(value) {
      return featureText[value] || value;
    }
  }
};
</script>

<style scoped>
.padded-container {
  padding: 2rem;
}
.button img {
  vertical-align: middle;
}
.pricing-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2rem;
}
.pricing-plan {
  border: 1px solid #ddd;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  width: 30%;
  text-align: center;
  margin-bottom: 2rem;
}
.plan-header {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.plan-price {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.plan-items .plan-item {
  margin-bottom: 0.5rem;
}
.plan-footer {
  margin-top: 1rem;
}
</style>
