import store from "@/store/store"
import api from "@/services/api/tracks.js"
import alertHelper from "@/services/helpers/alertHelper"
import router from "@/router/router"
import { getCoordinates } from "@/utils"
import Vue from 'vue';

export const namespaced = true

export const state = {
  tagoptions: {},
  allTracks: [],
  track: null,
  newTagSegment: null,
  prevCursor: null,
  nextCursor: '',
  myTracks: 'all',
  searchText: '',
  showFilterDates: false,
  filterDates: null,
  dates: [],
  oldestDate: null,
  filterUsers: null
}

export const mutations = {
  SET_TAGS(state, tags) {
    state.tagoptions = tags
  },
  ADD_TRACKS(state, tracks) {
    if(tracks.next){
      const next = new URL(tracks.next).searchParams.get('cursor')
      state.nextCursor = next
    }else{
      state.nextCursor = null
    }
    state.allTracks.push(...tracks.results)
  },
  SET_DATES(state, dates) {
    state.dates = dates
  },
  SET_OLDEST_DATE(state, oldestDate) {
    state.oldestDate = oldestDate
  },
  SET_TRACK(state, track) {
    state.track = {
      ...track,
    }
  },
  CREATE_TAG(state, newTagSegment) {
    state.newTagSegment = newTagSegment
  },
  DESTROY_TRACK(state) {
    state.track = null
  },
  DELETE_TRACK(state, track) {
    if (state.track && track.id === state.track.id) {
      state.track = null
    }
    state.allTracks = state.allTracks.filter((t) => t.id !== track.id)
  },
  DESTROY_ALLTRACKS(state) {
    state.allTracks.splice(0)
    state.dates = []
  },
  CLEAR_ALL(state) {
    state.track = null
    state.allTracks = []
    state.tagoptions = {}
    state.newTagSegment = null
  },
  SET_MYTRACKS(state, myTracks) {
    state.myTracks = myTracks
  },
  SET_SEARCH_TEXT(state, searchText) {
    state.searchText = searchText
  },
  SET_SHOW_FILTER_DATES(state, showFilterDates) {
    state.showFilterDates = showFilterDates
  },
  SET_FILTER_DATES(state, filterDates) {
    state.filterDates = filterDates
  },
  SET_FILTER_USERS(state, filterUsers) {
    state.filterUsers = filterUsers
  },
  FORCE_WIND_UPDATE(state, track) {
    const idx = state.allTracks.findIndex(x => x.id == track.id);
    const updatedTrack = track;
    updatedTrack.processing_status = 4;
    Vue.set(state.allTracks, idx, updatedTrack);
  },
  UPDATE_TRACK_IN_STORE(state, track) {
    const idx = state.allTracks.findIndex(x => x.id == track.id);
    Vue.set(state.allTracks, idx, track);
  }
}

export const actions = {
  createTag({ commit, dispatch }, newTagSegment) {
    api
      .createTag(newTagSegment)
      .then((response) => {
        dispatch("tracks/fetchTrack", newTagSegment.track, { root: true })
        alertHelper.successNotification(dispatch, 'Tag created successfully.')
        //commit('CREATE_TAG', response.data)
      })
      .catch((error) => {
        alertHelper.successNotification(dispatch, 'Could not create that tag. Please try again.')
        console.log({
          type: "error",
          message: "There was problem creating tags:" + error.response,
        })
      })
  },
  getTags({ commit, dispatch }) {
    api
      .getTags()
      .then((response) => {
        commit("SET_TAGS", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem fetching tags:" + error.response,
        })
      })
  },
  getDates({ commit, dispatch, state }) {
    api.getDates()
    .then((response) => {
      commit("SET_DATES", response.data)
    })
  },
  getOldestDate({ commit }) {
    api.getOldestDate()
      .then((response) => {
        commit("SET_OLDEST_DATE", response.data)
      })
  },
  getTracks({ commit, dispatch, state }, options) {
    if(options.reset){
      api.getTracks('', state.myTracks, state.searchText, state.filterDates, state.filterUsers).then((response) => {
        commit('DESTROY_ALLTRACKS')
        commit('ADD_TRACKS', response.data)
      })
      dispatch('getDates')
    }else{
      if(state.prevCursor != state.nextCursor){
        api
          .getTracks(state.nextCursor, state.myTracks, state.searchText, state.filterDates, state.filterUsers)
          .then((response) => {
            commit("ADD_TRACKS", response.data)
          })
          .catch((error) => {
            alertHelper.alertNotification(dispatch, error)
            console.log({
              type: "error",
              message: "There was problem fetching tracks:" + error.response,
            })
          })
          state.prevCursor = state.nextCursor
      }
    }
  },
  fetchTrack({ commit, getters, dispatch }, id) {
    api
      .getTrack(id)
      .then((response) => {
        if (response.data.id) {
          commit("SET_TRACK", response.data)
        } else {
          alertHelper.successNotification(
            dispatch,
            "Track is pending now, try again in a few seconds"
          )
          router.push({
            name: "tracks",
          })
        }
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem fetching tracks:" + error.response,
        })
      })
  },
  removeTrack({ commit }) {
    commit("DESTROY_TRACK")
  },
  deleteTrack({ commit, getters, dispatch }, track) {
    api.deleteTrack(track.id).then((response) => {
      if (response.status === 204) {
        commit("DELETE_TRACK", track)
        alertHelper.successNotification(dispatch, "Track has been deleted.")
      } else {
        console.log({ type: "error", message: "Could not remove track." })
      }
    })
  },
  updateTrack({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      api
        .updateTrack(params.id, params.params)
        .then((response) => {
          alertHelper.successNotification(dispatch, "Track has been updated.")
          resolve(response)
        })
        .catch((error) => {
          alertHelper.alertNotification(dispatch, error)
          console.log({
            type: "error",
            message: `There was problem updating track: ${error.response}`,
          })
          reject(error)
        })
    })
  },
  removeAllTracks({ commit }) {
    commit("DESTROY_ALLTRACKS")
  },
  clearAll({ commit }) {
    commit("CLEAR_ALL")
  },
  setSearchText({ commit }, searchText) {
    commit("SET_SEARCH_TEXT", searchText)
  },
  setShowFilterDates({ commit }, showFilterDates) {
    commit("SET_SHOW_FILTER_DATES", showFilterDates)
  },
  setFilterDates({ commit }, filterDates) {
    commit("SET_FILTER_DATES", filterDates)
  },
  setFilterUsers({ commit }, filterUsers) {
    commit("SET_FILTER_USERS", filterUsers)
  },
  forceWindUpdate({ commit }, track) {
    commit("FORCE_WIND_UPDATE", track)
  },
  updateTrackInStore({ commit }, track) {
    commit("UPDATE_TRACK_IN_STORE", track)
  },
}

export const getters = {
  getAllTracks: (state) => {
    return state.allTracks
  },

  getDates: (state) => {
    return state.dates
  },

  my_tracks: (state) => {
    const user = store.getters["auth/user"]

    return user ? state.allTracks.filter((track) => track.user === user.id) : []
  },
}
