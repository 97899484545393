<template>
  <div class="windchart m-4">
    <h1 class="is-size-4 mb-4">Leaderboard</h1>
    <div class="card">
      <b-table :data="truncatedLeaderboard" :mobile-cards="false">
        <b-table-column label="Rank" v-slot="props">
          {{ props.index + 1}}
        </b-table-column>
        <b-table-column field="profile_img" label="Name" v-slot="props">
          <img class="member-img" v-if="props.row.profile_img" :src="props.row.profile_img">
        </b-table-column>
        <b-table-column field="last_name" v-slot="props">
          {{ props.row.first_name }} {{ props.row.last_name }}
        </b-table-column>
        <b-table-column field="time_on_water" label="Hours" v-slot="props">
          {{ props.row.time_on_water.toFixed(2) }}
        </b-table-column>
      </b-table>
      <b-button v-if="leaderboard.length > 10" @click="expanded = !expanded" style="margin-bottom: 16px">
        {{ expanded ? "Show Less" : "Show All" }}
      </b-button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'Leaderboard',
  props: ['start', 'end', 'boatclass', 'user'],
  components: {
  },
  data(){
    return {
      expanded: false,
      leaderboard: [],
      columns:[
        {
          'field': 'first_name',
          'label': 'First Name'
        },
        {
          'field': 'last_name',
          'label': 'Last Name',
        },
        {
          'field': 'time_on_water',
          'label': 'Hours',
          'numeric': true
        }
      ]
    }
  },
  mounted(){
    this.getLeaderboard()
  },
  methods:{
    getLeaderboard(){
      const url = `/reports/leaderboard/?start=${this.start}&end=${this.end}&class=${this.boatclass}&user=${this.user}`
      axios.get(url).then(response => {
        this.leaderboard = response.data
      })
    }
  },
  computed:{
    truncatedLeaderboard() {
      if (this.expanded) {
        return this.leaderboard
      } else {
        return this.leaderboard.slice(0, 10)
      }
    }
  },
  watch:{
    start(){
      this.getLeaderboard()
    },
    end(){
      this.getLeaderboard()
    },
    boatclass(){
      this.getLeaderboard()
    },
    user(){
      this.getLeaderboard()
    }
  }
}
</script>
<style>
  .windchart {
    max-width: 500px;
    text-align: center;
  }
  .member-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    clip-path: circle();
  }
  .table td {
    text-align: left !important;
  }
</style>
