<template>
  <div class="card mb-4">
    <header class="card-header">
      <p class="card-header-title">
        <span v-if="displayJournal.race">
          Race {{ displayJournal.race.time | moment("M/D/YYYY") }} {{ displayJournal.race }}
        </span>
        <span v-else-if="displayJournal.track.time">
          {{ displayJournal.track.full_name }} - {{ displayJournal.track.start_time| moment("M/D/YYYY") }} - {{ displayJournal.track.location }}
        </span>
        <span v-else>
          {{ journal.created | moment("M/D/YYYY")}}
        </span>
      </p>
      <a class="card-header-icon" title="Reply" @click="reply"><b-icon icon="reply"></b-icon></a>
      <Dropdown v-if="ownerOrAdmin" class="custom-dropdown">
        <a class="card-header-icon" slot="icon">
          <b-icon icon="ellipsis-h" slot="icon"></b-icon>
        </a>
        <a class="journal-action" @click="edit = true"><b-icon icon="edit" aria-hidden="true"></b-icon> Edit</a>
        <a class="journal-action" @click="deleteJournal(displayJournal.id)"><b-icon icon="trash" aria-hidden="true"></b-icon> Delete</a>
      </Dropdown>
    </header>
    <div class="card-content">
      <div class="content" v-if="!edit">
        <div class="tags">
          <span class="tag is-success">
            {{ displayJournal.author.first_name }} {{ displayJournal.author.last_name }}
          </span>
          <span
            class="tag"
            v-for="(tag, index) in displayJournal.tags"
            v-bind:key="index"
          >
            {{ tag.value }}
          </span>
          <span v-if="displayJournal.straight_analysis_id" class="tag">
            straight line analysis
          </span>
          <span v-if="displayJournal.regatta_report" class="tag">
            regatta report
          </span>
        </div>
        <p v-if="displayJournal.kite_brand">
          Kite: <strong>{{ displayJournal.kite_brand }} {{ displayJournal.kite_model }}</strong>, Size <strong>{{ displayJournal.kite_size }}</strong>.
        </p>
        <p v-if="displayJournal.foil_brand">
          Foil: <strong>{{ displayJournal.foil_brand }} {{ displayJournal.foil_model }}</strong>,  Wing <strong>{{ displayJournal.foil_wing }}</strong>.
        </p>
        <p v-if="showFeel">
          Feel: <strong>{{ displayJournal.feel }}/5</strong>
        </p>
        <router-link v-if="displayJournal.straight_analysis_name" :to="'/straight-analysis/' + displayJournal.straight_analysis_id">
          {{ displayJournal.straight_analysis_name }} ({{ displayJournal.straight_analysis_segments.join(", ")}})
        </router-link>
        <router-link v-else-if="displayJournal.straight_analysis_id" :to="'/straight-analysis/' + displayJournal.straight_analysis_id">
          Straight Line Analysis ({{ displayJournal.straight_analysis_segments.join(", ")}})
        </router-link>
        <p v-else class="preserve-whitespace">
          {{ strippedJournal }}
        </p>

        <div v-if="displayJournal.regatta_report">
          <div class="columns">
            <div class="column">
              <p>
                <b>Regatta Name:</b>
                {{ regattaReport.name }}
              </p>
              <p>
                <b>Number of Competitors:</b>
                {{ regattaReport.number_competitors }}
              </p>
            </div>
            <div class="column">
              <p>
                <b>Date:</b>
                {{ regattaReport.start_date | moment("M/D/YYYY") }} — {{ regattaReport.end_date | moment("M/D/YYYY") }}
              </p>
              <p>
                <b>Finishing Position:</b>
                {{ regattaReport.finish_position }}
              </p>
            </div>
          </div>
          <div v-if="regattaCompetitors.length > 0">
            <b>Competitor Results</b>
            <div v-for="comp in regattaCompetitors" class="competitor">
              <p v-if="comp.name">
                {{ comp.finish_position }}. {{ comp.name }}
              </p>
              <p v-else>
                {{ comp.finish_position }}. {{ comp.user_name }}
              </p>
            </div>
          </div>
          <b>Associated Tracks</b>
          <p>
            <router-link v-for="track in regattaReport.tracks"
                         :to="{ name: 'track-details', params: { id: track } }">
              {{ track }}
            </router-link>
          </p>
        </div>

        <div v-if="detectYT" class="youtube">
          <iframe class="youtube-vid" :src="detectYT" frameborder="0" allowfullscreen></iframe>
        </div>
        <ImageGrid :images="photoUrls"></ImageGrid>

        <!-- gold star segment stuff? Not sure what this is -->
        <div v-if="displayJournal.jType === 'star'">
          <p>
            <router-link :to="thumbnailLink"><img :src="displayJournal.static_map" alt="mapbox"/></router-link>
          </p>
        </div>

      </div>
      <JournalForm :initial="displayJournal" @journal-created="journalCreated" v-else></JournalForm>
      <p :id="repliesid"><strong>{{ journal.replies.length }} replies</strong></p>
      <JournalReplyDetail :reply="reply" v-for="reply in journal.replies" :key="reply.id" @reply-updated="replyUpdated" @delete-reply="deleteReply"></JournalReplyDetail>
      <JournalReplyForm :journal-id="journal.id" v-show="showReply && !edit" v-bind:editing="false" @reply-added="replyAdded"></JournalReplyForm>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import ImageGrid from "@/components/ui/ImageGrid"
import JournalForm from "@/views/journal/JournalForm"
import JournalReplyForm from "@/views/journal/JournalReplyForm"
import JournalReplyDetail from "@/views/journal/JournalReplyDetail"
import Dropdown from "@/components/ui/Dropdown.vue"
import api from "@/services/api/journal"
import moment from 'moment'

export default {
  name: "JournalDetail",
  props: {
    journal: {
      type: Object,
      required: true,
    },
    showtrack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { ImageGrid, JournalForm, JournalReplyForm, JournalReplyDetail, Dropdown },
  data() {
    return {
      edit: false,
      editedJournal: null,
      showReply: false
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    ownerOrAdmin() {
      const user = this.user
      const author = this.displayJournal.author
      var is_owner = false
      var is_admin = false
      if (user !== undefined) {
        is_owner = user.id === author.id
        is_admin = user.role === 1
      }
      return is_owner || is_admin
    },
    strippedJournal(){
      const regExp = /^https?:\/\/(www)?\.yout.*/m
      return this.displayJournal.content.replace(regExp, '')
    },
    displayJournal(){
      return this.editedJournal ? this.editedJournal : this.journal
    },
    regattaReport() {
      return this.displayJournal.regatta_report
    },
    regattaCompetitors() {
      return this.regattaReport.competitor_results.sort((a, b) => a.finish_position - b.finish_position)
    },
    showFeel() {
      if(this.displayJournal.jType === 'kite') {
        return true
      }
      // if jType isn't set to kite it could be an older
      // kite journal, see if it has basic kite data and a valid feel
      let hasKiteData = this.displayJournal.kite_brand && this.displayJournal.foil_brand
      let validFeel = this.displayJournal.feel > 0
      return hasKiteData && validFeel 
    },
    photoUrls() {
      return this.displayJournal.photos.map((photo) => photo.file)
    },
    detectYT() {
    const regExp = /^[\s\S]*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]{11}).*/;
    const match = this.displayJournal.content.match(regExp);

    return (match && match[2].length === 11)
      ? 'https://www.youtube.com/embed/' + match[2]
      : null;
    },
    repliesid(){
      return 'replies-' + this.journal.id
    },
    thumbnailLink(){
      if(this.displayJournal.jType === 'star'){
        if(this.displayJournal.race){
         return `/races/${this.displayJournal.race}?content=${this.displayJournal.content}`
        }
        else if(this.displayJournal.track){
         return `/tracks/${this.displayJournal.track.id}?content=${this.displayJournal.content}`
       }
      }
      return null
    }
  },
  methods: {
    deleteJournal(id) {
      this.$emit("delete-journal", id)
    },
    journalCreated(journal){
      this.edit = false
      this.editedJournal = journal
      this.$store.dispatch("journal/fetchJournals")
    },
    reply(){
      this.showReply = true
      let el = document.getElementById(this.repliesid)
      el.scrollIntoView({behavior: 'smooth', block: 'center'})
    },
    replyAdded(reply){
      this.showReply = false
      this.journal.replies.push(reply)
    },
    replyUpdated(reply_change) {
      var reply_index = this.journal.replies.indexOf(reply_change.old_reply)
      this.journal.replies.splice(reply_index, 1, reply_change.new_reply)
    },
    deleteReply(reply) {
      api.deleteReply(this.journal.id, reply.id)
        .then(response => {
          var reply_index = this.journal.replies.indexOf(reply)
          this.journal.replies.splice(reply_index, 1)
        })
    },
  },
  mounted() {
  },
}
</script>
<style lang="sass">
// @import "~font-awesome/css/font-awesome.css"

.competitor:last-child
  margin-bottom: 0.75em

.journal-entry
    margin-bottom: 20px
    padding: 0px 0px 10px 10px
    border: 1px solid $light-grey
    border-left: 2px solid $black
    background: $white

.delete-journal
    cursor: pointer
    text-align: right
    height: 0px
    margin-right: 4px
    padding-top: 5px
    font-size: 12px
    &:hover
        color: $red

.preserve-whitespace
    white-space: pre-wrap

.time
    opacity: 0.5
    font-size: 10px

.track-info
    padding-left: 10px
    background: $blue
    color: $white

.journal-action
  width: 80px
  color: $font-color
  cursor: pointer
  &:hover
    color: #6D5CAE

.youtube
  position: relative
  overflow: hidden
  width: 100%
  padding-top: 56.25%

.youtube-vid
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  width: 100%
  height: 100%
</style>
