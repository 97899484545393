<template>
  <div>
    <b-table  :data="tableData"
              bordered
              striped
              hoverable
              default-sort="name"
              style="margin-top: 1em;"
    >
      <b-table-column field="tags" label="Tags" :td-attrs="attrsForItem">
        <template v-slot="props">
          <b-taglist>
            <div v-for="tag in props.row.tags" class="control">
              <b-tag :type="`is-primary`"
                     aria-close-label="Close tag"
              >
                {{ tag.value }}
              </b-tag>
            </div>
          </b-taglist>
        </template>
      </b-table-column>
      <template v-for="column in columns">
        <b-table-column :sortable="column.sortable" :field="column.field" :label="column.label">
          <template v-slot="props">
            <p>
              {{ props.row[column.field] }}
            </p>
          </template>
        </b-table-column>
      </template>
    </b-table>
    <p class="is-size-7" style="margin-top: 0.25em; margin-bottom: 1em;">* regardless of filters applied</p>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "StraightLineChartStats",
  props: ["selectedSegments", "selectionAnalysis", "selectedVar", "userTags"],
  watch: {
    selectionAnalysis: {
      handler(val){
        this.$emit('here')
      },
      deep: true
    }
  },
  data() {
    return {
      key: 0,
      columns: [
        {
          field: 'name',
          label: 'Name',
          sortable: true
        },
        {
          field: 'avg',
          label: 'Avg',
          sortable: true
        },
        {
          field: 'std',
          label: 'Std',
          sortable: true
        },
        {
          field: '25th',
          label: 'Q1',
          sortable: true
        },
        {
          field: '50th',
          label: 'Q2',
          sortable: true
        },
        {
          field: '75th',
          label: 'Q3',
          sortable: true
        },
        {
          field: 'percent_first_quartile',
          label: '% in Q1',
          sortable: true
        },
        {
          field: 'percent_middle_fifty',
          label: '% in Q2-3',
          sortable: true
        },
        {
          field: 'percent_upper_quartile',
          label: '% in Q4',
          sortable: true
        },
      ]
    }
  },
  computed: {
    tableData() {
      let data = [];

      for (const key of Object.keys(this.selectionAnalysis)) {
        data.push(this.dataForSelection(key));
      }
      /*data.push(this.dataForSelection("all_segments"));
      for (const [key] of Object.entries(this.selectedSegments)) {
        if (this.selectionAnalysis[key] !== null) {
          this.$emit('key', key)
          data.push(this.dataForSelection(key));
        }
      };*/
      return data;
    },
  },
  methods: {
    dataForSelection(key) {
      const selection = this.selectionAnalysis[key];

      let obj = {};
      if (key === "all_segments") {
        obj['name'] = "All Segments*"
      } else if (!isNaN(key)) { // tag
        obj['name'] = `Tag: "${this.userTags.find(x => x.id == Number(key)).value}"`
      } else { // segment
        obj['name'] = key
        obj['tags'] = this.selectedSegments[key].tags
      }

      for (const [k, v] of Object.entries(selection[this.selectedVar])) {
        if (k.includes("percent")) {
          obj[k] = Number((v * 100).toFixed(2));
        } else {
          obj[k] = Number(v.toFixed(2));
        }
      }
      return obj
    },
    attrsForItem(row, column) {
      if (column.field.includes("tags")) {
        return {
          style: {
            // 'display': 'block !important',
            'min-width': '15em',
            'max-width': '20em',
          }
        }
      }
    }
  }
};
</script>
