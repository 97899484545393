import { render, staticRenderFns } from "./Accelerations.vue?vue&type=template&id=5d84dc37&scoped=true&"
import script from "./Accelerations.vue?vue&type=script&lang=js&"
export * from "./Accelerations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d84dc37",
  null
  
)

export default component.exports