<template>
  <layout>
    <div class="container p-4">
      <div class="columns">
        <div class="column">
          <div class="tabs">
            <ul>
              <router-link tag="li" active-class="is-active" :to="{name: 'dashboard-journal'}"><a>Journal</a></router-link>
              <router-link tag="li" active-class="is-active" :to="{name: 'dashboard-reports'}"><a>Reports</a></router-link>
            </ul>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import layout from "@/views/layouts/Default"
export default {
  name: "Dashboard",
  components: { layout},
  data() {
    return {}
  },
  computed: {
    ...mapState({
      user(state) {
        return state.auth.user
      },
    }),
  },
}
</script>
<style lang="sass">
.profile-img
  max-width: 200px
  max-height: 200px

dt
  font-weight: bold
  text-decoration: underline

dd
  margin: 0
  padding: 0 0 0.3em 0

.full-name
  font-size: 1.2em
  font-weight: 600
  text-align: center
</style>
