<template>
  <div class="box">
    <span class="tag is-success">{{ reply.author.first_name }} {{ reply.author.last_name }}</span>
    {{ reply.modified | moment("M/D/YYYY hh:mm A")}}
    <b-icon icon="reply"></b-icon>
    <Dropdown v-if="ownerOrAdmin" class="custom-dropdown">
      <b-icon icon="ellipsis-h" slot="icon"></b-icon>
      <a class="journal-action" @click="edit = true"><b-icon icon="edit" aria-hidden="true"></b-icon> Edit</a>
      <a class="journal-action" @click="deleteReply"><b-icon icon="trash" aria-hidden="true"></b-icon> Delete</a>
    </Dropdown>
    <p v-if="!edit" :id="anchor" class="mt-2 preserve-whitespace">
      {{ strippedJournal }}
    </p>
    <JournalReplyForm v-else :reply="reply" v-bind:editing="edit" @reply-updated="replyUpdated" :journal-id="reply.journal" ></JournalReplyForm>
    <div v-if="detectYT" class="youtube">
      <iframe class="youtube-vid" :src="detectYT" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</template>
<script>
import JournalReplyForm from "@/views/journal/JournalReplyForm"
import Dropdown from "@/components/ui/Dropdown.vue"
export default {
  name: 'JournalReplyDetail',
  props: ['reply'],
  components: { JournalReplyForm, Dropdown},
  data() {
    return {
      edit: false
    }
  },
  computed: {
    anchor(){
      return 'reply-' + this.reply.id
    },
    ownerOrAdmin() {
      const user = this.$store.getters["auth/user"]
      const author = this.reply.author
      var is_owner = false
      var is_admin = false
      if (user !== undefined) {
        is_owner = user.id === author.id
        is_admin = user.role === 1
      }
      return is_owner || is_admin
    },
    detectYT() {
    const regExp = /^[\s\S]*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]{11}).*/;
    const match = this.reply.content.match(regExp);

    return (match && match[2].length === 11)
      ? 'https://www.youtube.com/embed/' + match[2]
      : null;
    },
    strippedJournal(){
      const regExp = /^https?:\/\/(www)?\.yout.*/m
      return this.reply.content.replace(regExp, '')
    },
  },
  methods: {
    replyUpdated(reply_change) {
      this.edit = false
      this.$emit('reply-updated', reply_change)
    },
    deleteReply() {
      this.$emit('delete-reply', this.reply)
    }
  }
}
</script>
<style scoped>
.preserve-whitespace {
  white-space: pre-wrap;
}
</style>
