<template>
    <div class="boat-data-list">
        <div class="card">
            <div class="data-row">
                <div class="boat-cell">
                    <div class="expand-button-container">
                        <button class="size-button">
                            <i
                                @click="toggleExpand"
                                class="far"
                                v-bind:class="{
                                    'fa-plus-square': !expanded,
                                    'fa-minus-square': expanded,
                                }"
                            ></i>
                        </button>
                    </div>
                    <p class="competitor header-text">Competitor</p>
                </div>
                <p v-for="col in columns" :key="col" class="data-value header-text">
                    {{ col.toUpperCase() }} {{ units[col] }}
                </p>
            </div>
        </div>
        <div
            class="card"
            v-for="(race, index) in races"
            :key="`race-track-${race.full_name}-${index}`"
            v-bind:class="[
                index === followedBoatIndex ? 'highlight-boat' : '',
                boatIsDisplayed(index) ? '' : 'disabled-click',
            ]"
            @click="setFollowedBoatIndex(index)"
        >
            <div class="data-row">
                <div class="boat-cell">
                    <div
                        class="track-info-card-boat boat-logo"
                        v-bind:style="{ backgroundColor: colors[index] }"
                    >
                        <img
                            src="../../assets/svg/yot.svg"
                            alt="svg"
                            width="20"
                            height="20"
                        />
                    </div>
                    <h6 class="competitor">
                        {{ race.user.first_name }} {{ race.user.last_name }}
                    </h6>
                </div>
                <p v-for="col in columns" :key="col" class="data-value">
                    {{ indicators[index][col] }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { COLORS } from "@/constants";
export default {
    name: "BoatDataList",
    props: [
        "races",
        "indicators",
        "modes",
        "selectedMode",
        "followedBoatIndex",
    ],
    data() {
        return {
            colors: COLORS,
            expanded: false,
            units: {
                lvg: "(m/s)",
                vmg: "(kts)",
            },
        };
    },
    computed: {
        columns() {
            if (this.expanded) {
                return this.modes.filter(
                    (mode) => mode !== "tags" && mode !== "none"
                );
            } else {
                if (this.selectedMode === "none") {
                    return [];
                }
                let is_tags = this.selectedMode === "tags";
                return is_tags ? [] : [this.selectedMode];
            }
        },
    },
    methods: {
        toggleExpand(event) {
            this.expanded = !this.expanded;
        },

        setFollowedBoatIndex(trackIndex) {
            const boatDisplayed = Object.keys(
                this.indicators[trackIndex]
            ).length;
            // prevent from setting as the followed boat.
            if (!boatDisplayed) {
                return;
            }
            this.$emit("setFollowedBoatIndex", trackIndex);
        },

        boatIsDisplayed(boatIndex) {
            if (!this.indicators) {
                return false;
            }
            const boatData = this.indicators[boatIndex];
            return boatData && Object.keys(boatData).length;
        },
    },
};
</script>
<style scoped>
.boat-data-list {
    cursor: pointer;
    margin: 0 5px 10px 5px;
}
.card {
    margin: auto;
    margin-top: 5px;
    padding: 5px;
    width: min-content;
}
.data-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.boat-cell {
    display: flex;
    justify-content: flex-start;
}
.boat-logo {
    flex-shrink: 0;
    margin-right: 10px;
}
.competitor {
    width: 100px;
    word-break: break-all;
}
.header-text {
    font-weight: bold;
}
.data-value {
    width: 45px;
}
.expand-button-container {
    width: 20px;
    margin-right: 10px;
}
.size-button {
    border: none;
    background-color: white;
}
.size-button:hover {
    color: #6d5cae;
}
.highlight-boat {
    background-color: rgba(255, 254, 115, 0.8);
}
.disabled-click {
    cursor: not-allowed;
}
</style>
