import axios from "axios"

export default {
  getTeams() {
    return axios.get("/teams/")
  },
  getTeam(id) {
    return axios.get(`/teams/${id}/`)
  },
  updateTeam(id, formData) {
    return axios.put(`/teams/${id}/`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  },
  createTeam(formData) {
    return axios.post("/teams/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  },
  deleteTeam(id) {
    return axios.delete(`/teams/${id}/`)
  },
  addMember(id, email) {
    return axios.post(`/teams/${id}/add_member/`, { email: email })
  },
  removeMember(id, email) {
    return axios.post(`/teams/${id}/remove_member/`, { email: email })
  },
  updateRole(id, email, role) {
    return axios.post(`/teams/${id}/update_role/`, {
      email: email,
      role: role,
    })
  },
  leaveTeam(id) {
    return axios.post(`/teams/${id}/leave/`)
  },
  changePermission(id, permission) {
    return axios.post(`/teams/${id}/change_permission/`, {
      permission: permission,
    })
  },
}
