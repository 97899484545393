import store from "@/store/store"
import api from "@/services/api/journal.js"
import alertHelper from "@/services/helpers/alertHelper"
import router from "@/router/router"

export const namespaced = true

export const state = {
  journalTrackId: null,
  trackJournal: [],
  journals: [],
  raceJournal: [],
  prevCursor: null,
  nextCursor: ''
}

export const mutations = {
  SET_TRACK_JOURNAL(state, journals) {
    state.trackJournal = journals
  },
  SET_JOURNAL_TRACK_ID(state, id) {
    state.journalTrackId = id
  },
  SET_JOURNALS(state, journals) {
    if(journals.next){
      const next = new URL(journals.next).searchParams.get('cursor')
      state.nextCursor = next
    }else{
      state.nextCursor = null
    }
    state.journals.push(...journals.results)
  },
  SET_RACE_JOURNAL(state, journals) {
    state.raceJournal = journals
  },
  DESTROY_ALLJOURNALS(state) {
    state.journals.splice(0)
  }
}

export const actions = {
  fetchTrackJournal({ commit, dispatch }) {
    api
      .getJournalsByTrack(state.journalTrackId)
      .then((response) => {
        commit("SET_TRACK_JOURNAL", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem fetching journal:" + error.response,
        })
      })
  },

  fetchRaceJournal({ commit, dispatch }, params) {
    api
      .getJournalsByRace(params.race_id, params.share_id)
      .then((response) => {
        commit("SET_RACE_JOURNAL", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem fetching journal:" + error.response,
        })
      })
  },

  setJournalTrackId({ commit, dispatch }, trackId) {
    commit("SET_JOURNAL_TRACK_ID", trackId)
    dispatch("fetchTrackJournal")
  },

  clearJournalTrack({ commit }){
    commit("SET_JOURNAL_TRACK_ID", null)
  },

  deleteJournal({ commit, dispatch }, journalId) {
    api
      .deleteJournal(journalId)
      .then((response) => {
        if(state.journalTrackId){
          dispatch("fetchTrackJournal")
        }
        dispatch("fetchJournals")
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem deleting journal:" + error.response,
        })
      })
  },

  fetchJournals({ commit, dispatch }, options) {
    if(options.reset){
      api.getJournals('', options.search).then((response) => {
        commit('DESTROY_ALLJOURNALS')
        commit('SET_JOURNALS', response.data)
      })
    }else{
      if(state.prevCursor != state.nextCursor){
        api
          .getJournals(state.nextCursor, options.search)
          .then((response) => {
            commit("SET_JOURNALS", response.data)
          })
          .catch((error) => {
            alertHelper.alertNotification(dispatch, error)
            console.log({
              type: "error",
              message: "There was problem fetching journals:" + error.response,
            })
          })
          state.prevCursor = state.nextCursor
      }
    }
  },
}

export const getters = {
  getTrackJournal: (state) => {
    return state.trackJournal
  },
  getRaceJournal: (state) => {
    return state.raceJournal
  },
  getJournalTrackId: (state) => {
    return state.journalTrackId
  },
  getJournals: (state) => {
    return state.journals
  },
}
