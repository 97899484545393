<template>
  <layout>
    <div class="container is-fluid full-height">
      <div v-if="track">
        <div class="spinner-container" v-if="track_id != track.id">
          <p><i class="fa fa-spinner fa-spin"></i></p>
        </div>
        <div v-else >
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <h1 class="is-size-3">Track Report</h1>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <h1 class="title is-1">{{track_id}}</h1>
              </div>
            </div>
          </div>
          <h4 class="is-size-4">{{ track.full_name }}</h4>
          <p>
            {{ track.start_time | moment("M/D/YYYY") }}<br />
            {{track.location}}
          </p>
          <div class="columns" >
              <div class="column">
              <dl v-if="this.track.metric">
                  <dt>DISTANCE </dt>
                  <dd>{{ distance }}mi</dd>
                  <dt>TWA</dt>
                  <dd>{{ track.metric.average_wind_direction }}</dd>
                  <dt>DURATION</dt>
                  <dd>{{ duration }}</dd>
                  <dt>MAX SPEED</dt>
                  <dd>{{ track.metric.max_speed }}</dd>
              </dl>
              </div>
              <div class="column">
                <dl>
                  <dt>TACKS</dt>
                  <dd>{{ track.metric.tacks }}</dd>
                  <dt>SETS</dt>
                  <dd>{{ track.metric.sets }}</dd>
                  <dt>GYBES</dt>
                  <dd>{{ track.metric.gybes }}</dd>
                  <dt>DOUSES</dt>
                  <dd>{{ track.metric.douses }}</dd>
                </dl>
              </div>
          </div>

          <div v-if="track.metrics.length > 0" class="columns" >
            <div class="column">
              <LossChart class="block" :track="track" tag="T"/>
              <dl v-if="has_maneuver_angle" class="block">
                  <dt>AVG TACKING ANGLE </dt>
                  <dd>{{ this.avg_tacking_angle }} °</dd>
                  <dt>AVG TURN RATE</dt>
                  <dd>{{ this.avg_tack_rate }} °/s</dd>
              </dl>
              <LossChart :track="track" tag="B"/>
            </div>
            <div class="column">
              <LossChart class="block" :track="track" tag="G"/>
              <dl v-if="has_maneuver_angle" class="block">
                  <dt>AVG GYBING ANGLE </dt>
                  <dd>{{ this.avg_gybing_angle }} °</dd>
                  <dt>AVG TURN RATE</dt>
                  <dd>{{ this.avg_gybe_rate }} °/s</dd>
              </dl>
              <LossChart :track="track" tag="D"/>
            </div>
          </div>
        </div>
      </div>
      <div class="spinner-container" v-else>
          <p><i class="fa fa-spinner fa-spin"></i></p>
      </div>
    </div>
  </layout>
</template>

<script>
export default {
  name: "BottomMark",
  props: ["track"], // Receives track data as a prop
};
</script>

<style scoped>

</style>