<template>
  <div class="card nopad">
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img :src="athlete.profile_img" />
          </figure>
        </div>
        <div class="media-content">
          <p class="title is-5">
            {{ athlete.first_name }} {{ athlete.last_name }}
          </p>
          <p class="subtitle is-6">{{ athlete.email }}</p>
        </div>
      </div>
      <slot> </slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>
<script>
export default {
  name: "AtheleteCard",
  props: ["athlete"],
}
</script>
<style scoped>
.nopad {
  padding: 0;
}
</style>
