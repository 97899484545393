<template>
  <layout>
    <div class="container full-height px-4 pb-4">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <!-- Tabs Navigation -->
          <div class="tabs">
            <ul>
              <router-link tag="li" :to="{name: 'account'}"><a>Profile</a></router-link>
              <router-link tag="li" :to="{name: 'garmin'}"><a>Garmin</a></router-link>
              <router-link tag="li" :to="{name: 'plan'}"><a>Plan</a></router-link>
              <li class="is-active"><a>Beta Features</a></li>
            </ul>
          </div>

          <!-- Beta Features Form Card -->
          <div class="box has-background-light padded-container">
            <h1 class="title has-text-centered">Beta Features</h1>

            <!-- Form for Enabling Beta Features -->
            <form @submit.prevent="save">
              <!-- Auto Segmentation Switch -->
              <b-field>
                <b-switch
                  id="auto_segmentation"
                  v-model="auto_segmentation_enabled"
                  :disabled="!user.enabled_features['auto_segmentation']">
                    Auto Segmentation
                </b-switch>
              </b-field>

              <!-- Wind Direction Arrow Switch (visible only if auto segmentation is enabled) -->
              <b-field v-if="auto_segmentation_enabled">
                <b-switch
                  id="show_wind_arrow"
                  v-model="show_wind_arrow">
                    Wind Direction Arrow
                </b-switch>
              </b-field>

              <!-- Save Button -->
              <b-field>
                <b-button native-type="submit" type="is-primary" class="button is-link is-fullwidth">Save</b-button>
              </b-field>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer-links />
  </layout>
</template>

<script>
import {mapState} from 'vuex';
import layout from "@/views/layouts/Default";
import alertHelper from "@/services/helpers/alertHelper";
import footerLinks from "@/components/shared/footer-links";

export default {
  name: 'Beta',
  components: {
    layout,
    footerLinks,
  },
  data() {
    return {
      auto_segmentation_enabled: false,
      show_wind_arrow: false
    };
  },
  beforeCreate() {
    this.$store.dispatch("auth/me");
  },
  created(){
    this.auto_segmentation_enabled = this.user.auto_segmentation_enabled;
    this.show_wind_arrow = this.user.show_wind_arrow;
  },
  methods: {
    save(){
      const userData = {
        auto_segmentation_enabled: this.auto_segmentation_enabled,
        show_wind_arrow: this.show_wind_arrow
      };
      this.$store.dispatch("auth/userupdate", userData).catch(() => {
        alertHelper.alertNotification(this.$store.dispatch, 'Could not set features. Please try again.');
      });
    }
  },
  computed: {
    ...mapState({
      user(state) {
        return state.auth.user;
      },
    }),
  }
};
</script>

<style scoped>
.padded-container {
  padding: 2rem;
}
.button {
  margin-top: 1rem;
}
</style>
