<template>
  <div>
    <div class="flex-container">
      <h1 class="is-size-3">Race List</h1>
      <div v-if="user" class="activities-selector">
        <b-button v-if="user.role === 1 || user.enabled_features['coach_tools']" @click="race">
          New Race
        </b-button>
      </div>
      <template
        v-for="race in races"
      >
        <Race
          v-if="race.track"
          :race="race"
          :key="`race-${race.id}`"
        />
      </template>
    </div>
    <RaceForm
      :races="races"
      :tracks="tracks"
      :showRace="showRace"
      @race-created="showRace = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import RaceForm from "@/views/race/RaceForm.vue"
import Race from "@/views/tracks/Race.vue"
import layout from "@/views/layouts/Default.vue"
import Modal from "@/components/ui/Modal.vue"
import moment from "moment"

export default {
  name: "RaceList",
  components: {
    layout,
    Race,
    RaceForm,
    Modal,
  },

  data() {
    return {
      showRace: false,
    }
  },
  beforeCreate() {
    this.$store.dispatch("races/getRaces")
  },
  methods: {
    race() {
      this.showRace = true
    },
  },
  computed: {
    ...mapGetters({
      races: "races/getAllRaces",
      tracks: "tracks/getAllTracks",
      user: "auth/user",
    }),
 },
}
</script>

<style lang="sass">
.activities-selector
  display: flex
  flex-direction: column
  justify-content: center

</style>
