<template>
  <div>
    <div class="map-overlay-tag-area" :style="tagAreaStyles">
      <div class="map-overlay-inner">
        <b-tabs expanded v-model="activeTab">
          <b-tab-item value="data" label="Data">
            <div class="tab-scroll-box" v-if="indicators[0]">
              <div class="track-data-container">
                <div class="is-flex is-align-items-center">
                  <div
                    class="track-info-card-boat mr-3"
                    v-bind:style="{ backgroundColor: colors[0] }"
                  >
                    <img
                      src="@/assets/svg/yot.svg"
                      alt="svg"
                      width="30"
                      height="30"
                    />
                  </div>
                  <h5 class="is-size-5 track-info-card-boat-name">
                    {{ track.user.first_name }} {{ track.user.last_name }}
                  </h5>
                </div>
                <hr class="mt-1 mb-1">
                <div class="is-size-6">TIME: {{ indicators[0].time | moment("timezone", track.tz, "h:mm A z") }}</div>
                <div class="is-size-6" v-for="(measure, index) in trackpointKeys" :key="index">
                  {{ measure.toUpperCase() }}: {{ indicators[0][measure] }}
                  {{ units[measure] }}
                </div>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item
            v-if="track.user.auto_segmentation_enabled"
            value="hooper"
            label="Maneuvers"
          >
            <div class="tab-scroll-box">
              <HooperCarousel
                :metrics="metrics"
                :track="track"
                @moveToStartpoint="moveToStartpoint"
              />
            </div>
          </b-tab-item>
          <b-tab-item
            v-if="track.user.auto_segmentation_enabled"
            value="hooper2"
            label="Straights"
          >
            <div style="display: flex; flex-direction: column;">
              <div class="tab-scroll-box" style="flex-shrink: 5; max-height: 35vh !important; min-height: 20vh">
                <HooperCarousel
                  :metrics="straightMetrics"
                  :track="track"
                  :clickedSegments="clickedSegments"
                  :userTags="userTags"
                  @moveToStartpoint="moveToStartpoint"
                  @straightLineHover="straightLineHover"
                  @toggleSegmentClicked="toggleSegmentClicked"
                  @clearHover="clearHover"
                  @tagRemoved="tagRemoved"
                  @createAndAddTag="createAndAddTag"
                  @addTagToSegment="addTagToSegment"
                  @hideTag="hideTag"
                />
              </div>
              <b-button type="is-primary"
                        style="margin-top: 20px; flex-shrink: 0;"
                        @click="showNameModal = true"
                        :disabled="clickedSegments.length === 0"
              >Create Analysis</b-button>
            </div>
          </b-tab-item>
          <b-tab-item value="star" label="Gold Star" v-if="trackJournal.length">
            <div class="tab-scroll-box">
              <GoldStarList
                :trackJournal="trackJournal"
                @goldStarClicked="goldStarClicked"
                @goldStarHover="goldStarHover"
              />
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
    <b-modal v-model="showNameModal">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Segment</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Straight Line Analysis Name">
          <b-input
            v-model="analysisName"
            placeholder="Name for report (optional)"
            >
          </b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          label="Cancel"
          @click="showNameModal = false" />
        <b-button
          label="Create Analysis"
          type="is-primary"
          @click="createStraightAnalysis"
        />
      </footer>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import HooperCarousel from "@/components/hooper";
import GoldStarList from "./gold-star-list.vue";
import moment from "moment";
import {
  COLORS,
} from "@/constants";
export default {
  props: [
    "track",
    "metrics",
    "straightMetrics",
    "trackpointKeys",
    "indicators",
    "units",
    "trackJournal",
    "clickedSegments",
    "userTags"
  ],

  components: { HooperCarousel, GoldStarList },

  data: function () {
    return {
      activeTab: "data",
      window: {
        width: 0,
        height: 0,
      },
      colors: COLORS,
      showNameModal: false,
      analysisName: ""
    };
  },
  filters: {
    localTime(isoString) {
      var local = moment.utc(isoString).local().format("h:mm:ss A");
      return local;
    },
  },

  computed: {
    ...mapState({
      tagoptions: (state) => state.tracks.tagoptions,
    }),

    tagAreaStyles() {
      let height = 0;
      if (this.window.width > 767) {
        height = `calc(100% - 190px)`;
      } else {
        height = `calc(100% - 260px)`;
      }
      return {
        "max-height": height,
      };
    },
  },

  methods: {
    moveToStartpoint(value) {
      this.$emit("moveToStartpoint", value);
    },

    straightLineHover(value) {
      this.$emit("straightLineHover", value);
    },

    toggleSegmentClicked(value) {
      this.$emit("toggleSegmentClicked", value);
    },

    clearHover(value) {
      this.$emit("clearHover", value);
    },

    goldStarClicked(journal) {
      this.$emit("goldStarClicked", journal);
    },

    goldStarHover(range) {
      this.$emit("goldStarHover", range);
    },

    createStraightAnalysis() {
      this.$emit("createStraightAnalysis", this.analysisName);
    },
    createAndAddTag(args) {  // forward events to map controller
      this.$emit('createAndAddTag', args);
    },
    addTagToSegment(args) {
      this.$emit('addTagToSegment', args);
    },
    tagRemoved(args) {
      this.$emit('tagRemoved', args);
    },
    hideTag(tag) {
      this.$emit('hideTag', tag);
    },
  },
};
</script>
<style lang="sass">
.map-overlay-tag-area
  z-index: 5
  min-width: 300px
  font-size: 12px
  position: absolute
  top: 20px
  left: 10px
  background-color: #fff
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
  border-radius: 3px
  display: flex
  flex-direction: column
  align-items: center

  .map-overlay-inner
    position: relative
    padding: 10px
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    height: 100%

.hooper-slide
  background-color: $bg-color
  margin-bottom: 5px
  border: 1px solid $border-color
  border-radius: 4px
  display: flex

  .hooper-slide-image
    width: 100px
    height: 100px
    flex-shrink: 0
    padding: 5px

    img
      height: 100%
      width: 100%
      border-radius: 3px

  .hooper-slide-description
    width: 100%
    padding: 5px
    display: flex
    flex-direction: column
    font-size: 0.9em

    .segment-name
      padding-bottom: 5px

    fieldset
      width: 160px
      display: flex
      flex-direction: column
      align-items: center
      border: none
      padding: 0

      &:last-child
        margin: 0

    select
      margin-bottom: 10px
      width: 100%

    label
      display: block
      font-weight: bold
      margin: 0 0 5px

  button
    width: 100%
</style>

<style lang="sass" scoped>
.tab-scroll-box
  overflow: auto
  max-height: 40vh
</style>