import axios from "axios"
import moment from "moment-timezone"

export default {
  getTracks(cursor, owner, search='', dates=null, filterUsers=null) {
    let query_url = '/tracks/'
    query_url += `?cursor=${cursor}`
    let start_date=''
    let end_date = ''
    if (dates) {
      start_date = dates[0]
      end_date = dates[1]
      query_url += `&tz=${moment.tz.guess()}`
    }
    query_url += `&owner=${owner}`
    query_url += `&search=${search}`
    query_url += `&start_date=${start_date}`
    query_url += `&end_date=${end_date}`

    if (filterUsers) {
      for (const filterUser of filterUsers) {
        query_url += `&filter_users=${filterUser}`
      }
    }
    return axios.get(query_url)
  },

  getDates() {
    return axios.get('/tracks/dates/')
  },

  getOldestDate() {
    return axios.get('tracks/oldest_date/')
  },

  getDayTracks(cursor, owner, day) {
    // Get all tracks on the same day as the day timestamp
    return axios.get(`/tracks/?cursor=${cursor}&owner=${owner}&day=${day}`)
  },

  getTrack(id, noTrackpoints = false) {
    let url = "/tracks/" + id + "/"
    if (noTrackpoints) {
      url += "?notrackpoints=true"
    }
    return axios.get(url)
  },

  getTags() {
    return axios.get("/tags/")
  },

  getSegment(id) {
    return axios.get(`/segments/${id}/`)
  },

  createTag(newTagSegment) {
    return axios.post("/segments/", newTagSegment)
  },

  deleteTrack(id) {
    return axios.delete("/tracks/" + id + "/")
  },

  updateTrack(id, params) {
    return axios.patch(`/tracks/${id}/`, params)
  },

  createStraightLineAnalysis(params) {
    return axios.post('/straights/', params)
  },

  getStraightLineAnalysis(id) {
    return axios.get(`/straights/${id}/`)
  },

  updateStraightLineAnalysisName(id, name) {
    return axios.patch(`/straights/${id}/`, {'name': name})
  },

  getStraightSelectionAnalysis(id, start, end) {
    return axios.get(`/straights/${id}/selection/`, {params: {'start_time': start, 'end_time': end}})
  },

  getTagSelectionAnalysis(id, tag_ids) {
    return axios.get(`/straights/${id}/selection/`, {params: {'tag_ids': tag_ids}})
  },

  addUserTagToSegment(segment_id, tag_id, track_id) {
    return axios.post(`/segments/${segment_id}/tags/`, {'tag_id': tag_id, 'track': track_id})
  },

  removeTagFromSegment(segment_id, tag_id, track_id) {
    return axios.delete(`/segments/${segment_id}/tags/`, {data: {'tag_id': tag_id, 'track': track_id}})
  },

  getUserSegmentTags() {
    return axios.get("/user-tags/")
  },

  createUserSegmentTags(tagValue) {
    return axios.post("/user-tags/", {color: 'green', value: tagValue})
  },

  hideUserSegmentTag(tagId) {
    return axios.post(`/user-tags/${tagId}/hide/`)
  },

  showUserSegmentTag(tagId) {
    return axios.post(`/user-tags/${tagId}/show/`)
  },

  windFileUploaded(windFilePath, origFileName) {
    return axios.post(`/windfiles/`, {'file_path': windFilePath, 'orig_file_name': origFileName})
  },

  getCorrs(id, data) {
    return axios.get(`/straights/${id}/corrs/`, {params: {'vals': data}})
  },
}
