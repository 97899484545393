<template>
  <b-modal :active="show" :on-cancel="close">
    <div v-if="show" class="modal-card-container modal-height">
      <div class="modal-card">
        <div class="modal-card-head">
          <p class="modal-card-title is-flex-shrink-1">Change Race Name</p>
        </div>
        <section class="modal-card-body">
          <b-field label="Name">
            <b-input v-model="race_name">
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <div class="footer-container">
            <b-button type="is-primary" @click="save"> Save </b-button>
            <b-button type="is-danger" @click="close"> Cancel </b-button>
            <p style="color: red;" v-if="showError">{{errors.save}}</p>
          </div>
        </footer>
      </div>
    </div>
  </b-modal>
  
</template>

<script>
export default {
  props: {
    race: Object,
    show: Boolean,
  },
  data() {
    return {
      initialName: "",
      race_name: "",
    }
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      if (this.initialName === this.race_name) {
        // no changes were made
        this.close()
        return
      }
      let newRace = Object.assign({}, this.race)
      newRace.name = this.race_name
      this.$store.dispatch("races/updateRaceName", newRace)
      this.close()
    },
  },
  watch: {
    show(val) {
      if(!val) {
        return
      }
      this.initialName = this.race.name ? this.race.name : ""
      this.race_name = this.initialName === "" ? `Race ${this.race.id}` : this.initialName
    }
  }
}
</script>

<style scoped>
</style>