import _ from "lodash"
import {
  COLORS,
  TAIL_LENGTH,
} from "@/constants"
export function emptySegment(color = "#000") {
  return {
    type: "Feature",
    properties: {
      color,
    },
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  }
}

export function getSourceHelper(map, id, data) {
  try {
    if (map.getSource(id)) {
      map.getSource(id).setData(
        data || {
          type: "FeatureCollection",
          features: [],
        }
      )
    }
  } catch (error) {}
}

export function setCenter(map, currentTime, trackpoints) {
  let coordinate = []
  if (trackpoints[currentTime]) {
    coordinate = [trackpoints[currentTime].lon, trackpoints[currentTime].lat]
  } else {
    const timestamp = nearBy(Object.keys(trackpoints), currentTime)
    coordinate = [trackpoints[timestamp].lon, trackpoints[timestamp].lat]
  }

  if (coordinate) {
    map.flyTo({
      center: coordinate,
    })
  }
}

export function createTracks(tracks, checkNames) {
  let trackList = []

  tracks.forEach((track, i) => {
    checkNames.push(i)
    trackList[i] = track.trackpoints.reduce((obj, t) => {
      const d = new Date(t.time).getTime()
      obj[d] = t
      return obj
    }, {})
  })

  return trackList
}

export function getAngle(prev, current) {
  const dx = current.lon - prev.lon
  const dy = current.lat - prev.lat

  const ar = Math.atan2(dy, dx)
  const ag = (ar * 180) / Math.PI

  let angle = 360 - ag
  if (angle >= 360) angle = angle - 360

  return angle
}

export function nearBy(numbers, needle) {
  numbers.sort((a, b) => {
    return Math.abs(needle - parseInt(a)) - Math.abs(needle - parseInt(b))
  })

  return numbers[0]
}

class MapTrackerWithBoat {
  tail = 0
  pause = true
  // input
  points = []

  onNextTick = null
  _lastTime = null

  workGeoJson = []
  speedGeoJson = []
  tailWorkGeoJson = []
  animationDebounce = 0
  tailWorkGeoOpacityJson = []
  debounceTime = 50
  ind = 0

  routes = []
  currentTime = 0
  angel = []

  limits = {}
  startTime = 0
  endTime = 0
  _prevTimestamp = []
  _prevPTimestamp = []
  mode = "sog"
  indicators = []
  opacity_coordinates = []
  opacity_pre = []
  taggedFeatures = []

  constructor(mode, taggedFeatures, tracks, limits, map, onNextTick) {
    this.taggedFeatures = taggedFeatures
    this.mode = mode
    this.map = map
    this.tracks = tracks
    this.limits = limits

    tracks.forEach((t, i) => {
      this.map.addLayer({
        id: `lineOpacity${i}`,
        type: "line",
        source: {
          type: "geojson",
          lineMetrics: true,
          data: {
            type: "FeatureCollection",
            features: [],
          },
        },
        paint: {
          "line-width": 2,
          "line-color": COLORS[i],
          "line-opacity": 0.6,
          "line-gradient": [
            "interpolate",
            ["linear"],
            ["line-progress"],
            0,
            "transparent",
            1,
            COLORS[i],
          ],
        },
      })

      this.tailWorkGeoJson[i] = {
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        }, ],
      }

      this.tailWorkGeoOpacityJson[i] = {
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        }, ],
      }

      this.workGeoJson[i] = {
        type: "FeatureCollection",
        features: [],
      }

      this.speedGeoJson[i] = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: Object.keys(t).map((timestamp) => [
            t[timestamp].lon,
            t[timestamp].lat,
          ]),
        },
      }

      this.routes[i] = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: Object.keys(t).map((timestamp) => [
            t[timestamp].lon,
            t[timestamp].lat,
          ]),
        },
      }

      this.angel[i] = 0

      this.indicators[i] = []
    })
    this.setFeatures()
    if (typeof onNextTick === "function") this.onNextTick = onNextTick

    // this.restart();
  }

  setFeatures(features) {
    const pause = this.pause

    this.tracks.forEach((_, ind) => {
      getSourceHelper(this.map, `line${ind}`)
    })
  }

  setTagSegment() {
    this.tracks.map((_, ind) => {
      if (this.taggedFeatures) {
        const data = {
          type: "FeatureCollection",
          features: [...this.taggedFeatures],
        }
        getSourceHelper(this.map, `line${ind}`)
        getSourceHelper(this.map, `route${ind}`)
        getSourceHelper(this.map, `speed${ind}`)
        getSourceHelper(this.map, `tagSegment`, data)
      }
    })
  }

  setTagSegments(checkNames = null) {
    let taggedSegments = this.taggedFeatures
    if (this.selected) {
      taggedSegments = this.selectedTaggedFeatures(checkNames)
    }
    taggedSegments.map((feature, ind) => {
      getSourceHelper(this.map, `line${ind}`)
      getSourceHelper(this.map, `route${ind}`)
      getSourceHelper(this.map, `speed${ind}`)
      if (feature.length > 0) {
        const data = {
          type: "FeatureCollection",
          features: [...feature],
        }
        getSourceHelper(this.map, `TagSegmentsLayer${ind}`, data)
      }
      if(checkNames) {
        if (!checkNames.has(ind)) {
          // if not in checked names remove the segments
          getSourceHelper(this.map, `TagSegmentsLayer${ind}`)
        }
      }
    })
  }

  setHoverTagSegment(features) {
    if (features) {
      const data = {
        type: "FeatureCollection",
        features: [...features],
      }
      getSourceHelper(this.map, `hoverTagSegment`, data)
    } else {
      getSourceHelper(this.map, `hoverTagSegment`)
    }
  }

  setTimeLine(newTime) {
    if (newTime > 0) {
      this._animationRunSpeed(newTime)
    }
  }

  setClickedTime(time) {
    this._lastTime = time
  }

  setOpacityTimeLine(time_stamp, checkNames = null) {
    const count = this.tracks.length
    for (let [i, t] of this.tracks.entries()) {
      let showTrack = checkNames ? checkNames.has(i) : true
      if (!showTrack) {
        // don't update opacity for boat's that aren't shown
        continue
      }
      // draw boat
      let timestamp = time_stamp
      if (t[time_stamp]) {
        timestamp = time_stamp
      } else {
        timestamp = nearBy(Object.keys(t), time_stamp)
      }

      const c_p = Object.keys(t).indexOf(String(timestamp))

      if (c_p !== -1) {
        let p_timestamp
        if (c_p === 0) {
          p_timestamp = Object.keys(t)[c_p + 1]
        } else {
          p_timestamp = Object.keys(t)[c_p - 1]
        }
        const coordinates = [t[timestamp].lon, t[timestamp].lat]
        const pre = [t[p_timestamp].lon, t[p_timestamp].lat]

        this.opacity_coordinates[i] = coordinates
        this.opacity_pre[i] = pre

        getSourceHelper(this.map, `boatLayerId4${i + count}`, {
          type: "Point",
          coordinates,
        })

        const bearing = this.map.getBearing()

        if (coordinates.length > 0 && pre.length > 0) {
          c_p === 0 ?
            this.setBoatOpacityAngel(pre, coordinates, bearing, i) :
            this.setBoatOpacityAngel(coordinates, pre, bearing, i)
        }
        if (this.tail === 1) {
          this.drawOpacityTail(t, timestamp, i)
        }
      }
    }
  }

  selectSection(selected, checkNames = null) {
    this.selected = selected
    if(this.tail === 2 && this.mode === 'tags') {
      this.setTagSegments(checkNames)
    } else {
      this.drawLine(checkNames)
    }
  }

  

  selectedTaggedFeatures(checkNames = null){
    // for each track's tagged features,
    // filter out tags that are not in the selection,
    // and trim those that stick out
    let selectedTags = this.taggedFeatures.map((trackTags) => {
      let newTrackTags = []
      trackTags.forEach((trackTag, tag_i) => {
        let coordinates = trackTag.geometry.coordinates
        let start_coordinate = coordinates[0]
        let end_coordinate = coordinates[coordinates.length - 1]
        let coord_time_index = 2
        let tag_start_time = new Date(start_coordinate[coord_time_index]).getTime()
        let tag_end_time = new Date(end_coordinate[coord_time_index]).getTime()
        let start_in = tag_start_time > this.selected.start && tag_start_time < this.selected.end
        let end_in = tag_end_time > this.selected.start && tag_end_time < this.selected.end
        let tag_fully_in = start_in && end_in
        let tag_partially_in = start_in || end_in
        let is_first_tag = tag_i === 0
        // trim the first tag because it covers gaps between tags
        if(is_first_tag || tag_partially_in) {
          // trim the tag
          let newCoordinates = coordinates.filter((coordinate) => {
            let time = new Date(coordinate[coord_time_index]).getTime()
            let coordinateIn = time >= this.selected.start && time <= this.selected.end
            return coordinateIn
          })
          // modify coordinates on a copy and add it to newTrackTags
          let trackTag_copy = _.cloneDeep(trackTag)
          trackTag_copy.geometry.coordinates = newCoordinates
          newTrackTags.push(trackTag_copy)

        } else if(tag_fully_in) {
          newTrackTags.push(trackTag)
        }
      })
      return newTrackTags
    })
    this.addSubTags(selectedTags, checkNames)
    return selectedTags
  }

  addSubTags(selectedTags, checkNames = null) {
    // See if the selection is within a segment
    // for any tracks. If so, find the segment
    // that has a sub-section selected, trim it, and
    // add it to the track's tags.
    selectedTags.forEach((trackTags, trackIndex) => {
        let hasSegment = trackTags.length > 1
        let hasTags = (checkNames === null) ? true : checkNames.has(trackIndex)
        // if the track has tags and
        // there's no segment other than the grey segment
        // then the track must have a sub segment
        let hasSubSeg = hasTags && !hasSegment 
        if (hasSubSeg) {
          let subSegment = this.findSelectedSubsegment(trackIndex)
          selectedTags[trackIndex].push(subSegment)
        }
    })

  }

  findSelectedSubsegment(trackIndex) {
    // find the segment in a given track that has
    // a sub-section selected
    let trackTags = this.taggedFeatures[trackIndex]
    let coord_time_index = 2
    let subSegments = trackTags.filter((segmentTag, tagIndex) => {
      // first segment is the default gray, ignore it
      if(tagIndex === 0) {
        return false
      }
      let coordinates = segmentTag.geometry.coordinates
      // see if the selected start is greater than the segment start
      // and the selected end is less than the segment end
      let start_coordinate = coordinates[0]
      let end_coordinate = coordinates[coordinates.length - 1]
      let tag_start_time = new Date(start_coordinate[coord_time_index]).getTime()
      let tag_end_time = new Date(end_coordinate[coord_time_index]).getTime()
      let start_in_seg = this.selected.start >= tag_start_time
      let end_in_seg = this.selected.end <= tag_end_time
      let is_sub_seg = start_in_seg && end_in_seg
      return is_sub_seg
    })
    // make a copy before trimming
    let subSeg = _.cloneDeep(subSegments[0])
    // trim the subSegment
    subSeg.geometry.coordinates = subSeg.geometry.coordinates.filter((coordinate) => {
      let time = new Date(coordinate[coord_time_index]).getTime()
      let coordinateIn = time > this.selected.start && time < this.selected.end
      return coordinateIn
    })
    return subSeg
  }

  drawLine(checkNames) {
    this.tracks.map((t, ind) => {
      // default to showing the track
      // otherwise see if the track is checked
      let showTrack = checkNames ? checkNames.has(ind) : true
      if (showTrack) {
        if (this.selected) {
          let selectedTrackPoints = Object.keys(t)
            .filter(
              (time) =>
              time >= this.selected.start &&
              time <= this.selected.end
            )
          let selectedRoute = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: selectedTrackPoints
                .map((timestamp) => [t[timestamp].lon, t[timestamp].lat]),
            },
          }

          if (this.tail === 0) {
            getSourceHelper(this.map, `route${ind}`, selectedRoute)
            getSourceHelper(this.map, `speed${ind}`)
            getSourceHelper(this.map, `line${ind}`)
            getSourceHelper(this.map, `tagSegment`)
            getSourceHelper(this.map, `TagSegmentsLayer${ind}`)
          } else if (this.tail === 2) {
            getSourceHelper(this.map, `route${ind}`)
            getSourceHelper(this.map, `line${ind}`)
            getSourceHelper(this.map, `tagSegment`)
            getSourceHelper(this.map, `TagSegmentsLayer${ind}`)
          } else {
            this.drawTail(t, this._lastTime, ind)
            getSourceHelper(this.map, `route${ind}`)
            getSourceHelper(this.map, `speed${ind}`)
            getSourceHelper(this.map, `tagSegment`)
            getSourceHelper(this.map, `TagSegmentsLayer${ind}`)
          }
          getSourceHelper(this.map, `line-full-path${ind}`, selectedRoute)
        } else {
          if (this.tail === 1) {
            this.drawTail(t, this._lastTime, ind)
            getSourceHelper(this.map, `line-full-path${ind}`, this.tailWorkGeoJson[ind])
            getSourceHelper(this.map, `route${ind}`)
            getSourceHelper(this.map, `speed${ind}`)
            getSourceHelper(this.map, `tagSegment`)
            getSourceHelper(this.map, `TagSegmentsLayer${ind}`)
          } else if (this.tail === 0) {
            getSourceHelper(this.map, `line${ind}`)
            getSourceHelper(this.map, `line-full-path${ind}`, this.routes[ind])
            getSourceHelper(this.map, `route${ind}`, this.routes[ind])
            getSourceHelper(this.map, `speed${ind}`)
            getSourceHelper(this.map, `tagSegment`)
            getSourceHelper(this.map, `TagSegmentsLayer${ind}`)
          } else {
            getSourceHelper(this.map, `line${ind}`)
            getSourceHelper(this.map, `route${ind}`)
            getSourceHelper(this.map, `tagSegment`)
            getSourceHelper(this.map, `TagSegmentsLayer${ind}`)
            getSourceHelper(this.map, `line-full-path${ind}`, this.routes[ind])
          }
        }
      } else {
        // remove the track
        getSourceHelper(this.map, `route${ind}`);
        getSourceHelper(this.map, `line-full-path${ind}`);
        getSourceHelper(this.map, `TagSegmentsLayer${ind}`);
        getSourceHelper(this.map, `line${ind}`);
        getSourceHelper(this.map, `speed${ind}`);
      }
    })
  }

  getTailData(track, timestamp) {
    let t = track
    let selectedTrackPoints = Object.keys(t)
    if (this.selected){
      selectedTrackPoints = Object.keys(t)
        .filter(
          (time) =>
          time >= this.selected.start &&
          time <= this.selected.end
        )
    }
    let tailCoordinates = selectedTrackPoints
      .filter((times) => times > timestamp - TAIL_LENGTH && times <= timestamp)
      .map((times) => [t[times].lon, t[times].lat])
    
    return tailCoordinates
  }

  drawTail(track, timestamp, i) {
    let tailCoordinates = this.getTailData(track, timestamp)
    this.tailWorkGeoJson[
        i
      ].features[0].geometry.coordinates = tailCoordinates
    getSourceHelper(this.map, `line${i}`, this.tailWorkGeoJson[i])
  }

  drawOpacityTail(track, timestamp, i) {
    let tailCoordinates = this.getTailData(track, timestamp)
    this.tailWorkGeoOpacityJson[
        i
      ].features[0].geometry.coordinates = tailCoordinates
    getSourceHelper(this.map, `lineOpacity${i}`, this.tailWorkGeoOpacityJson[i])
  }

  tailToggle() {
    this.tail = (this.tail + 1) % 3
    if (this.mode !== "tags") {
      this.drawLine()
    }
  }

  changeTail(tail) {
    this.tail = tail
    this.drawLine()
  }

  changeMode(mode) {
    this.mode = mode
  }

  setBoatAngel(coordinates, prevCoordinates, bearing = 0, i) {
    if (!this.map.getLayer(`boatLayerId4${i}`)) {
      // early return if layer isn't on map
      return
    }
    const [ax, ay] = prevCoordinates
    const dx = coordinates[0] - ax
    const dy = coordinates[1] - ay

    const ar = Math.atan2(dy, dx)
    const ag = (ar * 180) / Math.PI

    let angel = 360 - ag - bearing
    if (angel >= 360) angel = angel - 360
    angel === 0 ? (angel = this.angel[i]) : (this.angel[i] = angel)
    this.map.setLayoutProperty(`boatLayerId4${i}`, "icon-rotate", angel)
  }

  setBoatOpacityAngel(coordinates, prevCoordinates, bearing = 0, i) {
    const count = this.tracks.length
    if (!this.map.getLayer(`boatLayerId4${i + count}`)) {
      return
    }
    const [ax, ay] = prevCoordinates
    const dx = coordinates[0] - ax
    const dy = coordinates[1] - ay

    const ar = Math.atan2(dy, dx)
    const ag = (ar * 180) / Math.PI

    let angel = 360 - ag - bearing
    if (angel >= 360) angel = angel - 360
    this.map.setLayoutProperty(
      `boatLayerId4${i + count}`,
      "icon-rotate",
      angel
    )
  }

  hasBoat(track_index) {
    // see if the boat with track_index is on the map
    const count = this.tracks.length
    let boat = this.map.getLayer(`boatLayerId4${track_index}`)
    let boatO = this.map.getLayer(`boatLayerId4${track_index + count}`)
    let has_boat = boat !== undefined && boatO !== undefined
    return has_boat
  }

  setRotate() {
    const count = this.tracks.length
    this.tracks.map((t, i) => {
      // only rotate if the boat is on the map
      if (this.map.getLayer(`boatLayerId4${i}`)) {
        const bearing = this.map.getBearing()
        if (this._prevTimestamp[i] && this._prevPTimestamp[i]) {
          this.setBoatAngel(
            this._prevTimestamp[i],
            this._prevPTimestamp[i],
            bearing,
            i
          )
        } else {
          this.map.setLayoutProperty(`boatLayerId4${i}`, "icon-rotate", bearing)
        }
        if (this.opacity_coordinates[i] && this.opacity_pre[i]) {
          this.setBoatOpacityAngel(
            this.opacity_coordinates[i],
            this.opacity_pre[i],
            bearing,
            i
          )
        } else {
          if(this.map.getLayer(`boatLayerId4${i + count}`)) {
            this.map.setLayoutProperty(
              `boatLayerId4${i + count}`,
              "icon-rotate",
              bearing
            )
          }
        }
      }
    })
  }

  _animationRunSpeed(newTime) {
    this._lastTime = newTime
    for (let [i, t] of this.tracks.entries()) {
      if (!this.map.getLayer(`boatLayerId4${i}`)) {
        // only update boats that are on the map
        continue
      }
      let timestamp = newTime
      if (t[newTime]) {
        timestamp = newTime
      } else {
        timestamp = nearBy(Object.keys(t), newTime)
      }

      this.indicators[i] = t[timestamp]
      const c_p = Object.keys(t).indexOf(String(timestamp))
      let p_timestamp
      if (c_p !== -1) {
        if (c_p === 0) {
          p_timestamp = Object.keys(t)[c_p + 1]
        } else {
          p_timestamp = Object.keys(t)[c_p - 1]
        }
        const coordinates = [t[timestamp].lon, t[timestamp].lat]
        const pre = [t[p_timestamp].lon, t[p_timestamp].lat]

        if (coordinates.length > 0) {
          const bearing = this.map.getBearing()

          if (typeof this.onNextTick === "function") {
            this.onNextTick(this.indicators)
          }

          this._prevTimestamp[i] = coordinates
          this._prevPTimestamp[i] = pre
          c_p === 0 ?
            this.setBoatAngel(pre, coordinates, bearing, i) :
            this.setBoatAngel(coordinates, pre, bearing, i)

          if (this.tail === 1) {
            this.drawTail(t, timestamp, i)
          }
        }

        if (newTime) {
          getSourceHelper(this.map, `boatLayerId4${i}`, {
            type: "Point",
            coordinates: coordinates,
          })
        }
      }

    }
  }
}

let Track = null
export function getSingleTrack(...args) {
  if (!Track) Track = new MapTrackerWithBoat(...args)
  return Track
}

// export function factoryTrack(...args) {
// 	return new SingleMapTrackerWithBoat(...args);
// }

export function mutlyFactoryTrack(...args) {
  return new MapTrackerWithBoat(...args)
}