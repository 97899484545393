<template>
  <layout>
    <div class="map-container" v-if="track">
      <mapbox
        access-token="pk.eyJ1Ijoib2xla3NhbmRyemlib3JvdiIsImEiOiJjazU1N2RjbTAwYXJ4M25tcTVpOHM4MzRhIn0.5zAQ6BGd72UXtVzs0wurFA"
        :map-options="{
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [track.trackpoints[0].lon, track.trackpoints[0].lat],
          bearing: this.initialWindDir,
          zoom: 14,
          pitchWithRotate: false,
        }"
        :nav-control="{
          show: true,
          options: {
            showCompass: true,
            showZoom: false,
            visualizePitch: false,
          },
        }"
        :scale-control="{
          show: true,
          position: 'top-right',
        }"
        :fullscreen-control="{
          show: true,
          position: 'top-right',
        }"
        @map-load="load"
        @map-rotate="rotate"
      />
      <WindDirection
        v-if="user.show_wind_arrow && windMeasure"
        :currentPoint="indicators[0]"
        :mapRotation="mapRotation"
        :initialWindDir="initialWindDir"
        :measure="windMeasure"
      />
      <MapDataTabs
        class="is-hidden-mobile"
        :track="track"
        :metrics="selectedMetrics"
        :straightMetrics="straightSelectedMetrics"
        :trackpointKeys="trackpointKeys"
        :indicators="indicators"
        :units="units"
        :trackJournal="trackJournal"
        :clickedSegments="clickedSegments"
        :userTags="userTags"
        @moveToStartpoint="moveToStartpoint"
        @straightLineHover="straightLineHover"
        @toggleSegmentClicked="toggleSegmentClicked"
        @clearHover="clearHover"
        @goldStarHover="goldStarHover"
        @goldStarClicked="setClickedGoldStar"
        @createStraightAnalysis="createStraightAnalysis"
        @tagRemoved="tagRemoved"
        @createAndAddTag="createAndAddTag"
        @addTagToSegment="addTagToSegment"
        @hideTag="hideTag"
      />
      <ColorBox
        v-if="track && dataMode"
        :minMaxValues="getMinMax"
        :selectionMode="selectionMode"
      />
      <div class="map-player-container">
        <div class="start-buttons-container">
          <div class="start-button" @click="onPause">
            <i
              v-if="pause"
              class="fa fa-play"
              aria-hidden="true"
              style="color: white; font-size: 16px"
            >
            </i>
            <i
              v-if="!pause"
              class="fa fa-pause"
              aria-hidden="true"
              style="color: white; font-size: 16px"
            >
            </i>
          </div>
          <div v-if="xSpeed" class="x-speed" @click="changeSpeed">
            {{ xSpeed[currentSpeed] }}x
          </div>
        </div>
        <TrackChart
          :tracks="tracks"
          :currentTime="currentTime"
          :map="map"
          :pause="pause"
          :startTime="startTime"
          :endTime="endTime"
          :selectionMode="selectionMode"
          :lastDataMode="lastDataMode"
          :modes="getModes"
          :colors="colors"
          :units="units"
          :speed="xSpeed[currentSpeed]"
          :track_id="id"
          :hoverTime="hoverTime"
          :trackJournal="trackJournal"
          :indicators="indicators"
          :changeMathType="changeMathType"
          :mathType="mathType"
          :selectedMathType="selectedMathType"
          :getAggregates="getAggregates"
          :highlightedGoldStar="highlightedGoldStar"
          :clickedGoldStar="clickedGoldStar"
          currentBoat="0"
          @setCurrentTime="setCurrentTime"
          @isLoading="isLoading"
          @setOpacityTime="updateHover"
          @mouseLeft="mouseLeft"
          @selectSection="selectSection"
          @setClickedTime="moveToStartpoint"
          @toggleIsShowGoldStar="toggleIsShowGoldStar"
          @goldStarClicked="setClickedGoldStar"
          @goldStarSaved="setClickedGoldStar"
          @onPause="onPause"
        />
        <div class="extra-buttons-container">
          <div class="extra-button">
            <p>Data Mode</p>
            <div class="select uppercase">
              <select v-model="selectionMode">
                <option
                  class="uppercase"
                  v-for="(mode, index) in getModes"
                  v-bind:value="mode"
                  :key="index"
                >
                  {{ mode }}
                </option>
              </select>
            </div>
          </div>
          <div class="extra-button" @click="tailToggle">
            <template v-if="tail">
              <p>tail</p>
              <img src="../../assets/icons/tail.png" width="50" />
            </template>
            <template v-else>
              <p>full tail</p>
              <img src="../../assets/icons/fulltail.png" width="50" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import Mapbox from "mapbox-gl-vue";
import { mapActions, mapState } from "vuex";
import boaticons from "@/services/map/boaticons.js";
import TrackChart from "@/components/track-chart";
import ColorBox from "@/components/color-box";
import MapDataTabs from "@/views/tracks/trackMap/map-data-tabs";
import WindDirection from "@/views/tracks/trackMap/wind-direction.vue";
import layout from "@/views/layouts/Default.vue";
import { COLORS } from "@/constants";
import { MapMixin } from "@/mixins/MapMixin";
import api from "@/services/api/tracks";
import journalApi from "@/services/api/journal";
import alertHelper from "@/services/helpers/alertHelper";


export default {
  name: "Map",
  props: ["id"],
  mixins: [MapMixin],
  components: {
    Mapbox,
    boaticons,
    layout,
    TrackChart,
    ColorBox,
    MapDataTabs,
    WindDirection,
  },
  data() {
    return {
      loading: true,
      colors: COLORS,
      mapRotation: 0,
      windMeasure: "ewd",
      clickedSegments: [],
      userTags: [],
    };
  },

  created() {
    this.map = null;
    this.fetchTrack(this.id);
    this.$store.dispatch("journal/setJournalTrackId", this.id);
    this.$store.dispatch("tracks/getTags");
    api.getUserSegmentTags().then(response => {
      this.userTags = response.data.tags.sort((a, b) => a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'}));
    }).catch((error) => {
      alertHelper.alertNotification(this.$store.dispatch, error)
    });
  },

  beforeDestroy() {
    this.removeTrack();
  },

  computed: {
    ...mapState({
      track(state) {
        return state.tracks.track;
      },
      trackJournal(state) {
        return state.journal.trackJournal.filter(entry => entry.jType === "star");
      },
      user(state) {
        return state.auth.user;
      },
    }),

    linkedJournal() {
      if (this.$route.query.content) {
        const requestedSegment = this.trackJournal.find(
          (j) => j.content === this.$route.query.content
        );
        return requestedSegment;
      }
      return null;
    },

    allSelectedMetrics() {
      // return all metrics, or only those in the selection
      let selection = this.selectedSection;
      if (!selection) {
        return this.track.metrics;
      }
      let selected_metrics = this.track.metrics.filter((metric) => {
        let startTime = new Date(metric.start_time).getTime();
        let endTime = new Date(metric.end_time).getTime();
        let startInSelection =
          startTime >= selection.start && startTime <= selection.end;
        let endInSelection =
          endTime <= selection.end && endTime >= selection.start;
        let keepMetric = startInSelection || endInSelection;
        return keepMetric;
      });

      // If the metrics starts outside the selection,
      // add a selected_start_time so that the boat goes
      // to the start of the selected metric when clicked
      // from the hooper
      selected_metrics = selected_metrics.map((metric) => {
        let startTime = new Date(metric.start_time).getTime();
        let startsOutside = startTime < selection.start;
        if (startsOutside) {
          metric.selected_start_time = selection.start;
          return metric;
        } else {
          return metric;
        }
      });

      // If the metrics starts outside the selection,
      // add a selected_end_time so that the boat goes
      // to the start of the selected metric when clicked
      // from the hooper
      selected_metrics = selected_metrics.map((metric) => {
        let endTime = new Date(metric.end_time).getTime();
        let endsOutside = endTime > selection.end;
        if (endsOutside) {
          metric.selected_end_time = selection.end;
          return metric;
        } else {
          return metric;
        }
      });

      return selected_metrics;
    },

    selectedMetrics() {
      // return metrics for maneuvers
      let allMetrics = this.allSelectedMetrics
      return allMetrics.filter((metric) => {
        return metric.label !== 'S'
      })
    },

    straightSelectedMetrics() {
      // return metrics for straight segments
      let allMetrics = this.allSelectedMetrics
      return allMetrics.filter((metric) => {
        return metric.label === 'S'
      })
    },

    initialWindDir() {
      if (!this.track) {
        return 0;
      }
      this.windMeasure = this.getWindMeasure();
      if (this.windMeasure !== null) {
        return this.track.trackpoints[0][this.windMeasure];
      }
      return 0;
    },
    trackpointKeys() {
      return this.getModes.filter((mode) => mode !== "tags" && mode !== "none");
    },

    getModes() {
      if (this.track) {
        let first_trackpoint = this.track.trackpoints[0];
        let keys = Object.keys(first_trackpoint);
        let modes = keys.filter((key) => first_trackpoint[key] !== null);
        let excluded_modes = new Set(["id", "lat", "lon", "time"]);
        modes = modes.filter((mode) => !excluded_modes.has(mode));
        let hasSegments = this.track.segments.length > 1;
        if (hasSegments) {
          modes.push("tags");
        }
        modes.unshift("none");
        return modes;
      } else {
        return ["sog", "cog"];
      }
    },
  },

  methods: {
    rotate(map, e) {
      this.mapRotation = (map.getBearing() - this.initialWindDir) % 360;
    },

    getWindMeasure() {
      // Use twd or ewd,
      // return null if no wind measure is available
      let points_have_twd = this.track.trackpoints.every((point) => point.twd);
      let all_zero_twd = this.track.trackpoints.every(
        (point) => point.twd === 0
      );
      let valid_twd = points_have_twd && !all_zero_twd;
      if (valid_twd) {
        return "twd";
      }
      // see if we have ewd
      let points_have_ewd = this.track.trackpoints.every((point) => point.ewd);
      if (points_have_ewd) {
        return "ewd";
      }
      return null;
    },

    isLoading(loading) {
      this.loading = loading;
    },

    load(map) {
      if (map) {
        this.map = map;
        this.tracks = [this.track];
        this.loadMap();
        if (this.linkedJournal) {
          this.setClickedGoldStar(this.linkedJournal);
          return;
        }
        let startTime = new Date(this.track.trackpoints[0].time).getTime();
        this.setCurrentTime(startTime);
      }
    },

    moveToStartpoint(time) {
      this.setCurrentTime(time)
    },

    straightLineHover(time) {
      this.setCurrentTime((time.start + time.end)/2) // set to midpoint
    },

    toggleSegmentClicked(id) {
      let clickedSegments = this.clickedSegments
      let index = clickedSegments.indexOf(id)
      if (index !== -1) {
        clickedSegments.splice(index, 1) // remove if present
      } else {
        clickedSegments.push(id)
      }
    },

    clearHover() {
      // for if map eventually highlights segments
    },

    createStraightAnalysis(name) {
      const params = {
        track: this.id,
        segments: this.clickedSegments,
        name: name
      }
      api.createStraightLineAnalysis(params).then(response => {
        let analysisId = response.data.id
        const journalParams = {
          track: this.id,
          jType: "straight",
          straight_analysis_segments: this.clickedSegments,
          straight_analysis_id: analysisId,
          content: `${this.clickedSegments.join(", ")}`,
          tags: []
        }
        journalApi.createJournal(journalParams).then(response => {
          this.$router.push('/straight-analysis/' + analysisId)
        }).catch((error) => {
          alertHelper.alertNotification(this.$store.dispatch, error);
        })
      }).catch((error) => {
        if (error.response.status  === 409) {
          const analysisId = error.response.data.id
          this.$router.push('/straight-analysis/' + analysisId);
          alertHelper.alertNotification(this.$store.dispatch, error);
        } else {
          alertHelper.alertNotification(this.$store.dispatch, error)
        }
      });

      /* api.updateReply(this.journalId, this.reply)
        .then(response => {
          const reply_change = {
            "old_reply": this.reply,
            "new_reply": response.data,
          }
          this.$emit('reply-updated', reply_change)
          this.reply.content = ""
        })
        .catch((error) => {
          this.errors.nonField = error
        })*/
    },

    segmentIndexForName(segmentName) {
      return this.track.segments.findIndex(x => x.seg_name === segmentName);
    },

    // ideally refactor somehow -- code is the similar to that on straight line report
    tagRemoved(args) {
      api.removeTagFromSegment(args.segment.id, args.tagId, this.track.id).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
      const index = this.segmentIndexForName(args.segment.seg_name);
      this.track.segments[index].user_tags = this.track.segments[index].user_tags.filter(x => x.id !== args.tagId);
    },
    createAndAddTag(args) {
      // first check if tag already exists for value
      let existingTag = this.userTags.findIndex(x => x.value === args.tagValue);
      if (existingTag !== -1) {
        this.addTagToSegment({...args, tag: this.userTags[existingTag]});
        if (!this.userTags[existingTag].visible) {
          const tagId = this.userTags[existingTag].id
          api.showUserSegmentTag(tagId).then(_ => {
            const tagIndex = this.userTags.findIndex(x => x.id === tagId);
            this.userTags[tagIndex].visible = true;
          }).catch((error) => {
            alertHelper.alertNotification(this.$store.dispatch, error);
          });
        }
        return;
      }

      api.createUserSegmentTags(args.tagValue).then((response) => {
        this.userTags.push(response.data.tag);
        this.userTags.sort((a, b) => a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'}));
        this.addTagToSegment({...args, tag: response.data.tag})
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
    },
    addTagToSegment(args) {
      api.addUserTagToSegment(args.segment.id, args.tag.id, this.track.id).then((_) => {
        this.track.segments[this.segmentIndexForName(args.segment.seg_name)].user_tags.push(args.tag);
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
    },
    hideTag(tag) {
      api.hideUserSegmentTag(tag.id).then((_) => {
        const tagIndex = this.userTags.findIndex(x => x.id === tag.id);
        this.userTags[tagIndex].visible = false;
      }).catch((error) => {
        alertHelper.alertNotification(this.$store.dispatch, error);
      });
    },

    ...mapActions("tracks", ["fetchTrack", "removeTrack", "getTags"]),
  },
};
</script>

<style lang="sass">
.map-container
  display: flex
  flex-direction: column
  height: calc(100vh - 66px)
  position: relative
  overflow-y: hidden

  @media (max-width: 1023px)
    height: calc(100vh - 52px)

  #map
    height: 100%

  .player-container
    display: flex
    align-items: stretch
    background-color: #dee7fd
    flex-direction: column
    padding-top: 30px
    padding-bottom: 30px

    .play_tail_cont
      display: flex
      justify-content: center

      .button-tail
        background-color: $facebook
        height: 60px
        width: 60px
        border-radius: 50%
        -webkit-transition: all 0.12s ease
        transition: all 0.12s ease
        color: $white

        &:hover
          background-color: $facebook-hover

      .button-pause
        background-color: $facebook
        height: 60px
        width: 60px
        border-radius: 50%
        -webkit-transition: all 0.12s ease
        transition: all 0.12s ease
        color: $white

        &:hover
          background-color: $facebook-hover

      .inputSlider
        width: 50%
        margin: 10px

  .marker
    background-image: url('/mapbox-icon.png')
    background-size: cover
    width: 50px
    height: 50px
    border-radius: 50%
    cursor: pointer

  .boat2
    background-image: url('/boat2.ico')
    background-size: cover
    width: 48px
    height: 48px

  #fit
    display: block
    position: relative
    margin: 0px auto
    width: 50%
    height: 40px
    padding: 10px
    border: none
    border-radius: 3px
    font-size: 12px
    text-align: center
    color: #fff
    background: #ee8a65

  // map overlay
  .map-overlay-selector
    padding: 10px
    font-size: 14px

    .map-overlay-inner
      height: 100%
      overflow-y: auto
      background-color: #fff
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
      border-radius: 3px
      padding: 10px
      margin-bottom: 10px

      display: flex
      flex-direction: column
      align-items: center

      fieldset
        width: 160px
        border: none
        padding: 0

        &:last-child
          margin: 0

      select
        margin-bottom: 10px
        width: 100%

      label
        display: block
        font-weight: bold
        margin: 0 0 5px

  .map-overlay-track-title
    width: 250px
    font-size: 12px
    position: absolute
    top: 10px
    left: 10px
    background-color: #fff
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
    border-radius: 3px
    padding: 10px
    text-align: center
</style>

<style scoped>
select,
select option {
  text-transform: uppercase;
}
</style>
