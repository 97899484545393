import _ from "lodash"
import {
  COLORS,
  TAIL_LENGTH,
} from "@/constants"
import {
  bearingToAzimuth,
  lineString,
  point
} from "@turf/helpers"
import bearing from "@turf/bearing"
import distance from "@turf/distance"
import along from "@turf/along"
import {
  maxLargeArray,
  minLargeArray
} from "@/utils";
import {
  getColorForPercentage
} from "@/services/map/colorScale";

// time between points / updates in ms
const FPS = 60;
export const ANIMATION_INTERVAL_MS = 1000 / FPS;

export const MapMixin = {
  data() {
    return {
      xSpeed: [1, 2, 10, 20, 100],
      mathType: ["Max", "Average", "StdDev"],
      selectedMathType: 0,
      currentSpeed: 3,
      hoverTime: null,
      startTime: 0,
      endTime: 0,
      isShowGoldStar: false,
      selectedSection: null,
      tail: true,
      pause: true,
      units: {
        // maps metrics to their units
        sog: "kts",
        cog: "°",
        vmg: "kts",
        ewd: "°",
      },

      // A GeoJSON feature collection holding all
      // of the boat points.
      boatPoints: {
        type: 'FeatureCollection',
        features: [],
      },
      // A GeoJSON feature collection holding all
      // of the points for boats that move on hovering.
      hoverBoatPoints: {
        type: 'FeatureCollection',
        features: [],
      },
      // data for all the tracks
      trackGeoJson: {
        type: 'FeatureCollection',
        features: [],
      },
      // data for all the track tails on hover
      hoverTailGeoJson: {
        type: 'FeatureCollection',
        features: [],
      },
      hoverTailsLayer: {
        id: 'hoverTailsLayer',
        type: "line",
        source: 'hoverTails',
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ['get', 'color'],
          "line-width": 2,
          "line-opacity": 1,
        },
      },
      hoverBoatsLayer: {
        id: 'hoverBoatsLayer',
        source: 'hoverBoats',
        type: 'symbol',
        layout: {
          // get the image id from the feature properties
          'icon-image': ['get', 'imageId'],
          "icon-size": 0.6,
          // get the bearing from the feature properties
          "icon-rotate": ['get', 'bearing'],
          "icon-rotation-alignment": 'map',
          "icon-allow-overlap": true,
          'icon-ignore-placement': true,
        },
        paint: {
          // gets the icon color from the feature properties
          'icon-color': ['get', 'color'],
          'icon-opacity': 0.3,
        }
      },
      currentTime: 0,
      indexesBySecond: [],
      selectionMode: "sog",
      // The last selectionMode used that is a data mode.
      lastDataMode: "sog",
      degreeModes: new Set(["cog", "ewd", "twd", "twa", "etwa"]),
      indicators: [],
      tracks: null,
      // The index of the boat/track to follow
      // by keeping in the center of the map.
      followedBoatIndex: 0,
      followedBoatHighlightColor: 'rgba(255, 254, 115, 0.8)',
      // The currently hovered gold star segment.
      // {start, end, text} 
      highlightedGoldStar: null,
      clickedGoldStar: null,
    }
  },

  created() {
    window.addEventListener("keydown", this.handleKeyDown)
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown)
  },

  methods: {

    onPause() {
      this.pause = !this.pause;
    },

    loadMap() {
      this.addMapEventHandlers()
      this.setStartAndEndTime()
      this.checkedNames = new Set(this.tracks.map((track, trackIndex) => trackIndex))
      this.setUpAnimation()
      // create the GeoJSON objects
      this.createBoatPoints()
      // add sources to the map
      this.map.addSource('boats', {
        'type': 'geojson',
        'data': this.boatPoints,
      })
      this.map.addSource('hoverBoats', {
        'type': 'geojson',
        'data': this.hoverBoatPoints,
      })
      this.addBoatLayers()
      // create GeoJSON for the tracks
      // this.createTracks()
      this.map.addSource('tracks', {
        type: 'geojson',
        data: this.trackGeoJson,
        tolerance: 0.00000001, // prevents lines from disappearing when zooming out
      })
      this.map.addSource('hoverTails', {
        type: 'geojson',
        data: this.hoverTailGeoJson,
        tolerance: 0.00000001, // prevents lines from disappearing when zooming out
      })
      this.addTrackLayers()
      this.removeSegmentGaps() 
    },

    moveToStartpoint(time) {
      const timestamp = new Date(time).getTime()
      this.currentTime = timestamp
    },

    /**
     * Update the gold star highlight.
     * Ignore the update if a gold star is already
     * selected or a selection has been made and
     * the gold star is not in the selection.
     * @param {Object} data The gold star
     */
    goldStarHover(data) {
      let highlightedGoldStar = data;
      const goldStarSelected = this.clickedGoldStar !== null;
      const ignoreHoverUpdate = (
        goldStarSelected || (
          this.selectedSection &&
          !this.goldStarInSelection(highlightedGoldStar)
        )
      );
      if (ignoreHoverUpdate) {
        return;
      }
      const resetToSelection = this.selectedSection && highlightedGoldStar === null;
      if (resetToSelection) {
        highlightedGoldStar = {...this.selectedSection}
      }
      this.highlightedGoldStar = highlightedGoldStar;
    },

    goldStarInSelection(goldStar) {
      if (!goldStar) {
        return true;
      }
      if (!this.selectedSection) {
        return false;
      }
      const goldStarStartInSelection = (
        goldStar.start >= this.selectedSection.start &&
        goldStar.start <= this.selectedSection.end
      );

      const goldStarEndInSelection = (
        goldStar.end >= this.selectedSection.start &&
        goldStar.end <= this.selectedSection.end
      );

      return goldStarStartInSelection || goldStarEndInSelection;
    },

    setClickedGoldStar(journal) {
      this.clickedGoldStar = journal;
      // If the clicked gold star is being
      // set to null, then clear the hover.
      const clearClickedGoldStar = journal === null; 
      if (clearClickedGoldStar) {
        this.highlightedGoldStar = null;
        return;
      }
      const start = new Date(journal.s_point).getTime();
      const end = new Date(journal.e_point).getTime();
      this.highlightedGoldStar = {start, end, text: journal.content};
    },

    toggleIsShowGoldStar() {
      this.isShowGoldStar = !this.isShowGoldStar
    },

    handleKeyDown(event) {
      if (event) {
        switch (event.code) {
          case "Space":
            this.onPause()
            break
          case "ArrowUp":
            this.changeSpeedUp()
            break
          case "ArrowDown":
            this.changeSpeedDown()
            break
          case "ArrowRight":
            this.moveForward()
            break
          case "ArrowLeft":
            this.moveBackward()
            break
        }
      }
    },

    changeSpeedUp() {
      if (this.currentSpeed < 4) {
        this.currentSpeed++
      }
    },

    changeSpeedDown() {
      if (this.currentSpeed > 0) {
        this.currentSpeed--
      }
    },

    changeSpeed() {
      if (this.currentSpeed < 4) {
        this.currentSpeed++;
      } else {
        this.currentSpeed = 0;
      }
    },

    moveForward() {
      let changedTime = this.currentTime + 1000 * this.xSpeed[this.currentSpeed]
      if (this.selectedSection) {
        if (changedTime > this.selectedSection.end) {
          changedTime = this.selectedSection.start
        }
      }
      this.setCurrentTime(changedTime);
    },

    moveBackward() {
      const changedTime = this.currentTime - 1000 * this.xSpeed[this.currentSpeed]
      let minTime = this.startTime
      if (this.selectedSection) {
        minTime = this.selectedSection.start
      }
      const newTime = changedTime < minTime ? minTime : changedTime;
      this.setCurrentTime(newTime);
    },

    addMapEventHandlers() {
      this.map.on('mousemove', 'trackClick', (e) => this.tracksHovered(e))

      this.map.on("mouseleave", 'trackClick', (e) => {
        this.mouseLeft();
      });

      this.map.on("click", 'trackClick', (e) => {
        this.clicked(e);
      });
    },

    tracksHovered(e) {
      let clickedFeatures = e.features
      if (clickedFeatures.length === 0) {
        return
      }
      let trackIndexes = clickedFeatures.map(feature => feature.properties.trackIndex)
      // remove duplicates
      trackIndexes = [...new Set(trackIndexes)]
      let {
        trackpoint
      } = this.nearestNeighbor(trackIndexes, e.lngLat);
      this.updateHover(new Date(trackpoint.time).getTime())
      this.map.getCanvas().style.cursor = "pointer";
    },

    mouseLeft() {
      this.updateHover(null);
      this.map.getCanvas().style.cursor = "";
      if (this.map.getLayer('hoverTailsLayer')) {
        this.map.removeLayer('hoverTailsLayer')
      }
      if (this.map.getLayer('hoverBoatsLayer')) {
        this.map.removeLayer('hoverBoatsLayer')
      }
    },

    clicked(e) {
      let clickedFeatures = e.features
      if (clickedFeatures.length === 0) {
        return
      }
      let trackIndexes = clickedFeatures.map(feature => feature.properties.trackIndex)
      let {
        trackpoint
      } = this.nearestNeighbor(trackIndexes, e.lngLat);
      let timestamp = new Date(trackpoint.time).getTime();
      // Update the followed boat by choosing the
      // first clicked track index.
      const firstClickedTrackIndex = trackIndexes[0];
      if (firstClickedTrackIndex != null) {
        this.setFollowedBoatIndex(firstClickedTrackIndex);
      }
      this.setCurrentTime(timestamp)
    },

    setFollowedBoatIndex(boatIndex) {
      this.followedBoatIndex = boatIndex;
      this.setCenter(this.currentTime);
    },

    nearestNeighbor(trackIndexes, click) {
      /*
      Go through all the tracks that have an index
      in trackIndexes, and find the closest trackpoint to the
      click coordinate. If in tail mode or a section is selected,
      limit the search accordingly.
      */
      let result = null
      for (let trackIndex of trackIndexes) {
        const trackpoints = this.trackpointsByTailAndSelection(this.currentTime, trackIndex);
        for (let i = 0; i < trackpoints.length; i++) {
          let currTrackpoint = trackpoints[i]
          let currPoint = point([currTrackpoint.lon, currTrackpoint.lat])
          let clickPoint = point([click.lng, click.lat])
          let newDistance = distance(currPoint, clickPoint)
          if (result) {
            if (newDistance < result.distance) {
              result = {
                trackpointIndex: i,
                trackpoint: currTrackpoint,
                distance: newDistance
              }
            }
          } else {
            result = {
              trackpointIndex: i,
              trackpoint: currTrackpoint,
              distance: newDistance
            }
          }
        }
      }
      return result
    },

    setStartAndEndTime() {
      /*
      Set the startTime and endTime
      as the UNIX timestamps of the
      earliest and latest trackpoints.
      */
      let startTimes = [];
      let endTimes = [];
      for (let trackIndex = 0; trackIndex < this.tracks.length; trackIndex++) {
        let trackpoints = this.tracks[trackIndex].trackpoints
        let startTrackpoint = trackpoints[0]
        let endTrackpoint = trackpoints[trackpoints.length - 1]
        let startTime = new Date(startTrackpoint.time).getTime()
        let endTime = new Date(endTrackpoint.time).getTime()
        startTimes.push(startTime)
        endTimes.push(endTime)
      }
      this.startTime = minLargeArray(startTimes);
      this.endTime = maxLargeArray(endTimes);
    },

    /**
     * Add a boat point and hover boat point
     * feature to the GeoJSON objects
     * for each track.
     */
    createBoatPoints() {
      for (let [trackIndex, track] of this.tracks.entries()) {
        let firstTrackpoint = track.trackpoints[0]
        let startCoordinate = [firstTrackpoint.lon, firstTrackpoint.lat]
        let nextTrackpoint = track.trackpoints[1]
        let nextCoordinate = [nextTrackpoint.lon, nextTrackpoint.lat]
        // get the initial bearing of the boat
        let initialBearing = this.getBoatBearing(startCoordinate, nextCoordinate)
        // get the boat name for assigning the image
        let boatName
        if (track.boat_name === '49er' || track.boat_name === '49erFX') {
          boatName = '49er'
        } else if (track.boat_name === '29er') {
          boatName = '29er'
        } else {
          boatName = 'Boat'
        }

        let boatPoint = {
          'id': `boatPoint${trackIndex}`,
          'type': 'Feature',
          'properties': {
            size: 0.6,
            color: COLORS[trackIndex],
            imageId: `${boatName}Image`,
            bearing: initialBearing,
          },
          'geometry': {
            'type': 'Point',
            'coordinates': startCoordinate,
          }
        }
        let hoverBoatPoint = {
          'id': `hoverBoatPoint${trackIndex}`,
          'type': 'Feature',
          'properties': {
            color: COLORS[trackIndex],
            imageId: `${boatName}Image`,
            bearing: 0,
          },
          'geometry': {
            'type': 'Point',
            'coordinates': []
          }
        }
        this.boatPoints.features.push(boatPoint)
        this.hoverBoatPoints.features.push(hoverBoatPoint)
      }
      this.highlightFollowedBoat();
    },

    async addBoatLayers() {
      /*
      Add a layer for the boats and a layer for the hover boats.
      */
      let boatImage = await this.loadBoatImage('Boat')
      let boat49erImage = await this.loadBoatImage('49er')
      let boat29erImage = await this.loadBoatImage('29er')
      if (boatImage) {
        this.map.addImage('BoatImage', boatImage, {
          sdf: true
        })
      }
      if (boat49erImage) {
        this.map.addImage('49erImage', boat49erImage, {
          sdf: true
        })
      }
      if (boat29erImage) {
        this.map.addImage('29erImage', boat29erImage, {
          sdf: true
        })
      }
      // add boats layer
      this.map.addLayer({
        id: 'boatsLayer',
        source: 'boats',
        type: 'symbol',
        layout: {
          // get the image id from the feature properties
          'icon-image': ['get', 'imageId'],
          // get the size from the feature properties
          "icon-size": ['get', 'size'],
          // get the bearing from the feature properties
          "icon-rotate": ['get', 'bearing'],
          "icon-rotation-alignment": 'map',
          "icon-allow-overlap": true,
          'icon-ignore-placement': true,
        },
        paint: {
          // gets the icon color from the feature properties
          'icon-color': ['get', 'color'],
        },
      })
      // add hover boats layer
      this.map.addLayer(this.hoverBoatsLayer)

    },

    loadBoatImage(boatName) {
      return new Promise((resolve, reject) => {
        this.map.loadImage(`/boats/${boatName}.png`, (error, image) => {
          if (error) {
            console.log(error)
            resolve(null)
          }
          resolve(image)
        })
      })
    },

    getTrackpointSecond(trackpoint) {
      let time = new Date(trackpoint.time).getTime()
      return this.getSecond(time)
    },

    getSecond(time) {
      return Math.floor((time / 1000))
    },

    /**
     * An animation point is needed if the tail is being rendered
     * and the animation time is between trackpoints, and the animation time
     * is in the selection.
     * @param {number} trackIndex The index within this.tracks.
     * @param {UnixTimestamp} timestamp The timestamp to consider adding an animation point at.
     * @returns {boolean}
     */
    animationPointNeeded(trackIndex, timestamp) {
      let timestampInSelection = true
      if (this.selectedSection) {
        timestampInSelection = (
          timestamp >= this.selectedSection.start &&
          timestamp <= this.selectedSection.end
        );
      }
      const track = this.tracks[trackIndex];
      const firstTrackpoint = track.trackpoints[0];
      const trackStartTime = new Date(firstTrackpoint.time).getTime();
      if (timestamp < trackStartTime) {
        return false;
      }
      const trackpointIndex = this.getClosestTrackpointIndex(timestamp, trackIndex);
      const trackpoint = track.trackpoints[trackpointIndex];
      const atTrackpointTime = new Date(trackpoint.time).getTime() === timestamp;
      const needed = this.tail && !atTrackpointTime && timestampInSelection;
      return needed;
    },

    /**
     * Get a list of features representing the lines between each pair
     * of trackpoints to be rendered. Color the segments based on the data mode.
     * @param {number} trackIndex The index of the track in this.tracks.
     * @param {Trackpoint[]} trackpoints The list of trackpoints considering the tail and selection.
     * @returns FeatureCollection features  A list of features representing the lines between each trackpoint.
     */
    getFeatureLines(trackIndex, trackpoints) {
      let {
        max
      } = this.getMinMax
      // consider the data selection mode
      let color;
      if (!this.dataMode) {
        color = COLORS[trackIndex]
      }

      let features = []
      for (let trackpointIndex = 1; trackpointIndex < trackpoints.length; trackpointIndex++) {
        const prevTrackpoint = trackpoints[trackpointIndex - 1]
        const trackpoint = trackpoints[trackpointIndex];

        if (this.dataMode) {
          const rawValue = trackpoint[this.selectionMode];
          // If the trackpoint doesn't have a value for that mode
          // color their trackpoints gray
          if (!rawValue) {
            color = "gray";
          } else {
            let value = Math.abs(rawValue);
            let ratio = value / max;
            color = getColorForPercentage(ratio);
          }
        }
        const prevPoint = [prevTrackpoint.lon, prevTrackpoint.lat]
        const currPoint = [trackpoint.lon, trackpoint.lat]
        const trackpointSegment = [prevPoint, currPoint]
        const feature = {
          type: "Feature",
          properties: {
            color,
            trackIndex,
          },
          geometry: {
            type: "LineString",
            coordinates: trackpointSegment,
          }
        }
        features.push(feature)
      }
      return features
    },

    /**
     * A segment of the track with a given tag.
     * @typedef {Object} Segment
     */

    /**
     * Get a list of features for each segment. Each feature
     * will be colored based on the segment color and contain all
     * the points within that segment. 
     * 
     * @param {number} trackIndex The index of the track in this.tracks.
     * @param {Segment[]} segments The list of segments in the tail and selection.
     * @returns FeatureCollection features  A list of features representing the segments.
     */
    getSegmentFeatures(trackIndex, segments, animationTime){
      const segmentFeatures = [];
      for (let segmentIndex = 0; segmentIndex < segments.length; segmentIndex++) {
        const segment = segments[segmentIndex];
        const segmentCoordinates = this.segmentCoordinatesByTailAndSelection(segment, trackIndex, animationTime);
        if (segmentCoordinates.length < 1) {
          continue;
        }

        // Create a feature.
        const segmentFeature = {
          type: "Feature",
          properties: {
            color: segment.color,
            trackIndex,
          },
          geometry: {
            type: "LineString",
            coordinates: segmentCoordinates,
          }
        }
        segmentFeatures.push(segmentFeature);
      }

      // If the features are empty, or there is no tail or selection
      // then return the features without adding animation points.
      const emptyFeatures = segmentFeatures.length === 0; 
      const tailOrSelection = this.tail || this.selectedSection;
      if (emptyFeatures || !tailOrSelection) {
        return segmentFeatures;
      }

      // Add animation points to the first and last feature if needed.
      // This makes the tail update smoothly.
      const {
        tailStart,
        tailEnd,
        selectionStart,
        selectionEnd
      } = this.tailAndSelectionTimes(animationTime);
      // Use the smallest range combination of tail and selection.
      const rangeStart = Math.max(tailEnd, selectionStart);
      const rangeEnd = Math.min(tailStart, selectionEnd);
      const firstFeature = segmentFeatures[0];
      const lastFeature = segmentFeatures[segmentFeatures.length - 1];
      const firstFeatureCoordinates = firstFeature.geometry.coordinates;
      const lastFeatureCoordinates = lastFeature.geometry.coordinates;
      const firstSegmentCoordinate = firstFeatureCoordinates[0];
      const lastSegmentCoordinate = lastFeatureCoordinates[lastFeatureCoordinates.length - 1]


      // Add an animation coordinate on the start if needed.
      if (this.animationPointNeeded(trackIndex, rangeStart)) {
        const closestTailTrackpointIndex = this.getClosestTrackpointIndex(rangeStart, trackIndex);
        const tailAnimationPoint = this.getAnimationPoint(closestTailTrackpointIndex, trackIndex, rangeStart);
        const tailAnimationCoordinate = tailAnimationPoint.geometry.coordinates;
        // Only add the animation coordinate if it's not already there.
        // The animation coordinate could have been added when segments
        // were merged.
        const isFirstCoordinate = (
          tailAnimationCoordinate.lon === firstSegmentCoordinate[0] &&
          tailAnimationCoordinate.lat === firstSegmentCoordinate[1]
        );
        if (!isFirstCoordinate) {
          firstFeatureCoordinates.unshift(tailAnimationCoordinate);
        }
      }

      // Add an animation coordinate for the current animation time if needed.
      if (this.animationPointNeeded(trackIndex, rangeEnd)) {
        const closestTrackpointIndex = this.getClosestTrackpointIndex(rangeEnd, trackIndex);
        const animationPoint = this.getAnimationPoint(closestTrackpointIndex, trackIndex, rangeEnd);
        const animationCoordinate = animationPoint.geometry.coordinates;
        // Only add the animation coordinate if it's not already there.
        const isLastCoordinate = (
          animationCoordinate.lon === lastSegmentCoordinate[0] &&
          animationCoordinate.lat === lastSegmentCoordinate[1]
        );
        if (!isLastCoordinate) {
          lastFeatureCoordinates.push(animationCoordinate)
        }
      }
      return segmentFeatures;
    },

    /**
     * Get a list of coordinates in the segment trimmed to the tail and selection.
     * Add the start and end coordinates for closing gaps.
     * @param {Segment} segment The segment that is partially or fully in the selection.
     * @param {number} trackIndex The index in this.tracks.
     * @param {UnixTimestamp} animationTime The current animation time.
     * @returns {GeoJSONPosition[]}
     */
    segmentCoordinatesByTailAndSelection(segment, trackIndex, animationTime) {
      // get the segment coordinates trimmed to the tail and selection
      const track = this.tracks[trackIndex]
      const segmentCoordinates = [];
      const segmentTimes = this.getSegmentTimes(track, segment);
      const startTrackpointTime = segmentTimes.segmentStartTime;
      const endTrackpointTime = segmentTimes.segmentEndTime;

      const {
        tailStart,
        tailEnd,
        selectionStart,
        selectionEnd
      } = this.tailAndSelectionTimes(animationTime);

      // Add the start coordinates if they have been
      // extended to close a gap.
      const startExtended = segment.startTime !== startTrackpointTime;
      const startInTail = segment.startTime >= tailEnd && segment.startTime <= tailStart;
      const startInSelection = segment.startTime >= selectionStart && segment.startTime <= selectionEnd;
      const addStartCoordinate = startExtended && startInTail && startInSelection; 
      if(addStartCoordinate) {
        segmentCoordinates.push(segment.startCoordinates);
      }

      // Add coordinates for all the segment trackpoints
      for (let trackpointIndex = segment.start; trackpointIndex <= segment.finish; trackpointIndex++) {
        const trackpoint = track.trackpoints[trackpointIndex];
        const trackpointTime = new Date(trackpoint.time).getTime();
        const inTail = trackpointTime >= tailEnd && trackpointTime <= tailStart;
        const inSelection = trackpointTime >= selectionStart && trackpointTime <= selectionEnd;
        if (inTail && inSelection) {
          segmentCoordinates.push([trackpoint.lon, trackpoint.lat]);
        }
      }

      // Add the end coordinates if they have been
      // extended to close a gap.
      const endExtended = segment.endTime !== endTrackpointTime;
      const endInTail = segment.endTime >= tailEnd && segment.endTime <= tailStart;
      const endInSelection = segment.endTime >= selectionStart && segment.endTime <= selectionEnd;
      const addEndCoordinate = endExtended && endInTail && endInSelection;
      if(addEndCoordinate) {
        segmentCoordinates.push(segment.endCoordinates);
      }

      return segmentCoordinates;
    },

    tailToggle() {
      this.tail = !this.tail
      this.updateTracks()
    },

    addTrackLayers() {
      // track line layer
      this.map.addLayer({
        id: 'track',
        type: "line",
        source: 'tracks',
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ['get', 'color'],
          "line-width": 2,
          "line-opacity": 1,
        },
      })
      // track click layer
      this.map.addLayer({
        id: 'trackClick',
        type: "line",
        source: 'tracks',
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          // hidden layer
          "line-opacity": 0,
          // wider line for easy clicking
          "line-width": 20,
        },
      })

    },

    /**
     * Add start and end coordinates and times to each segment. For adjacent segments
     * with no trackpoints between them, extend the end of the previous and the start of
     * the current segment to the midpoint between the segments.
     */
    removeSegmentGaps() {
      for (let trackIndex = 0; trackIndex < this.tracks.length; trackIndex++) {
        const track = this.tracks[trackIndex];
        // Remove null segments between adjacent trackpoints.
        track.segments = track.segments.filter(segment => {
          const isNull = segment.tag === null;
          const betweenAdjacent = segment.finish - segment.start === 1;
          return !(isNull && betweenAdjacent)
        })
        for (let segmentIndex = 1; segmentIndex < track.segments.length; segmentIndex++) {
          const segment = track.segments[segmentIndex];
          const prevSegment = track.segments[segmentIndex - 1];
          const prevStartTrackpoint = track.trackpoints[prevSegment.start];
          const prevEndTrackpoint = track.trackpoints[prevSegment.finish];
          const segmentStartTrackpoint = track.trackpoints[segment.start];
          const segmentEndTrackpoint = track.trackpoints[segment.finish];

          let prevSegStartTime = prevSegment['startTime'];
          if (!prevSegStartTime) {
            prevSegStartTime = new Date(prevStartTrackpoint.time).getTime();
          }
          let prevSegEndTime = prevSegment['endTime'];
          if (!prevSegEndTime) {
            prevSegEndTime = new Date(prevEndTrackpoint.time).getTime();
          }
          let segStartTime = segment['startTime'];
          if (!segStartTime) {
            segStartTime = new Date(segmentStartTrackpoint.time).getTime();
          }
          let segEndTime = segment['endTime'];
          if (!segEndTime) {
            segEndTime = new Date(segmentEndTrackpoint.time).getTime();
          }

          // If the previous and current segment are separated by only one trackpoint
          // then extend each one so that they touch 
          const adjacentSegments = segment.start - prevSegment.finish === 1;

          // Set up the segment coordinates
          let prevStartCoordinates = prevSegment['startCoordinates'];
          if (!prevStartCoordinates) {
            prevStartCoordinates = [prevStartTrackpoint.lon, prevStartTrackpoint.lat];
          }
          let prevEndCoordinates = prevSegment['endCoordinates'];
          if (!prevEndCoordinates) {
            prevEndCoordinates = [prevEndTrackpoint.lon, prevEndTrackpoint.lat];
          }
          let segmentStartCoordinates = segment['startCoordinates'];
          if (!segmentStartCoordinates) {
            segmentStartCoordinates = [segmentStartTrackpoint.lon, segmentStartTrackpoint.lat];
          }
          let segmentEndCoordinates = segment['endCoordinates'];
          if (!segmentEndCoordinates) {
            segmentEndCoordinates = [segmentEndTrackpoint.lon, segmentEndTrackpoint.lat];
          }

          if (adjacentSegments) {
            // remove the gap between them
            const midTime = prevSegEndTime + ((segStartTime - prevSegEndTime) / 2);
            const midPoint = this.getAnimationPoint(prevSegment.finish, trackIndex, midTime);
            prevEndCoordinates = [...midPoint.geometry.coordinates];
            segmentStartCoordinates = [...midPoint.geometry.coordinates];
            prevSegEndTime = midTime;
            segStartTime = midTime;
          }
          // Update the start and end coordinates.
          prevSegment['startCoordinates'] = prevStartCoordinates;
          prevSegment['endCoordinates'] = prevEndCoordinates;
          segment['startCoordinates'] = segmentStartCoordinates;
          segment['endCoordinates'] = segmentEndCoordinates;

          // Update the start and end times.
          prevSegment['startTime'] = prevSegStartTime;
          prevSegment['endTime'] = prevSegEndTime;
          segment['startTime'] = segStartTime;
          segment['endTime'] = segEndTime;
        }
        
      }
    },


    /**
     * Create a map of seconds to trackpoint indexes for each track.
     */
    setUpAnimation() {
      this.indexesBySecond = []
      for (let [track_i, track] of this.tracks.entries()) {
        let indexMapping = new Map();
        for (const [currTrackpointIndex, trackpoint] of track.trackpoints.entries()) {
          let timestamp = new Date(trackpoint.time).getTime()
          let second = Math.floor(timestamp / 1000)
          if (indexMapping.has(second)) {
            // Add the current trackpoint index to the list of trackpoint
            // indexes within this second.
            const trackpointIndexes = indexMapping.get(second);
            trackpointIndexes.push(currTrackpointIndex);
          } else {
            // Add a list of trackpoint indexes for this second.
            const trackpointIndexes = [currTrackpointIndex];
            indexMapping.set(second, trackpointIndexes);
          }
        }
        this.indexesBySecond.push(indexMapping);
      }
    },


    /**
     * Find the index of the array item closest to
     * the given time. 
     * @param {any[]} arr An array to look through.
     * @param {UnixTimestamp} time The time to compare to.
     * @param {Object} param2 Optional object key.
     * @returns {number} 
     */
    closestIndex(arr, time, {
      key
    } = {}) {
      let minDiff = Infinity
      let closestIndex = null
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        let timestamp = new Date(item).getTime()
        if (key) {
          timestamp = new Date(item[key]).getTime()
        }
        let diff = Math.abs(time - timestamp)
        if (diff < minDiff) {
          closestIndex = timestamp
          minDiff = diff
        }
      }
      return closestIndex
    },


    /**
     * Set the center of the map to the animation point
     * for the given track at the given time. 
     * @param {UnixTimestamp} currentTime The current time.
     */
    setCenter(currentTime) {
      const trackIndex = this.followedBoatIndex;
      // given a currentTime, set the center based on the boat's location
      const trackpointIndex = this.getClosestTrackpointIndex(currentTime, trackIndex)
      const animationPoint = this.getAnimationPoint(trackpointIndex, trackIndex, currentTime);
      this.map.setCenter(animationPoint.geometry.coordinates);
    },

    setCurrentTime(currentTime) {
      this.currentTime = Math.round(currentTime);
      // Set the current time to the selection start after it goes beyond it.
      if (this.selectedSection) {
        if (this.currentTime > this.selectedSection.end) {
          this.pause = true;
          this.currentTime = this.selectedSection.start;
        }
      }
      if (this.currentTime > this.endTime) {
        this.pause = true;
        this.currentTime = this.startTime;
      }
      this._animationRunSpeed()
      this.pickFollowedBoat();
      this.setCenter(this.currentTime);
    },

    setClickedTime(time) {
      this.pause = true
      this.currentTime = time
    },

    pickFollowedBoat() {
      // Always follow the same boat for 1 track
      if (this.tracks.length === 1) {
        return;
      }
      const followedBoatPoint = this.boatPoints.features[this.followedBoatIndex];
      const followedBoatDisplayed = (
        followedBoatPoint &&
        followedBoatPoint.geometry.coordinates.length
      );
      // No need to update the followed boat.
      if (followedBoatDisplayed) {
        return;
      }
      let trackIndexes = this.tracks.map((_, trackIndex) => trackIndex);
      const displayedBoatIndexes = trackIndexes.filter(trackIndex => {
        const boatIsRemoved = this.boatPoints.features[trackIndex].geometry.coordinates.length === 0
        return !boatIsRemoved;
      })
      if (!displayedBoatIndexes.length) {
        console.log("Error updating followed boat: no boats displayed");
        return;
      }
      // Pick the first displayed boat index
      this.followedBoatIndex = displayedBoatIndexes[0];
    },

    highlightFollowedBoat() {
      const isRace = this.tracks.length > 1; 
      if (!isRace) {
        return;
      }
      const followedBoatPoint = this.boatPoints.features[this.followedBoatIndex];
      if (!followedBoatPoint) {
        console.log("non followed boat point")
      }
      let followedBoatHighlight = _.cloneDeep(followedBoatPoint);
      const followHighlightAdded = this.boatPoints.features.length !== this.tracks.length;
      if (followHighlightAdded) {
        this.boatPoints.features.pop()
      }
      // Modify the highlight
      followedBoatHighlight.properties.color = this.followedBoatHighlightColor; 
      followedBoatHighlight.properties.size = 0.8;
      this.boatPoints.features.push(followedBoatHighlight);
    },


    /**
     * Remove the boat from the feature list
     * by setting it's coordinates to an empty list. 
     * @param {number} trackIndex The index in this.tracks.
     * @param {Feature[]} The list of features 
     * (ex. boatPoints or hoverBoatPoints) to modify.
     * @returns {Feature[]}
     */
    removeBoat(trackIndex, features) {
      // Remove this boat
      features[trackIndex].geometry.coordinates = [];
      return features
    },

    updateHover(newTime) {
      this.hoverTime = newTime
      for (let [trackIndex, t] of this.tracks.entries()) {
        let showTrack = this.checkedNames ? this.checkedNames.has(trackIndex) : true
        if (!showTrack) {
          // don't update hover boats for boat's that aren't shown
          continue
        }
        if (!this.hoverTime) {
          continue;
        }
        let currTrackpointIndex = this.getClosestTrackpointIndex(newTime, trackIndex);
        if (currTrackpointIndex === null) {
          this.hoverBoatPoints.features = this.removeBoat(trackIndex, this.hoverBoatPoints.features);
          continue;
        }

        // update the boat
        this.hoverBoatPoints.features[trackIndex] = this.updateBoat(currTrackpointIndex, trackIndex, this.hoverBoatPoints.features[trackIndex], newTime);

      }
      // update the hover boats
      this.map.getSource('hoverBoats').setData(this.hoverBoatPoints)
      // add hover boats layer if needed
      if (!this.map.getLayer('hoverBoatsLayer')) {
        this.map.addLayer(this.hoverBoatsLayer)
      }
      if (!this.hoverTime) {
        this.map.removeLayer('hoverBoatsLayer');
      }
      if (this.tail) {
        this.drawOpacityTail(newTime)
      }
    },

    selectSection(section) {
      this.selectedSection = section;
      if (this.selectedSection === null) {
        this.updateTracks();
        return;
      }
      this.goldStarHover({...section, text: ""});
      // If the selected section is not the clicked gold
      // star then set the clicked gold star to null
      // to allow for computed updates.
      if (!this.selectionIsClickedGoldStar(section)) {
        // Set directly so that the highlight doesn't clear.
        this.clickedGoldStar = null;
      }
      const inSelection = (
        this.currentTime >= this.selectedSection.start &&
        this.currentTime <= this.selectedSection.end
      );
      if (inSelection) {
        this.updateTracks();
        return;
      }
      // Set the current time to the start of the selection.
      this.setCurrentTime(this.selectedSection.start);
    },

    selectionIsClickedGoldStar({start, end}) {
      if (!this.clickedGoldStar) {
        return false;
      }
      const goldStart = new Date(this.clickedGoldStar.s_point).getTime();
      const goldEnd = new Date(this.clickedGoldStar.e_point).getTime();
      const matchesGoldStar = goldStart === start && goldEnd === end;
      return matchesGoldStar
    },

    /**
     * @typedef {Object} AnimationData Data needed to animate a boat.
     * @property {number} trackIndex The index within this.tracks.
     * @property {Object} boatData A feature with the boat's coordinates.
     * @property {UnixTimestamp} animationTime The current time for the boat's animation.
     */
    /**
     * Get a list of trackpoints based on the tail, selection,
     * and animation data. 
     * @param {AnimationData} animationData Holds the data needed to animate a boat. 
     * @returns {Trackpoint[]}
     */
    getTailTrackpoints({
      trackIndex,
      boatData,
      animationTime
    }) {
      let trackpoints = this.trackpointsByTailAndSelection(animationTime, trackIndex);
      const animationCoordinate = boatData.geometry.coordinates;
      const tailEndTime = animationTime - TAIL_LENGTH;
      // See if an animation point is needed on the end of the tail.
      if (this.animationPointNeeded(trackIndex, tailEndTime)) {
        const closestTailTrackpointIndex = this.getClosestTrackpointIndex(tailEndTime, trackIndex);
        const tailAnimationPoint = this.getAnimationPoint(closestTailTrackpointIndex, trackIndex, tailEndTime);
        const tailAnimationCoordinate = tailAnimationPoint.geometry.coordinates;
        // Add an animation trackpoint for the end of the tail
        const tailAnimationTrackpoint = this.addAnimationTrackpoint({
          animationTime: tailEndTime,
          trackIndex,
          animationCoordinate: tailAnimationCoordinate
        }) 
        trackpoints.unshift(tailAnimationTrackpoint);
      }
      // See if an animation point is needed at the head/start of the tail.
      if (this.animationPointNeeded(trackIndex, animationTime)) {
        const animationTrackpoint = this.addAnimationTrackpoint({
          animationTime,
          trackIndex,
          animationCoordinate
        }) 
        trackpoints.push(animationTrackpoint);
      }
      return trackpoints;
    },

    addAnimationTrackpoint({animationTime, trackIndex, animationCoordinate}) {
      const trackpointIndex = this.getClosestTrackpointIndex(animationTime, trackIndex);
      const nextTrackpoint = this.getNextTrackpoint(trackpointIndex, trackIndex);
      // Add an animationTrackpoint by copying the next trackpoint
      const animationTrackpoint = {
        ...nextTrackpoint
      }
      // Set the coordinates to match the animation coordinate
      animationTrackpoint.lon = animationCoordinate[0];
      animationTrackpoint.lat = animationCoordinate[1];
      return animationTrackpoint;
    },

    /**
     * @typedef {Object} Trackpoint
     * @property {number} id
     * @property {string} time Datetime string.
     * @property {number} lat Latitude.
     * @property {number} lon Longitude.
     * @property {number} sog Speed over ground in knots.
     * @property {number} cog Course over ground in degrees.
     * @property {number} [ewd] Estimated wind direction in degrees.
     * @property {number} [twd] True wind direction in degrees.
     * @property {number} [etwa] Estimated true wind angle in degrees.
     * @property {number} [twa] True wind angle in degrees.
     * @property {number} [vmg] Velocity made good (upwind component of velocity) in knots.
     * @property {number} [heading] True compass heading in degrees.
     * @property {number} [pitch] In degrees.
     * @property {number} [heel] In degrees.
     * @property {number} [rudder] In degrees.
     * @property {number} [boom] Degrees off of centerline.
     * @property {number} [clew_load] In 0.1 kg.
     */

    /**
     * Filter trackpoints for a given track based on the tail, animation time,
     * and selection.
     * @param {UnixTimestamp} animationTime The current animation time
     * @param {number} trackIndex The index within this.tracks
     * @returns {Trackpoint[]}
     */
    trackpointsByTailAndSelection(animationTime, trackIndex) {
      const track = this.tracks[trackIndex];
      let trackpoints = track.trackpoints
      if (this.tail) {
        trackpoints = trackpoints.filter(
          (trackpoint) =>
          new Date(trackpoint.time).getTime() >= animationTime - TAIL_LENGTH &&
          new Date(trackpoint.time).getTime() <= animationTime
        );
      }
      if (this.selectedSection) {
        trackpoints = trackpoints.filter(
          (trackpoint) =>
          new Date(trackpoint.time).getTime() >= this.selectedSection.start &&
          new Date(trackpoint.time).getTime() <= this.selectedSection.end
        );
      }
      return trackpoints
    },

    /**
     * Filter the given track's segments to keep only those that are
     * at least partially in the tail and selection. 
     * @param {UnixTimestamp} animationTime The current animation time.
     * @param {number} trackIndex The index in this.tracks
     * @returns {Object[]}
     */
    segmentsByTailAndSelection(animationTime, trackIndex) {
      const track = this.tracks[trackIndex];
      let segments = track.segments
      const {
        tailStart,
        tailEnd,
        selectionStart,
        selectionEnd
      } = this.tailAndSelectionTimes(animationTime);

      segments = segments.filter(segment => {
        // Keep the segment if it is at least partially
        // in the tail and selection, or if the tail and
        // selection is in the segment.
        const {segmentStartTime, segmentEndTime} = this.getSegmentTimes(track, segment);
        const startInTail = segmentStartTime >= tailEnd && segmentStartTime <= tailStart; 
        const endInTail = segmentEndTime >= tailEnd && segmentEndTime <= tailStart; 
        const partiallyInTail = startInTail || endInTail;
        const startInSelection = segmentStartTime >= selectionStart && segmentStartTime <= selectionEnd;
        const endInSelection = segmentEndTime >= selectionStart && segmentEndTime <= selectionEnd;
        const partiallyInSelection = startInSelection || endInSelection;

        // Checking if the tail and selection is in the segment.
        const tailStartInSegment = tailStart >= segmentStartTime && tailStart <= segmentEndTime;
        const tailEndInSegment = tailEnd >= segmentStartTime && tailEnd <= segmentEndTime;
        const tailInSegment = tailStartInSegment && tailEndInSegment;
        let selectionInSegment = false
        if (this.selectedSection) {
          const selectionStartInSegment = selectionStart >= segmentStartTime && selectionStart <= segmentEndTime;
          const selectionEndInSegment = selectionEnd >= segmentStartTime && selectionEnd <= segmentEndTime;
          selectionInSegment = selectionStartInSegment && selectionEndInSegment;
        }
        const tailOrSelectionInSegment = tailInSegment || selectionInSegment;
        const keepSegment = (partiallyInTail && partiallyInSelection) || tailOrSelectionInSegment;
        return keepSegment
      })
      return segments;
    },

    /**
     * Return an object with the tail start and end
     * and the selection start and end. If either does
     * not exist, set the start to 0 and the end to Infinity.
     * @param {UnixTimestamp} animationTime The current animation time.
     * @returns {Object}
     */
    tailAndSelectionTimes(animationTime) {
      // Setting up default ranges
      // The tail extends backwards in time so it's end is the earlier point in time.
      let tailStart = Infinity;
      let tailEnd = 0;
      let selectionStart = 0;
      let selectionEnd = Infinity;
      if (this.tail) {
        tailStart = animationTime;
        tailEnd = animationTime - TAIL_LENGTH;
      }
      if (this.selectedSection) {
        selectionStart = this.selectedSection.start;
        selectionEnd = this.selectedSection.end;
      } 
      return {
        tailStart,
        tailEnd,
        selectionStart,
        selectionEnd
      }
    },
    
    /**
     * Given a track and segment, get its start and end times.
     * @param {Track} track
     * @param {Segment} segment 
     * @returns {Object}
     */
    getSegmentTimes(track, segment) {
      const segmentStartTrackpoint = track.trackpoints[segment.start];
      const segmentEndTrackpoint = track.trackpoints[segment.finish];
      const segmentStartTime = new Date(segmentStartTrackpoint.time).getTime();
      const segmentEndTime = new Date(segmentEndTrackpoint.time).getTime();
      return {
        segmentStartTime,
        segmentEndTime
      }
    },

    updateTracks() {
      // clear tracks
      this.trackGeoJson.features = []
      for (let trackIndex = 0; trackIndex < this.tracks.length; trackIndex++) {
        const boatData = this.boatPoints.features[trackIndex];
        const boatRemoved = boatData.geometry.coordinates.length === 0;
        if (boatRemoved) {
          this.trackGeoJson.features.push([]);
          continue;
        }
        const animationTime = this.currentTime;
        let trackLines; 
        if (this.selectionMode === 'tags') {
          const tailSelectionSegments = this.segmentsByTailAndSelection(animationTime, trackIndex);
          trackLines = this.getSegmentFeatures(trackIndex, tailSelectionSegments, animationTime);
        } else {
          const trackpoints = this.getTailTrackpoints({
            trackIndex,
            boatData,
            animationTime
          });
          trackLines = this.getFeatureLines(trackIndex, trackpoints);
        }
        this.trackGeoJson.features.push(...trackLines)
      }
      this.map.getSource('tracks').setData(this.trackGeoJson)
    },

    drawOpacityTail(timestamp) {
      this.hoverTailGeoJson.features = []
      for (let trackIndex = 0; trackIndex < this.tracks.length; trackIndex++) {
        const boatData = this.hoverBoatPoints.features[trackIndex];
        const hoverBoatRemoved = boatData.geometry.coordinates.length === 0;
        if (hoverBoatRemoved) {
          // Clear the boat's hover tail
          this.hoverTailGeoJson.features.push([]);
          continue;
        }
        let trackLines; 
        if (this.selectionMode === 'tags') {
          const tailSelectionSegments = this.segmentsByTailAndSelection(timestamp, trackIndex);
          trackLines = this.getSegmentFeatures(trackIndex, tailSelectionSegments, timestamp);
        } else {
          const hoverTailTrackpoints = this.getTailTrackpoints({
            trackIndex,
            boatData,
            animationTime: timestamp
          })
          trackLines = this.getFeatureLines(trackIndex, hoverTailTrackpoints, timestamp)
        }
        this.hoverTailGeoJson.features.push(...trackLines)
      }
      this.map.getSource('hoverTails').setData(this.hoverTailGeoJson)
      if (!this.map.getLayer('hoverTailsLayer')) {
        this.map.addLayer(this.hoverTailsLayer)
      }
    },

    changeMathType(mode) {
      this.selectedMathType = mode;
    },

    /**
     * Create an animation point by interpolating between the current trackpoint and the next
     * trackpoint.
     * @param {number} currTrackpointIndex The index of the current trackpoint.
     * @param {number} trackIndex The index in this.tracks.
     * @param {UnixTimestamp} animationTime The current animation time.
     * @returns {GeoJSONPoint}
     */
    getAnimationPoint(currTrackpointIndex, trackIndex, animationTime) {
      const track = this.tracks[trackIndex];
      const currTrackpoint = track.trackpoints[currTrackpointIndex];
      const currTrackpointTime = new Date(currTrackpoint.time).getTime()
      const interpolationTime = animationTime - currTrackpointTime;
      const nextTrackpoint = this.getNextTrackpoint(currTrackpointIndex, trackIndex);
      const currCoordinate = [currTrackpoint.lon, currTrackpoint.lat]
      const currPoint = point(currCoordinate)
      const nextCoordinate = [nextTrackpoint.lon, nextTrackpoint.lat]
      const nextPoint = point(nextCoordinate)
      // Get the point between the current and next trackpoint based on
      // the interpolation time.
      const line = lineString([currCoordinate, nextCoordinate])
      // get the distance to move, assuming constant speed between trackpoints
      const trackpointDistance = distance(currPoint, nextPoint)
      const nextTrackpointTime = new Date(nextTrackpoint.time).getTime()
      const timeBetween = nextTrackpointTime - currTrackpointTime
      let animationPoint = currPoint;
      if (timeBetween > 0) {
        const ratioAlong = interpolationTime / timeBetween
        const movementDistance = ratioAlong * trackpointDistance
        animationPoint = along(line, movementDistance)
      }
      return animationPoint;
    },

    /**
     * Get the next trackpoint if it exists,
     * otherwise return the current trackpoint.
     * @param {number} currTrackpointIndex The index of the trackpoint in the track.
     * @param {number} trackIndex The index in this.tracks.
     * @returns {Trackpoint}
     */
    getNextTrackpoint(currTrackpointIndex, trackIndex) {
      const track = this.tracks[trackIndex];
      let nextTrackpointIndex = currTrackpointIndex + 1
      if (nextTrackpointIndex >= track.trackpoints.length) {
        nextTrackpointIndex = currTrackpointIndex
      }
      const nextTrackpoint = track.trackpoints[nextTrackpointIndex]
      return nextTrackpoint;
    },

    /**
     * 
     * @typedef {number} UnixTimestamp the number of milliseconds elapsed since January 1, 1970 00: 00: 00 UTC.
     */
    /**
     * Move the boat to a new position and updates its bearing
     * based on the new time.
     * @param {Trackpoint} currTrackpointIndex The index of the boat's closest current trackpoint.
     * @param {Trackpoint} trackIndex The index of the boat's track in this.tracks.
     * @param {Object} boatData The feature to update. 
     * @param {UnixTimestamp} newTime The new time to use for the update.
     * @returns {Object} boatData The updated boatData, a feature holding the boat's coordinate. 
     */
    updateBoat(currTrackpointIndex, trackIndex, boatData, newTime) {
      const currTrackpoint = this.tracks[trackIndex].trackpoints[currTrackpointIndex];
      const nextTrackpoint = this.getNextTrackpoint(currTrackpointIndex, trackIndex);
      const currCoordinate = [currTrackpoint.lon, currTrackpoint.lat]
      const nextCoordinate = [nextTrackpoint.lon, nextTrackpoint.lat]

      const equalCoordinates = currCoordinate.every((_, index) => currCoordinate[index] === nextCoordinate[index]);
      if (!equalCoordinates) {
        // update the boat's bearing
        const newBearing = this.getBoatBearing(currCoordinate, nextCoordinate);
        boatData.properties.bearing = newBearing; 
      }

      const animationPoint = this.getAnimationPoint(currTrackpointIndex, trackIndex, newTime);

      // moving the boat's position
      boatData.geometry.coordinates = animationPoint.geometry.coordinates

      return boatData
    },


    getBoatBearing(startCoordinates, endCoordinates) {
      let startPoint = point(startCoordinates)
      let endPoint = point(endCoordinates)
      // turf bearing function return -180 to 180, positive is clockwise
      let turfBearing = bearing(startPoint, endPoint)
      // converting to 0-360 bearing
      let compassBearing = bearingToAzimuth(turfBearing)
      return compassBearing;
    },

    /**
     * Get the index of the closest trackpoint to the new time.
     * @param {UnixTimestamp} newTime The new animation time. 
     * @param {number} trackIndex The index in this.tracks.
     * @returns {number}
     */
    getClosestTrackpointIndex(newTime, trackIndex) {
      const track = this.tracks[trackIndex];
      const firstTrackpoint = track.trackpoints[0];
      const lastTrackpoint = track.trackpoints[track.trackpoints.length - 1];
      const firstSecond = this.getTrackpointSecond(firstTrackpoint);
      const lastSecond = this.getTrackpointSecond(lastTrackpoint);
      let bySecond = this.indexesBySecond[trackIndex]
      let second = Math.floor(newTime / 1000)

      const secondOutsideOfTrack = second < firstSecond || second > lastSecond;
      if (secondOutsideOfTrack) {
        return null;
      }


      // get the index object at this second,
      // or at the closest second if there is no
      // entry for this second

      let trackpointIndexes
      let looking = true;
      do {
        trackpointIndexes = bySecond.get(second);
        second--
        looking = (trackpointIndexes === undefined) && second >= firstSecond;
      } while (looking)

      if (trackpointIndexes === undefined) {
        console.log("error finding trackpoint");
        return null;
      }

      // getting the closest trackpoint index
      let trackpointIndex
      let trackpoints = this.tracks[trackIndex].trackpoints
      if (trackpointIndexes.length === 1) {
        trackpointIndex = trackpointIndexes[0]
      } else {
        let secondTrackpoints = trackpointIndexes.map(index => trackpoints[index])
        let closestIndex = this.closestIndex(secondTrackpoints, newTime, {
          key: 'time'
        })
        trackpointIndex = secondTrackpoints[closestIndex]
      }
      return trackpointIndex
    },

    /**
     * Update all animations based on the currentTime.
     */
    _animationRunSpeed() {
      for (let [trackIndex, track] of this.tracks.entries()) {
        if (!this.checkedNames.has(trackIndex)) {
          // only update boats that are on the map
          continue
        }
        const currTrackpointIndex = this.getClosestTrackpointIndex(this.currentTime, trackIndex);
        if (currTrackpointIndex === null) {
          this.boatPoints.features = this.removeBoat(trackIndex, this.boatPoints.features);
          // Clear the data
          this.$set(this.indicators, trackIndex, {})
          continue;
        }

        const currTrackpoint = track.trackpoints[currTrackpointIndex];

        // update the boat
        this.boatPoints.features[trackIndex] = this.updateBoat(currTrackpointIndex, trackIndex, this.boatPoints.features[trackIndex], this.currentTime);

        // update the indicator for this boat
        // use the vue set method so that the change can be detected
        this.$set(this.indicators, trackIndex, currTrackpoint)
        // equivalent to:
        // this.indicators[trackIndex] = currTrackpoint
      }
      this.highlightFollowedBoat();
      // update all the boats on the map 
      this.map.getSource('boats').setData(this.boatPoints)
      this.updateTracks()
    },

    calculateAggregate(modeData, aggregates) {
      if (this.selectedMathType === 0) {
        aggregates.push(maxLargeArray(modeData));
      } else if (this.selectedMathType === 1) {
        const average = modeData.reduce((p, c) => p + c, 0) / modeData.length;
        aggregates.push(average.toFixed(2));
      } else {
        const average = modeData.reduce((p, c) => p + c, 0) / modeData.length;
        let variance = 0;
        modeData.forEach((value, i) => {
          variance += Math.pow(value - average, 2);
        });

        aggregates.push(Math.sqrt(variance / modeData.length).toFixed(2));
      }
    }
  },

  computed: {
    dataMode() {
      let dataMode = this.selectionMode !== "tags" && this.selectionMode !== "none"
      return dataMode;
    },

    getAggregates() {
      /*
      Return a list of aggregate values for each track
      based on the selected math type
      */
      const aggregates = []
    
      let seriesMode = this.selectionMode;
      if (!this.dataMode) {
        // use the last mode
        seriesMode = this.lastDataMode;
      }
      if (!this.tracks) {
        return aggregates
      }
      let allTrackpoints = this.tracksBySelection
      // a list of all values for the selected metric
      for (let trackIndex = 0; trackIndex < allTrackpoints.length; trackIndex++) {
        const trackpoints = allTrackpoints[trackIndex];
        const modeData = trackpoints.map(trackpoint => trackpoint[seriesMode])
        if (modeData.length == 0) {
          aggregates.push(null)
          continue
        }
        this.calculateAggregate(modeData, aggregates)
      }
      return aggregates
    },


    /**
     * Filter trackpoints based on the selection.
     * @returns {Trackpoint[][]} tracksBySelection Trackpoints for each track
     * within the selection. 
     */
    tracksBySelection() {
      let tracksBySelection = []
      // react to the selection
      let selection = this.selectedSection
      for (let track of this.tracks) {
        const trackpoints = track.trackpoints
        const selectedTrackpoints = !this.selectedSection ?
          trackpoints :
          trackpoints.filter(
            (trackpoint) =>
            new Date(trackpoint.time).getTime() > this.selectedSection.start &&
            new Date(trackpoint.time).getTime() <= this.selectedSection.end
          );

        tracksBySelection.push(selectedTrackpoints)
      }
      return tracksBySelection
    },

    getMinMax() {
      if (!this.tracks) {
        return {
          min: 0,
          max: 0,
        }
      }

      let allTrackpoints = this.tracksBySelection

      // a list of all values for the selected metric
      const modeData = []
      for (let trackIndex = 0; trackIndex < allTrackpoints.length; trackIndex++) {
        const trackpoints = allTrackpoints[trackIndex];
        for (let trackpointIndex = 0; trackpointIndex < trackpoints.length; trackpointIndex++) {
          const trackpoint = trackpoints[trackpointIndex];
          modeData.push(Math.abs(trackpoint[this.selectionMode]))
        }
      }

      let minValue = minLargeArray(modeData);
      let maxValue = maxLargeArray(modeData);
      maxValue = this.degreeModes.has(this.selectionMode) ?
        360 :
        Math.ceil(maxValue / 4) * 4;
      return {
        min: minValue,
        max: maxValue,
      };
    },

  },

  watch: {
    selectionMode(oldMode, newMode) {
      this.updateTracks()
      const hasData = oldMode !== "tags" && oldMode !== "none";
      if (hasData) {
        this.lastDataMode = oldMode;
      }
    },

    followedBoatIndex(newFollowedBoatIndex) {
      this.highlightFollowedBoat();
      // update all the boats on the map 
      this.map.getSource('boats').setData(this.boatPoints)
    },
  }
}
