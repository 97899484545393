const alertHelper = {
  alertNotification(dispatch, error) {
    if (error.response.data.detail) {
      dispatch(
        "notifications/add",
        {
          type: "error",
          message: error.response.data.detail,
        },
        { root: true }
      )
      throw error
    } else {
      for (var i = 0; i < Object.keys(error.response.data).length; i++) {
        for (var j = 0; j < Object.values(error.response.data).length; j++) {
          dispatch(
            "notifications/add",
            {
              type: "error",
              message: Object.values(error.response.data)[i][j],
            },
            { root: true }
          )
          throw error
        }
      }
    }
  },

  successNotification(dispatch, message) {
    dispatch(
      "notifications/add",
      {
        type: "success",
        message: message,
      },
      { root: true }
    )
  },
}

export default alertHelper
