<template>
  <div>
    <div class="tracks-item bg-white padded-container mb-5 p-5">
      <Dropdown v-if="!journalView" class="track-menu">
        <a class="track-download" @click="attachWind">
          <b-icon icon="wind"></b-icon> Attach Wind File
        </a>
        <a class="track-download" @click="editBoat">
          <b-icon icon="ship"></b-icon> Edit Boat Class
        </a>
        <a class="exportCSV" @click="exportCSV">
          <b-icon icon="file-excel"></b-icon> All Trackpoint Data
          <div v-if="errors.download" class="validation-error-text">
            {{ errors.download.message }}
          </div>
        </a>
        <a class="ssreport" @click="ssReport">
          <b-icon icon="file-excel"></b-icon> Spreadsheet Report
        </a>
        <a
          class="modify-vis"
          @click="showVis = true"
          v-if="track.user === user.id"
        >
          <b-icon icon="eye"></b-icon> Modify Visibility
        </a>
        <span v-if="showVis">
          <div class="field">
            <b-radio v-model="permissions" native-value="PRIVATE"
              >Private</b-radio
            >
            <b-radio v-model="permissions" native-value="PUBLIC"
              >Public</b-radio
            >
          </div>
        </span>
        <a class="track-download" v-if="canDownload" @click="download">
          <b-icon icon="file-download"></b-icon> Download Source
        </a>
        <!-- stop propagation on click so that menu doesn't close -->
        <a class="track-download" @click.stop="showConfirm = true">
          <b-icon icon="trash" aria-hidden="true"></b-icon> Delete Track
        </a>
        <span v-if="showConfirm" class=""
          >Are you sure?
          <a class="button btn-warning" @click="deleteTrack">Yes</a>
          <a class="button btn-primary" @click="showConfirm = false">No</a>
        </span>
      </Dropdown>
      <a v-if="!journalView" class="open-journal" @click="journal">
        <span
          class="fa-stack fa-1x has-badge"
          :data-count="track.journal_count"
        >
          <i class="fa fa-book fa-stack-2x"></i>
        </span>
      </a>
      <div
        v-if="track.sample_rate && track.sample_rate <= 0.5"
        @click="showSampleRateModal = true"
      >
        <b-icon 
          class="sample-warning"
          icon="exclamation-circle"
          slot="icon"
          size="is-medium"
        />
      </div>
      <b-modal v-model="showSampleRateModal" :has-modal-card="true">
        <div class="card modal-height warning-container">
          <header class="card-header">
            <p class="card-header-title">
              Low GPS Sample Rate
            </p>
          </header>
          <div class="card-content">
            <p>
              Racing Alpha works best with GPS devices recording once per second.
            </p>
            <div v-if="track.user == user.id">
              <p v-if="track.garmin_id">
                Please see Garmin's 
                <a
                  :href="garminSupport"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                support page</a> for instructions
                on increasing sample rate to once per second.
              </p>
              <p v-else>
                Please read your device manual to see if you can increase it's sample rate.
              </p>
            </div>
            <p>This track can still be viewed and will work as expected, but some metrics
              may be less accurate due to the low sample rate.
            </p>
            <b-button class="mt-3" type="is-primary" @click="showSampleRateModal = false">
              OK
            </b-button>
          </div>
        </div>
      </b-modal>
      <TrackListDetails v-if="track"
        :static-map="track.static_map"
        :track="track"
        :key="listKey"
        :journalView="journalView"
      ></TrackListDetails>
    </div>
    <b-modal v-model="showBoatClass">
      <div class="card">
        <div class="card-content">
          <h4>Change Boat Class</h4>
          <b-field>
            <b-select
              placeholder="Select a name"
              v-model="boatclass"
            >
              <option
                v-for="(option, index) in boatClasses"
                :value="option"
                :key="index"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <b-button type="is-primary" @click="submit">
            Submit
          </b-button>
          <b-button type="is-light" @click="cancel">
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
    <AttachWindModal :show="showAttachWindModal"
                     :trackId="track.id"
                     :windfiles="windfiles"
                     @close="showAttachWindModal = false"
                     @selected="windfileSelected"
    ></AttachWindModal>
  </div>
</template>
<script>
import Dropdown from "@/components/ui/Dropdown.vue"
import { mapGetters } from "vuex"
import TrackListDetails from "@/views/tracks/TrackListDetails.vue"
import Modal from "@/components/ui/Modal.vue"
import axios from "axios"
import AddWindModal from "@/views/wind/AddWindModal";
import AttachWindModal from "@/views/wind/AttachWindModal";
import api from "@/services/api/windfiles.js"
import alertHelper from "@/services/helpers/alertHelper";

export default {
  name: "Track",
  props: ["track", "windfiles", "journalView"],
  components: {
    AttachWindModal,
    Dropdown,
    TrackListDetails,
    Modal,
    AddWindModal
  },
  data() {
    return {
      permissions: this.track.permissions,
      showVis: false,
      showConfirm: false,
      errors: {
        download: null,
      },
      showBoatClass: false,
      boatClasses: ["29er", "470", "49er", "49erFX", "C420", "CFJ", "Foil Pumping", "Formula Kite", "i420", "J70", "Kite Flying", "Laser", "Melges 24", "Moth", "Nacra 17", "Viper 640", "Other"],
      boatclass: this.track.boat_name || "",
      showSampleRateModal: false,
      garminSupport: "https://support.garmin.com/en-US/?faq=s4w6kZmbmK0P6l20SgpW28",
      showAttachWindModal: false,
      listKey: 0,
    }
  },
  watch: {
    boatclass: function (val) {},
    permissions(perm) {
      this.$store.dispatch("tracks/updateTrack", {
        id: this.track.id,
        params: { permissions: perm },
      })
    },
  },
  methods: {
    attachWind() {
      this.showAttachWindModal = true;
    },

    windfileSelected(id) {
      api.attachWindFile(this.track.id, id).then((result) => {
        this.$store.dispatch("tracks/forceWindUpdate", this.track)
        this.$emit('trackUpdated');
        this.listKey += 1;
      }).catch((error) => {
        this.$emit('here', error)
        alertHelper.alertNotification(this.$store.dispatch, error);
      })
    },

    editBoat() {
      this.showBoatClass = true;
    },

    submit() {
      if (this.boatclass) {
        this.showBoatClass = false
        this.$store.dispatch("tracks/updateTrack", {
          id: this.track.id,
          params: { boat_name: this.boatclass },
        }).then(response => {
          this.track.boat_name = response.data.boat_name
        })
      }
    },

    cancel() {
      this.showBoatClass = false
    },

    exportCSV() {
      this.errors = {
        download: null,
      }
      axios
        .get("/download/exportCSV/" + this.track.id + "/")
        .then((response) => {
          if (response.status == 204) {
            this.errors.download = {
              id: id,
              message: "No Report for Download",
            }
            this.$store.dispatch("notifications/add", {
              type: "error",
              message: " No Report for Download",
            })
          } else {
            const s3instance = axios.create({
              timeout: 1000,
            })

            s3instance({
              method: "get",
              url: response.data,
              responseType: "blob",
            })
              .then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", this.track.boat_name + ".csv") //or any other extension
                document.body.appendChild(link)
                link.click()
              })
              .catch((error) => console.log(error))
          }
        })
        .catch((error) => {
          console.log("error occured")
        })
    },
    ssReport() {
      const reportURL = `/reports/track/?track_id=${this.track.id}`
      axios.get(reportURL, {responseType: 'blob'}).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        let fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `${this.track.id}-track-report.xlsx`)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    download() {
      this.errors = {
        download: null,
      }
      let sourceURL = "download/track/source/" + this.track.id + "/"
      axios.get(sourceURL).then((response) => {
        const fileURL = response.data
        const s3instance = axios.create({
          timeout: 1000,
        })
        s3instance({
          method: "get",
          url: fileURL,
          responseType: "blob",
        })
        axios.get(fileURL, {responseType: "blob"})
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data])
          )
          let extension = fileURL.split('.').pop();
          let fileLink = document.createElement('a')
          fileLink.href = url
          fileLink.setAttribute('download', `track-${this.track.id}.${extension}`)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch((error) => console.log(error))
      })
    },
    journal() {
      this.$emit("show-journal", this.track.id)
    },
    deleteTrack() {
      this.$store.dispatch("tracks/deleteTrack", this.track)
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    canDownload() {
      let isOwner = this.track.user === this.user.id  
      let isCoach = this.user.role === 2 || this.user.enabled_features.coach_tools
      let isAdmin = this.user.role === 1
      return isOwner || isCoach || isAdmin
    }
  },
}
</script>
<style>
  .tracks-item {
    padding:  20px;
  }
</style>
<style lang="sass">
.tracks-item
  position: relative

  &:hover
    box-shadow: 0 0 25px 0 rgba(93, 148, 218, .1)

  .validation-error-text
    padding-top: 6px
    height: 14px
    font-size: 10px
    color: $coral

  a
    color: $font-color
    text-decoration: none

    &:hover
      text-decoration: none

  .title
    font-size: 18px
    margin: 10px 0px

  .subtitle
    font-size: 16px
    margin: 1px

  .track-menu
    position: absolute
    right: 15px
    top: 10px
    font-size: 14px

    .dropdown-container
      width: 200px

  .track-download
    cursor: pointer
    &:hover
      color: #6D5CAE

  .open-journal
    cursor: pointer
    position: absolute
    right: 20px
    top: 45px

    &:hover
      color: #6D5CAE
  
  .sample-warning
    cursor: pointer
    color: $warning 
    position: absolute
    right: 20px
    top: 85px
    z-index: 1

    &:hover
      color: $warning-hover
  
  .warning-container
    max-width: 600px
    a
      text-decoration: underline
      color: revert

  .exportCSV
    cursor: pointer

    &:hover
      color: #6D5CAE

  .track-title-container
    display: flex
    justify-content: space-between
    align-items: center

  .track-time
    opacity: 0.5
    font-size: 10px

  .track-id
    font-size: 100px
    opacity: .5
    position: absolute
    right: -15px
    bottom: -50px
    color: #ccc
    margin: 0
    padding: 0
    z-index: 0
    font-weight: 600
    display: block
    line-height: 160px

  .fa-stack[data-count]:after
    position: absolute
    right: 0%
    top: 1%
    content:  attr(data-count)
    font-size: 50%
    padding: .6em
    border-radius: 999px
    line-height: .75em
    color:  white
    background: rgba(255,0,0,.85)
    text-align: center
    // min-width: 2em
    font-weight: bold
</style>
