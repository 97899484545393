<template>
  <div class="layout-wrapper">
    <app-header></app-header>
    <div class="alert-container">
      <alert
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      >
      </alert>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import appHeader from "@/components/app-header/index"
import alert from "@/components/ui/Alert"
import { mapState } from "vuex"

export default {
  name: "default",
  components: {
    alert,
    appHeader,
  },
  computed: {
    ...mapState("notifications", ["notifications"]),
  },
}
</script>

<style lang="sass">
.alert-container
  z-index: 9999
</style>
