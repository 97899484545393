<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <b-modal :active="displayModal" :on-cancel="close">
      <div class="modal-card-container">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Welcome to Racing Alpha!</p>
          </header>
          <section class="modal-card-body" style="text-align: center;">
            <div class="videoWrapper">
              <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/_ULq09BnmFw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </section>
          <footer class="modal-card-foot">
            <b-button class="is-danger" @click="close">Close</b-button>
          </footer>
        </div>
      </div>
    </b-modal>
    <set-boat-class/>
  </div>
</template>

<script>
  import {mapGetters } from "vuex"
  import SetBoatClass from './views/account/SetBoatClass.vue'
  export default {
    name: 'App',
    components: {
      SetBoatClass,
    },
    data(){
      return {
        introVideo: true
      }
    },
    computed:{
      ...mapGetters({
        user: "auth/user",
      }),
      displayModal(){
        return this.showVideo && this.introVideo
      },
      showVideo(){
        const videoWatched = JSON.parse(localStorage.getItem('videoWatched'))
        const isUser = this.user
        if(videoWatched === null && isUser){
          return true
        }
      }
    },
    methods: {
      close() {
        localStorage.setItem('videoWatched', JSON.stringify(true))
        this.introVideo = false
      }
    }
  }
</script>

<style lang="sass">
  html
    overflow-y: auto !important
</style>

<style scoped>
/* https://css-tricks.com/fluid-width-video/ */
.videoWrapper {
  /* falls back to 16/9, but otherwise uses ratio from HTML */
  padding-bottom: calc(var(--aspect-ratio, .5625) * 100%); 
  position: relative;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-card {
  /* Space around modal on mobile */
  max-width: 90vw;
}
</style>
