<template>
  <layout>
    <div class="container mt-5">
      <h1 class="title is-size-3 has-text-centered">Teams</h1>
      <div class="columns is-multiline">
        <!-- Team List Column -->
        <div class="column is-three-quarters">
          <TeamList></TeamList>
        </div>

        <!-- Create Team Form Column (visible only for coaches) -->
        <div class="column is-one-quarter" v-if="user.enabled_features['coach_tools']">
          <b-card class="mb-5">
            <br>
            <br>
            <br>
            <h2 class="subtitle">Manage Teams</h2>
            <b-button @click="openTeamDialog" type="is-primary" class="is-fullwidth">+ New Team</b-button>
          </b-card>
        </div>
      </div>

      <!-- New Team Modal -->
      <b-modal :active.sync="isTeamModalActive" has-modal-card :scroll="true" custom-class="modern-modal">
        <div class="modal-card">
          <header class="modal-card-head has-background-light">
            <p class="modal-card-title">Create a New Team</p>
            <button class="delete" @click="closeTeamDialog" aria-label="close"></button>
          </header>
          <section class="modal-card-body">
            <TeamForm @submitted="createTeam" ref="teamForm"></TeamForm>
          </section>
        </div>
      </b-modal>
    </div>
  </layout>
</template>

<script>
import layout from "@/views/layouts/Default";
import TeamList from "@/views/teams/TeamList.vue";
import TeamForm from "@/views/teams/TeamForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "TeamIndex",
  components: { layout, TeamList, TeamForm },
  data() {
    return {
      isTeamModalActive: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    openTeamDialog() {
      this.isTeamModalActive = true;
    },
    closeTeamDialog() {
      this.isTeamModalActive = false;
    },
    createTeam(formData) {
      this.$store.dispatch("teams/createTeam", {formData});
      this.closeTeamDialog();
      this.$refs.teamForm.reset();
    },
  },
};
</script>

<style scoped>
/* Modal Style */
.modern-modal .modal-card {
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.modern-modal .modal-card-head {
  border-bottom: none;
  padding: 1.5rem;
  font-size: 1.25rem;
}

.modal-card-body {
  padding: 2rem;
}

/* Form Elements */
.b-field label {
  font-weight: bold;
}

.b-input {
  border-radius: 8px;
  padding: 0.75rem;
}

.vue-dropzone {
  border: 2px dashed #bdbdbd;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.b-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: bold;
}

.b-button.is-info {
  background-color: #3273dc;
  color: white;
  border: none;
}

.b-button.is-info:hover {
  background-color: #275bbe;
}
</style>
