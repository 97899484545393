<template>
  <div class="container">
    <div class="columns">
      <div class="column is-3">
        <b-field label="Select a date range">
          <b-datepicker v-model="dates" :range="true" :mobile-native="false"></b-datepicker>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Boat Class">
          <b-select v-model="boatClass">
            <option
                v-for="boatClass in boatClasses"
                :value="boatClass"
                :key="boatClass">
                {{ boatClass }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-2" v-if="user.enabled_features['coach_tools']">
        <b-field label="View As">
          <b-select v-model="viewUser" placeholder="Myself">
            <option
              v-for="athlete in athletes"
              :value="athlete.id"
              :key="athlete.id">
                {{ athlete.first_name }} {{ athlete.last_name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-3 is-offset-2">
        <b-icon icon="file-excel"></b-icon><a @click="downloadReport()" title="Download Report">Download Daily Report</a>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <h1 class="is-size-4">On the Water Stats</h1>
        <UserStats :user="selectedUser" :start="start" :end="end" :boatclass="boatClass"></UserStats>
      </div>
      <div class="column is-half">
        <WindConditions :user="selectedUser" :start="this.start" :end="this.end" :boatclass="boatClass"></WindConditions>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <TackingImprovement :user="selectedUser" :start="this.start" :end="this.end" tag="T" :boatclass="boatClass"></TackingImprovement>
      </div>
      <div class="column is-half">
        <TackingImprovement :user="selectedUser" :start="this.start" :end="this.end" tag="G" :boatclass="boatClass"></TackingImprovement>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <ExpectedLoss :user="selectedUser" :start="this.start" :end="this.end" tag="T" :boatclass="boatClass"></ExpectedLoss>
      </div>
      <div class="column is-half">
        <ExpectedLoss :user="selectedUser" :start="this.start" :end="this.end" tag="G" :boatclass="boatClass"></ExpectedLoss>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <Leaderboard :user="selectedUser" :start="this.start" :end="this.end" :boatclass="boatClass"></Leaderboard>
      </div>
      <div class="column is-half">
        <RegattaResults :user="selectedUser" :start="this.start" :end="this.end" :boatclass="boatClass"></RegattaResults>
      </div>
    </div>
  </div>
</template>
<script>
import UserStats from '@/views/dashboard/UserStats'
import WindConditions from '@/views/dashboard/widgets/WindConditions'
import TackingImprovement from '@/views/dashboard/widgets/TackingImprovement'
import ExpectedLoss from '@/views/dashboard/widgets/ExpectedLoss'
import VueApexCharts from 'vue-apexcharts'
import Leaderboard from '@/views/dashboard/widgets/Leaderboard'
import axios from 'axios'
import { mapState, mapGetters, mapActions } from "vuex"
import RegattaResults from "@/views/dashboard/widgets/RegattaResults";
export default {
    name: 'Reports',
    components: {
      RegattaResults,
      'apexchart': VueApexCharts,
      WindConditions,
      TackingImprovement,
      UserStats,
      ExpectedLoss,
      Leaderboard
    },
    data(){
      return {
        dates: [
          new Date(new Date().setDate(new Date().getDate() - 30)),
          new Date(),
        ],
        timeRanges: [
          {
            text: "Last 30 Days",
            start: new Date(
              new Date().setDate(new Date().getDate() - 30)
            ).toISOString(),
            end: new Date().toISOString(),
          },
          {
            text: "This Year",
            start: new Date(new Date().getFullYear(), 0, 1).toISOString(),
            end: new Date().toISOString(),
          },
        ],
        boatClass: 'All',
        boatClasses: ['All'],
        viewUser: null
      }
    },
    beforeCreate(){
      this.$store.dispatch("teams/fetchTeams")
    },
    mounted(){
      this.getBoatClasses()
    },
    computed: {
      start(){
        return this.dates[0].toISOString()
      },
      end(){
        return this.dates[1].toISOString()
      },
      ...mapState({
        user(state) {
          return state.auth.user
        },
      }),
      ...mapGetters({
          teams: "teams/getTeams",
      }),
      athletes(){
        const flattened = [].concat(...this.teams.map(team => {
          return [...team.athletes, ...team.coaches]
        }))
        return flattened.filter((athlete, index, self) =>
          index === self.findIndex((a) =>(
            a.id === athlete.id
          ))
        )
      },
      selectedUser(){
        return this.viewUser ? this.viewUser : this.user.id
      }
    },
    methods: {
      getBoatClasses(){
        const url = `/reports/boatclasses/`
        axios.get(url).then(response => {
          this.boatClasses = ['All']
          this.boatClasses.push(...response.data)
        })
      },
      downloadReport(){
        const reportURL = `/reports/daily/?start=${this.start}&end=${this.end}`
        axios.get(reportURL, {responseType: 'blob'}).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'report.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })

      }
    }
}
</script>
