<template>
  <layout>
    <div class="container full-height px-4 pb-4">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <!-- Tabs Navigation -->
          <div class="tabs">
            <ul>
              <li class="is-active"><a>Profile</a></li>
              <router-link tag="li" :to="{name: 'garmin'}"><a>Garmin</a></router-link>
              <router-link tag="li" :to="{name: 'plan'}"><a>Plan</a></router-link>
              <router-link tag="li" :to="{name: 'beta'}"><a>Beta Features</a></router-link>
            </ul>
          </div>

          <!-- Profile Form Card -->
          <div class="box has-background-light padded-container">
            <h1 class="title has-text-centered">Edit Profile</h1>

            <!-- Form for Profile Update -->
            <form class="account-form" @submit.prevent="save">
              <!-- First Name and Last Name Full Width -->
              <div class="columns is-gapless">
                <div class="column is-half">
                  <b-field label="First Name" class="tight-column">
                    <b-input
                      id="first_name"
                      v-model="myaccount.first_name"
                      :class="{'validation-error': myaccount.errors.first_name, disabled: !myaccount.edit}"
                      placeholder="First name"
                    ></b-input>
                    <span class="validation-error-text">{{ myaccount.errors.first_name }}</span>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="Last Name" class="tight-column">
                    <b-input
                      id="last_name"
                      v-model="myaccount.last_name"
                      :class="{'validation-error': myaccount.errors.last_name, disabled: !myaccount.edit}"
                      placeholder="Last name"
                    ></b-input>
                    <span class="validation-error-text">{{ myaccount.errors.last_name }}</span>
                  </b-field>
                </div>
              </div>

              <!-- Email and Password Full Width -->
              <div class="columns is-gapless">
                <div class="column is-half">
                  <b-field label="Email" class="tight-column">
                    <b-input
                      id="email"
                      v-model="myaccount.email"
                      :class="{'validation-error': myaccount.errors.email, disabled: !myaccount.edit}"
                      placeholder="your email"
                    ></b-input>
                    <span class="validation-error-text">{{ myaccount.errors.email }}</span>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field label="Password" class="tight-column">
                    <b-input
                      id="password"
                      type="password"
                      v-model="myaccount.password"
                      :class="{'validation-error': myaccount.errors.password, disabled: !myaccount.edit}"
                      placeholder="**********"
                    ></b-input>
                    <span class="validation-error-text">{{ myaccount.errors.password }}</span>
                  </b-field>
                </div>
              </div>

              <!-- Primary Boat Class and Email Notifications on Same Line -->
              <div class="columns is-gapless">
                <div class="column is-half">
                  <b-field label="Primary Boat Class" class="tight-column">
                    <b-select v-model="myaccount.primary_boat_class">
                      <option v-for="(option, index) in boatClasses" :value="option" :key="index">
                        {{ option }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-half">
                  <br>
                  <b-field class="tight-column">
                    <b-switch id="email_notifications" style="top: 15px" v-model="myaccount.email_notifications">
                      Email Notifications
                    </b-switch>
                  </b-field>
                </div>
              </div>

              <!-- Profile Image Upload -->
              <b-field label="Upload a profile image">
                <vue-dropzone
                  ref="profileDropzone"
                  id="dropzone"
                  @vdropzone-sending="sendingEvent"
                  @vdropzone-queue-complete="queueComplete"
                  @vdropzone-drop="dropped"
                  @vdropzone-mounted="dropMounted"
                  :options="dropzoneOptions"
                ></vue-dropzone>
              </b-field>

              <!-- Save Button -->
              <div class="button-container">
                <b-button class="button is-link is-fullwidth" :disabled="$v.$invalid" native-type="submit">
                  Save
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer-links />
  </layout>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { required, email, minLength } from "vuelidate/lib/validators";
import layout from "@/views/layouts/Default";
import footerLinks from "@/components/shared/footer-links";
import {mapState, mapActions} from "vuex";

export default {
  name: "profile",
  components: {
    layout,
    footerLinks,
    vueDropzone: vue2Dropzone,
  },
  validations: {
    myaccount: {
      first_name: {required, minLength: minLength(3)},
      last_name: {required, minLength: minLength(3)},
      email: {required, email},
      password: {minLength: minLength(6)},
    },
  },
  data() {
    return {
      myaccount: {
        edit: false,
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        primary_boat_class: '',
        profile_img: null,
        email_notifications: false,
        errors: {},
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API + "/users/profileimg/",
        thumbnailWidth: 150,
        dictDefaultMessage: "Drag and Drop an image",
        headers: {
          Authorization: "Bearer " + this.$store.getters["auth/user"].access,
        },
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        autoProcessQueue: true,
      },
      boatClasses: ["29er", "470", "49er", "49erFX", "C420", "CFJ", "Foil Pumping", "Formula Kite", "i420", "J70", "Kite Flying", "Laser", "Melges 24", "Moth", "Nacra 17", "Viper 640", "Other"],
    };
  },
  beforeCreate() {
    this.$store.dispatch("auth/me");
  },
  created() {
    this.myaccount.first_name = this.user.first_name;
    this.myaccount.last_name = this.user.last_name;
    this.myaccount.email = this.user.email;
    this.myaccount.profile_img = this.user.profile_img;
    this.myaccount.email_notifications = this.user.email_notifications;
    this.myaccount.primary_boat_class = this.user.primary_boat_class || '';
  },
  methods: {
    save() {
      this.myaccount.edit = !this.myaccount.edit;
      this.myaccount.errors = {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        email_notifications: null,
      };

      if (!this.$v.$invalid) {
        let userData = {
          first_name: this.myaccount.first_name,
          last_name: this.myaccount.last_name,
          email: this.myaccount.email,
          email_notifications: this.myaccount.email_notifications,
          primary_boat_class: this.myaccount.primary_boat_class,
        };
        if (this.myaccount.password) {
          userData.password = this.myaccount.password;
        }
        this.$store.dispatch("auth/userupdate", userData).catch((error) => {
          for (var key in error.response.data.errors) {
            if (key == "first_name") {
              this.myaccount.errors.first_name = Object.values(error.response.data.errors.first_name).toString();
            }
            if (key == "last_name") {
              this.myaccount.errors.last_name = Object.values(error.response.data.errors.last_name).toString();
            }
            if (key == "email") {
              this.myaccount.errors.email = Object.values(error.response.data.errors.email).toString();
            }
            if (key == "password") {
              this.myaccount.errors.password = Object.values(error.response.data.errors.password).toString();
            }
            if (key == "email_notifications") {
              this.myaccount.errors.email_notifications = Object.values(error.response.data.errors.email_notifications);
            }
          }
        });
      } else {
        if (!this.$v.myaccount.first_name.required)
          this.myaccount.errors.first_name = "First name required";
        if (!this.$v.myaccount.first_name.minLength)
          this.myaccount.errors.first_name = "First name must be 3 characters";
        if (!this.$v.myaccount.last_name.required)
          this.myaccount.errors.last_name = "Last name required";
        if (!this.$v.myaccount.last_name.minLength)
          this.myaccount.errors.last_name = "Last name must be 3 characters";
        if (!this.$v.myaccount.email.required)
          this.myaccount.errors.email = "Email required";
        if (!this.$v.myaccount.email.email)
          this.myaccount.errors.email = "Email not valid";
        if (!this.$v.myaccount.password.minLength)
          this.myaccount.errors.password = "Password must be 6 characters";
      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append("file", file);
    },
    queueComplete() {
      this.$store.dispatch("auth/me");
    },
    dropped() {
      this.$refs.profileDropzone.removeAllFiles();
    },
    dropMounted() {
      if (this.myaccount.profile_img != null) {
        const file = {size: 0, name: "ProfileImg"};
        this.$refs.profileDropzone.manuallyAddFile(file, this.myaccount.profile_img);
      }
    },
  },
  computed: {
    ...mapState({
      user(state) {
        return state.auth.user;
      },
    }),
  },
};
</script>

<style scoped>
.padded-container {
  padding: 2rem;
}

.button-container {
  margin-top: 1rem;
}

.validation-error-text {
  color: red;
  font-size: 0.85rem;
  margin-top: -10px;
}

.tight-column {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
</style>
