import store from "@/store/store"
import api from "@/services/api/windfiles.js"
import alertHelper from "@/services/helpers/alertHelper";
import Vue from 'vue';

export const namespaced = true

export const state = {
  windfiles: [],
}

export const mutations = {
  SET_WINDFILES(state, windfiles) {
    state.windfiles = windfiles;
  },
  SET_WINDFILE(state, windfile) {
    const idx = state.windfiles.findIndex(x => x.id == windfile.id);
    Vue.set(state.windfiles, idx, windfile);
  }
}

export const actions = {
  getWindfiles({ commit, dispatch, state }, options) {
    api.getWindFiles()
      .then((response) => {
        commit("SET_WINDFILES", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem fetching wind files:" + error.response,
        })
      })
  },
  getWindfile({ commit, dispatch, state }, id) {
    api.getWindFile(id)
      .then((response) => {
        commit("SET_WINDFILE", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: "There was problem fetching wind files:" + error.response,
        })
      })
  }
}

export const getters = {
  getWindfiles: (state) => {
    return state.windfiles;
  },
}