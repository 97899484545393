import axios from "axios"

export default {
  getJournals(cursor, search) {
    return axios.get(`/journals/?cursor=${cursor}&search=${search}`)
  },

  getJournalsByTrack(id) {
    return axios.get("/tracks/" + id + "/journal/")
  },

  getJournalsByRace(id, share_id) {
    let url = `/races/${id}/journal/`
    if(share_id != undefined){
      url += `?share_id=${share_id}`
    }
    return axios.get(url)
  },

  getJournal(id) {
    return axios.get("/journals/" + id + "/")
  },

  createJournal(journal) {
    return axios.post("/journals/", journal)
  },

  updateJournal(journal, journalId) {
    return axios.put("/journals/" + journalId + "/", journal)
  },

  deleteJournal(journalId) {
    return axios.delete("/journals/" + journalId + "/")
  },

  deleteJournalPhoto(journalId, photoId){
    return axios.delete(`/journals/${journalId}/photo/?photo_id=${photoId}` )
  },

  createReply(journalId, reply){
    return axios.post(`/journals/${journalId}/reply/`, reply)
  },

  updateReply(journalId, reply) {
    return axios.patch(`/journals/${journalId}/reply/`, reply)
  },

  deleteReply(journalId, reply_id) {
    return axios.delete(`/journals/${journalId}/reply/?reply_id=${reply_id}`)
  },

}
