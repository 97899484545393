import apiAuth from "@/services/api/auth"
import alertHelper from "@/services/helpers/alertHelper"

export const namespaced = true

export const state = {
  status: false,
  user: null,
}

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = null
    localStorage.setItem("user", JSON.stringify(userData))
    state.status = true
    state.user = userData
  },
  UPDATE_USER_DATA(state, userData) {
    localStorage.removeItem("user")
    //state.user.id = userData.id

    if (userData.email) {
      state.user.email = userData.email
    }
    if (userData.first_name) {
      state.user.first_name = userData.first_name
    }
    if (userData.last_name) {
      state.user.last_name = userData.last_name
    }
    if (userData.garmin_connected) {
      state.user.garmin_connected = userData.garmin_connected
    }
    if (userData.profile_img) {
      state.user.profile_img = userData.profile_img
    }
    if (userData.role) {
      state.user.role = userData.role
    }
    if (userData.enabled_features) {
      state.user.enabled_features = userData.enabled_features
    }
    if(userData.hasOwnProperty('auto_segmentation_enabled')){
      state.user.auto_segmentation_enabled = userData.auto_segmentation_enabled
    }
    if(userData.email_notifications) {
      state.user.email_notifications = userData.email_notifications
    }
    if(userData.hasOwnProperty('show_wind_arrow')){
      state.user.show_wind_arrow = userData.show_wind_arrow
    }
    if(userData.primary_boat_class) {
      state.user.primary_boat_class = userData.primary_boat_class
    }
    if(userData.teammates) {
      state.user.teammates = userData.teammates
    }
    localStorage.setItem("user", JSON.stringify(state.user))
  },
  CLEAR_USER_DATA(state) {
    localStorage.removeItem("user")
    state.user.access = null
    state.user.refresh = null
    state.user.email = null
    state.user.role = null
    state.user.id = null
    state.user.first_name = null
    state.user.last_name = null
    state.user.profile_img = null
    state.user.auto_segmentation_enabled = false
    state.user.email_notifications = false
    state.user.garmin_connected = false
    state.user.show_wind_arrow = false
    state.user.primary_boat_class = null
    state.user.enabled_features = {
      'athlete': false,
      'auto_segmentation': false,
      'coach_tools': false
    }
    state.status = false
  },
}

export const actions = {
  userupdate({ commit, dispatch }, data) {
    return apiAuth
      .update(data)
      .then((response) => {
        commit("UPDATE_USER_DATA", data)
        alertHelper.successNotification(dispatch, "User Updated")
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
      })
  },

  autoLogin({ commit }, userData) {
    commit("SET_USER_DATA", userData)
  },

  register({ commit, dispatch }, credentials) {
    return apiAuth
      .register(credentials)
      .then((response) => {
        commit("SET_USER_DATA", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
      })
  },

  login({ commit, dispatch }, credentials) {
    return apiAuth
      .login(credentials)
      .then((response) => {
        console.log('Login response:', response);
        commit("SET_USER_DATA", response.data)
      })
      .catch((error) => {
        console.error('Login error:', error);
        alertHelper.alertNotification(dispatch, error)
      })
  },


  forgot({ commit, dispatch }, data) {
    return apiAuth
      .forgot(data)
      .then((response) => {
        alertHelper.successNotification(
          dispatch,
          "A reset link was sent to your email"
        )
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
      })
  },

  reset({ commit, dispatch }, email) {
    return apiAuth
      .reset(email)
      .then((response) => {
        alertHelper.successNotification(dispatch, "Password changed")
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
      })
  },

  me({ commit, dispatch }, credentials) {
    return apiAuth
      .me(credentials)
      .then((response) => {
        commit("UPDATE_USER_DATA", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
      })
  },

  logout({ commit }) {
    commit("CLEAR_USER_DATA")
    //return apiAuth.logout().then((response) => {commit('CLEAR_USER_DATA')})
  },

  removeProfileImg({ commit }) {
    return apiAuth.deleteProfileImg().then((response) => {
      commit("UPDATE_USER_DATA", { profile_img: null })
    })
  },
}

export const getters = {
  isAuthenticated: (state) => {
    return state.status
  },
  user: (state) => {
    return state.user
  },
}
