<template>
  <div>
    <JournalForm></JournalForm>
    <br/>
    <JournalList :journals="trackJournal"></JournalList>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex"
import JournalForm from "@/views/journal/JournalForm.vue"
import JournalList from "@/views/journal/JournalList.vue"
export default {
  name: "Journal",
  components: {
    JournalForm,
    JournalList,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      trackJournal: "journal/getTrackJournal",
    }),
  },
}
</script>
