<template>
  <div class="multi-column">
    <div>
      <dt>ALL SEGMENTS</dt>
      <p v-for="datum in overallLagData">
        <i>{{ datum.variable }}</i>: {{ datum.avg }}s
      </p>
    </div>
    <div v-for="segment in dataForSegments">
      <div>
        <dt>{{ segment.segmentName }}</dt>
        <p v-for="datum in segment.lagData">
          <i>{{ datum.variable }}</i>: {{ datum.avg }}s
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StraightLineRhythm",
  props: ["lagDataAllSegments", "selectedSegments"],
  computed: {
    overallLagData() {
      return this.unfurl(this.lagDataAllSegments);
    },
    dataForSegments() {
      let data = [];
      for (let key in this.selectedSegments) {
        data.push({
          lagData: this.unfurl(this.selectedSegments[key].sog_lag),
          segmentName: key
        })
      }
      return data;
    }
  },
  methods: {
    unfurl(rawLagData) {  // puts key for dict into object and then sorts
      let data = []; //
      for (let key in rawLagData) {
        if (key === '0') {
          continue
        }
        data.push({
          variable: key.toUpperCase().split("_").join(" "),
          avg: Number(rawLagData[key].avg.toFixed(2)),
          med: Number(rawLagData[key].med.toFixed(2))
        });
      }
      return data.sort((a, b) => a.avg > b.avg ? 1 : -1);
    }
  }
};
</script>
<style lang="sass" scoped>
  .multi-column
    display: flex
    flex-wrap: wrap
    margin-top: -1em
    width: 100%
    justify-content: center
  .multi-column>*
    margin: 15px
</style>