<template>
  <div>
    <div v-if="!stats">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <dl v-else>
      <dt>Sessions:</dt>
      <dd>{{ stats.sessions }}</dd>
      <dt>Hours on the water</dt>
      <dd>{{ stats.water_time }}</dd>
      <dt>Tacks</dt>
      <dd>{{ stats.tacks }}</dd>
      <dt>Gybes</dt>
      <dd>{{ stats.gybes }}</dd>
      <dt>Accelerations</dt>
      <dd>{{ stats.accelerations }}</dd>
      <dt>Sets</dt>
      <dd>{{ stats.sets }}</dd>
      <dt>Douses</dt>
      <dd>{{ stats.douses }}</dd>
    </dl>
  </div>
</template>
<script>
import axios from "axios"
export default {
  name: "UserStats",
  props: ['user', 'start', 'end', 'boatclass'],
  data() {
    return {
      stats: null,
    }
  },
  created() {
    this.fetchStats()
  },
  methods: {
    fetchStats() {
      const queryString = `?user=${this.user}&start=${this.start}&end=${this.end}&class=${this.boatclass}`
      axios
        .get("/reports/user/" + queryString)
        .then((response) => {
          this.stats = response.data
        })
        .catch((e) => {
          console.warn(e)
        })
    },
  },
  watch:{
    start(){
      this.fetchStats()
    },
    end(){
      this.fetchStats()
    },
    boatclass(){
      this.fetchStats()
    },
    user(){
      this.fetchStats()
    }
  }
}
</script>
