<template>
  <div class="flex-row">
    <div class="flex-content-row race-item bg-white padded-container">
      <Dropdown class="race-menu">
        <a
          v-if="canManageTracks"
          class="race-download"
          @click="changeName = true"
        >
          <b-icon icon="pen"></b-icon> Change Name
        </a>
        <a
          v-if="canManageTracks"
          class="race-download"
          @click="manageTracks = true"
        >
          <b-icon icon="list"></b-icon> Manage Tracks
        </a>
        <a class="race-download" @click="showShare = true">
          <b-icon icon="share"></b-icon> Share Race
        </a>
        <!-- stop propagation on click so that menu doesn't close -->
        <a class="race-download" @click.stop="showConfirm = true">
          <b-icon icon="trash"></b-icon> Delete Race
        </a>
        <span v-if="showConfirm" class=""
          >Are you sure?
          <a class="button btn-warning" @click="deleteTrack">Yes</a>
          <a class="button btn-primary" @click="showConfirm = false">No</a>
        </span>
      </Dropdown>
      <div class="details-container">
        <div class="columns">
          <!-- race details -->
          <div class="column is-one-quarter">
            <router-link
              :to="{ name: 'race-details', params: { id: race.id } }"
            >
              <h4 class="is-size-4" v-if="!race.name">Race {{ race.id }}</h4>
              <h4 class="is-size-4" v-else>{{ race.name }}</h4>
              <p v-if="race.start_time" class="rc-subtitle">
                {{ race.start_time | moment("M/D/YYYY - h:mm a") }}
              </p>
              <div v-else>
                <p v-if="race.track[race.track.length - 1]" class="rc-subtitle">
                  {{
                    race.track[race.track.length - 1].start_time
                      | moment("M/D/YYYY - h:mm a")
                  }}
                </p>
              </div>
              <p v-if="race.track[0]" class="rc-subtitle">
                {{ race.track[0].location }}
              </p>
              <span class="rc-id is-hidden-touch">R{{ race.id }}</span>
            </router-link>
          </div>
          <!-- coach and processing status -->
          <div class="column is-one-quarter">
            <h4 class="rc-title">Coach</h4>
            <div class="is-flex is-align-items-center">
              <img
                class="member-img race-img mr-3"
                v-bind:src="coach.profile_img"
                alt="img"
                style="margin-right: 10px"
              />
              <h4 class="comp">{{ coach.first_name }} {{ coach.last_name }}</h4>
            </div>
            <div v-if="!loaded" class="mt-3">
              <div v-if="!error">
                <i class="fa fa-spinner fa-spin"></i>
                <p>{{ message }}</p>
              </div>
              <div v-else>
                <p class="error">There was an error processing this race.</p>
              </div>
            </div>
          </div>
          <div class="column" v-if="userList.length > 0">
            <h4 class="rc-title">{{userList.length}} Competitors</h4>
            <div class="competitors-grid">
              <div
                v-for="(val, index) in userList"
                :key="index"
                class="is-flex is-align-items-center mb-3 competitor"
              >
                <img
                  class="member-img race-img mr-3"
                  v-bind:src="val.profile_img"
                  alt="img"
                />
                <h4>
                  {{ val.first_name }} {{ val.last_name }}
                </h4>
              </div>
            </div>
            <div class="rc-id-spacer is-hidden-touch"></div>
          </div>
          <div class="column" v-else>
            <h4 v-for="(val, index) in trackList" :key="index">
              Track {{ val }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showShare" :has-modal-card="true">
      <div class="card modal-height share-width">
        <div class="card-content">
          <p>Share this race:</p>
          <b-field :message="copyMessage">
            <b-input v-model="shareUrl" ref="shareUrlInput"></b-input>
          </b-field>
          <b-button type="is-primary" @click="copyShareUrl">Copy</b-button>
        </div>
      </div>
    </b-modal>
    <ManageRaceTracks
      :race="race"
      :show="manageTracks"
      v-on:close="manageTracks = false"
    >
    </ManageRaceTracks>
    <ChangeRaceName
      :race="race"
      :show="changeName"
      v-on:close="changeName = false"
    >
    </ChangeRaceName>
  </div>
</template>

<script>
import Dropdown from "@/components/ui/Dropdown.vue";
import api from "@/services/api/races.js";
import ManageRaceTracks from "@/views/race/ManageRaceTracks.vue";
import ChangeRaceName from "@/views/race/ChangeRaceName.vue";
import { mapGetters } from "vuex";
export default {
  name: "Race",
  props: ["race"],
  components: {
    Dropdown,
    ManageRaceTracks,
    ChangeRaceName,
  },
  data() {
    return {
      showConfirm: false,
      showShare: false,
      errors: {
        download: null,
      },
      trackList: [],
      time: null,
      copyMessage: "",
      loaded: false,
      fetchCount: 0,
      error: false,
      message: "",
      manageTracks: false,
      changeName: false,
    };
  },
  methods: {
    deleteTrack() {
      this.$store.dispatch("races/deleteRace", this.race);
    },
    copyShareUrl() {
      navigator.clipboard.writeText(this.shareUrl).then(() => {
        this.copyMessage = "Copied to clipboard!";
      });
    },
    setLoading(race) {
      if (race.processing_status == 1 || race.processing_status == 4) {
        this.getRaceInfo();
      } else {
        this.loaded = true;
      }
    },
    getRaceInfo() {
      api
        .getRaceInfo(this.race.id)
        .then((info) => {
          if (
            info.data.processing_status === 3 ||
            info.data.processing_status === 1
          ) {
            this.loaded = true;
          }
          if (info.data.processing_status !== 3) {
            if (info.data.processing_status == 2) {
              this.error = true;
            } else if (info.data.processing_status == 4) {
              this.loaded = false;
              this.message = "Race wind data is updating";
            }
            setTimeout(() => {
              this.getRaceInfo();
            }, 5000);
          }
        })
        .catch((error) => {
          console.log("Race info req failed with error ", error);
        });
    },
  },
  computed: {
    shareUrl() {
      return `https://racingalpha.com/races/${this.race.id}?share_id=${this.race.share_id}`;
    },
    userList() {
      let user_list = [];
      if (!this.race) {
        return user_list;
      }
      let users = [];
      this.race.track.forEach((track) => {
        users.push(track.user);
      });
      if (users.length === 1) {
        this.race.track.forEach((track) => {
          this.trackList.push(track.id);
        });
      } else {
        user_list = users.filter(
          (user, index, self) =>
            self.findIndex((t) => t.id === user.id) === index
        );
      }
      return user_list;
    },
    coach() {
      let coach = this.race.user;
      return coach;
    },
    canManageTracks() {
      // if user is the race owner or admin
      return this.user.id === this.race.user.id || this.user.role === 1;
    },
    ...mapGetters({
      user: "auth/user",
    }),
  },
  watch: {
    race(val) {
      if (val) {
        this.setLoading(val);
      }
    },
  },
  async mounted() {
    if (this.race) {
      this.setLoading(this.race);
    }
  },
};
</script>

<style lang="sass">
.status-spacer
  width: 6rem

.share-width
  min-width: 50vw

.race-item
  position: relative
  &:hover
    box-shadow: 0 0 25px 0 rgba(93, 148, 218, .1)

  .validation-error-text
    padding-top: 6px
    height: 14px
    font-size: 10px
    color: $coral

  a
    color: $font-color
    text-decoration: none

    &:hover
      text-decoration: none

  .race-menu
    position: absolute
    right: 15px
    top: 10px
    z-index: 1

  .race-download
    width: 9rem
    cursor: pointer
    &:hover
      color: #6D5CAE

  .rc-title
    font-size: 18px
    margin: 0
    margin-bottom: 10px

  .rc-subtitle
    font-size: 16px
    margin: 1px

  .rc-id
    font-size: 100px
    opacity: .5
    position: absolute
    right: -15px
    bottom: -50px
    color: #ccc
    margin: 0
    padding: 0
    z-index: 0
    font-weight: 600
    display: block
    line-height: 160px
  
  .rc-id-spacer
    height: 4rem

  .race-img
    width: 40px
    height: 40px
  
  .competitors-grid
    display: grid
    width: 100%
    grid-template-columns: 1fr 1fr
    grid-template-rows: auto
    grid-auto-flow: row
    gap: 0 1rem

</style>

<style scoped>
@media only screen and (max-width: 500px) {
  .competitors-grid {
    grid-template-columns: 1fr;
  }
}
</style>
