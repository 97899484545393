<template>
  <div>
    <div class="tag-input">
      <VarTagInput :varOptions="varOptions" @varAdded="varAdded"/>
    </div>
    <div class="chart">
      <div>
        <div class="chart-box" :key="reloadKey">
          <div v-for="(variable, index) in selectedVars">
            <dt>{{ variable.toUpperCase().split("_").join(" ") }}</dt>
            <apexchart
              :options="getOptions(variable)"
              width="100%"
              height="200"
              :series="getSeries(variable)"
              ref="chart"
            />
            <h2 class="is-1"><b>Detail:</b></h2>
            <b-collapse
              :open="false"
              position="is-bottom"
              aria-id="contentIdForA11y4"
              style="margin-bottom: 2em">
              <template #trigger="props">
                <a
                  aria-controls="contentIdForA11y4"
                  :aria-expanded="props.open">
                  <b-icon :icon="!props.open ? 'caret-down' : 'caret-up'"></b-icon>
                  {{ !props.open ? 'Show stats' : 'Hide' }}
                </a>
              </template>
              <StraightLineChartStats :selectedSegments="selectedSegments"
                                      :selectionAnalysis="selectionAnalysis"
                                      :selectedVar="variable"
                                      :userTags="userTags"
              />
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import VarTagInput from "@/components/straight-line-chart/VarTagInput";
import StraightLineChartStats from "@/components/straight-line-chart/StraightLineChartStats";

export default {
  name: "StraightLineChart",
  components: {
    StraightLineChartStats,
    VarTagInput,
    apexchart: VueApexCharts,
  },
  props: ["trackpoints", "colors", "selectedSegments", "segmentNames", "selectionAnalysis", "userTags"],
  computed: {
    /*allSeries() {
      return this.selectedVars.map(x => this.getSeries(x));
    },*/
    yAxisOptions() {
      let axisOne = {
        labels: {
          formatter: function(val) {
            return val.toFixed(2);
          },
        },
      };
      /*if (this.selectedVars.length > 1) {
        let axisTwo = {
          ...axisOne,
          opposite: true
        };
        return [axisOne, axisTwo]
      } else {
        return axisOne
      }*/
      return axisOne
    }
  },
  created() {
    let keyList = [] // for some reason Object.keys does not work
    for (var key in this.trackpoints[0]) {
      keyList.push(String(key));
    }
    this.varOptions = keyList;
  },
  data() {
    return {
      varOptions: [],
      selectedVars: [],
      reloadKey: 0,
    }
  },
  methods: {
    dragComplete(event, axes) {
      let start = axes.xaxis.min;
      if (start < 0) {
        start = 0;
      }
      const end = axes.xaxis.max;
      this.$emit('dragComplete', {start: start, end: end});
    },
    varAdded(vars) {
      this.selectedVars = vars;
      this.reloadKey += 1;
    },
    getSeries(forVar) {
      let allSeriesForSegments = []
      for (let segment in this.selectedSegments) {
        let startTime = this.selectedSegments[segment].trackpoints[0]['time']
        let seriesData = this.selectedSegments[segment].trackpoints.map((trackpoint) => {
          let dataPoint = {
            x: moment(trackpoint["time"]) - moment(startTime),
            y: trackpoint[forVar],
          };
          return dataPoint;
        });
        const series = {
          name: `${forVar}_${segment}`,
          type: "line",
          data: seriesData,
        };
        allSeriesForSegments.push(series);
      }
      // return series;
      return allSeriesForSegments;
    },
    getOptions(forVar) {
      const instance = this;
      return {
        chart: {
          id: forVar,
          group: "timeline",
          width: "100%",
          height: 200,
          type: "line",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },

          events: {
            zoomed: this.dragComplete,
          },

          toolbar: {
            show: true,
          },
          parentHeightOffset: 0,
          selection: {
            enabled: true,
            type: "x",
          },
        },
        colors: this.colors,
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        markers: {
          size: 5,
        },
        yaxis: this.yAxisOptions,
        xaxis: {
          type: "numeric",
          labels: {
            formatter: function(value, opts) {
              return moment.utc(value).format("HH:mm:ss");
            },
          },
          crosshairs: {
            show: true,
          },
        },
        tooltip: {
          shared: true,
          followCursor: false,
          x: {
            show: false
          },

          y: {
            show: false
          }
        },
      }
    },
  },
}
</script>

<style lang="sass" scoped>
  .tag-input
    margin-bottom: 1em
</style>