<template>
  <b-modal :active="promptBoatClass" :on-cancel="cancel">
    <div class="modal-card-container">
      <div class="modal-card">
        <div class="modal-card-head">
          <p class="modal-card-title">Please Select Your Primary Boat Class</p>
        </div>
        <section class="modal-card-body">
          <p>
            All uploaded tracks will default to your primary boat class.
          </p>
          <br>
          <p>
            Change your primary boat class at any time by going to My Account
            > Profile.<br>
            You can change the boat class of a specific track by clicking three
            dots in the top right and choosing "Edit Boat Class".
          </p>
          <br>
          <b-field label="Primary Boat Class">
            <b-select v-model="boatClass">
              <option
                v-for="(option, index) in boatClasses"
                :value="option"
                :key="index"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button type="is-primary" :disabled="saveDisabled" @click="save"> Save </b-button>
          <b-button type="is-danger" @click="cancel"> Close </b-button>
        </footer>
      </div>
    </div>
    
  </b-modal>
</template>

<script>
import {mapGetters } from "vuex"
export default {
  name: "SetBoatClass",
  data() {
    return {
      boatClasses: [
        "29er", 
	"470", 
	"49er", 
	"49erFX", 
	"C420", 
	"CFJ", 
	"Formula Kite", 
	"i420", 
	"J70", 
	"Laser", 
	"Melges 24",
  "Moth",
	"Nacra 17", 
	"Viper 640",
	"Other",
      ],
      boatClass: '',
      closed: false,
    };
  },
  computed: {
    promptBoatClass() {
      if (!this.loggedIn) {
        return false
      }
      let has_boat_class = this.user.primary_boat_class !== null
      let watched_video = this.videoWatched
      return !this.closed && !has_boat_class && watched_video
    },
    videoWatched() {
        return JSON.parse(localStorage.getItem('videoWatched'))
    },
    saveDisabled() {
      return this.boatClass === ''
    },
    ...mapGetters({
      user: "auth/user",
      loggedIn: "auth/isAuthenticated",
    }),
  },
  methods: {
    save() {
      let userData = {
        primary_boat_class: this.boatClass
      }
      this.$store.dispatch("auth/userupdate", userData)
      this.closed = true
    },
    cancel() {
      this.closed = true
      console.log(this.closed)
    }
  },
};
</script>

<style>
.modal-card-container {
  display: flex;
  justify-content: center;
}
</style>
<style scoped>
.modal-card {
  /* Space around modal on mobile */
  max-width: 90vw;
}
.modal-card-title {
  /* Makes the title text wrap on mobile */
  flex-shrink: 1;
}
</style>