<template>
  <form class="journalreply-form" @submit.prevent="submitForm">
    <span class="validation-error-text">{{ errors.nonField }}</span>
    <b-field>
      <b-input type="textarea"
        v-model="reply.content"
        rows="7"
        :class="{ 'validation-error': errors.content }"
        placeholder="Add reply"
        required
      >
      </b-input>
    </b-field>
    <b-button v-if="editing" type="is-primary" native-type="submit">Save</b-button>
    <b-button v-else type="is-primary" native-type="submit">Reply</b-button>
  </form>
</template>
<script>
import api from "@/services/api/journal"
export default {
  name: 'JournalReplyForm',
  props: {
    journalId: Number,
    reply: {
      type: Object,
      default() {
        return {
          content: "",
        }
      },
    },
    editing: Boolean,
  },
  data(){
    return {
      errors: {
        content: null,
        nonField: null,
      },
    }
  },
  methods: {
    submitForm(){
      this.errors = {
        content: null,
        nonField: null,
      }
      if(this.editing) {
        api.updateReply(this.journalId, this.reply)
        .then(response => {
          const reply_change = {
            "old_reply": this.reply,
            "new_reply": response.data,
          }
          this.$emit('reply-updated', reply_change) 
          this.reply.content = ""
        })
        .catch((error) => {
          this.errors.nonField = error
        })
      } else {
        api.createReply(this.journalId, this.reply).then(response => {
          this.$emit('reply-added', response.data)
          this.reply.content = ""
        })
        .catch((error) => {
          this.errors.nonField = error
        })
      }
    }
  },
}
</script>
