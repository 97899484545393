<template>
  <b-taginput
    v-model="selectedVars"
    :data="filteredVars"
    autocomplete
    placeholder="Select variables to compare"
    :allow-new="false"
    :allow-duplicates="false"
    @typing="getFilteredVars"
    @add="varAdded"
    @remove="varAdded"
  >
  </b-taginput>
</template>
<script>
export default {
  name: "VarTagInput",
  props: {
    varOptions: {}
  },
  data() {
    return {
      filteredVars: [],
      selectedVars: []
    }
  },
  methods: {
    varAdded() {
      this.$emit("varAdded", this.selectedVars)
    },
    getFilteredVars(text) {
      this.filteredVars = this.varOptions.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0;
      })
    }
  }
}

</script>
