import { render, staticRenderFns } from "./gold-star-list.vue?vue&type=template&id=7633e258&scoped=true&"
import script from "./gold-star-list.vue?vue&type=script&lang=js&"
export * from "./gold-star-list.vue?vue&type=script&lang=js&"
import style0 from "./gold-star-list.vue?vue&type=style&index=0&id=7633e258&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7633e258",
  null
  
)

export default component.exports