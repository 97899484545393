<template>
  <div>
    <layout>
      <div class="container p-4">
        <div class="columns">
          <div class="column">
            <div class="container full-height">
              <nav>
                <div class="level mb-5">
                  <div class="level-left">
                    <div class="level-item">
                      <h1 class="is-size-3">Wind Files</h1>
                    </div>
                  </div>
                  <div class="level-left">
                    <div class="level-item">
                      <b-button class="is-primary" @click="showWindModal = true">
                        Upload Wind File
                      </b-button>
                    </div>
                  </div>
                </div>
              </nav>
              <WindFile v-for="windfile in windfiles" :windfile="windfile">
              </WindFile>
            </div>
            <AddWindModal :show="showWindModal" @close="showWindModal = false">

            </AddWindModal>
          </div>
        </div>
      </div>
    </layout>
  </div>
</template>

<script>
import layout from "@/views/layouts/Default.vue";
import WindFile from "@/views/wind/WindFile";
import AddWindModal from "@/views/wind/AddWindModal";
import { mapGetters } from "vuex";

export default {
  name: "Wind",
  components: {
    AddWindModal,
    layout,
    WindFile
  },
  data: function() {
    return {
      showWindModal: false
    }
  },
  beforeCreate() {
    this.$store.dispatch("windfiles/getWindfiles");
  },
  computed: {
    ...mapGetters({
        windfiles: "windfiles/getWindfiles",
        user: "auth/user",
      }),
  },
}
</script>

<style scoped>

</style>