<template>
  <layout>
    <div class="columns desktop-container">
      <div class="column is-two-fifths">
        <div class="card">
          <div class="card-content">
            <h3 class="is-size-3">Reset Password</h3>
            <form class="reset-form" @submit.prevent="reset">
              <b-field label="New Password" :message="errors.password" :type="errors.password ? 'is-danger' : null ">
                <b-input v-model="password" type="password" password-reveal></b-input>
              </b-field>
              <b-field label="Confirm New Password" :message="errors.password_confirmation" :type="errors.password_confirmation ? 'is-danger' : null ">
                <b-input v-model="password_confirmation" type="password" password-reveal></b-input>
              </b-field>
              <div class="buttons">
                <b-button
                  :class="{ 'btn-disabled': $v.$invalid }"
                  class="button button-submit btn-info"
                  native-type="submit"
                  value="Change Password">
                    Change Password
                </b-button>
            <router-link class="button button-submit btn-info" to="/login"
              >Back to Login</router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer-links />
  </layout>
</template>

<script>
import card from "@/components/ui/Card"
import layout from "@/views/layouts/Default.vue"

import { required, sameAs, minLength } from "vuelidate/lib/validators"

export default {
  name: "ResetPassword",
  components: {
    card,
    layout,
  },
  props: ["uuid"],
  validations: {
    password: { required, minLength: minLength(3) },
    password_confirmation: { sameAsPassword: sameAs("password") },
  },
  data() {
    return {
      password: null,
      password_confirmation: null,
      errors: {},
    }
  },
  methods: {
    reset() {
      this.errors = {}
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("auth/reset", {
            uuid: this.uuid,
            password: this.password,
          })
          .then(() => {
            this.$router.push({
              name: "login",
            })
          })
      } else {
        if (!this.$v.password.required)
          this.errors.password = "Password required"
        if (!this.$v.password.minLength)
          this.errors.password = "Password must be 3 characters"
        if (!this.$v.password_confirmation.sameAsPassword)
          this.errors.password_confirmation = "Password mismatch"
      }
    },
  },
}
</script>

<style scoped></style>
