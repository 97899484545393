<template>
    <JournalList :journals="journals" :showadd="true"></JournalList>
</template>
<script>
import { mapState, mapGetters} from "vuex"
import JournalList from "@/views/journal/JournalList.vue"
export default {
    name: 'UserJournal',
    components: {JournalList},
    beforeCreate() {
      this.$store.dispatch("journal/fetchJournals", {reset: true, search: ''})
    },
    computed: {
    ...mapState({
      user(state) {
        return state.auth.user
      },
    }),
    ...mapGetters({
      journals: "journal/getJournals",
    }),
  }
}
</script>
