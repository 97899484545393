<template>
  <div v-if="selectionMode !== 'tags'" class="speed-color-box-wrapper">
    <div class="speed-color-box">
      <div class="speed-color-box-inside">
        <div class="speed-color-box-numbers">
          <p
            v-for="(val, index) of tickMarks"
            :key="index"
            v-bind:style="{ top: (tickMarks.length - index - 1) * 25 + '%' }"
          >
            {{ val }}
          </p>
        </div>
        <div class="speed-color-picker"></div>
      </div>
      <p
        style="font-size: 15px; text-align: center; text-transform: uppercase;"
      >
        {{ selectionMode }}
      </p>
      <p style="font-size: 9px; text-align: center; margin-bottom: 8px;">
        Knots
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorBox",
  props: ["selectionMode", "minMaxValues"],
  data() {
    return {
      numIntervals: 4,

    }
  },
  methods: {
    getMaxTicks(max) {
      let tick_marks = []
      return tick_marks
    }
  },
  computed: {
    tickMarks() {
      let tick_marks = []
      let {min, max} = this.minMaxValues
      let interval = Math.ceil(max / this.numIntervals)
      for (let i = 0; i < this.numIntervals + 1; i++) {
        tick_marks.push(i * interval)
      }
      return tick_marks
    },
  },
}
</script>

<style lang="sass">
.speed-color-box-wrapper
  display: flex
  position: absolute
  right: 0
  z-index: 3
  flex-direction: column
  pointer-events: none
  top: 120px
  background: none

  @media (max-width: 767px)
    right: auto
    top: 50px
    left: 10px

  .speed-color-box
    align-self: flex-end
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    margin-left: auto
    margin-right: 16px
    margin-bottom: 16px
    pointer-events: auto
    display: flex
    background: rgb(255,255,255)
    user-select: none
    border-radius: 11px
    flex-direction: column

    .speed-color-box-inside
      display: flex
      margin: 16px

      .speed-color-box-numbers
        position: relative

        p
          right: -8px
          position: absolute
          font-size: 10px
          transform: translate(0, -50%)

          &:first-child
            transform: translate(0, -100%)
          &:last-child
            transform: none

      .speed-color-picker
        background: linear-gradient(0deg, rgb(161, 0, 199) 0%, rgb(50, 79, 212) 20%, rgb(72, 201, 31) 40%, rgb(224, 209, 0) 60%, rgb(255, 23, 0) 80%, rgb(121, 0, 0) 100%)
        width: 24px
        height: 224px
        margin-left: 16px
        border-radius: 8px

        @media (max-width: 767px)
          width: 10px
</style>
