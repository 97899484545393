import api from "@/services/api/teams.js"
import alertHelper from "@/services/helpers/alertHelper"

export const namespaced = true

export const state = {
  team: null,
  teams: [],
}

export const mutations = {
  SET_TEAM(state, team) {
    state.team = team
  },
  SET_TEAMS(state, teams) {
    state.teams = teams
  },
  CLEAR_TEAM(state) {
    state.team = null
  },
}

export const actions = {
  fetchTeam({ commit, dispatch }, params) {
    api
      .getTeam(params.id)
      .then((response) => {
        commit("SET_TEAM", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem fetching team: ${error.response}`,
        })
      })
  },
  fetchTeams({ commit, dispatch }) {
    api
      .getTeams()
      .then((response) => {
        commit("SET_TEAMS", response.data)
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem fetching teams: ${error.response}`,
        })
      })
  },
  updateTeam({ commit, dispatch }, params) {
    api
      .updateTeam(params.id, params.formData)
      .then((response) => {
        commit("SET_TEAM", response.data)
        alertHelper.successNotification(dispatch, "Team Updated")
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem updating the team: ${error.response}`,
        })
      })
  },
  createTeam({ commit, dispatch }, params) {
    api
      .createTeam(params.formData)
      .then((response) => {
        dispatch("fetchTeams")
        alertHelper.successNotification(dispatch, "Team created.")
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem updating the team: ${error.response}`,
        })
      })
  },
  deleteTeam({ commit, dispatch }, params) {
    api
      .deleteTeam(params.id)
      .then((response) => {
        dispatch("fetchTeams")
        alertHelper.successNotification(dispatch, "Team Deleted.")
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem deleting the team: ${error.response}`,
        })
      })
  },
  addMember({ commit, dispatch }, params) {
    api
      .addMember(params.id, params.email)
      .then((response) => {
        commit("SET_TEAM", response.data)
        alertHelper.successNotification(
          dispatch,
          "Team member added successfully."
        )
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem adding the member: ${error.response}`,
        })
      })
  },
  removeMember({ commit, dispatch }, params) {
    api
      .removeMember(params.id, params.email)
      .then((response) => {
        commit("SET_TEAM", response.data)
        alertHelper.successNotification(
          dispatch,
          "Team member removed successfully."
        )
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem removing the member: ${error.response}`,
        })
      })
  },
  updateRole({ commit, dispatch }, params) {
    api
      .updateRole(params.id, params.email, params.role)
      .then((response) => {
        commit("SET_TEAM", response.data)
        alertHelper.successNotification(dispatch, "Team member role updated.")
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem updating the role: ${error.response}`,
        })
      })
  },
  changePermission({ commit, dispatch }, params) {
    api
      .changePermission(params.id, params.permission)
      .then((response) => {
        commit("SET_TEAM", response.data)
        alertHelper.successNotification(
          dispatch,
          "Permissions have been updated."
        )
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem changing permissions: ${error.response}`,
        })
      })
  },
  leaveTeam({ commit, dispatch }, params) {
    api
      .leaveTeam(params.id)
      .then((response) => {
        commit("SET_TEAM", null)
        dispatch("fetchTeams")
        alertHelper.successNotification(
          dispatch,
          "You have left the team successfully"
        )
      })
      .catch((error) => {
        alertHelper.alertNotification(dispatch, error)
        console.log({
          type: "error",
          message: `There was problem leaving the team: ${error.response}`,
        })
      })
  },
  clearTeam({ commit }) {
    commit("CLEAR_TEAM")
  },
}

export const getters = {
  getTeam(state) {
    return state.team
  },
  getTeams(state) {
    return state.teams
  },
}
