<template>
  <div class="windchart mt-5">
    <h1 class="is-size-4">Regatta Results</h1>
    <apexchart type="line" :options="options" :series="series"></apexchart>
    <b-field>
      <b-switch v-model="percentage">
        Display as % of Total Competitors (1st = 100%)
      </b-switch>
    </b-field>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import moment from "moment-timezone"

export default {
  name: "RegattaResults",
  props: ['start', 'end', 'boatclass', 'user'],
  components: {
    'apexchart': VueApexCharts,
  },
  data() {
    return {
      results: [],
      percentage: false
    }
  },
  mounted() {
    this.getResults()
  },
  computed: {
    options() {
      const instance = this
      let max = this.series[0].data.reduce((prev, next) => next.y > prev ? next.y : prev, 1)
      const data = {
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          title: {
            text: instance.percentage ? 'Finish Position (%)' : 'Finish Position (Absolute)'
          },
          reversed: !instance.percentage,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            }
          },
          min: instance.percentage ? 0 : 1,
          max: instance.percentage ? 100 : max
        },
        tooltip: {
          x: {
            show: false
          },
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              const desc = w.config.series[seriesIndex].data[dataPointIndex].description
              let text = ""
              if (instance.percentage) {
                text = `Finish Position: ${value.toFixed(0)}%<br>`
              } else {
                text = `Finish Position: ${value}<br>`
              }
              text += `Regatta: ${desc.name}<br>`
              text += `Start: ${moment(desc.start).format('M/D/YYYY')}<br>`
              text += `End: ${moment(desc.end).format('M/D/YYYY')}`
              return text
            }
          }
        },
        stroke: {
          curve: 'straight',
        },
        markers: {
          fillOpacity: 1,
          showNullDataPoints: true,
        },
        legend: {
          show: false
        },
      }
      return data
    },
    series() {
      const instance = this
      let data = this.results.map(datum => {
        return {
          x: datum.date,
          y: instance.percentage ? this.mapRange(datum.pos, 1, datum.num_competitors, 100, 0) : datum.pos,
          description: {
            name: datum.regatta_name,
            start: datum.regatta_start,
            end: datum.regatta_end
          }
        }
      })
      return [
        {
          name: '',
          data: data,
          type: 'line'
        },
      ]
    }
  },
  methods: {
    getResults() {
      const url = `/reports/results/?start=${this.start}&end=${this.end}&class=${this.boatclass}&user=${this.user}`
      axios.get(url).then(response => {
        this.results = response.data
      })
    },
    // https://stackoverflow.com/a/23202637
    mapRange (number, inMin, inMax, outMin, outMax) {
      return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    }
  },
  watch: {
    start(){
      this.getResults()
    },
    end(){
      this.getResults()
    },
    boatclass(){
      this.getResults()
    },
    user(){
      this.getResults()
    }
  }
}
</script>

<style scoped>

</style>