<template>
  <div class="chart">
    <div>
      <div class="chart-header">
        <div class="y-axios-value">
          {{ displayedMode }}
        </div>
        <div class="time-duration" v-if="duration !== 0">
          Duration: {{ duration }}
        </div>
        <div class="current-position" v-if="currentPosition !== 0">
          Timestamp: {{ currentPosition | moment("timezone", tracks[0].tz, "HH:mm:ss z") }}
        </div>
        <div class="yot-max-values">
          <div v-for="(val, index) in getAggregates" :key="index">
            <div
              v-if="val"
              style="display: flex; align-items: center; margin-right: 10px"
            >
              <div
                class="track-info-card-boat"
                :style="{ backgroundColor: colors[index] }"
              >
                <img
                  src="../../assets/svg/yot.svg"
                  alt="svg"
                  width="30"
                  height="30"
                />
              </div>
              <span>{{ val }}</span>
            </div>
          </div>
          <ul class="math-type">
            <li
              v-for="(val, index) in mathType"
              :key="index"
              @click="changeMathType(index)"
              :class="{
                'selected-math-type': index === selectedMathType,
              }"
            >
              {{ val }}
            </li>
          </ul>
        </div>
      </div>
      <div class="chart-box" @mouseleave="mouseLeft">
        <apexchart
          v-if="tracks && tracks.length > 0"
          :options="options"
          width="100%"
          height="150"
          :series="getSeries"
          ref="chart"
        />
        <div
          v-if="dragging"
          class="cancel-zooming"
          @click="cancelZoom"
        >
          ←
        </div>
        <Golden
          v-if="dragging && !isJournal && (track_id || race_id)"
          :track_id="track_id"
          :race_id="race_id"
          ref="golden"
          :start="dragStart"
          :end="dragEnd"
          @toggleGoldStarSection="toggleGoldStarSection"
          @goldStarSaved="goldStarSaved"
        />
        <div v-if="isJournal" class="removesegment" @click="removeSegment">
          <b-icon icon="trash" class="is-warning"> </b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Golden from "../Golden";
import { COLORS } from "@/constants";
import { ANIMATION_INTERVAL_MS } from "@/mixins/MapMixin";
import VueApexCharts from "vue-apexcharts";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  props: [
    "tracks",
    "currentTime",
    "pause",
    "selectionMode",
    "lastDataMode",
    "colors",
    "units",
    "speed",
    "track_id",
    "race_id",
    "hoverTime",
    "trackJournal",
    "indicators",
    "modes",
    "changeMathType",
    "mathType",
    "selectedMathType",
    "getAggregates",
    "highlightedGoldStar",
    "clickedGoldStar",
  ],
  components: {
    Golden,
    apexchart: VueApexCharts,
  },
  data() {
    const instance = this;
    return {
      options: {
        chart: {
          width: "100%",
          height: 200,
          events: {
            click: this.mouseClick,
            mouseMove: this.mouseHover,
            zoomed: this.dragComplete,
            mounted: function (chartContext, config) {
              instance.chartReady = true;
              console.log('Chart mounted event fired');
              if (instance.pendingCurrentTime !== null) {
                console.log("Adding pending current time annotation:", instance.pendingCurrentTime);
                instance.addCurrentTimeAnnotation(instance.pendingCurrentTime);
                instance.pendingCurrentTime = null;
              }
            },
          },
          type: "line",
          defaultCursor: "default",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
          },
          parentHeightOffset: 0,
          selection: {
            enabled: false,
            type: "x",
          },
        },
        colors: this.colors,
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        markers: {
          size: 0,
          strokeWidth: 0,
          hover: {
            size: 0,
            sizeOffset: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
          crosshairs: {
            show: false,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function (value, timestamp) {
              let timezone = "UTC";
              if (
                instance.tracks &&
                instance.tracks[0] &&
                instance.tracks[0].tz
              ) {
                timezone = instance.tracks[0].tz;
              }
              return moment(timestamp)
                .tz(timezone)
                .format("HH:mm:ss");
            },
          },
          crosshairs: {
            show: true,
            width: 1,
            position: "back",
            opacity: 0.9,
            stroke: {
              color: "#008FFB",
              width: 1,
              dashArray: 0,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (val) {
              return val;
            },
            crosshairs: {
              show: false,
            },
          },
          x: {
            show: false,
          },
          custom: () => {
            return "<div></div>";
          },
          marker: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            distributed: false,
            dataLabels: {
              position: "top",
            },
          },
        },
      },
      width: 0,
      selectionUpdating: false,
      lastAnimationTime: null,
      startT: 0,
      endT: 0,
      series: [],
      displayedMode: "",
      duration: 0,
      currentPosition: 0,
      movementTime: 0,
      dragStart: 0,
      dragEnd: 0,
      isJournal: false,
      isShowOpacityLine: false,
      zoomedSegment: null,
      localTracks: null,
      zoomStack: [],
      chartReady: false,
      pendingCurrentTime: null,
    };
  },
  created() {
    this.localTracks = _.cloneDeep(this.tracks);
  },
  computed: {
    dragging() {
      return this.zoomStack.length !== 0;
    },
    getSeries() {
      const tracks = this.tracks;
      if (!tracks || tracks.length === 0) {
        return [];
      }
      if (!this.localTracks || this.localTracks.length === 0) {
        this.localTracks = _.cloneDeep(tracks);
      }
      let seriesMode = this.selectionMode;
      let noSeries =
        this.selectionMode === "none" || this.selectionMode === "tags";
      if (noSeries) {
        seriesMode = this.lastDataMode;
      }
      this.series = [];
      for (
        let trackIndex = 0;
        trackIndex < this.localTracks.length;
        trackIndex++
      ) {
        const track = this.localTracks[trackIndex];
        let selectionTrackpoints = track.trackpoints;
        if (this.dragging) {
          selectionTrackpoints = selectionTrackpoints.filter((trackpoint) => {
            const time = new Date(trackpoint.time).getTime();
            return time >= this.dragStart && time <= this.dragEnd;
          });
        }
        let seriesData = selectionTrackpoints.map((trackpoint) => {
          let dataPoint = {
            x: new Date(trackpoint["time"]).getTime(), // Convert to timestamp
            y: trackpoint[seriesMode],
          };
          return dataPoint;
        });
        const series = {
          name: `track_${trackIndex}`,
          type: "line",
          data: seriesData,
        };
        this.series.push(series);
      }
      this.displayedMode = seriesMode;
      return this.series;
    },
    journal() {
      if (this.trackJournal) {
        const journal = this.trackJournal.filter((j) => {
          if (!this.zoomedSegment && !this.dragging) {
            return j.jType === "star";
          } else {
            return j.content === this.zoomedSegment;
          }
        });
        return journal;
      }
    },
  },
  mounted() {
    this.$emit("isLoading", false);
    // No need to set this.chartReady here; it's handled by the chart's mounted event
  },
  watch: {
    pause(val) {
      if (!val) {
        this.moveLine();
      }
    },
    currentTime(val) {
      this.currentPosition = new Date(val);
      console.log("currentTime watcher called with val:", val);
      this.addCurrentTimeAnnotation(val);
    },
    highlightedGoldStar(data) {
      if (this.chartReady && this.$refs.chart) {
        this.$refs.chart.removeAnnotation("goldStarRange");
        if (!data) {
          return;
        }
        const { start, end, text } = data;
        this.$refs.chart.addXaxisAnnotation({
          id: "goldStarRange",
          x: start,
          x2: end,
          fillColor: "#008ffb",
          borderColor: "#24292e",
          strokeDashArray: 0,
          opacity: 0.3,
          label: {
            text,
            textAnchor: "left",
            orientation: "horizontal",
          },
        });
      }
    },
    clickedGoldStar(journal) {
      if (!journal) {
        return;
      }
      this.zoomInJournal(journal);
    },
    hoverTime(val) {
      if (!val) {
        return;
      }
      // No need to add x-axis annotation
    },
    movementTime(val) {
      this.currentPosition = new Date(val);
    },
  },
  methods: {
    mouseLeft() {
      this.isShowOpacityLine = false;
      this.movementTime = this.currentTime + this.startT;
      this.$emit("mouseLeft");
    },
    removeSegment() {
      if (confirm("Delete journal segment?")) {
        const journalToDelete = this.trackJournal.find(
          (j) => j.content === this.zoomedSegment
        );
        this.$store.dispatch("journal/deleteJournal", journalToDelete.id);
        this.cancelZoom();
      }
    },
    moveLine(timestamp) {
      if (this.pause) {
        this.lastAnimationTime = null;
        return;
      }
      let animationInterval = ANIMATION_INTERVAL_MS;
      if (!this.lastAnimationTime) {
        this.lastAnimationTime = timestamp;
      } else {
        animationInterval = timestamp - this.lastAnimationTime;
      }
      const animationIntervalDelta = ANIMATION_INTERVAL_MS - animationInterval;
      if (animationIntervalDelta > 1) {
        requestAnimationFrame(this.moveLine);
        return;
      }
      this.lastAnimationTime = timestamp;
      const currentTime = this.currentTime + animationInterval * this.speed;
      this.setTime(currentTime);
      requestAnimationFrame(this.moveLine);
    },
    mouseClick(event, chartContext, config) {
      if (this.selectionUpdating) {
        this.selectionUpdating = false;
        return;
      }
      let { seriesIndex, dataPointIndex } = config;
      if (seriesIndex === -1 || dataPointIndex === -1) {
        return;
      }
      let dataPoint = this.getSeries[seriesIndex].data[dataPointIndex];
      if (!dataPoint) {
        return;
      }
      const clickedTime = dataPoint.x; // Use timestamp directly
      this.setTime(clickedTime);
      this.$emit("setClickedTime", clickedTime);
    },
    mouseHover(event, chartContext, config) {
      let { seriesIndex, dataPointIndex } = config;
      if (seriesIndex === -1 || dataPointIndex === -1) {
        return;
      }
      let dataPoint = this.getSeries[seriesIndex].data[dataPointIndex];
      if (!dataPoint) {
        return;
      }
      this.isShowOpacityLine = true;
      this.movementTime = dataPoint.x; // Use timestamp directly
      this.$emit("setOpacityTime", this.movementTime);
    },
    setTime(currentTime) {
      this.$emit("setCurrentTime", currentTime);
    },
    cancelZoom() {
      this.zoomStack.pop();
      if (this.zoomStack.length > 0) {
        let obj = this.zoomStack.slice(-1)[0];

        if (obj.zoomedSegment) {
          this.$emit("goldStarClicked", obj.zoomedSegment);
          this.zoomStack.pop();
        } else {
          this.$emit("goldStarClicked", null);
          this.isJournal = obj.isJournal;
          this.duration = obj.duration;
          this.selectionUpdating = true;
          this.$emit("selectSection", { start: obj.start, end: obj.end });
          this.dragStart = obj.dragStart;
          this.dragEnd = obj.dragEnd;
          this.zoomedSegment = obj.zoomedSegment;
        }
      } else {
        if (this.chartReady && this.$refs.chart) {
          this.$refs.chart.resetSeries();
        }
        this.$emit("selectSection", null);
        this.$emit("goldStarClicked", null);
        this.duration = 0;
        this.toggleGoldStarSection();
        this.isJournal = false;
        this.zoomedSegment = null;
      }
    },
    zoomInJournal(journal) {
      const start = new Date(journal.s_point).getTime();
      const end = new Date(journal.e_point).getTime();
      if (this.chartReady && this.$refs.chart) {
        this.$refs.chart.zoomX(start, end);
      }
      let date = new Date(0);
      date.setSeconds((end - start) / 1000);
      this.duration = date.toISOString().substr(11, 8);
      this.dragStart = start;
      this.dragEnd = end;
      this.isJournal = true;
      this.zoomedSegment = journal.content;

      // Mark name in zoom stack so we know to show gold star label
      let index = this.zoomStack.findIndex(
        (obj) => obj.start === start && obj.end === end
      );
      if (index !== -1) {
        this.zoomStack[index].zoomedSegment = journal;
      }
    },
    dragComplete(event, axes) {
      this.isJournal = false;
      const start = axes.xaxis.min;
      const end = axes.xaxis.max;
      if (!start || !end) {
        console.log("dragComplete invalid range", { start, end });
        return;
      }
      let date = new Date(0);
      date.setSeconds((end - start) / 1000);
      this.duration = date.toISOString().substr(11, 8);
      this.selectionUpdating = true;
      this.$emit("selectSection", {start, end});
      this.dragStart = start;
      this.dragEnd = end;
      this.zoomStack.push({
        start,
        end,
        duration: this.duration,
        dragStart: this.dragStart,
        dragEnd: this.dragEnd,
        isJournal: this.isJournal,
        zoomedSegment: null,
      });
    },
    toggleGoldStarSection() {
      this.$emit("toggleIsShowGoldStar");
    },
    goldStarSaved(journal) {
      this.$emit("goldStarSaved", journal);
    },
    addCurrentTimeAnnotation(val) {
      if (this.chartReady && this.$refs.chart) {
        console.log("Adding x-axis annotation at:", val);
        this.$refs.chart.removeAnnotation("x_0");
        this.$refs.chart.addXaxisAnnotation(
            {
              id: "x_0",
              x: val,
              strokeDashArray: 0,
              borderColor: "#008FFB",
              // No label to display only the thin vertical line
            },
            true
        );
      } else {
        console.log("Chart not ready or chart ref not available. Storing pending current time.");
        this.pendingCurrentTime = val; // Store the value
      }
    },
  },
};
</script>

<style lang="sass">
.cancel-zooming
  font-size: 16px
  position: absolute
  top: 40px
  right: 10px
  cursor: pointer
  color: black
  border: 1px solid black
  padding: 0px 7px
  border-radius: 50%
  z-index: 1

.chart-line
  width: 1.5px
  height: 100%
  background-color: grey
  position: absolute
  top: -13px
  transform: scaleY(0.815)
  z-index: 6

.chart-line-value
  position: absolute
  top: -15px
  color: grey
  font-weight: 500

.chart-line-opacity
  width: 1.5px
  height: 100%
  background-color: grey
  position: absolute
  opacity: 0.3
  top: -13px
  transform: scaleY(0.815)
  pointer-events: none
  z-index: 7

.chart-line-opacity-value
  position: absolute
  top: -15px
  color: grey
  opacity: 0.5

#container_UserInteraction
  opacity: 0

.time-duration
  color: black
  font-weight: bold

.current-position
  color: black
  font-weight: bold

/* Adjust cursor and hide unwanted elements */
.apexcharts-cursor-pointer
  cursor: default !important

.apexcharts-marker,
.apexcharts-tooltip
  display: none !important

/* Ensure crosshairs are styled correctly */
.apexcharts-crosshairs.apexcharts-active
  stroke: #008FFB !important

/* Hide y-axis crosshair */
.apexcharts-ycrosshairs
  opacity: 0 !important
</style>

<style lang="sass" scoped>
.map-player-container
  .chart
    width: 100%

    .chart-header
      .yot-max-values
        .math-type
          margin-right: 10px

.removesegment
  position: absolute
  top: 40px
  left: 40px
  width: 85%
  cursor: pointer
</style>
