<template>
  <div class="">
    <div class= "card" v-for="(metric, indx) in metrics" :key="indx" :index="indx">
      <div class="hooper-slide"
           v-on:click="clicked(metric)"
           v-on:mouseover="straightLineHover(metric)"
           v-on:mouseleave="clearHover()"
           :style="slideClicked(metric) ? {'background': '#e1e1e1'} : {} "
           >
        <div class="hooper-slide-image">
          <img v-if="metric.preview" :src="metric.preview" alt="" />
          <img v-else src="/osearch.jpg" alt="" />
        </div>

        <div class="hooper-slide-description">
          <div class="segment-name">Segment: {{ metric.segment_name }}</div>
          <div v-if="metric.label != 'S'">
            <div v-if="metric.time">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              Time: {{ metric.time.substr(11, 8) }}
            </div>
            <div v-if="metric.loss_80">
              <i class="fa fa-repeat" aria-hidden="true"></i>
              Loss: {{ metric.loss_80 }}
            </div>
            <div v-if="metric.entry_speed">
              <i class="fa fa-hourglass-start" aria-hidden="true"></i>
              Entry: {{ metric.entry_speed }}
            </div>
            <div v-if="metric.exit_speed">
              <i class="fa fa-hourglass-end" aria-hidden="true"></i>
              Exit: {{ metric.exit_speed }}
            </div>
          </div>

          <div v-else>
            <div v-if="metric.start_time">
              <i class="fa fa-hourglass-start" aria-hidden="true"></i>
              Start: {{ metric.start_time | moment("timezone", track.tz, "h:mm:ss A") }}
            </div>
            <div v-if="metric.end_time">
              <i class="fa fa-hourglass-end" aria-hidden="true"></i>
              End: {{ metric.end_time | moment("timezone", track.tz, "h:mm:ss A") }}
            </div>
            <div v-if="userTagsForSegment(metric.segment_name)" style="display: block; max-width: 15em; margin-top: 0.5em">
              <b-taglist>
                <div v-for="tag in userTagsForSegment(metric.segment_name)" class="control">
                  <b-tag :type="`is-primary`"
                         aria-close-label="Close tag"
                         closable
                         @close.stop="tagRemoved(segmentForName(metric.segment_name), tag.id)"
                  >
                    {{ tag.value }}
                  </b-tag>
                </div>
                <b-dropdown @click.native.stop aria-role="list" append-to-body scrollable max-height="200" trap-focus expanded  style="width: 1.5em; margin-bottom: 6px">
                  <template #trigger>
                    <b-icon class="is-small ic-button"
                            icon="plus"
                            role="button"
                    />
                  </template>
                  <b-dropdown-item custom aria-role="listitem">
                    <b-input v-model="tagToCreate"
                             placeholder="Create and add tag"
                             @keyup.native.enter="createAndAddTag(segmentForName(metric.segment_name))"
                             @keydown.native.stop=""
                    />
                  </b-dropdown-item>
                  <b-dropdown-item v-for="userTag in userTags.filter(x => userTagsForSegment(metric.segment_name).filter(y => y.id === x.id).length === 0 && x.visible)"
                                   aria-role="listitem"
                                   style="text-align: left"
                                   @click="addTagToSegment(segmentForName(metric.segment_name), userTag)">
                    {{ userTag.value }}
                    <b-icon class="is-small ic-button"
                            icon="eye-slash"
                            role="button"
                            style="margin-left: 0.5em"
                            @click.native.stop="hideTag(userTag)"></b-icon>
                  </b-dropdown-item>
                </b-dropdown>
              </b-taglist>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: ["metrics", "clickedSegments", "track", "userTags"],
  data: function() {
    return {
      showTagModal: false,
      segmentToTag: null,
      tagToCreate: null
    }
  },
  methods: {
    createAndAddTag(segment) {
      this.$emit('createAndAddTag', {segment: segment, tagValue: this.tagToCreate});
      this.tagToCreate = null;
    },
    addTagToSegment(segment, tag) {
      this.$emit('addTagToSegment', {segment: segment, tag: tag});
    },
    tagRemoved(segment, tagId) {
      this.$emit('tagRemoved', {segment:segment, tagId:tagId});
    },
    hideTag(tag) {
      this.$emit('hideTag', tag);
    },
    segmentForName(segmentName) {
      return this.track.segments.find(x => x.seg_name === segmentName);
    },
    userTagsForSegment(segmentName) {
      const seg = this.segmentForName(segmentName)
      if (seg.tag === 'S') {
        return seg.user_tags;
      }
      return null;
    },
    slideClicked(metric) {
      if (metric.label !== 'S') {
        return false
      }
      return this.clickedSegments.includes(metric.segment_name)
    },
    clicked(metric) {
      if (metric.label !== 'S') {
        // use selected_start_time if available
        let start_time = metric.selected_start_time
          ? metric.selected_start_time
          : metric.start_time;
        this.$emit("moveToStartpoint", new Date(start_time).getTime());
      } else {
        let id = metric.segment_name
        this.$emit("toggleSegmentClicked", id);
      }
    },
    straightLineHover(metric) {
      if (metric.label === 'S') {
        // use selected_start_time if available
        let start_time = metric.selected_start_time
          ? metric.selected_start_time
          : metric.start_time;
        let end_time = metric.selected_end_time ? metric.selected_start_time : metric.end_time;
        this.$emit("straightLineHover", {start: new Date(start_time).getTime(), end: new Date(end_time).getTime()});
      }
    },
    clearHover() {
      /*if (metric.label === 'S') {
        this.$emit("clearHover")
      }*/
    }
  },
};
</script>
<style>
  .dropdown:first-child {
    margin-left: -4px;
  }
</style>
